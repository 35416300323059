/*!
 * Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa, .fa-brands, .fa-classic, .fa-duotone, .fa-light, .fa-regular, .fa-sharp, .fa-sharp-solid, .fa-solid, .fa-thin, .fab, .fad, .fal, .far, .fas, .fasl, .fasr, .fass, .fat {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fa-classic, .fa-light, .fa-regular, .fa-solid, .fa-thin, .fal, .far, .fas, .fat {
  font-family: "Font Awesome 6 Pro";
}

.fa-brands, .fab {
  font-family: "Font Awesome 6 Brands";
}

.fa-classic.fa-duotone, .fa-duotone, .fad {
  font-family: "Font Awesome 6 Duotone";
}

.fa-sharp, .fasl, .fasr, .fass {
  font-family: "Font Awesome 6 Sharp";
}

.fa-sharp, .fass {
  font-weight: 900;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .08333em;
}

.fa-sm {
  vertical-align: .05357em;
  font-size: .875em;
  line-height: .07143em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .04167em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-radius: var(--fa-border-radius, .1em);
  border: var(--fa-border-width, .08em) var(--fa-border-style, solid) var(--fa-border-color, #eee);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade, .fa-fade {
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-shake, .fa-spin {
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
}

.fa-spin {
  animation-name: fa-spin;
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-beat-fade, .fa-bounce, .fa-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    -webkit-transform: scale(1) translateY(var(--fa-bounce-rebound, -.125em));
    transform: scale(1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  to {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, to {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, to {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  z-index: var(--fa-stack-z-index, auto);
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0:before {
  content: "0";
}

.fa-1:before {
  content: "1";
}

.fa-2:before {
  content: "2";
}

.fa-3:before {
  content: "3";
}

.fa-4:before {
  content: "4";
}

.fa-5:before {
  content: "5";
}

.fa-6:before {
  content: "6";
}

.fa-7:before {
  content: "7";
}

.fa-8:before {
  content: "8";
}

.fa-9:before {
  content: "9";
}

.fa-fill-drip:before {
  content: "";
}

.fa-arrows-to-circle:before {
  content: "";
}

.fa-chevron-circle-right:before, .fa-circle-chevron-right:before {
  content: "";
}

.fa-wagon-covered:before {
  content: "";
}

.fa-line-height:before {
  content: "";
}

.fa-bagel:before {
  content: "";
}

.fa-transporter-7:before {
  content: "";
}

.fa-at:before {
  content: "@";
}

.fa-rectangles-mixed:before {
  content: "";
}

.fa-phone-arrow-up-right:before, .fa-phone-arrow-up:before, .fa-phone-outgoing:before {
  content: "";
}

.fa-trash-alt:before, .fa-trash-can:before {
  content: "";
}

.fa-circle-l:before {
  content: "";
}

.fa-head-side-goggles:before, .fa-head-vr:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-user-times:before, .fa-user-xmark:before {
  content: "";
}

.fa-face-hand-yawn:before {
  content: "";
}

.fa-gauge-simple-min:before, .fa-tachometer-slowest:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-coffin:before {
  content: "";
}

.fa-comment-alt:before, .fa-message:before {
  content: "";
}

.fa-bowl-salad:before, .fa-salad:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-robot-astromech:before {
  content: "";
}

.fa-ring-diamond:before {
  content: "";
}

.fa-fondue-pot:before {
  content: "";
}

.fa-theta:before {
  content: "";
}

.fa-face-hand-peeking:before {
  content: "";
}

.fa-square-user:before {
  content: "";
}

.fa-compress-alt:before, .fa-down-left-and-up-right-to-center:before {
  content: "";
}

.fa-explosion:before {
  content: "";
}

.fa-file-alt:before, .fa-file-lines:before, .fa-file-text:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-building-un:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-tire-pressure-warning:before {
  content: "";
}

.fa-wifi-2:before, .fa-wifi-fair:before {
  content: "";
}

.fa-calendar-alt:before, .fa-calendar-days:before {
  content: "";
}

.fa-mp3-player:before {
  content: "";
}

.fa-anchor-circle-check:before {
  content: "";
}

.fa-tally-4:before {
  content: "";
}

.fa-rectangle-history:before {
  content: "";
}

.fa-building-circle-arrow-right:before {
  content: "";
}

.fa-volleyball-ball:before, .fa-volleyball:before {
  content: "";
}

.fa-sun-haze:before {
  content: "";
}

.fa-text-size:before {
  content: "";
}

.fa-ufo:before {
  content: "";
}

.fa-fork:before, .fa-utensil-fork:before {
  content: "";
}

.fa-arrows-up-to-line:before {
  content: "";
}

.fa-mobile-signal:before {
  content: "";
}

.fa-barcode-scan:before {
  content: "";
}

.fa-sort-desc:before, .fa-sort-down:before {
  content: "";
}

.fa-folder-arrow-down:before, .fa-folder-download:before {
  content: "";
}

.fa-circle-minus:before, .fa-minus-circle:before {
  content: "";
}

.fa-face-icicles:before {
  content: "";
}

.fa-shovel:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-films:before {
  content: "";
}

.fa-right-from-bracket:before, .fa-sign-out-alt:before {
  content: "";
}

.fa-face-glasses:before {
  content: "";
}

.fa-nfc:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-heart-music-camera-bolt:before, .fa-icons:before {
  content: "";
}

.fa-microphone-alt-slash:before, .fa-microphone-lines-slash:before {
  content: "";
}

.fa-closed-captioning-slash:before {
  content: "";
}

.fa-calculator-alt:before, .fa-calculator-simple:before {
  content: "";
}

.fa-bridge-circle-check:before {
  content: "";
}

.fa-sliders-up:before, .fa-sliders-v:before {
  content: "";
}

.fa-location-minus:before, .fa-map-marker-minus:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-ski-boot:before {
  content: "";
}

.fa-rectangle-sd:before, .fa-standard-definition:before {
  content: "";
}

.fa-h1:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-magnifying-glass-location:before, .fa-search-location:before {
  content: "";
}

.fa-message-bot:before {
  content: "";
}

.fa-forward-step:before, .fa-step-forward:before {
  content: "";
}

.fa-face-smile-beam:before, .fa-smile-beam:before {
  content: "";
}

.fa-light-ceiling:before {
  content: "";
}

.fa-comment-alt-exclamation:before, .fa-message-exclamation:before {
  content: "";
}

.fa-bowl-scoop:before, .fa-bowl-shaved-ice:before {
  content: "";
}

.fa-square-x:before {
  content: "";
}

.fa-utility-pole-double:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-chevron-double-up:before, .fa-chevrons-up:before {
  content: "";
}

.fa-football-ball:before, .fa-football:before {
  content: "";
}

.fa-user-vneck:before {
  content: "";
}

.fa-school-circle-exclamation:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-angle-double-down:before, .fa-angles-down:before {
  content: "";
}

.fa-users-rectangle:before {
  content: "";
}

.fa-people-roof:before {
  content: "";
}

.fa-arrow-square-right:before, .fa-square-arrow-right:before {
  content: "";
}

.fa-location-plus:before, .fa-map-marker-plus:before {
  content: "";
}

.fa-lightbulb-exclamation-on:before {
  content: "";
}

.fa-people-line:before {
  content: "";
}

.fa-beer-mug-empty:before, .fa-beer:before {
  content: "";
}

.fa-crate-empty:before {
  content: "";
}

.fa-diagram-predecessor:before {
  content: "";
}

.fa-transporter:before {
  content: "";
}

.fa-calendar-circle-user:before {
  content: "";
}

.fa-arrow-up-long:before, .fa-long-arrow-up:before {
  content: "";
}

.fa-person-carry-box:before, .fa-person-carry:before {
  content: "";
}

.fa-burn:before, .fa-fire-flame-simple:before {
  content: "";
}

.fa-male:before, .fa-person:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-union:before {
  content: "";
}

.fa-chevron-double-left:before, .fa-chevrons-left:before {
  content: "";
}

.fa-circle-heart:before, .fa-heart-circle:before {
  content: "";
}

.fa-truck-plane:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-bring-forward:before {
  content: "";
}

.fa-square-p:before {
  content: "";
}

.fa-face-grin-stars:before, .fa-grin-stars:before {
  content: "";
}

.fa-sigma:before {
  content: "";
}

.fa-camera-movie:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-clarinet:before {
  content: "";
}

.fa-truck-flatbed:before {
  content: "";
}

.fa-pastafarianism:before, .fa-spaghetti-monster-flying:before {
  content: "";
}

.fa-arrow-down-up-across-line:before {
  content: "";
}

.fa-leaf-heart:before {
  content: "";
}

.fa-house-building:before {
  content: "";
}

.fa-cheese-swiss:before {
  content: "";
}

.fa-spoon:before, .fa-utensil-spoon:before {
  content: "";
}

.fa-jar-wheat:before {
  content: "";
}

.fa-envelopes-bulk:before, .fa-mail-bulk:before {
  content: "";
}

.fa-file-circle-exclamation:before {
  content: "";
}

.fa-bow-arrow:before {
  content: "";
}

.fa-cart-xmark:before {
  content: "";
}

.fa-hexagon-xmark:before, .fa-times-hexagon:before, .fa-xmark-hexagon:before {
  content: "";
}

.fa-circle-h:before, .fa-hospital-symbol:before {
  content: "";
}

.fa-merge:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-cart-minus:before {
  content: "";
}

.fa-address-book:before, .fa-contact-book:before {
  content: "";
}

.fa-pan-frying:before {
  content: "";
}

.fa-grid-3:before, .fa-grid:before {
  content: "";
}

.fa-football-helmet:before {
  content: "";
}

.fa-hand-love:before {
  content: "";
}

.fa-trees:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-page:before {
  content: "";
}

.fa-k:before {
  content: "K";
}

.fa-diagram-previous:before {
  content: "";
}

.fa-gauge-min:before, .fa-tachometer-alt-slowest:before {
  content: "";
}

.fa-folder-grid:before {
  content: "";
}

.fa-eggplant:before {
  content: "";
}

.fa-ram:before {
  content: "";
}

.fa-landmark-flag:before {
  content: "";
}

.fa-lips:before {
  content: "";
}

.fa-pencil-alt:before, .fa-pencil:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-file-clipboard:before, .fa-paste:before {
  content: "";
}

.fa-desktop-arrow-down:before {
  content: "";
}

.fa-code-pull-request:before {
  content: "";
}

.fa-pumpkin:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-pen-field:before {
  content: "";
}

.fa-blueberries:before {
  content: "";
}

.fa-truck-loading:before, .fa-truck-ramp-box:before {
  content: "";
}

.fa-note:before {
  content: "";
}

.fa-arrow-down-to-square:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-cloud-xmark:before {
  content: "";
}

.fa-vial-virus:before {
  content: "";
}

.fa-book-alt:before, .fa-book-blank:before {
  content: "";
}

.fa-golf-flag-hole:before {
  content: "";
}

.fa-comment-alt-arrow-down:before, .fa-message-arrow-down:before {
  content: "";
}

.fa-face-unamused:before {
  content: "";
}

.fa-sheet-plastic:before {
  content: "";
}

.fa-circle-9:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-pencil-slash:before {
  content: "";
}

.fa-bowling-pins:before {
  content: "";
}

.fa-person-arrow-up-from-line:before {
  content: "";
}

.fa-down-right:before {
  content: "";
}

.fa-scroll-torah:before, .fa-torah:before {
  content: "";
}

.fa-webhook:before {
  content: "";
}

.fa-blinds-open:before {
  content: "";
}

.fa-fence:before {
  content: "";
}

.fa-arrow-alt-up:before, .fa-up:before {
  content: "";
}

.fa-broom-ball:before, .fa-quidditch-broom-ball:before, .fa-quidditch:before {
  content: "";
}

.fa-drumstick:before {
  content: "";
}

.fa-square-v:before {
  content: "";
}

.fa-face-awesome:before, .fa-gave-dandy:before {
  content: "";
}

.fa-dial-off:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-face-smile-horns:before {
  content: "";
}

.fa-archive:before, .fa-box-archive:before {
  content: "";
}

.fa-grapes:before {
  content: "";
}

.fa-person-drowning:before {
  content: "";
}

.fa-dial-max:before {
  content: "";
}

.fa-circle-m:before {
  content: "";
}

.fa-calendar-image:before {
  content: "";
}

.fa-caret-circle-down:before, .fa-circle-caret-down:before {
  content: "";
}

.fa-arrow-down-9-1:before, .fa-sort-numeric-desc:before, .fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-face-grin-tongue-squint:before, .fa-grin-tongue-squint:before {
  content: "";
}

.fa-shish-kebab:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-alarm-snooze:before {
  content: "";
}

.fa-scarecrow:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-gift-card:before {
  content: "";
}

.fa-w:before {
  content: "W";
}

.fa-code-pull-request-draft:before {
  content: "";
}

.fa-square-b:before {
  content: "";
}

.fa-elephant:before {
  content: "";
}

.fa-earth-africa:before, .fa-globe-africa:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-tablet-alt:before, .fa-tablet-screen-button:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-message-question:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-trowel-bricks:before {
  content: "";
}

.fa-square-3:before {
  content: "";
}

.fa-face-flushed:before, .fa-flushed:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-microwave:before {
  content: "";
}

.fa-chf-sign:before {
  content: "";
}

.fa-tent-arrow-left-right:before {
  content: "";
}

.fa-cart-circle-arrow-up:before {
  content: "";
}

.fa-trash-clock:before {
  content: "";
}

.fa-gavel:before, .fa-legal:before {
  content: "";
}

.fa-sprinkler-ceiling:before {
  content: "";
}

.fa-browsers:before {
  content: "";
}

.fa-trillium:before {
  content: "";
}

.fa-music-slash:before {
  content: "";
}

.fa-truck-ramp:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-circle-c:before {
  content: "";
}

.fa-star-christmas:before {
  content: "";
}

.fa-chart-bullet:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-tree-christmas:before {
  content: "";
}

.fa-tire-flat:before {
  content: "";
}

.fa-sunglasses:before {
  content: "";
}

.fa-badge:before {
  content: "";
}

.fa-comment-alt-edit:before, .fa-message-edit:before, .fa-message-pen:before {
  content: "";
}

.fa-bell-concierge:before, .fa-concierge-bell:before {
  content: "";
}

.fa-pen-ruler:before, .fa-pencil-ruler:before {
  content: "";
}

.fa-arrow-progress:before {
  content: "";
}

.fa-chess-rook-alt:before, .fa-chess-rook-piece:before {
  content: "";
}

.fa-square-root:before {
  content: "";
}

.fa-album-collection-circle-plus:before {
  content: "";
}

.fa-people-arrows-left-right:before, .fa-people-arrows:before {
  content: "";
}

.fa-face-angry-horns:before {
  content: "";
}

.fa-mars-and-venus-burst:before {
  content: "";
}

.fa-tombstone:before {
  content: "";
}

.fa-caret-square-right:before, .fa-square-caret-right:before {
  content: "";
}

.fa-cut:before, .fa-scissors:before {
  content: "";
}

.fa-list-music:before {
  content: "";
}

.fa-sun-plant-wilt:before {
  content: "";
}

.fa-toilets-portable:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-mustache:before {
  content: "";
}

.fa-hyphen:before {
  content: "-";
}

.fa-table:before {
  content: "";
}

.fa-user-chef:before {
  content: "";
}

.fa-comment-alt-image:before, .fa-message-image:before {
  content: "";
}

.fa-users-medical:before {
  content: "";
}

.fa-sensor-alert:before, .fa-sensor-triangle-exclamation:before {
  content: "";
}

.fa-magnifying-glass-arrow-right:before {
  content: "";
}

.fa-digital-tachograph:before, .fa-tachograph-digital:before {
  content: "";
}

.fa-face-mask:before {
  content: "";
}

.fa-pickleball:before {
  content: "";
}

.fa-star-sharp-half:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-clover:before {
  content: "";
}

.fa-meat:before {
  content: "";
}

.fa-mail-reply:before, .fa-reply:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-empty-set:before {
  content: "";
}

.fa-house-fire:before {
  content: "";
}

.fa-minus-square:before, .fa-square-minus:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-bird:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-caret-square-down:before, .fa-square-caret-down:before {
  content: "";
}

.fa-heart-half-alt:before, .fa-heart-half-stroke:before {
  content: "";
}

.fa-file-circle-question:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-joystick:before {
  content: "";
}

.fa-grill-fire:before {
  content: "";
}

.fa-rectangle-vertical-history:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-bars:before, .fa-navicon:before {
  content: "";
}

.fa-keyboard-left:before {
  content: "";
}

.fa-people-group:before {
  content: "";
}

.fa-hourglass-3:before, .fa-hourglass-end:before {
  content: "";
}

.fa-heart-broken:before, .fa-heart-crack:before {
  content: "";
}

.fa-face-beam-hand-over-mouth:before {
  content: "";
}

.fa-droplet-percent:before, .fa-humidity:before {
  content: "";
}

.fa-external-link-square-alt:before, .fa-square-up-right:before {
  content: "";
}

.fa-face-kiss-beam:before, .fa-kiss-beam:before {
  content: "";
}

.fa-corn:before {
  content: "";
}

.fa-roller-coaster:before {
  content: "";
}

.fa-photo-film-music:before {
  content: "";
}

.fa-radar:before {
  content: "";
}

.fa-sickle:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-coconut:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-shield-cross:before {
  content: "";
}

.fa-cassette-tape:before {
  content: "";
}

.fa-square-terminal:before {
  content: "";
}

.fa-people-robbery:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-comment-middle:before {
  content: "";
}

.fa-trash-can-list:before {
  content: "";
}

.fa-block:before {
  content: "";
}

.fa-circle-exclamation:before, .fa-exclamation-circle:before {
  content: "";
}

.fa-school-circle-xmark:before {
  content: "";
}

.fa-arrow-right-from-bracket:before, .fa-sign-out:before {
  content: "";
}

.fa-face-frown-slight:before {
  content: "";
}

.fa-chevron-circle-down:before, .fa-circle-chevron-down:before {
  content: "";
}

.fa-sidebar-flip:before {
  content: "";
}

.fa-unlock-alt:before, .fa-unlock-keyhole:before {
  content: "";
}

.fa-temperature-list:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-headphones-alt:before, .fa-headphones-simple:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-pipe-section:before {
  content: "";
}

.fa-space-station-moon-alt:before, .fa-space-station-moon-construction:before {
  content: "";
}

.fa-circle-dollar-to-slot:before, .fa-donate:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-face-sleeping:before {
  content: "";
}

.fa-road-spikes:before {
  content: "";
}

.fa-fire-burner:before {
  content: "";
}

.fa-squirrel:before {
  content: "";
}

.fa-arrow-to-top:before, .fa-arrow-up-to-line:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-face-cowboy-hat:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-chart-scatter-3d:before {
  content: "";
}

.fa-display-chart-up:before {
  content: "";
}

.fa-square-code:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-volume-down:before, .fa-volume-low:before {
  content: "";
}

.fa-times-to-slot:before, .fa-vote-nay:before, .fa-xmark-to-slot:before {
  content: "";
}

.fa-box-alt:before, .fa-box-taped:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-swords:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-album:before {
  content: "";
}

.fa-circle-n:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-wheat-alt:before, .fa-wheat-awn:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-hands-holding-child:before {
  content: "";
}

.fa-asterisk:before {
  content: "*";
}

.fa-key-skeleton-left-right:before {
  content: "";
}

.fa-comment-lines:before {
  content: "";
}

.fa-luchador-mask:before, .fa-luchador:before, .fa-mask-luchador:before {
  content: "";
}

.fa-check-square:before, .fa-square-check:before {
  content: "";
}

.fa-shredder:before {
  content: "";
}

.fa-book-open-alt:before, .fa-book-open-cover:before {
  content: "";
}

.fa-sandwich:before {
  content: "";
}

.fa-peseta-sign:before {
  content: "";
}

.fa-parking-slash:before, .fa-square-parking-slash:before {
  content: "";
}

.fa-train-tunnel:before {
  content: "";
}

.fa-header:before, .fa-heading:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-face-anguished:before {
  content: "";
}

.fa-hockey-sticks:before {
  content: "";
}

.fa-abacus:before {
  content: "";
}

.fa-film-alt:before, .fa-film-simple:before {
  content: "";
}

.fa-list-squares:before, .fa-list:before {
  content: "";
}

.fa-tree-palm:before {
  content: "";
}

.fa-phone-square-alt:before, .fa-square-phone-flip:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-border-center-v:before {
  content: "";
}

.fa-circle-dot:before, .fa-dot-circle:before {
  content: "";
}

.fa-clipboard-medical:before {
  content: "";
}

.fa-dizzy:before, .fa-face-dizzy:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-arrow-alt-to-top:before, .fa-up-to-line:before {
  content: "";
}

.fa-house-medical-circle-xmark:before {
  content: "";
}

.fa-watch-fitness:before {
  content: "";
}

.fa-clock-nine-thirty:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-jug:before {
  content: "";
}

.fa-futbol-ball:before, .fa-futbol:before, .fa-soccer-ball:before {
  content: "";
}

.fa-snow-blowing:before {
  content: "";
}

.fa-paint-brush:before, .fa-paintbrush:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-arrow-down-from-line:before, .fa-arrow-from-top:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-signal-alt-slash:before, .fa-signal-bars-slash:before {
  content: "";
}

.fa-monkey:before {
  content: "";
}

.fa-pro:before, .fa-rectangle-pro:before {
  content: "";
}

.fa-house-night:before {
  content: "";
}

.fa-hot-tub-person:before, .fa-hot-tub:before {
  content: "";
}

.fa-globe-pointer:before {
  content: "";
}

.fa-blanket:before {
  content: "";
}

.fa-map-location:before, .fa-map-marked:before {
  content: "";
}

.fa-house-flood-water:before {
  content: "";
}

.fa-comments-question-check:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-arrows-cross:before {
  content: "";
}

.fa-backpack:before {
  content: "";
}

.fa-square-small:before {
  content: "";
}

.fa-folder-arrow-up:before, .fa-folder-upload:before {
  content: "";
}

.fa-bridge-lock:before {
  content: "";
}

.fa-crosshairs-simple:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-edit:before, .fa-pen-to-square:before {
  content: "";
}

.fa-sliders-h-square:before, .fa-square-sliders:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-comment-middle-top-alt:before, .fa-message-middle-top:before {
  content: "";
}

.fa-lightbulb-on:before {
  content: "";
}

.fa-knife:before, .fa-utensil-knife:before {
  content: "";
}

.fa-share-alt:before, .fa-share-nodes:before {
  content: "";
}

.fa-display-chart-up-circle-dollar:before {
  content: "";
}

.fa-wave-sine:before {
  content: "";
}

.fa-heart-circle-minus:before {
  content: "";
}

.fa-circle-w:before {
  content: "";
}

.fa-calendar-circle:before, .fa-circle-calendar:before {
  content: "";
}

.fa-hourglass-2:before, .fa-hourglass-half:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-sunset:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-calendar-exclamation:before {
  content: "";
}

.fa-truck-container-empty:before {
  content: "";
}

.fa-hand-heart:before {
  content: "";
}

.fa-bag-shopping:before, .fa-shopping-bag:before {
  content: "";
}

.fa-arrow-down-z-a:before, .fa-sort-alpha-desc:before, .fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-reply-clock:before, .fa-reply-time:before {
  content: "";
}

.fa-person-rays:before {
  content: "";
}

.fa-arrow-alt-right:before, .fa-right:before {
  content: "";
}

.fa-circle-f:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-face-pleading:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-flask-vial:before {
  content: "";
}

.fa-police-box:before {
  content: "";
}

.fa-cucumber:before {
  content: "";
}

.fa-head-side-brain:before {
  content: "";
}

.fa-hand-paper:before, .fa-hand:before {
  content: "";
}

.fa-biking-mountain:before, .fa-person-biking-mountain:before {
  content: "";
}

.fa-utensils-slash:before {
  content: "";
}

.fa-print-magnifying-glass:before, .fa-print-search:before {
  content: "";
}

.fa-folder-bookmark:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-pi:before {
  content: "";
}

.fa-flask-potion:before, .fa-flask-round-potion:before {
  content: "";
}

.fa-face-shush:before {
  content: "";
}

.fa-worm:before {
  content: "";
}

.fa-house-circle-xmark:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-calendar-circle-exclamation:before {
  content: "";
}

.fa-square-i:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-face-saluting:before {
  content: "";
}

.fa-gauge-simple-low:before, .fa-tachometer-slow:before {
  content: "";
}

.fa-face-persevering:before {
  content: "";
}

.fa-camera-circle:before, .fa-circle-camera:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-spider-web:before {
  content: "";
}

.fa-circle-microphone:before, .fa-microphone-circle:before {
  content: "";
}

.fa-book-arrow-up:before {
  content: "";
}

.fa-popsicle:before {
  content: "";
}

.fa-command:before {
  content: "";
}

.fa-blinds:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-saxophone:before {
  content: "";
}

.fa-square-2:before {
  content: "";
}

.fa-field-hockey-stick-ball:before, .fa-field-hockey:before {
  content: "";
}

.fa-arrow-up-square-triangle:before, .fa-sort-shapes-up-alt:before {
  content: "";
}

.fa-face-scream:before {
  content: "";
}

.fa-square-m:before {
  content: "";
}

.fa-camera-web:before, .fa-webcam:before {
  content: "";
}

.fa-comment-arrow-down:before {
  content: "";
}

.fa-lightbulb-cfl:before {
  content: "";
}

.fa-window-frame-open:before {
  content: "";
}

.fa-face-kiss:before, .fa-kiss:before {
  content: "";
}

.fa-bridge-circle-xmark:before {
  content: "";
}

.fa-period:before {
  content: ".";
}

.fa-face-grin-tongue:before, .fa-grin-tongue:before {
  content: "";
}

.fa-up-to-dotted-line:before {
  content: "";
}

.fa-thought-bubble:before {
  content: "";
}

.fa-skeleton-ribs:before {
  content: "";
}

.fa-raygun:before {
  content: "";
}

.fa-flute:before {
  content: "";
}

.fa-acorn:before {
  content: "";
}

.fa-video-arrow-up-right:before {
  content: "";
}

.fa-grate-droplet:before {
  content: "";
}

.fa-seal-exclamation:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-message-sms:before {
  content: "";
}

.fa-coffee-beans:before {
  content: "";
}

.fa-hat-witch:before {
  content: "";
}

.fa-face-grin-wink:before, .fa-grin-wink:before {
  content: "";
}

.fa-clock-three-thirty:before {
  content: "";
}

.fa-deaf:before, .fa-deafness:before, .fa-ear-deaf:before, .fa-hard-of-hearing:before {
  content: "";
}

.fa-alarm-clock:before {
  content: "";
}

.fa-eclipse:before {
  content: "";
}

.fa-face-relieved:before {
  content: "";
}

.fa-road-circle-check:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-minus-octagon:before, .fa-octagon-minus:before {
  content: "";
}

.fa-rss-square:before, .fa-square-rss:before {
  content: "";
}

.fa-face-zany:before {
  content: "";
}

.fa-tricycle:before {
  content: "";
}

.fa-land-mine-on:before {
  content: "";
}

.fa-square-arrow-up-left:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-chart-mixed-up-circle-dollar:before {
  content: "";
}

.fa-salt-shaker:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-file-plus:before {
  content: "";
}

.fa-draw-square:before {
  content: "";
}

.fa-toilet-paper-reverse-slash:before, .fa-toilet-paper-under-slash:before {
  content: "";
}

.fa-stairs:before {
  content: "";
}

.fa-drone-alt:before, .fa-drone-front:before {
  content: "";
}

.fa-glass-empty:before {
  content: "";
}

.fa-dial-high:before {
  content: "";
}

.fa-user-construction:before, .fa-user-hard-hat:before, .fa-user-helmet-safety:before {
  content: "";
}

.fa-i:before {
  content: "I";
}

.fa-hryvnia-sign:before, .fa-hryvnia:before {
  content: "";
}

.fa-arrow-down-left-and-arrow-up-right-to-center:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-face-grin-wide:before, .fa-grin-alt:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-basketball-hoop:before {
  content: "";
}

.fa-objects-align-bottom:before {
  content: "";
}

.fa-v:before {
  content: "V";
}

.fa-sparkles:before {
  content: "";
}

.fa-squid:before {
  content: "";
}

.fa-leafy-green:before {
  content: "";
}

.fa-circle-arrow-up-right:before {
  content: "";
}

.fa-calendars:before {
  content: "";
}

.fa-bangladeshi-taka-sign:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-hammer-war:before {
  content: "";
}

.fa-circle-d:before {
  content: "";
}

.fa-spider-black-widow:before {
  content: "";
}

.fa-rod-asclepius:before, .fa-rod-snake:before, .fa-staff-aesculapius:before, .fa-staff-snake:before {
  content: "";
}

.fa-pear:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-triangle:before {
  content: "";
}

.fa-apartment:before {
  content: "";
}

.fa-ambulance:before, .fa-truck-medical:before {
  content: "";
}

.fa-pepper:before {
  content: "";
}

.fa-piano:before {
  content: "";
}

.fa-gun-squirt:before {
  content: "";
}

.fa-wheat-awn-circle-exclamation:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-user-alien:before {
  content: "";
}

.fa-shield-check:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-road-barrier:before {
  content: "";
}

.fa-chart-candlestick:before {
  content: "";
}

.fa-briefcase-blank:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-bracket-round:before, .fa-parenthesis:before {
  content: "(";
}

.fa-joint:before {
  content: "";
}

.fa-horse-saddle:before {
  content: "";
}

.fa-mug-marshmallows:before {
  content: "";
}

.fa-filters:before {
  content: "";
}

.fa-bell-on:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-dial-med:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-q:before {
  content: "Q";
}

.fa-monitor-heart-rate:before, .fa-monitor-waveform:before {
  content: "";
}

.fa-link-simple:before {
  content: "";
}

.fa-whistle:before {
  content: "";
}

.fa-g:before {
  content: "G";
}

.fa-fragile:before, .fa-wine-glass-crack:before {
  content: "";
}

.fa-slot-machine:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-car-wash:before {
  content: "";
}

.fa-escalator:before {
  content: "";
}

.fa-comment-image:before {
  content: "";
}

.fa-temperature-2:before, .fa-temperature-half:before, .fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-dong-sign:before {
  content: "";
}

.fa-donut:before, .fa-doughnut:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-poo-bolt:before, .fa-poo-storm:before {
  content: "";
}

.fa-tally-1:before {
  content: "";
}

.fa-face-frown-open:before, .fa-frown-open:before {
  content: "";
}

.fa-square-dashed:before {
  content: "";
}

.fa-square-j:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-arrow-up-big-small:before, .fa-sort-size-up:before {
  content: "";
}

.fa-barcode-read:before {
  content: "";
}

.fa-baguette:before {
  content: "";
}

.fa-bowl-soft-serve:before {
  content: "";
}

.fa-face-holding-back-tears:before {
  content: "";
}

.fa-arrow-alt-square-up:before, .fa-square-up:before {
  content: "";
}

.fa-subway-tunnel:before, .fa-train-subway-tunnel:before {
  content: "";
}

.fa-exclamation-square:before, .fa-square-exclamation:before {
  content: "";
}

.fa-semicolon:before {
  content: ";";
}

.fa-bookmark:before {
  content: "";
}

.fa-fan-table:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-battery-1:before, .fa-battery-low:before {
  content: "";
}

.fa-credit-card-front:before {
  content: "";
}

.fa-brain-arrow-curved-right:before, .fa-mind-share:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-helmet-un:before {
  content: "";
}

.fa-location-smile:before, .fa-map-marker-smile:before {
  content: "";
}

.fa-arrow-left-to-line:before, .fa-arrow-to-left:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-nigiri:before, .fa-sushi:before {
  content: "";
}

.fa-comment-alt-captions:before, .fa-message-captions:before {
  content: "";
}

.fa-trash-list:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-option:before {
  content: "";
}

.fa-raccoon:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-arrow-up-from-bracket:before {
  content: "";
}

.fa-head-side-gear:before {
  content: "";
}

.fa-trash-plus:before {
  content: "";
}

.fa-objects-align-top:before {
  content: "";
}

.fa-folder-blank:before, .fa-folder:before {
  content: "";
}

.fa-face-anxious-sweat:before {
  content: "";
}

.fa-credit-card-blank:before {
  content: "";
}

.fa-file-medical-alt:before, .fa-file-waveform:before {
  content: "";
}

.fa-microchip-ai:before {
  content: "";
}

.fa-mug:before {
  content: "";
}

.fa-plane-up-slash:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-pen-circle:before {
  content: "";
}

.fa-bag-seedling:before {
  content: "";
}

.fa-chart-simple:before {
  content: "";
}

.fa-crutches:before {
  content: "";
}

.fa-circle-parking:before, .fa-parking-circle:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-leaf-oak:before {
  content: "";
}

.fa-square-bolt:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-dashboard:before, .fa-gauge-med:before, .fa-gauge:before, .fa-tachometer-alt-average:before {
  content: "";
}

.fa-magic-wand-sparkles:before, .fa-wand-magic-sparkles:before {
  content: "";
}

.fa-lambda:before {
  content: "";
}

.fa-e:before {
  content: "E";
}

.fa-pizza:before {
  content: "";
}

.fa-bowl-chopsticks-noodles:before {
  content: "";
}

.fa-h3:before {
  content: "";
}

.fa-pen-alt:before, .fa-pen-clip:before {
  content: "";
}

.fa-bridge-circle-exclamation:before {
  content: "";
}

.fa-badge-percent:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-sensor:before {
  content: "";
}

.fa-comma:before {
  content: ",";
}

.fa-school-circle-check:before {
  content: "";
}

.fa-toilet-paper-reverse:before, .fa-toilet-paper-under:before {
  content: "";
}

.fa-light-emergency:before {
  content: "";
}

.fa-arrow-down-to-arc:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-shuttle-van:before, .fa-van-shuttle:before {
  content: "";
}

.fa-building-user:before {
  content: "";
}

.fa-light-switch:before {
  content: "";
}

.fa-caret-square-left:before, .fa-square-caret-left:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-heart-rate:before, .fa-wave-pulse:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-hat-santa:before {
  content: "";
}

.fa-tamale:before {
  content: "";
}

.fa-box-check:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-steak:before {
  content: "";
}

.fa-location-crosshairs-slash:before, .fa-location-slash:before {
  content: "";
}

.fa-person-dolly:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-file-chart-column:before, .fa-file-chart-line:before {
  content: "";
}

.fa-person-half-dress:before {
  content: "";
}

.fa-folder-image:before {
  content: "";
}

.fa-calendar-edit:before, .fa-calendar-pen:before {
  content: "";
}

.fa-road-bridge:before {
  content: "";
}

.fa-face-smile-tear:before {
  content: "";
}

.fa-comment-alt-plus:before, .fa-message-plus:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-c:before {
  content: "C";
}

.fa-tablet-button:before {
  content: "";
}

.fa-person-dress-fairy:before {
  content: "";
}

.fa-rectangle-history-circle-user:before {
  content: "";
}

.fa-building-lock:before {
  content: "";
}

.fa-chart-line-up:before {
  content: "";
}

.fa-mailbox:before {
  content: "";
}

.fa-truck-bolt:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-area-chart:before, .fa-chart-area:before {
  content: "";
}

.fa-house-flag:before {
  content: "";
}

.fa-circle-three-quarters-stroke:before {
  content: "";
}

.fa-person-circle-minus:before {
  content: "";
}

.fa-scalpel:before {
  content: "";
}

.fa-ban:before, .fa-cancel:before {
  content: "";
}

.fa-bell-exclamation:before {
  content: "";
}

.fa-bookmark-circle:before, .fa-circle-bookmark:before {
  content: "";
}

.fa-egg-fried:before {
  content: "";
}

.fa-face-weary:before {
  content: "";
}

.fa-uniform-martial-arts:before {
  content: "";
}

.fa-camera-rotate:before {
  content: "";
}

.fa-sun-dust:before {
  content: "";
}

.fa-comment-text:before {
  content: "";
}

.fa-air-freshener:before, .fa-spray-can-sparkles:before {
  content: "";
}

.fa-signal-alt-4:before, .fa-signal-alt:before, .fa-signal-bars-strong:before, .fa-signal-bars:before {
  content: "";
}

.fa-diamond-exclamation:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-dial-min:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-file-caret-down:before, .fa-page-caret-down:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-clock-seven-thirty:before {
  content: "";
}

.fa-arrow-pointer:before, .fa-mouse-pointer:before {
  content: "";
}

.fa-clock-four-thirty:before {
  content: "";
}

.fa-signal-alt-3:before, .fa-signal-bars-good:before {
  content: "";
}

.fa-cactus:before {
  content: "";
}

.fa-lightbulb-gear:before {
  content: "";
}

.fa-expand-arrows-alt:before, .fa-maximize:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-shapes:before, .fa-triangle-circle-square:before {
  content: "";
}

.fa-plane-tail:before {
  content: "";
}

.fa-gauge-simple-max:before, .fa-tachometer-fastest:before {
  content: "";
}

.fa-circle-u:before {
  content: "";
}

.fa-shield-slash:before {
  content: "";
}

.fa-phone-square-down:before, .fa-square-phone-hangup:before {
  content: "";
}

.fa-arrow-up-left:before {
  content: "";
}

.fa-transporter-1:before {
  content: "";
}

.fa-peanuts:before {
  content: "";
}

.fa-random:before, .fa-shuffle:before {
  content: "";
}

.fa-person-running:before, .fa-running:before {
  content: "";
}

.fa-mobile-retro:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-bin-bottles-recycle:before {
  content: "";
}

.fa-arrow-up-from-square:before {
  content: "";
}

.fa-file-dashed-line:before, .fa-page-break:before {
  content: "";
}

.fa-bracket-curly-right:before {
  content: "}";
}

.fa-spider:before {
  content: "";
}

.fa-clock-three:before {
  content: "";
}

.fa-hands-bound:before {
  content: "";
}

.fa-scalpel-line-dashed:before, .fa-scalpel-path:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-pipe-smoking:before {
  content: "";
}

.fa-face-astonished:before {
  content: "";
}

.fa-window:before {
  content: "";
}

.fa-plane-circle-exclamation:before {
  content: "";
}

.fa-ear:before {
  content: "";
}

.fa-file-lock:before {
  content: "";
}

.fa-diagram-venn:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-goal-net:before {
  content: "";
}

.fa-coffin-cross:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-location-xmark:before, .fa-map-marker-times:before, .fa-map-marker-xmark:before {
  content: "";
}

.fa-circle-quarter-stroke:before {
  content: "";
}

.fa-lasso:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-person-to-portal:before, .fa-portal-enter:before {
  content: "";
}

.fa-calendar-star:before {
  content: "";
}

.fa-computer-mouse:before, .fa-mouse:before {
  content: "";
}

.fa-arrow-right-to-bracket:before, .fa-sign-in:before {
  content: "";
}

.fa-pegasus:before {
  content: "";
}

.fa-files-medical:before {
  content: "";
}

.fa-nfc-lock:before {
  content: "";
}

.fa-person-ski-lift:before, .fa-ski-lift:before {
  content: "";
}

.fa-square-6:before {
  content: "";
}

.fa-shop-slash:before, .fa-store-alt-slash:before {
  content: "";
}

.fa-wind-turbine:before {
  content: "";
}

.fa-sliders-simple:before {
  content: "";
}

.fa-grid-round:before {
  content: "";
}

.fa-badge-sheriff:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-virus-covid-slash:before {
  content: "";
}

.fa-intersection:before {
  content: "";
}

.fa-shop-lock:before {
  content: "";
}

.fa-family:before {
  content: "";
}

.fa-hourglass-1:before, .fa-hourglass-start:before {
  content: "";
}

.fa-user-hair-buns:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-hourglass-clock:before {
  content: "";
}

.fa-person-seat-reclined:before {
  content: "";
}

.fa-paper-plane-alt:before, .fa-paper-plane-top:before, .fa-send:before {
  content: "";
}

.fa-comment-alt-arrow-up:before, .fa-message-arrow-up:before {
  content: "";
}

.fa-lightbulb-exclamation:before {
  content: "";
}

.fa-layer-group-minus:before, .fa-layer-minus:before {
  content: "";
}

.fa-chart-pie-simple-circle-currency:before {
  content: "";
}

.fa-circle-e:before {
  content: "";
}

.fa-building-wheat:before {
  content: "";
}

.fa-gauge-max:before, .fa-tachometer-alt-fastest:before {
  content: "";
}

.fa-person-breastfeeding:before {
  content: "";
}

.fa-apostrophe:before {
  content: "'";
}

.fa-fire-hydrant:before {
  content: "";
}

.fa-right-to-bracket:before, .fa-sign-in-alt:before {
  content: "";
}

.fa-video-plus:before {
  content: "";
}

.fa-arrow-alt-square-right:before, .fa-square-right:before {
  content: "";
}

.fa-comment-smile:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-inbox-arrow-down:before, .fa-inbox-in:before {
  content: "";
}

.fa-heart-pulse:before, .fa-heartbeat:before {
  content: "";
}

.fa-circle-8:before {
  content: "";
}

.fa-clouds-moon:before {
  content: "";
}

.fa-clock-ten-thirty:before {
  content: "";
}

.fa-people-carry-box:before, .fa-people-carry:before {
  content: "";
}

.fa-folder-user:before {
  content: "";
}

.fa-trash-can-xmark:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-left-long-to-line:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-xmarks-lines:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-calendar-range:before {
  content: "";
}

.fa-flower-daffodil:before {
  content: "";
}

.fa-hand-back-point-up:before {
  content: "";
}

.fa-weight-scale:before, .fa-weight:before {
  content: "";
}

.fa-arrow-up-to-arc:before {
  content: "";
}

.fa-star-exclamation:before {
  content: "";
}

.fa-books:before {
  content: "";
}

.fa-user-friends:before, .fa-user-group:before {
  content: "";
}

.fa-arrow-up-a-z:before, .fa-sort-alpha-up:before {
  content: "";
}

.fa-layer-group-plus:before, .fa-layer-plus:before {
  content: "";
}

.fa-play-pause:before {
  content: "";
}

.fa-block-question:before {
  content: "";
}

.fa-snooze:before, .fa-zzz:before {
  content: "";
}

.fa-scanner-image:before {
  content: "";
}

.fa-tv-retro:before {
  content: "";
}

.fa-square-t:before {
  content: "";
}

.fa-barn-silo:before, .fa-farm:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-bars-sort:before {
  content: "";
}

.fa-palette-boxes:before, .fa-pallet-alt:before, .fa-pallet-boxes:before {
  content: "";
}

.fa-face-laugh-squint:before, .fa-laugh-squint:before {
  content: "";
}

.fa-code-simple:before {
  content: "";
}

.fa-bolt-slash:before {
  content: "";
}

.fa-panel-fire:before {
  content: "";
}

.fa-binary-circle-check:before {
  content: "";
}

.fa-comment-minus:before {
  content: "";
}

.fa-burrito:before {
  content: "";
}

.fa-violin:before {
  content: "";
}

.fa-objects-column:before {
  content: "";
}

.fa-chevron-square-down:before, .fa-square-chevron-down:before {
  content: "";
}

.fa-comment-plus:before {
  content: "";
}

.fa-triangle-instrument:before, .fa-triangle-music:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-user-pilot-tie:before {
  content: "";
}

.fa-piano-keyboard:before {
  content: "";
}

.fa-bed-empty:before {
  content: "";
}

.fa-arrow-circle-up:before, .fa-circle-arrow-up:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-rectangle-portrait:before, .fa-rectangle-vertical:before {
  content: "";
}

.fa-person-walking:before, .fa-walking:before {
  content: "";
}

.fa-l:before {
  content: "L";
}

.fa-signal-stream:before {
  content: "";
}

.fa-down-to-bracket:before {
  content: "";
}

.fa-circle-z:before {
  content: "";
}

.fa-stars:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-bed-pulse:before, .fa-procedures:before {
  content: "";
}

.fa-house-day:before {
  content: "";
}

.fa-shuttle-space:before, .fa-space-shuttle:before {
  content: "";
}

.fa-shirt-long-sleeve:before {
  content: "";
}

.fa-chart-pie-alt:before, .fa-chart-pie-simple:before {
  content: "";
}

.fa-face-laugh:before, .fa-laugh:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-album-collection-circle-user:before {
  content: "";
}

.fa-candy:before {
  content: "";
}

.fa-bowl-hot:before, .fa-soup:before {
  content: "";
}

.fa-flatbread:before {
  content: "";
}

.fa-heart-circle-plus:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-signal-alt-1:before, .fa-signal-bars-weak:before {
  content: "";
}

.fa-microphone-alt:before, .fa-microphone-lines:before {
  content: "";
}

.fa-clock-twelve:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-citrus-slice:before {
  content: "";
}

.fa-sheep:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-colon-sign:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-badger-honey:before {
  content: "";
}

.fa-h4:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-road-circle-xmark:before {
  content: "";
}

.fa-signal-slash:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-mars-stroke-up:before, .fa-mars-stroke-v:before {
  content: "";
}

.fa-champagne-glasses:before, .fa-glass-cheers:before {
  content: "";
}

.fa-taco:before {
  content: "";
}

.fa-hexagon-plus:before, .fa-plus-hexagon:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-house-circle-exclamation:before {
  content: "";
}

.fa-file-arrow-up:before, .fa-file-upload:before {
  content: "";
}

.fa-wifi-3:before, .fa-wifi-strong:before, .fa-wifi:before {
  content: "";
}

.fa-comments-alt:before, .fa-messages:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before {
  content: "";
}

.fa-umbrella-alt:before, .fa-umbrella-simple:before {
  content: "";
}

.fa-rectangle-history-circle-plus:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-prescription-bottle-pill:before {
  content: "";
}

.fa-user-edit:before, .fa-user-pen:before {
  content: "";
}

.fa-binary-slash:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-anchor-lock:before {
  content: "";
}

.fa-building-ngo:before {
  content: "";
}

.fa-transporter-3:before {
  content: "";
}

.fa-engine-exclamation:before, .fa-engine-warning:before {
  content: "";
}

.fa-circle-down-right:before {
  content: "";
}

.fa-square-k:before {
  content: "";
}

.fa-manat-sign:before {
  content: "";
}

.fa-money-check-edit:before, .fa-money-check-pen:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-border-style:before, .fa-border-top-left:before {
  content: "";
}

.fa-map-location-dot:before, .fa-map-marked-alt:before {
  content: "";
}

.fa-tilde:before {
  content: "~";
}

.fa-jedi:before {
  content: "";
}

.fa-poll:before, .fa-square-poll-vertical:before {
  content: "";
}

.fa-arrow-down-square-triangle:before, .fa-sort-shapes-down-alt:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-dog-leashed:before {
  content: "";
}

.fa-battery-car:before, .fa-car-battery:before {
  content: "";
}

.fa-face-downcast-sweat:before {
  content: "";
}

.fa-mailbox-flag-up:before {
  content: "";
}

.fa-memo-circle-info:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-volume-medium:before, .fa-volume:before {
  content: "";
}

.fa-transporter-5:before {
  content: "";
}

.fa-gauge-circle-bolt:before {
  content: "";
}

.fa-coin-front:before {
  content: "";
}

.fa-file-slash:before {
  content: "";
}

.fa-message-arrow-up-right:before {
  content: "";
}

.fa-treasure-chest:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-paint-brush-alt:before, .fa-paint-brush-fine:before, .fa-paintbrush-alt:before, .fa-paintbrush-fine:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-hood-cloak:before {
  content: "";
}

.fa-square-quote:before {
  content: "";
}

.fa-up-left:before {
  content: "";
}

.fa-bring-front:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-burger-cheese:before, .fa-cheeseburger:before {
  content: "";
}

.fa-building-circle-check:before {
  content: "";
}

.fa-repeat-1:before {
  content: "";
}

.fa-arrow-down-to-line:before, .fa-arrow-to-bottom:before {
  content: "";
}

.fa-grid-5:before {
  content: "";
}

.fa-swap-arrows:before {
  content: "";
}

.fa-right-long-to-line:before {
  content: "";
}

.fa-person-chalkboard:before {
  content: "";
}

.fa-mars-stroke-h:before, .fa-mars-stroke-right:before {
  content: "";
}

.fa-hand-back-fist:before, .fa-hand-rock:before {
  content: "";
}

.fa-grid-round-5:before {
  content: "";
}

.fa-tally-5:before, .fa-tally:before {
  content: "";
}

.fa-caret-square-up:before, .fa-square-caret-up:before {
  content: "";
}

.fa-cloud-showers-water:before {
  content: "";
}

.fa-bar-chart:before, .fa-chart-bar:before {
  content: "";
}

.fa-hands-bubbles:before, .fa-hands-wash:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-up-from-dotted-line:before {
  content: "";
}

.fa-eye-low-vision:before, .fa-low-vision:before {
  content: "";
}

.fa-traffic-light-go:before {
  content: "";
}

.fa-face-exhaling:before {
  content: "";
}

.fa-sensor-fire:before {
  content: "";
}

.fa-user-unlock:before {
  content: "";
}

.fa-hexagon-divide:before {
  content: "";
}

.fa-00:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-betamax:before, .fa-cassette-betamax:before {
  content: "";
}

.fa-sailboat:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-nfc-magnifying-glass:before {
  content: "";
}

.fa-file-binary:before {
  content: "";
}

.fa-circle-v:before {
  content: "";
}

.fa-plus-square:before, .fa-square-plus:before {
  content: "";
}

.fa-bowl-scoops:before {
  content: "";
}

.fa-mistletoe:before {
  content: "";
}

.fa-custard:before {
  content: "";
}

.fa-lacrosse-stick:before {
  content: "";
}

.fa-hockey-mask:before {
  content: "";
}

.fa-sunrise:before {
  content: "";
}

.fa-subtitles:before {
  content: "";
}

.fa-panel-ews:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-cloud-exclamation:before {
  content: "";
}

.fa-comment-alt-lines:before, .fa-message-lines:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-bucket:before {
  content: "";
}

.fa-floppy-disk-pen:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-window-frame:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-file-zip:before {
  content: "";
}

.fa-square-ring:before {
  content: "";
}

.fa-arrow-alt-from-top:before, .fa-down-from-line:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-shield-times:before, .fa-shield-xmark:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-circle-sort-down:before, .fa-sort-circle-down:before {
  content: "";
}

.fa-folder-closed:before {
  content: "";
}

.fa-house-tsunami:before {
  content: "";
}

.fa-square-nfi:before {
  content: "";
}

.fa-forklift:before {
  content: "";
}

.fa-arrow-up-from-ground-water:before {
  content: "";
}

.fa-bracket-square-right:before {
  content: "]";
}

.fa-glass-martini-alt:before, .fa-martini-glass:before {
  content: "";
}

.fa-rotate-back:before, .fa-rotate-backward:before, .fa-rotate-left:before, .fa-undo-alt:before {
  content: "";
}

.fa-columns:before, .fa-table-columns:before {
  content: "";
}

.fa-square-a:before {
  content: "";
}

.fa-tick:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-dolly-box:before, .fa-dolly:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-compress-arrows-alt:before, .fa-minimize:before {
  content: "";
}

.fa-refrigerator:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-octagon-xmark:before, .fa-times-octagon:before, .fa-xmark-octagon:before {
  content: "";
}

.fa-align-slash:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-angle-double-right:before, .fa-angles-right:before {
  content: "";
}

.fa-truck-couch:before, .fa-truck-ramp-couch:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-circle-play:before, .fa-play-circle:before {
  content: "";
}

.fa-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-360-degrees:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-eur:before, .fa-euro-sign:before, .fa-euro:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-check-circle:before, .fa-circle-check:before {
  content: "";
}

.fa-square-dashed-circle-plus:before {
  content: "";
}

.fa-money-simple-from-bracket:before {
  content: "";
}

.fa-bat:before {
  content: "";
}

.fa-circle-stop:before, .fa-stop-circle:before {
  content: "";
}

.fa-head-side-headphones:before {
  content: "";
}

.fa-phone-rotary:before {
  content: "";
}

.fa-compass-drafting:before, .fa-drafting-compass:before {
  content: "";
}

.fa-plate-wheat:before {
  content: "";
}

.fa-calendar-circle-minus:before {
  content: "";
}

.fa-chopsticks:before {
  content: "";
}

.fa-car-mechanic:before, .fa-car-wrench:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-person-shelter:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-kazoo:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-bin-bottles:before {
  content: "";
}

.fa-face-laugh-beam:before, .fa-laugh-beam:before {
  content: "";
}

.fa-square-arrow-down-left:before {
  content: "";
}

.fa-battery-bolt:before {
  content: "";
}

.fa-tree-large:before {
  content: "";
}

.fa-helicopter-symbol:before {
  content: "";
}

.fa-aperture:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-gear-complex:before {
  content: "";
}

.fa-file-magnifying-glass:before, .fa-file-search:before {
  content: "";
}

.fa-up-right:before {
  content: "";
}

.fa-chevron-circle-up:before, .fa-circle-chevron-up:before {
  content: "";
}

.fa-user-police:before {
  content: "";
}

.fa-lari-sign:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-teddy-bear:before {
  content: "";
}

.fa-stocking:before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right:before {
  content: "";
}

.fa-image-slash:before {
  content: "";
}

.fa-mask-snorkel:before {
  content: "";
}

.fa-smoke:before {
  content: "";
}

.fa-gbp:before, .fa-pound-sign:before, .fa-sterling-sign:before {
  content: "";
}

.fa-battery-exclamation:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-square-person-confined:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-arrow-down-long:before, .fa-long-arrow-down:before {
  content: "";
}

.fa-tent-arrow-down-to-line:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-crystal-ball:before {
  content: "";
}

.fa-mail-reply-all:before, .fa-reply-all:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-person-skating:before, .fa-skating:before {
  content: "";
}

.fa-star-shooting:before {
  content: "";
}

.fa-binary-lock:before {
  content: "";
}

.fa-filter-circle-dollar:before, .fa-funnel-dollar:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-arrow-circle-down:before, .fa-circle-arrow-down:before {
  content: "";
}

.fa-comment-edit:before, .fa-comment-pen:before {
  content: "";
}

.fa-arrow-right-to-file:before, .fa-file-import:before {
  content: "";
}

.fa-banjo:before {
  content: "";
}

.fa-external-link-square:before, .fa-square-arrow-up-right:before {
  content: "";
}

.fa-light-emergency-on:before {
  content: "";
}

.fa-kerning:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-square-f:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-arrow-from-right:before, .fa-arrow-left-from-line:before {
  content: "";
}

.fa-strawberry:before {
  content: "";
}

.fa-location-pin-lock:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-clock-eight-thirty:before {
  content: "";
}

.fa-plane-alt:before, .fa-plane-engines:before {
  content: "";
}

.fa-hill-avalanche:before {
  content: "";
}

.fa-temperature-0:before, .fa-temperature-empty:before, .fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-gauge-low:before, .fa-tachometer-alt-slow:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-trash-can-plus:before {
  content: "";
}

.fa-address-card:before, .fa-contact-card:before, .fa-vcard:before {
  content: "";
}

.fa-balance-scale-right:before, .fa-scale-unbalanced-flip:before {
  content: "";
}

.fa-globe-snow:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-diamond-turn-right:before, .fa-directions:before {
  content: "";
}

.fa-integral:before {
  content: "";
}

.fa-burst:before {
  content: "";
}

.fa-house-laptop:before, .fa-laptop-house:before {
  content: "";
}

.fa-face-tired:before, .fa-tired:before {
  content: "";
}

.fa-money-bills:before {
  content: "";
}

.fa-blinds-raised:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-ufo-beam:before {
  content: "";
}

.fa-caret-circle-up:before, .fa-circle-caret-up:before {
  content: "";
}

.fa-user-vneck-hair-long:before {
  content: "";
}

.fa-square-a-lock:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-gas-pump-slash:before {
  content: "";
}

.fa-cloud-arrow-up:before, .fa-cloud-upload-alt:before, .fa-cloud-upload:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-transporter-4:before {
  content: "";
}

.fa-chart-mixed-up-circle-currency:before {
  content: "";
}

.fa-objects-align-right:before {
  content: "";
}

.fa-arrows-turn-right:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-pig:before {
  content: "";
}

.fa-inbox-full:before {
  content: "";
}

.fa-circle-envelope:before, .fa-envelope-circle:before {
  content: "";
}

.fa-construction:before, .fa-triangle-person-digging:before {
  content: "";
}

.fa-ferry:before {
  content: "";
}

.fa-bullseye-arrow:before {
  content: "";
}

.fa-arrows-down-to-people:before {
  content: "";
}

.fa-seedling:before, .fa-sprout:before {
  content: "";
}

.fa-clock-seven:before {
  content: "";
}

.fa-arrows-alt-h:before, .fa-left-right:before {
  content: "";
}

.fa-boxes-packing:before {
  content: "";
}

.fa-arrow-circle-left:before, .fa-circle-arrow-left:before {
  content: "";
}

.fa-flashlight:before {
  content: "";
}

.fa-group-arrows-rotate:before {
  content: "";
}

.fa-bowl-food:before {
  content: "";
}

.fa-square-9:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-arrow-down-wide-short:before, .fa-sort-amount-asc:before, .fa-sort-amount-down:before {
  content: "";
}

.fa-dollar-square:before, .fa-square-dollar:before, .fa-usd-square:before {
  content: "";
}

.fa-phone-arrow-right:before {
  content: "";
}

.fa-hand-holding-seedling:before {
  content: "";
}

.fa-comment-alt-check:before, .fa-message-check:before {
  content: "";
}

.fa-cloud-bolt:before, .fa-thunderstorm:before {
  content: "";
}

.fa-chart-line-up-down:before {
  content: "";
}

.fa-remove-format:before, .fa-text-slash:before {
  content: "";
}

.fa-watch:before {
  content: "";
}

.fa-circle-down-left:before {
  content: "";
}

.fa-text:before {
  content: "";
}

.fa-projector:before {
  content: "";
}

.fa-face-smile-wink:before, .fa-smile-wink:before {
  content: "";
}

.fa-tombstone-alt:before, .fa-tombstone-blank:before {
  content: "";
}

.fa-chess-king-alt:before, .fa-chess-king-piece:before {
  content: "";
}

.fa-circle-6:before {
  content: "";
}

.fa-arrow-alt-left:before, .fa-left:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-arrow-alt-square-down:before, .fa-square-down:before {
  content: "";
}

.fa-objects-align-center-vertical:before {
  content: "";
}

.fa-arrows-h:before, .fa-arrows-left-right:before {
  content: "";
}

.fa-house-lock:before {
  content: "";
}

.fa-cloud-arrow-down:before, .fa-cloud-download-alt:before, .fa-cloud-download:before {
  content: "";
}

.fa-wreath:before {
  content: "";
}

.fa-children:before {
  content: "";
}

.fa-meter-droplet:before {
  content: "";
}

.fa-blackboard:before, .fa-chalkboard:before {
  content: "";
}

.fa-user-alt-slash:before, .fa-user-large-slash:before {
  content: "";
}

.fa-signal-4:before, .fa-signal-strong:before {
  content: "";
}

.fa-lollipop:before, .fa-lollypop:before {
  content: "";
}

.fa-list-tree:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-draw-circle:before {
  content: "";
}

.fa-cat-space:before {
  content: "";
}

.fa-handshake-alt-slash:before, .fa-handshake-simple-slash:before {
  content: "";
}

.fa-rabbit-fast:before, .fa-rabbit-running:before {
  content: "";
}

.fa-memo-pad:before {
  content: "";
}

.fa-mattress-pillow:before {
  content: "";
}

.fa-alarm-plus:before {
  content: "";
}

.fa-alicorn:before {
  content: "";
}

.fa-comment-question:before {
  content: "";
}

.fa-gingerbread-man:before {
  content: "";
}

.fa-guarani-sign:before {
  content: "";
}

.fa-burger-fries:before {
  content: "";
}

.fa-mug-tea:before {
  content: "";
}

.fa-border-top:before {
  content: "";
}

.fa-arrows-rotate:before, .fa-refresh:before, .fa-sync:before {
  content: "";
}

.fa-book-circle:before, .fa-circle-book-open:before {
  content: "";
}

.fa-arrows-to-dotted-line:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-garage-open:before {
  content: "";
}

.fa-shelves-empty:before {
  content: "";
}

.fa-cruzeiro-sign:before {
  content: "";
}

.fa-watch-apple:before {
  content: "";
}

.fa-watch-calculator:before {
  content: "";
}

.fa-list-dropdown:before {
  content: "";
}

.fa-cabinet-filing:before {
  content: "";
}

.fa-burger-soda:before {
  content: "";
}

.fa-arrow-square-up:before, .fa-square-arrow-up:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-pallet-box:before {
  content: "";
}

.fa-face-confounded:before {
  content: "";
}

.fa-shield-alt:before, .fa-shield-halved:before {
  content: "";
}

.fa-truck-plow:before {
  content: "";
}

.fa-atlas:before, .fa-book-atlas:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-grid-round-2:before {
  content: "";
}

.fa-comment-middle-top:before {
  content: "";
}

.fa-envelope-circle-check:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-restroom-simple:before {
  content: "";
}

.fa-arrows-to-dot:before {
  content: "";
}

.fa-border-outer:before {
  content: "";
}

.fa-hashtag-lock:before {
  content: "";
}

.fa-clock-two-thirty:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-heart-circle-check:before {
  content: "";
}

.fa-house-chimney-crack:before, .fa-house-damage:before {
  content: "";
}

.fa-file-archive:before, .fa-file-zipper:before {
  content: "";
}

.fa-heart-half:before {
  content: "";
}

.fa-comment-check:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-memo:before {
  content: "";
}

.fa-glass-martini:before, .fa-martini-glass-empty:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cedi-sign:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-glass-citrus:before {
  content: "";
}

.fa-calendar-lines-pen:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-person-snowmobiling:before, .fa-snowmobile:before {
  content: "";
}

.fa-face-hushed:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-pickaxe:before {
  content: "";
}

.fa-link-simple-slash:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-face-confused:before {
  content: "";
}

.fa-pinball:before {
  content: "";
}

.fa-z:before {
  content: "Z";
}

.fa-person-skiing:before, .fa-skiing:before {
  content: "";
}

.fa-deer:before {
  content: "";
}

.fa-input-pipe:before {
  content: "";
}

.fa-road-lock:before {
  content: "";
}

.fa-a:before {
  content: "A";
}

.fa-bookmark-slash:before {
  content: "";
}

.fa-temperature-arrow-down:before, .fa-temperature-down:before {
  content: "";
}

.fa-mace:before {
  content: "";
}

.fa-feather-alt:before, .fa-feather-pointed:before {
  content: "";
}

.fa-sausage:before {
  content: "";
}

.fa-trash-can-clock:before {
  content: "";
}

.fa-p:before {
  content: "P";
}

.fa-broom-wide:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-stomach:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-ad:before, .fa-rectangle-ad:before {
  content: "";
}

.fa-guitar-electric:before {
  content: "";
}

.fa-arrow-turn-down-right:before {
  content: "";
}

.fa-moon-cloud:before {
  content: "";
}

.fa-bread-slice-butter:before {
  content: "";
}

.fa-arrow-circle-right:before, .fa-circle-arrow-right:before {
  content: "";
}

.fa-user-group-crown:before, .fa-users-crown:before {
  content: "";
}

.fa-circle-i:before {
  content: "";
}

.fa-toilet-paper-check:before {
  content: "";
}

.fa-filter-circle-xmark:before {
  content: "";
}

.fa-locust:before {
  content: "";
}

.fa-sort:before, .fa-unsorted:before {
  content: "";
}

.fa-list-1-2:before, .fa-list-numeric:before, .fa-list-ol:before {
  content: "";
}

.fa-chart-waterfall:before {
  content: "";
}

.fa-sparkle:before {
  content: "";
}

.fa-face-party:before {
  content: "";
}

.fa-kidneys:before {
  content: "";
}

.fa-wifi-exclamation:before {
  content: "";
}

.fa-chart-network:before {
  content: "";
}

.fa-person-dress-burst:before {
  content: "";
}

.fa-dice-d4:before {
  content: "";
}

.fa-money-check-alt:before, .fa-money-check-dollar:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-wheat-awn-slash:before {
  content: "";
}

.fa-face-kiss-wink-heart:before, .fa-kiss-wink-heart:before {
  content: "";
}

.fa-dagger:before {
  content: "";
}

.fa-podium:before {
  content: "";
}

.fa-memo-circle-check:before {
  content: "";
}

.fa-route-highway:before {
  content: "";
}

.fa-arrow-alt-to-bottom:before, .fa-down-to-line:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-square-g:before {
  content: "";
}

.fa-circle-phone:before, .fa-phone-circle:before {
  content: "";
}

.fa-clipboard-prescription:before {
  content: "";
}

.fa-user-nurse-hair:before {
  content: "";
}

.fa-question:before {
  content: "?";
}

.fa-file-signature:before {
  content: "";
}

.fa-toggle-large-on:before {
  content: "";
}

.fa-arrows-alt:before, .fa-up-down-left-right:before {
  content: "";
}

.fa-dryer-alt:before, .fa-dryer-heat:before {
  content: "";
}

.fa-house-chimney-user:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-arrow-up-small-big:before, .fa-sort-size-up-alt:before {
  content: "";
}

.fa-train-track:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-star-half-alt:before, .fa-star-half-stroke:before {
  content: "";
}

.fa-file-exclamation:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-glass-whiskey:before, .fa-whiskey-glass:before {
  content: "";
}

.fa-moon-stars:before {
  content: "";
}

.fa-building-circle-exclamation:before {
  content: "";
}

.fa-clothes-hanger:before {
  content: "";
}

.fa-mobile-iphone:before, .fa-mobile-notch:before {
  content: "";
}

.fa-magnifying-glass-chart:before {
  content: "";
}

.fa-arrow-up-right-from-square:before, .fa-external-link:before {
  content: "";
}

.fa-cubes-stacked:before {
  content: "";
}

.fa-images-user:before {
  content: "";
}

.fa-krw:before, .fa-won-sign:before, .fa-won:before {
  content: "";
}

.fa-image-polaroid-user:before {
  content: "";
}

.fa-virus-covid:before {
  content: "";
}

.fa-square-ellipsis:before {
  content: "";
}

.fa-pie:before {
  content: "";
}

.fa-chess-knight-alt:before, .fa-chess-knight-piece:before {
  content: "";
}

.fa-austral-sign:before {
  content: "";
}

.fa-cloud-plus:before {
  content: "";
}

.fa-f:before {
  content: "F";
}

.fa-leaf:before {
  content: "";
}

.fa-bed-bunk:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-cab:before, .fa-taxi:before {
  content: "";
}

.fa-person-circle-plus:before {
  content: "";
}

.fa-chart-pie:before, .fa-pie-chart:before {
  content: "";
}

.fa-bolt-lightning:before {
  content: "";
}

.fa-clock-eight:before {
  content: "";
}

.fa-sack-xmark:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-fish-fins:before {
  content: "";
}

.fa-circle-q:before {
  content: "";
}

.fa-building-flag:before {
  content: "";
}

.fa-face-grin-beam:before, .fa-grin-beam:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-face-disguise:before {
  content: "";
}

.fa-circle-arrow-down-right:before {
  content: "";
}

.fa-alien-8bit:before, .fa-alien-monster:before {
  content: "";
}

.fa-hand-point-ribbon:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-object-exclude:before {
  content: "";
}

.fa-telescope:before {
  content: "";
}

.fa-location-pin:before, .fa-map-marker:before {
  content: "";
}

.fa-square-list:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-hard-hat:before, .fa-hat-hard:before, .fa-helmet-safety:before {
  content: "";
}

.fa-comment-code:before {
  content: "";
}

.fa-sim-cards:before {
  content: "";
}

.fa-starship:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-arrow-alt-circle-right:before, .fa-circle-right:before {
  content: "";
}

.fa-plane-circle-check:before {
  content: "";
}

.fa-seal:before {
  content: "";
}

.fa-user-cowboy:before {
  content: "";
}

.fa-hexagon-vertical-nft:before {
  content: "";
}

.fa-face-rolling-eyes:before, .fa-meh-rolling-eyes:before {
  content: "";
}

.fa-bread-loaf:before {
  content: "";
}

.fa-rings-wedding:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-french-fries:before {
  content: "";
}

.fa-chart-line:before, .fa-line-chart:before {
  content: "";
}

.fa-calendar-arrow-down:before, .fa-calendar-download:before {
  content: "";
}

.fa-send-back:before {
  content: "";
}

.fa-mask-ventilator:before {
  content: "";
}

.fa-signature-lock:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-map-signs:before, .fa-signs-post:before {
  content: "";
}

.fa-octagon-plus:before, .fa-plus-octagon:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-person-circle-question:before {
  content: "";
}

.fa-melon-slice:before {
  content: "";
}

.fa-space-station-moon:before {
  content: "";
}

.fa-comment-alt-smile:before, .fa-message-smile:before {
  content: "";
}

.fa-cup-straw:before {
  content: "";
}

.fa-arrow-alt-from-right:before, .fa-left-from-line:before {
  content: "";
}

.fa-h:before {
  content: "H";
}

.fa-basket-shopping-simple:before, .fa-shopping-basket-alt:before {
  content: "";
}

.fa-hands-heart:before, .fa-hands-holding-heart:before {
  content: "";
}

.fa-clock-nine:before {
  content: "";
}

.fa-tarp:before {
  content: "";
}

.fa-face-sleepy:before {
  content: "";
}

.fa-hand-horns:before {
  content: "";
}

.fa-screwdriver-wrench:before, .fa-tools:before {
  content: "";
}

.fa-arrows-to-eye:before {
  content: "";
}

.fa-circle-three-quarters:before {
  content: "";
}

.fa-trophy-alt:before, .fa-trophy-star:before {
  content: "";
}

.fa-plug-circle-bolt:before {
  content: "";
}

.fa-face-thermometer:before {
  content: "";
}

.fa-grid-round-4:before {
  content: "";
}

.fa-shirt-running:before {
  content: "";
}

.fa-book-circle-arrow-up:before {
  content: "";
}

.fa-face-nauseated:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-file-chart-pie:before {
  content: "";
}

.fa-mars-and-venus:before {
  content: "";
}

.fa-home-user:before, .fa-house-user:before {
  content: "";
}

.fa-circle-arrow-down-left:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-hexagon-minus:before, .fa-minus-hexagon:before {
  content: "";
}

.fa-arrow-alt-to-left:before, .fa-left-to-line:before {
  content: "";
}

.fa-house-crack:before {
  content: "";
}

.fa-paw-alt:before, .fa-paw-simple:before {
  content: "";
}

.fa-arrow-left-long-to-line:before {
  content: "";
}

.fa-brackets-round:before, .fa-parentheses:before {
  content: "";
}

.fa-cocktail:before, .fa-martini-glass-citrus:before {
  content: "";
}

.fa-user-shakespeare:before {
  content: "";
}

.fa-arrow-right-to-arc:before {
  content: "";
}

.fa-face-surprise:before, .fa-surprise:before {
  content: "";
}

.fa-bottle-water:before {
  content: "";
}

.fa-circle-pause:before, .fa-pause-circle:before {
  content: "";
}

.fa-gauge-circle-plus:before {
  content: "";
}

.fa-folders:before {
  content: "";
}

.fa-angel:before {
  content: "";
}

.fa-value-absolute:before {
  content: "";
}

.fa-rabbit:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-circle-euro:before {
  content: "";
}

.fa-apple-alt:before, .fa-apple-whole:before {
  content: "";
}

.fa-kitchen-set:before {
  content: "";
}

.fa-diamond-half:before {
  content: "";
}

.fa-lock-alt:before, .fa-lock-keyhole:before {
  content: "";
}

.fa-r:before {
  content: "R";
}

.fa-temperature-1:before, .fa-temperature-quarter:before, .fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-info-square:before, .fa-square-info:before {
  content: "";
}

.fa-wifi-slash:before {
  content: "";
}

.fa-toilet-paper-xmark:before {
  content: "";
}

.fa-hands-holding-dollar:before, .fa-hands-usd:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-arrow-down-triangle-square:before, .fa-sort-shapes-down:before {
  content: "";
}

.fa-bitcoin-sign:before {
  content: "";
}

.fa-shutters:before {
  content: "";
}

.fa-shield-dog:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-table-tree:before {
  content: "";
}

.fa-house-chimney-heart:before {
  content: "";
}

.fa-tally-3:before {
  content: "";
}

.fa-elevator:before {
  content: "";
}

.fa-money-bill-transfer:before {
  content: "";
}

.fa-money-bill-trend-up:before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right:before {
  content: "";
}

.fa-poll-h:before, .fa-square-poll-horizontal:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-cart-circle-exclamation:before {
  content: "";
}

.fa-sword:before {
  content: "";
}

.fa-backward-fast:before, .fa-fast-backward:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-interrobang:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-circle-dashed:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-basketball-ball:before, .fa-basketball:before {
  content: "";
}

.fa-fork-knife:before, .fa-utensils-alt:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-badge-check:before {
  content: "";
}

.fa-arrow-alt-circle-up:before, .fa-circle-up:before {
  content: "";
}

.fa-slider:before {
  content: "";
}

.fa-mobile-alt:before, .fa-mobile-screen-button:before {
  content: "";
}

.fa-clock-one-thirty:before {
  content: "";
}

.fa-inbox-arrow-up:before, .fa-inbox-out:before {
  content: "";
}

.fa-cloud-slash:before {
  content: "";
}

.fa-volume-high:before, .fa-volume-up:before {
  content: "";
}

.fa-users-rays:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-octagon-check:before {
  content: "";
}

.fa-flatbread-stuffed:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-cart-circle-plus:before {
  content: "";
}

.fa-shipping-timed:before, .fa-truck-clock:before {
  content: "";
}

.fa-pool-8-ball:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-turn-down-left:before {
  content: "";
}

.fa-lock-hashtag:before {
  content: "";
}

.fa-chart-radar:before {
  content: "";
}

.fa-staff:before {
  content: "";
}

.fa-burger:before, .fa-hamburger:before {
  content: "";
}

.fa-utility-pole:before {
  content: "";
}

.fa-transporter-6:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-bugs:before {
  content: "";
}

.fa-vector-polygon:before {
  content: "";
}

.fa-diagram-nested:before {
  content: "";
}

.fa-rupee-sign:before, .fa-rupee:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-circle-question:before, .fa-question-circle:before {
  content: "";
}

.fa-image-user:before {
  content: "";
}

.fa-buoy:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-book-bookmark:before {
  content: "";
}

.fa-border-center-h:before {
  content: "";
}

.fa-can-food:before {
  content: "";
}

.fa-typewriter:before {
  content: "";
}

.fa-arrow-right-from-arc:before {
  content: "";
}

.fa-circle-k:before {
  content: "";
}

.fa-face-hand-over-mouth:before {
  content: "";
}

.fa-popcorn:before {
  content: "";
}

.fa-house-flood:before, .fa-house-water:before {
  content: "";
}

.fa-object-subtract:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-warehouse-alt:before, .fa-warehouse-full:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-bridge:before {
  content: "";
}

.fa-phone-alt:before, .fa-phone-flip:before {
  content: "";
}

.fa-arrow-down-from-dotted-line:before {
  content: "";
}

.fa-file-doc:before {
  content: "";
}

.fa-square-quarters:before {
  content: "";
}

.fa-truck-front:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-trash-xmark:before {
  content: "";
}

.fa-caret-circle-left:before, .fa-circle-caret-left:before {
  content: "";
}

.fa-files:before {
  content: "";
}

.fa-anchor-circle-exclamation:before {
  content: "";
}

.fa-face-clouds:before {
  content: "";
}

.fa-user-crown:before {
  content: "";
}

.fa-truck-field:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-cart-circle-check:before {
  content: "";
}

.fa-clipboard-question:before {
  content: "";
}

.fa-panorama:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-user-tie-hair-long:before {
  content: "";
}

.fa-file-circle-minus:before {
  content: "";
}

.fa-head-side-medical:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-fast-forward:before, .fa-forward-fast:before {
  content: "";
}

.fa-face-meh-blank:before, .fa-meh-blank:before {
  content: "";
}

.fa-user-robot:before {
  content: "";
}

.fa-parking:before, .fa-square-parking:before {
  content: "";
}

.fa-card-diamond:before {
  content: "";
}

.fa-face-zipper:before {
  content: "";
}

.fa-face-raised-eyebrow:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-chevron-square-up:before, .fa-square-chevron-up:before {
  content: "";
}

.fa-bars-progress:before, .fa-tasks-alt:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-arrows-to-line:before {
  content: "";
}

.fa-dolphin:before {
  content: "";
}

.fa-arrow-up-right:before {
  content: "";
}

.fa-circle-r:before {
  content: "";
}

.fa-cart-flatbed:before, .fa-dolly-flatbed:before {
  content: "";
}

.fa-ban-smoking:before, .fa-smoking-ban:before {
  content: "";
}

.fa-circle-sort-up:before, .fa-sort-circle-up:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-mobile-button:before {
  content: "";
}

.fa-house-medical-flag:before {
  content: "";
}

.fa-basket-shopping:before, .fa-shopping-basket:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-chestnut:before {
  content: "";
}

.fa-bus-alt:before, .fa-bus-simple:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-face-sad-cry:before, .fa-sad-cry:before {
  content: "";
}

.fa-heat:before {
  content: "";
}

.fa-ticket-airline:before {
  content: "";
}

.fa-boot-heeled:before {
  content: "";
}

.fa-arrows-minimize:before, .fa-compress-arrows:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-person-military-to-person:before {
  content: "";
}

.fa-file-shield:before {
  content: "";
}

.fa-hexagon:before {
  content: "";
}

.fa-manhole:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-tower-observation:before {
  content: "";
}

.fa-floppy-disks:before {
  content: "";
}

.fa-toilet-paper-blank-under:before, .fa-toilet-paper-reverse-alt:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-signal-5:before, .fa-signal-perfect:before, .fa-signal:before {
  content: "";
}

.fa-pump:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-heart-circle-xmark:before {
  content: "";
}

.fa-arrow-up-left-from-circle:before {
  content: "";
}

.fa-home-lg:before, .fa-house-chimney:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-dryer:before {
  content: "";
}

.fa-face-frown:before, .fa-frown:before {
  content: "";
}

.fa-chess-bishop-alt:before, .fa-chess-bishop-piece:before {
  content: "";
}

.fa-shirt-tank-top:before {
  content: "";
}

.fa-diploma:before, .fa-scroll-ribbon:before {
  content: "";
}

.fa-screencast:before {
  content: "";
}

.fa-walker:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-shop:before, .fa-store-alt:before {
  content: "";
}

.fa-floppy-disk:before, .fa-save:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-face-kiss-closed-eyes:before {
  content: "";
}

.fa-balance-scale-left:before, .fa-scale-unbalanced:before {
  content: "";
}

.fa-file-user:before {
  content: "";
}

.fa-user-police-tie:before {
  content: "";
}

.fa-face-tongue-money:before {
  content: "";
}

.fa-tennis-ball:before {
  content: "";
}

.fa-square-l:before {
  content: "";
}

.fa-sort-asc:before, .fa-sort-up:before {
  content: "";
}

.fa-calendar-arrow-up:before, .fa-calendar-upload:before {
  content: "";
}

.fa-comment-dots:before, .fa-commenting:before {
  content: "";
}

.fa-plant-wilt:before {
  content: "";
}

.fa-scarf:before {
  content: "";
}

.fa-album-circle-plus:before {
  content: "";
}

.fa-user-nurse-hair-long:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-arrow-alt-square-left:before, .fa-square-left:before {
  content: "";
}

.fa-face-grin-squint:before, .fa-grin-squint:before {
  content: "";
}

.fa-circle-ellipsis-vertical:before {
  content: "";
}

.fa-hand-holding-dollar:before, .fa-hand-holding-usd:before {
  content: "";
}

.fa-grid-dividers:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hands-praying:before, .fa-praying-hands:before {
  content: "";
}

.fa-face-pensive:before {
  content: "";
}

.fa-user-music:before {
  content: "";
}

.fa-arrow-right-rotate:before, .fa-arrow-rotate-forward:before, .fa-arrow-rotate-right:before, .fa-redo:before {
  content: "";
}

.fa-comments-alt-dollar:before, .fa-messages-dollar:before {
  content: "";
}

.fa-sensor-on:before {
  content: "";
}

.fa-balloon:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-chess-queen-alt:before, .fa-chess-queen-piece:before {
  content: "";
}

.fa-location-crosshairs:before, .fa-location:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-house-leave:before, .fa-house-person-depart:before, .fa-house-person-leave:before {
  content: "";
}

.fa-ruler-triangle:before {
  content: "";
}

.fa-card-club:before {
  content: "";
}

.fa-child-dress:before {
  content: "";
}

.fa-users-between-lines:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-spinner-third:before {
  content: "";
}

.fa-face-grin-tears:before, .fa-grin-tears:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-computer-mouse-scrollwheel:before, .fa-mouse-alt:before {
  content: "";
}

.fa-calendar-times:before, .fa-calendar-xmark:before {
  content: "";
}

.fa-child-reaching:before {
  content: "";
}

.fa-table-layout:before {
  content: "";
}

.fa-narwhal:before {
  content: "";
}

.fa-ramp-loading:before {
  content: "";
}

.fa-calendar-circle-plus:before {
  content: "";
}

.fa-toothbrush:before {
  content: "";
}

.fa-border-inner:before {
  content: "";
}

.fa-paw-claws:before {
  content: "";
}

.fa-kiwi-fruit:before {
  content: "";
}

.fa-traffic-light-slow:before {
  content: "";
}

.fa-rectangle-code:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-keyboard-brightness:before {
  content: "";
}

.fa-books-medical:before {
  content: "";
}

.fa-lightbulb-slash:before {
  content: "";
}

.fa-home-blank:before, .fa-house-blank:before {
  content: "";
}

.fa-square-5:before {
  content: "";
}

.fa-heart-square:before, .fa-square-heart:before {
  content: "";
}

.fa-puzzle:before {
  content: "";
}

.fa-user-cog:before, .fa-user-gear:before {
  content: "";
}

.fa-pipe-circle-check:before {
  content: "";
}

.fa-arrow-up-1-9:before, .fa-sort-numeric-up:before {
  content: "";
}

.fa-octagon-exclamation:before {
  content: "";
}

.fa-dial-low:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-laptop-mobile:before, .fa-phone-laptop:before {
  content: "";
}

.fa-conveyor-belt-alt:before, .fa-conveyor-belt-boxes:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-starfighter-alt-advanced:before, .fa-starfighter-twin-ion-engine-advanced:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-starfighter-alt:before, .fa-starfighter-twin-ion-engine:before {
  content: "";
}

.fa-rocket-launch:before {
  content: "";
}

.fa-mosquito-net:before {
  content: "";
}

.fa-vent-damper:before {
  content: "";
}

.fa-bridge-water:before {
  content: "";
}

.fa-ban-bug:before, .fa-debug:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-garage-car:before {
  content: "";
}

.fa-square-kanban:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-coffee-pot:before {
  content: "";
}

.fa-mouse-field:before {
  content: "";
}

.fa-digging:before, .fa-person-digging:before {
  content: "";
}

.fa-shower-alt:before, .fa-shower-down:before {
  content: "";
}

.fa-box-circle-check:before {
  content: "";
}

.fa-brightness:before {
  content: "";
}

.fa-car-side-bolt:before {
  content: "";
}

.fa-ornament:before {
  content: "";
}

.fa-phone-arrow-down-left:before, .fa-phone-arrow-down:before, .fa-phone-incoming:before {
  content: "";
}

.fa-cloud-word:before {
  content: "";
}

.fa-hand-fingers-crossed:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-gauge-simple-med:before, .fa-gauge-simple:before, .fa-tachometer-average:before {
  content: "";
}

.fa-arrow-down-small-big:before, .fa-sort-size-down-alt:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-face-melting:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-pen-alt-slash:before, .fa-pen-clip-slash:before {
  content: "";
}

.fa-quote-right-alt:before, .fa-quote-right:before {
  content: "";
}

.fa-scroll-old:before {
  content: "";
}

.fa-guitars:before {
  content: "";
}

.fa-phone-xmark:before {
  content: "";
}

.fa-hose:before {
  content: "";
}

.fa-clock-six:before {
  content: "";
}

.fa-shirt:before, .fa-t-shirt:before, .fa-tshirt:before {
  content: "";
}

.fa-billboard:before {
  content: "";
}

.fa-square-r:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-envelope-open-dollar:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-sun-cloud:before {
  content: "";
}

.fa-lamp-floor:before {
  content: "";
}

.fa-square-7:before {
  content: "";
}

.fa-tenge-sign:before, .fa-tenge:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-hands-holding:before {
  content: "";
}

.fa-campfire:before {
  content: "";
}

.fa-circle-ampersand:before {
  content: "";
}

.fa-snowflakes:before {
  content: "";
}

.fa-hands-clapping:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-leaf-maple:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-person-circle-xmark:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-cup-straw-swoosh:before {
  content: "";
}

.fa-temperature-hot:before, .fa-temperature-sun:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-high-definition:before, .fa-rectangle-hd:before {
  content: "";
}

.fa-j:before {
  content: "J";
}

.fa-galaxy:before {
  content: "";
}

.fa-users-viewfinder:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-cherries:before {
  content: "";
}

.fa-external-link-alt:before, .fa-up-right-from-square:before {
  content: "";
}

.fa-circle-sort:before, .fa-sort-circle:before {
  content: "";
}

.fa-table-cells:before, .fa-th:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-siren:before {
  content: "";
}

.fa-arrow-up-to-dotted-line:before {
  content: "";
}

.fa-image-landscape:before, .fa-landscape:before {
  content: "";
}

.fa-tank-water:before {
  content: "";
}

.fa-curling-stone:before, .fa-curling:before {
  content: "";
}

.fa-gamepad-alt:before, .fa-gamepad-modern:before {
  content: "";
}

.fa-messages-question:before {
  content: "";
}

.fa-bible:before, .fa-book-bible:before {
  content: "";
}

.fa-o:before {
  content: "O";
}

.fa-medkit:before, .fa-suitcase-medical:before {
  content: "";
}

.fa-briefcase-arrow-right:before {
  content: "";
}

.fa-expand-wide:before {
  content: "";
}

.fa-clock-eleven-thirty:before {
  content: "";
}

.fa-rv:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-dreidel:before {
  content: "";
}

.fa-female:before, .fa-person-dress:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-briefcase-clock:before, .fa-business-time:before {
  content: "";
}

.fa-flower-tulip:before {
  content: "";
}

.fa-people-pants-simple:before {
  content: "";
}

.fa-cloud-drizzle:before {
  content: "";
}

.fa-table-cells-large:before, .fa-th-large:before {
  content: "";
}

.fa-book-tanakh:before, .fa-tanakh:before {
  content: "";
}

.fa-solar-system:before {
  content: "";
}

.fa-seal-question:before {
  content: "";
}

.fa-phone-volume:before, .fa-volume-control-phone:before {
  content: "";
}

.fa-disc-drive:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-rows:before, .fa-table-rows:before {
  content: "";
}

.fa-location-exclamation:before, .fa-map-marker-exclamation:before {
  content: "";
}

.fa-face-fearful:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-bus-school:before {
  content: "";
}

.fa-film-slash:before {
  content: "";
}

.fa-square-arrow-down-right:before {
  content: "";
}

.fa-book-sparkles:before, .fa-book-spells:before {
  content: "";
}

.fa-washer:before, .fa-washing-machine:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-user-visor:before {
  content: "";
}

.fa-file-plus-minus:before {
  content: "";
}

.fa-chess-clock-alt:before, .fa-chess-clock-flip:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-plane-lock:before {
  content: "";
}

.fa-steering-wheel:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-stretcher:before {
  content: "";
}

.fa-book-law:before, .fa-book-section:before {
  content: "";
}

.fa-inboxes:before {
  content: "";
}

.fa-coffee-bean:before {
  content: "";
}

.fa-circle-yen:before {
  content: "";
}

.fa-brackets-curly:before {
  content: "";
}

.fa-ellipsis-stroke-vertical:before, .fa-ellipsis-v-alt:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-square-1:before {
  content: "";
}

.fa-birthday-cake:before, .fa-cake-candles:before, .fa-cake:before {
  content: "";
}

.fa-head-side:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-dolly-empty:before {
  content: "";
}

.fa-face-tissue:before {
  content: "";
}

.fa-angle-double-up:before, .fa-angles-up:before {
  content: "";
}

.fa-bin-recycle:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-chart-line-down:before {
  content: "";
}

.fa-arrow-right-to-city:before {
  content: "";
}

.fa-lock-a:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-person-pinball:before {
  content: "";
}

.fa-arrow-up-9-1:before, .fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-apple-core:before {
  content: "";
}

.fa-circle-y:before {
  content: "";
}

.fa-h6:before {
  content: "";
}

.fa-litecoin-sign:before {
  content: "";
}

.fa-circle-small:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-arrow-turn-down-left:before {
  content: "";
}

.fa-circle-nodes:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-comment-alt-medical:before, .fa-message-medical:before {
  content: "";
}

.fa-rugby-ball:before {
  content: "";
}

.fa-comment-music:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-tree-alt:before, .fa-tree-deciduous:before {
  content: "";
}

.fa-puzzle-piece-alt:before, .fa-puzzle-piece-simple:before {
  content: "";
}

.fa-truck-field-un:before {
  content: "";
}

.fa-nfc-trash:before {
  content: "";
}

.fa-hourglass-empty:before, .fa-hourglass:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-file-times:before, .fa-file-xmark:before {
  content: "";
}

.fa-home-heart:before, .fa-house-heart:before {
  content: "";
}

.fa-house-chimney-blank:before {
  content: "";
}

.fa-meter-bolt:before {
  content: "";
}

.fa-user-doctor:before, .fa-user-md:before {
  content: "";
}

.fa-slash-back:before {
  content: "\\";
}

.fa-circle-info:before, .fa-info-circle:before {
  content: "";
}

.fa-fishing-rod:before {
  content: "";
}

.fa-hammer-crash:before {
  content: "";
}

.fa-message-heart:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-camera-polaroid:before {
  content: "";
}

.fa-camera-alt:before, .fa-camera:before {
  content: "";
}

.fa-square-virus:before {
  content: "";
}

.fa-cart-arrow-up:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-car-on:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-arrow-down-1-9:before, .fa-sort-numeric-asc:before, .fa-sort-numeric-down:before {
  content: "";
}

.fa-buoy-mooring:before {
  content: "";
}

.fa-square-4:before {
  content: "";
}

.fa-hand-holding-droplet:before, .fa-hand-holding-water:before {
  content: "";
}

.fa-tricycle-adult:before {
  content: "";
}

.fa-waveform:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-star-sharp-half-alt:before, .fa-star-sharp-half-stroke:before {
  content: "";
}

.fa-nfc-signal:before {
  content: "";
}

.fa-plane-prop:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-clock-desk:before {
  content: "";
}

.fa-calendar-clock:before, .fa-calendar-time:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-prescription-bottle-alt:before, .fa-prescription-bottle-medical:before {
  content: "";
}

.fa-plate-utensils:before {
  content: "";
}

.fa-family-pants:before {
  content: "";
}

.fa-hose-reel:before {
  content: "";
}

.fa-house-window:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-cloud-rainbow:before {
  content: "";
}

.fa-person-cane:before {
  content: "";
}

.fa-alien:before {
  content: "";
}

.fa-tent:before {
  content: "";
}

.fa-laptop-binary:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-people-dress-simple:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-arrow-down-a-z:before, .fa-sort-alpha-asc:before, .fa-sort-alpha-down:before {
  content: "";
}

.fa-bowling-ball-pin:before {
  content: "";
}

.fa-bell-school-slash:before {
  content: "";
}

.fa-plus-large:before {
  content: "";
}

.fa-money-bill-wheat:before {
  content: "";
}

.fa-camera-viewfinder:before, .fa-screenshot:before {
  content: "";
}

.fa-comment-alt-music:before, .fa-message-music:before {
  content: "";
}

.fa-car-building:before {
  content: "";
}

.fa-border-bottom-right:before, .fa-border-style-alt:before {
  content: "";
}

.fa-octagon:before {
  content: "";
}

.fa-comment-arrow-up-right:before {
  content: "";
}

.fa-octagon-divide:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-arrow-left-rotate:before, .fa-arrow-rotate-back:before, .fa-arrow-rotate-backward:before, .fa-arrow-rotate-left:before, .fa-undo:before {
  content: "";
}

.fa-tv-music:before {
  content: "";
}

.fa-hard-drive:before, .fa-hdd:before {
  content: "";
}

.fa-reel:before {
  content: "";
}

.fa-face-grin-squint-tears:before, .fa-grin-squint-tears:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-list-alt:before, .fa-rectangle-list:before {
  content: "";
}

.fa-tarp-droplet:before {
  content: "";
}

.fa-alarm-exclamation:before {
  content: "";
}

.fa-house-medical-circle-check:before {
  content: "";
}

.fa-traffic-cone:before {
  content: "";
}

.fa-grate:before {
  content: "";
}

.fa-arrow-down-right:before {
  content: "";
}

.fa-person-skiing-nordic:before, .fa-skiing-nordic:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-person-from-portal:before, .fa-portal-exit:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-cowbell-circle-plus:before, .fa-cowbell-more:before {
  content: "";
}

.fa-arrow-alt-circle-left:before, .fa-circle-left:before {
  content: "";
}

.fa-distribute-spacing-vertical:before {
  content: "";
}

.fa-signal-alt-2:before, .fa-signal-bars-fair:before {
  content: "";
}

.fa-sportsball:before {
  content: "";
}

.fa-game-console-handheld-crank:before {
  content: "";
}

.fa-subway:before, .fa-train-subway:before {
  content: "";
}

.fa-chart-gantt:before {
  content: "";
}

.fa-face-smile-upside-down:before {
  content: "";
}

.fa-ball-pile:before {
  content: "";
}

.fa-badge-dollar:before {
  content: "";
}

.fa-money-bills-alt:before, .fa-money-bills-simple:before {
  content: "";
}

.fa-list-timeline:before {
  content: "";
}

.fa-indian-rupee-sign:before, .fa-indian-rupee:before, .fa-inr:before {
  content: "";
}

.fa-crop-alt:before, .fa-crop-simple:before {
  content: "";
}

.fa-money-bill-1:before, .fa-money-bill-alt:before {
  content: "";
}

.fa-left-long:before, .fa-long-arrow-alt-left:before {
  content: "";
}

.fa-keyboard-down:before {
  content: "";
}

.fa-circle-up-right:before {
  content: "";
}

.fa-cloud-bolt-moon:before, .fa-thunderstorm-moon:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-bracket-round-right:before {
  content: ")";
}

.fa-circle-sterling:before {
  content: "";
}

.fa-circle-5:before {
  content: "";
}

.fa-minus:before, .fa-subtract:before {
  content: "";
}

.fa-fire-flame:before, .fa-flame:before {
  content: "";
}

.fa-arrow-alt-to-right:before, .fa-right-to-line:before {
  content: "";
}

.fa-gif:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-trash-slash:before {
  content: "";
}

.fa-arrow-left-long:before, .fa-long-arrow-left:before {
  content: "";
}

.fa-plug-circle-check:before {
  content: "";
}

.fa-font-case:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-arrow-down-left:before {
  content: "";
}

.fa-franc-sign:before {
  content: "";
}

.fa-flask-poison:before, .fa-flask-round-poison:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-book-circle-arrow-right:before {
  content: "";
}

.fa-chart-user:before, .fa-user-chart:before {
  content: "";
}

.fa-american-sign-language-interpreting:before, .fa-asl-interpreting:before, .fa-hands-american-sign-language-interpreting:before, .fa-hands-asl-interpreting:before {
  content: "";
}

.fa-presentation-screen:before, .fa-presentation:before {
  content: "";
}

.fa-circle-bolt:before {
  content: "";
}

.fa-face-smile-halo:before {
  content: "";
}

.fa-cart-circle-arrow-down:before {
  content: "";
}

.fa-house-person-arrive:before, .fa-house-person-return:before, .fa-house-return:before {
  content: "";
}

.fa-comment-alt-times:before, .fa-message-times:before, .fa-message-xmark:before {
  content: "";
}

.fa-file-award:before, .fa-file-certificate:before {
  content: "";
}

.fa-user-doctor-hair-long:before {
  content: "";
}

.fa-camera-home:before, .fa-camera-security:before {
  content: "";
}

.fa-cog:before, .fa-gear:before {
  content: "";
}

.fa-droplet-slash:before, .fa-tint-slash:before {
  content: "";
}

.fa-book-heart:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-duck:before {
  content: "";
}

.fa-mosquito:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-flag-alt:before, .fa-flag-swallowtail:before {
  content: "";
}

.fa-person-military-rifle:before {
  content: "";
}

.fa-car-garage:before {
  content: "";
}

.fa-cart-shopping:before, .fa-shopping-cart:before {
  content: "";
}

.fa-book-font:before {
  content: "";
}

.fa-shield-plus:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-eye-dropper-full:before {
  content: "";
}

.fa-distribute-spacing-horizontal:before {
  content: "";
}

.fa-tablet-rugged:before {
  content: "";
}

.fa-temperature-frigid:before, .fa-temperature-snow:before {
  content: "";
}

.fa-moped:before {
  content: "";
}

.fa-face-smile-plus:before, .fa-smile-plus:before {
  content: "";
}

.fa-radio-alt:before, .fa-radio-tuner:before {
  content: "";
}

.fa-face-swear:before {
  content: "";
}

.fa-water-arrow-down:before, .fa-water-lower:before {
  content: "";
}

.fa-scanner-touchscreen:before {
  content: "";
}

.fa-circle-7:before {
  content: "";
}

.fa-plug-circle-plus:before {
  content: "";
}

.fa-person-ski-jumping:before, .fa-ski-jump:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-water-arrow-up:before, .fa-water-rise:before {
  content: "";
}

.fa-waveform-lines:before, .fa-waveform-path:before {
  content: "";
}

.fa-split:before {
  content: "";
}

.fa-film-canister:before, .fa-film-cannister:before {
  content: "";
}

.fa-folder-times:before, .fa-folder-xmark:before {
  content: "";
}

.fa-toilet-paper-alt:before, .fa-toilet-paper-blank:before {
  content: "";
}

.fa-tablet-android-alt:before, .fa-tablet-screen:before {
  content: "";
}

.fa-hexagon-vertical-nft-slanted:before {
  content: "";
}

.fa-folder-music:before {
  content: "";
}

.fa-desktop-medical:before, .fa-display-medical:before {
  content: "";
}

.fa-share-all:before {
  content: "";
}

.fa-peapod:before {
  content: "";
}

.fa-chess-clock:before {
  content: "";
}

.fa-axe:before {
  content: "";
}

.fa-square-d:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-mobile-signal-out:before {
  content: "";
}

.fa-arrow-turn-up:before, .fa-level-up:before {
  content: "";
}

.fa-u:before {
  content: "U";
}

.fa-arrow-up-from-dotted-line:before {
  content: "";
}

.fa-square-root-alt:before, .fa-square-root-variable:before {
  content: "";
}

.fa-light-switch-on:before {
  content: "";
}

.fa-arrow-down-arrow-up:before, .fa-sort-alt:before {
  content: "";
}

.fa-raindrops:before {
  content: "";
}

.fa-dash:before, .fa-minus-large:before {
  content: "";
}

.fa-clock-four:before, .fa-clock:before {
  content: "";
}

.fa-input-numeric:before {
  content: "";
}

.fa-truck-tow:before {
  content: "";
}

.fa-backward-step:before, .fa-step-backward:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-car-bolt:before {
  content: "";
}

.fa-arrows-maximize:before, .fa-expand-arrows:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-cloud-sleet:before {
  content: "";
}

.fa-lamp-street:before {
  content: "";
}

.fa-list-radio:before {
  content: "";
}

.fa-pen-nib-slash:before {
  content: "";
}

.fa-baseball-bat-ball:before {
  content: "";
}

.fa-square-up-left:before {
  content: "";
}

.fa-overline:before {
  content: "";
}

.fa-s:before {
  content: "S";
}

.fa-timeline:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-arrows-from-dotted-line:before {
  content: "";
}

.fa-usb-drive:before {
  content: "";
}

.fa-ballot:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-location-dot-slash:before, .fa-map-marker-alt-slash:before {
  content: "";
}

.fa-cards:before {
  content: "";
}

.fa-clinic-medical:before, .fa-house-chimney-medical:before {
  content: "";
}

.fa-boxing-glove:before, .fa-glove-boxing:before {
  content: "";
}

.fa-temperature-3:before, .fa-temperature-three-quarters:before, .fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-bell-school:before {
  content: "";
}

.fa-mobile-android-alt:before, .fa-mobile-screen:before {
  content: "";
}

.fa-plane-up:before {
  content: "";
}

.fa-folder-heart:before {
  content: "";
}

.fa-circle-location-arrow:before, .fa-location-circle:before {
  content: "";
}

.fa-face-head-bandage:before {
  content: "";
}

.fa-maki-roll:before, .fa-makizushi:before, .fa-sushi-roll:before {
  content: "";
}

.fa-car-bump:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-racquet:before {
  content: "";
}

.fa-car-mirrors:before {
  content: "";
}

.fa-industry-alt:before, .fa-industry-windows:before {
  content: "";
}

.fa-bolt-auto:before {
  content: "";
}

.fa-battery-3:before, .fa-battery-half:before {
  content: "";
}

.fa-flux-capacitor:before {
  content: "";
}

.fa-mountain-city:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-honey-pot:before {
  content: "";
}

.fa-olive:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-filter-list:before {
  content: "";
}

.fa-outlet:before {
  content: "";
}

.fa-sliders-h:before, .fa-sliders:before {
  content: "";
}

.fa-cauldron:before {
  content: "";
}

.fa-people:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-croissant:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-cent-sign:before {
  content: "";
}

.fa-swords-laser:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-person-pregnant:before {
  content: "";
}

.fa-square-u:before {
  content: "";
}

.fa-wand-sparkles:before {
  content: "";
}

.fa-router:before {
  content: "";
}

.fa-ellipsis-v:before, .fa-ellipsis-vertical:before {
  content: "";
}

.fa-sword-laser-alt:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-coin:before {
  content: "";
}

.fa-laptop-slash:before {
  content: "";
}

.fa-long-arrow-alt-right:before, .fa-right-long:before {
  content: "";
}

.fa-circle-b:before {
  content: "";
}

.fa-person-dress-simple:before {
  content: "";
}

.fa-pipe-collar:before {
  content: "";
}

.fa-lights-holiday:before {
  content: "";
}

.fa-citrus:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-laptop-file:before {
  content: "";
}

.fa-teletype:before, .fa-tty:before {
  content: "";
}

.fa-chart-tree-map:before {
  content: "";
}

.fa-diagram-next:before {
  content: "";
}

.fa-person-rifle:before {
  content: "";
}

.fa-clock-five-thirty:before {
  content: "";
}

.fa-pipe-valve:before {
  content: "";
}

.fa-arrow-up-from-arc:before {
  content: "";
}

.fa-face-spiral-eyes:before {
  content: "";
}

.fa-compress-wide:before {
  content: "";
}

.fa-circle-phone-hangup:before, .fa-phone-circle-down:before {
  content: "";
}

.fa-gear-complex-code:before {
  content: "";
}

.fa-house-medical-circle-exclamation:before {
  content: "";
}

.fa-badminton:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-hiking:before, .fa-person-hiking:before {
  content: "";
}

.fa-arrow-alt-from-left:before, .fa-right-from-line:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-shuttlecock:before {
  content: "";
}

.fa-user-hair:before {
  content: "";
}

.fa-eye-evil:before {
  content: "";
}

.fa-people-pulling:before {
  content: "";
}

.fa-n:before {
  content: "N";
}

.fa-swap:before {
  content: "";
}

.fa-garage:before {
  content: "";
}

.fa-cable-car:before, .fa-tram:before {
  content: "";
}

.fa-shovel-snow:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-face-lying:before {
  content: "";
}

.fa-sprinkler:before {
  content: "";
}

.fa-building-circle-xmark:before {
  content: "";
}

.fa-person-sledding:before, .fa-sledding:before {
  content: "";
}

.fa-game-console-handheld:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-clock-six-thirty:before {
  content: "";
}

.fa-battery-slash:before {
  content: "";
}

.fa-tugrik-sign:before {
  content: "";
}

.fa-arrows-down-to-line:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-angles-up-down:before {
  content: "";
}

.fa-inventory:before, .fa-shelves:before {
  content: "";
}

.fa-cloud-snow:before {
  content: "";
}

.fa-face-grin:before, .fa-grin:before {
  content: "";
}

.fa-backspace:before, .fa-delete-left:before {
  content: "";
}

.fa-oven:before {
  content: "";
}

.fa-cloud-binary:before {
  content: "";
}

.fa-eye-dropper-empty:before, .fa-eye-dropper:before, .fa-eyedropper:before {
  content: "";
}

.fa-comment-captions:before {
  content: "";
}

.fa-comments-question:before {
  content: "";
}

.fa-scribble:before {
  content: "";
}

.fa-rotate-exclamation:before {
  content: "";
}

.fa-file-circle-check:before {
  content: "";
}

.fa-glass:before {
  content: "";
}

.fa-loader:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-user-pilot:before {
  content: "";
}

.fa-mobile-android:before, .fa-mobile-phone:before, .fa-mobile:before {
  content: "";
}

.fa-code-pull-request-closed:before {
  content: "";
}

.fa-face-meh:before, .fa-meh:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-book-dead:before, .fa-book-skull:before {
  content: "";
}

.fa-drivers-license:before, .fa-id-card:before {
  content: "";
}

.fa-face-dotted:before {
  content: "";
}

.fa-face-worried:before {
  content: "";
}

.fa-dedent:before, .fa-outdent:before {
  content: "";
}

.fa-heart-circle-exclamation:before {
  content: "";
}

.fa-home-alt:before, .fa-home-lg-alt:before, .fa-home:before, .fa-house:before {
  content: "";
}

.fa-vector-circle:before {
  content: "";
}

.fa-car-circle-bolt:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-flying-disc:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-square-down-right:before {
  content: "";
}

.fa-b:before {
  content: "B";
}

.fa-seat-airline:before {
  content: "";
}

.fa-eclipse-alt:before, .fa-moon-over-sun:before {
  content: "";
}

.fa-pipe:before {
  content: "|";
}

.fa-file-medical:before {
  content: "";
}

.fa-potato:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-circle-a:before {
  content: "";
}

.fa-helmet-battle:before {
  content: "";
}

.fa-butter:before {
  content: "";
}

.fa-blanket-fire:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-castle:before {
  content: "";
}

.fa-golf-club:before {
  content: "";
}

.fa-arrow-right-arrow-left:before, .fa-exchange:before {
  content: "";
}

.fa-redo-alt:before, .fa-rotate-forward:before, .fa-rotate-right:before {
  content: "";
}

.fa-cutlery:before, .fa-utensils:before {
  content: "";
}

.fa-arrow-up-wide-short:before, .fa-sort-amount-up:before {
  content: "";
}

.fa-chart-pie-simple-circle-dollar:before {
  content: "";
}

.fa-balloons:before {
  content: "";
}

.fa-mill-sign:before {
  content: "";
}

.fa-bowl-rice:before {
  content: "";
}

.fa-timeline-arrow:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-game-board-alt:before, .fa-game-board-simple:before {
  content: "";
}

.fa-circle-video:before, .fa-video-circle:before {
  content: "";
}

.fa-chart-scatter-bubble:before {
  content: "";
}

.fa-house-turret:before {
  content: "";
}

.fa-banana:before {
  content: "";
}

.fa-hand-holding-skull:before {
  content: "";
}

.fa-people-dress:before {
  content: "";
}

.fa-couch-small:before, .fa-loveseat:before {
  content: "";
}

.fa-broadcast-tower:before, .fa-tower-broadcast:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-block-quote:before {
  content: "";
}

.fa-long-arrow-alt-up:before, .fa-up-long:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-money-check-dollar-pen:before, .fa-money-check-edit-alt:before {
  content: "";
}

.fa-arrow-alt-from-bottom:before, .fa-up-from-line:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-grid-round-2-plus:before {
  content: "";
}

.fa-people-pants:before {
  content: "";
}

.fa-mound:before {
  content: "";
}

.fa-windsock:before {
  content: "";
}

.fa-circle-half:before {
  content: "";
}

.fa-brake-warning:before {
  content: "";
}

.fa-toilet-portable:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-file-arrow-down:before, .fa-file-download:before {
  content: "";
}

.fa-sax-hot:before, .fa-saxophone-fire:before {
  content: "";
}

.fa-camera-web-slash:before, .fa-webcam-slash:before {
  content: "";
}

.fa-folder-medical:before {
  content: "";
}

.fa-folder-cog:before, .fa-folder-gear:before {
  content: "";
}

.fa-hand-wave:before {
  content: "";
}

.fa-arrow-up-arrow-down:before, .fa-sort-up-down:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-shield-cat:before {
  content: "";
}

.fa-comment-alt-slash:before, .fa-message-slash:before {
  content: "";
}

.fa-bolt:before, .fa-zap:before {
  content: "";
}

.fa-trash-can-check:before {
  content: "";
}

.fa-glass-water:before {
  content: "";
}

.fa-oil-well:before {
  content: "";
}

.fa-person-simple:before {
  content: "";
}

.fa-vault:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-plane-circle-xmark:before {
  content: "";
}

.fa-cny:before, .fa-jpy:before, .fa-rmb:before, .fa-yen-sign:before, .fa-yen:before {
  content: "";
}

.fa-gear-code:before {
  content: "";
}

.fa-notes:before {
  content: "";
}

.fa-rouble:before, .fa-rub:before, .fa-ruble-sign:before, .fa-ruble:before {
  content: "";
}

.fa-trash-arrow-turn-left:before, .fa-trash-undo:before {
  content: "";
}

.fa-champagne-glass:before, .fa-glass-champagne:before {
  content: "";
}

.fa-objects-align-center-horizontal:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-trash-alt-slash:before, .fa-trash-can-slash:before {
  content: "";
}

.fa-screen-users:before, .fa-users-class:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-arrow-square-left:before, .fa-square-arrow-left:before {
  content: "";
}

.fa-square-8:before {
  content: "";
}

.fa-face-smile-hearts:before {
  content: "";
}

.fa-brackets-square:before, .fa-brackets:before {
  content: "";
}

.fa-laptop-arrow-down:before {
  content: "";
}

.fa-hockey-stick-puck:before {
  content: "";
}

.fa-house-tree:before {
  content: "";
}

.fa-signal-2:before, .fa-signal-fair:before {
  content: "";
}

.fa-face-laugh-wink:before, .fa-laugh-wink:before {
  content: "";
}

.fa-circle-dollar:before, .fa-dollar-circle:before, .fa-usd-circle:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-arrows-repeat:before, .fa-repeat-alt:before {
  content: "";
}

.fa-bore-hole:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-image-polaroid:before {
  content: "";
}

.fa-wave-triangle:before {
  content: "";
}

.fa-person-running-fast:before {
  content: "";
}

.fa-arrow-alt-circle-down:before, .fa-circle-down:before {
  content: "";
}

.fa-grill:before {
  content: "";
}

.fa-arrows-turn-to-dots:before {
  content: "";
}

.fa-analytics:before, .fa-chart-mixed:before {
  content: "";
}

.fa-florin-sign:before {
  content: "";
}

.fa-arrow-down-short-wide:before, .fa-sort-amount-desc:before, .fa-sort-amount-down-alt:before {
  content: "";
}

.fa-less-than:before {
  content: "<";
}

.fa-desktop-code:before, .fa-display-code:before {
  content: "";
}

.fa-face-drooling:before {
  content: "";
}

.fa-oil-temp:before, .fa-oil-temperature:before {
  content: "";
}

.fa-question-square:before, .fa-square-question:before {
  content: "";
}

.fa-air-conditioner:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-mountains:before {
  content: "";
}

.fa-omega:before {
  content: "";
}

.fa-car-tunnel:before {
  content: "";
}

.fa-person-dolly-empty:before {
  content: "";
}

.fa-pan-food:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-arrow-right-long:before, .fa-long-arrow-right:before {
  content: "";
}

.fa-tickets-airline:before {
  content: "";
}

.fa-anchor-circle-xmark:before {
  content: "";
}

.fa-ellipsis-h:before, .fa-ellipsis:before {
  content: "";
}

.fa-nfc-slash:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-first-aid:before, .fa-kit-medical:before {
  content: "";
}

.fa-grid-2-plus:before {
  content: "";
}

.fa-bells:before {
  content: "";
}

.fa-person-through-window:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-envelope-badge:before, .fa-envelope-dot:before {
  content: "";
}

.fa-hands-holding-circle:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-bowl-chopsticks:before {
  content: "";
}

.fa-credit-card-alt:before, .fa-credit-card:before {
  content: "";
}

.fa-circle-s:before {
  content: "";
}

.fa-box-ballot:before {
  content: "";
}

.fa-automobile:before, .fa-car:before {
  content: "";
}

.fa-hand-holding-hand:before {
  content: "";
}

.fa-user-tie-hair:before {
  content: "";
}

.fa-podium-star:before {
  content: "";
}

.fa-business-front:before, .fa-party-back:before, .fa-trian-balbot:before, .fa-user-hair-mullet:before {
  content: "";
}

.fa-microphone-stand:before {
  content: "";
}

.fa-book-open-reader:before, .fa-book-reader:before {
  content: "";
}

.fa-family-dress:before {
  content: "";
}

.fa-circle-x:before {
  content: "";
}

.fa-cabin:before {
  content: "";
}

.fa-mountain-sun:before {
  content: "";
}

.fa-chart-simple-horizontal:before {
  content: "";
}

.fa-arrows-left-right-to-line:before {
  content: "";
}

.fa-hand-back-point-left:before {
  content: "";
}

.fa-comment-alt-dots:before, .fa-message-dots:before, .fa-messaging:before {
  content: "";
}

.fa-file-heart:before {
  content: "";
}

.fa-beer-foam:before, .fa-beer-mug:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-drone:before {
  content: "";
}

.fa-truck-droplet:before {
  content: "";
}

.fa-file-circle-xmark:before {
  content: "";
}

.fa-temperature-arrow-up:before, .fa-temperature-up:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-person-fairy:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-book-copy:before {
  content: "";
}

.fa-h-square:before, .fa-square-h:before {
  content: "";
}

.fa-square-c:before {
  content: "";
}

.fa-clock-two:before {
  content: "";
}

.fa-square-ellipsis-vertical:before {
  content: "";
}

.fa-calendar-users:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-bee:before {
  content: "";
}

.fa-temperature-4:before, .fa-temperature-full:before, .fa-thermometer-4:before, .fa-thermometer-full:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-candy-bar:before, .fa-chocolate-bar:before {
  content: "";
}

.fa-xmark-large:before {
  content: "";
}

.fa-pinata:before {
  content: "";
}

.fa-arrows-from-line:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-bowl-spoon:before {
  content: "";
}

.fa-hexagon-check:before {
  content: "";
}

.fa-plug-circle-xmark:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-traffic-light-stop:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-accent-grave:before {
  content: "`";
}

.fa-hands-helping:before, .fa-handshake-angle:before {
  content: "";
}

.fa-circle-0:before {
  content: "";
}

.fa-dial-med-low:before {
  content: "";
}

.fa-location-dot:before, .fa-map-marker-alt:before {
  content: "";
}

.fa-crab:before {
  content: "";
}

.fa-box-full:before, .fa-box-open-full:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-greater-than:before {
  content: ">";
}

.fa-quotes:before {
  content: "";
}

.fa-pretzel:before {
  content: "";
}

.fa-person-swimming:before, .fa-swimmer:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-user-robot-xmarks:before {
  content: "";
}

.fa-comment-alt-quote:before, .fa-message-quote:before {
  content: "";
}

.fa-candy-corn:before {
  content: "";
}

.fa-folder-magnifying-glass:before, .fa-folder-search:before {
  content: "";
}

.fa-notebook:before {
  content: "";
}

.fa-droplet:before, .fa-tint:before {
  content: "";
}

.fa-bullseye-pointer:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-hexagon-image:before {
  content: "";
}

.fa-earth-america:before, .fa-earth-americas:before, .fa-earth:before, .fa-globe-americas:before {
  content: "";
}

.fa-crate-apple:before, .fa-apple-crate:before {
  content: "";
}

.fa-person-burst:before {
  content: "";
}

.fa-game-board:before {
  content: "";
}

.fa-hat-chef:before {
  content: "";
}

.fa-hand-back-point-right:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-snowflake-droplets:before {
  content: "";
}

.fa-battery-0:before, .fa-battery-empty:before {
  content: "";
}

.fa-grid-4:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-face-sunglasses:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-square-0:before {
  content: "";
}

.fa-section:before {
  content: "";
}

.fa-box-up:before, .fa-square-this-way-up:before {
  content: "";
}

.fa-gauge-high:before, .fa-tachometer-alt-fast:before, .fa-tachometer-alt:before {
  content: "";
}

.fa-square-ampersand:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-lamp-desk:before {
  content: "";
}

.fa-hospital-alt:before, .fa-hospital-wide:before, .fa-hospital:before {
  content: "";
}

.fa-poll-people:before {
  content: "";
}

.fa-glass-whiskey-rocks:before, .fa-whiskey-glass-ice:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-user-bounty-hunter:before {
  content: "";
}

.fa-bars-staggered:before, .fa-reorder:before, .fa-stream:before {
  content: "";
}

.fa-diagram-sankey:before {
  content: "";
}

.fa-cloud-hail-mixed:before {
  content: "";
}

.fa-circle-up-left:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-objects-align-left:before {
  content: "";
}

.fa-oil-can-drip:before {
  content: "";
}

.fa-face-smiling-hands:before {
  content: "";
}

.fa-broccoli:before {
  content: "";
}

.fa-route-interstate:before {
  content: "";
}

.fa-ear-muffs:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-transporter-empty:before {
  content: "";
}

.fa-blind:before, .fa-person-walking-with-cane:before {
  content: "";
}

.fa-angle-90:before {
  content: "";
}

.fa-rectangle-terminal:before {
  content: "";
}

.fa-kite:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-scrubber:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-heart-circle-bolt:before {
  content: "";
}

.fa-fish-bones:before {
  content: "";
}

.fa-deer-rudolph:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-head-side-heart:before {
  content: "";
}

.fa-square-e:before {
  content: "";
}

.fa-meter-fire:before {
  content: "";
}

.fa-cloud-hail:before {
  content: "";
}

.fa-check-to-slot:before, .fa-vote-yea:before {
  content: "";
}

.fa-money-from-bracket:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-car-bus:before {
  content: "";
}

.fa-speaker:before {
  content: "";
}

.fa-timer:before {
  content: "";
}

.fa-boxes-alt:before, .fa-boxes-stacked:before, .fa-boxes:before {
  content: "";
}

.fa-grill-hot:before {
  content: "";
}

.fa-ballot-check:before {
  content: "";
}

.fa-chain:before, .fa-link:before {
  content: "";
}

.fa-assistive-listening-systems:before, .fa-ear-listen:before {
  content: "";
}

.fa-file-minus:before {
  content: "";
}

.fa-tree-city:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-coffee-togo:before, .fa-cup-togo:before {
  content: "";
}

.fa-square-down-left:before {
  content: "";
}

.fa-burger-lettuce:before {
  content: "";
}

.fa-rupiah-sign:before {
  content: "";
}

.fa-magnifying-glass:before, .fa-search:before {
  content: "";
}

.fa-ping-pong-paddle-ball:before, .fa-table-tennis-paddle-ball:before, .fa-table-tennis:before {
  content: "";
}

.fa-diagnoses:before, .fa-person-dots-from-line:before {
  content: "";
}

.fa-chevron-double-down:before, .fa-chevrons-down:before {
  content: "";
}

.fa-trash-can-arrow-up:before, .fa-trash-restore-alt:before {
  content: "";
}

.fa-signal-3:before, .fa-signal-good:before {
  content: "";
}

.fa-location-question:before, .fa-map-marker-question:before {
  content: "";
}

.fa-floppy-disk-circle-xmark:before, .fa-floppy-disk-times:before, .fa-save-circle-xmark:before, .fa-save-times:before {
  content: "";
}

.fa-naira-sign:before {
  content: "";
}

.fa-peach:before {
  content: "";
}

.fa-taxi-bus:before {
  content: "";
}

.fa-bracket-curly-left:before, .fa-bracket-curly:before {
  content: "{";
}

.fa-lobster:before {
  content: "";
}

.fa-cart-flatbed-empty:before, .fa-dolly-flatbed-empty:before {
  content: "";
}

.fa-colon:before {
  content: ":";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-wand:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-file-edit:before, .fa-file-pen:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-table-picnic:before {
  content: "";
}

.fa-pen-square:before, .fa-pencil-square:before, .fa-square-pen:before {
  content: "";
}

.fa-circle-microphone-lines:before, .fa-microphone-circle-alt:before {
  content: "";
}

.fa-desktop-slash:before, .fa-display-slash:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-person-circle-exclamation:before {
  content: "";
}

.fa-transporter-2:before {
  content: "";
}

.fa-hand-receiving:before, .fa-hands-holding-diamond:before {
  content: "";
}

.fa-money-bill-simple-wave:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-battery-5:before, .fa-battery-full:before, .fa-battery:before {
  content: "";
}

.fa-bell-plus:before {
  content: "";
}

.fa-book-arrow-right:before {
  content: "";
}

.fa-hospitals:before {
  content: "";
}

.fa-club:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-dewpoint:before, .fa-droplet-degree:before {
  content: "";
}

.fa-code-compare:before {
  content: "";
}

.fa-list-dots:before, .fa-list-ul:before {
  content: "";
}

.fa-hand-holding-magic:before {
  content: "";
}

.fa-watermelon-slice:before {
  content: "";
}

.fa-circle-ellipsis:before {
  content: "";
}

.fa-school-lock:before {
  content: "";
}

.fa-tower-cell:before {
  content: "";
}

.fa-sd-cards:before {
  content: "";
}

.fa-jug-bottle:before {
  content: "";
}

.fa-down-long:before, .fa-long-arrow-alt-down:before {
  content: "";
}

.fa-envelopes:before {
  content: "";
}

.fa-phone-office:before {
  content: "";
}

.fa-ranking-star:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-nfc-pen:before {
  content: "";
}

.fa-person-harassing:before {
  content: "";
}

.fa-hat-winter:before {
  content: "";
}

.fa-brazilian-real-sign:before {
  content: "";
}

.fa-landmark-alt:before, .fa-landmark-dome:before {
  content: "";
}

.fa-bone-break:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-down-from-dotted-line:before {
  content: "";
}

.fa-television:before, .fa-tv-alt:before, .fa-tv:before {
  content: "";
}

.fa-border-left:before {
  content: "";
}

.fa-circle-divide:before {
  content: "";
}

.fa-shrimp:before {
  content: "";
}

.fa-list-check:before, .fa-tasks:before {
  content: "";
}

.fa-diagram-subtask:before {
  content: "";
}

.fa-jug-detergent:before {
  content: "";
}

.fa-circle-user:before, .fa-user-circle:before {
  content: "";
}

.fa-square-y:before {
  content: "";
}

.fa-user-doctor-hair:before {
  content: "";
}

.fa-planet-ringed:before {
  content: "";
}

.fa-mushroom:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-megaphone:before {
  content: "";
}

.fa-wreath-laurel:before {
  content: "";
}

.fa-circle-exclamation-check:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-box-dollar:before, .fa-box-usd:before {
  content: "";
}

.fa-car-burst:before, .fa-car-crash:before {
  content: "";
}

.fa-y:before {
  content: "Y";
}

.fa-user-headset:before {
  content: "";
}

.fa-arrows-retweet:before, .fa-retweet-alt:before {
  content: "";
}

.fa-person-snowboarding:before, .fa-snowboarding:before {
  content: "";
}

.fa-chevron-square-right:before, .fa-square-chevron-right:before {
  content: "";
}

.fa-lacrosse-stick-ball:before {
  content: "";
}

.fa-shipping-fast:before, .fa-truck-fast:before {
  content: "";
}

.fa-user-magnifying-glass:before {
  content: "";
}

.fa-star-sharp:before {
  content: "";
}

.fa-comment-heart:before {
  content: "";
}

.fa-circle-1:before {
  content: "";
}

.fa-circle-star:before, .fa-star-circle:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-cloud-fog:before, .fa-fog:before {
  content: "";
}

.fa-waffle:before {
  content: "";
}

.fa-music-alt:before, .fa-music-note:before {
  content: "";
}

.fa-hexagon-exclamation:before {
  content: "";
}

.fa-cart-shopping-fast:before {
  content: "";
}

.fa-object-union:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-starfighter:before {
  content: "";
}

.fa-adjust:before, .fa-circle-half-stroke:before {
  content: "";
}

.fa-arrow-right-long-to-line:before {
  content: "";
}

.fa-arrow-square-down:before, .fa-square-arrow-down:before {
  content: "";
}

.fa-diamond-half-stroke:before {
  content: "";
}

.fa-clapperboard:before {
  content: "";
}

.fa-chevron-square-left:before, .fa-square-chevron-left:before {
  content: "";
}

.fa-phone-intercom:before {
  content: "";
}

.fa-chain-horizontal:before, .fa-link-horizontal:before {
  content: "";
}

.fa-mango:before {
  content: "";
}

.fa-music-alt-slash:before, .fa-music-note-slash:before {
  content: "";
}

.fa-circle-radiation:before, .fa-radiation-alt:before {
  content: "";
}

.fa-face-tongue-sweat:before {
  content: "";
}

.fa-globe-stand:before {
  content: "";
}

.fa-baseball-ball:before, .fa-baseball:before {
  content: "";
}

.fa-circle-p:before {
  content: "";
}

.fa-award-simple:before {
  content: "";
}

.fa-jet-fighter-up:before {
  content: "";
}

.fa-diagram-project:before, .fa-project-diagram:before {
  content: "";
}

.fa-pedestal:before {
  content: "";
}

.fa-chart-pyramid:before {
  content: "";
}

.fa-sidebar:before {
  content: "";
}

.fa-frosty-head:before, .fa-snowman-head:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-burger-glass:before {
  content: "";
}

.fa-volume-mute:before, .fa-volume-times:before, .fa-volume-xmark:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-bars-filter:before {
  content: "";
}

.fa-paintbrush-pencil:before {
  content: "";
}

.fa-party-bell:before {
  content: "";
}

.fa-user-vneck-hair:before {
  content: "";
}

.fa-jack-o-lantern:before {
  content: "";
}

.fa-grip-horizontal:before, .fa-grip:before {
  content: "";
}

.fa-share-from-square:before, .fa-share-square:before {
  content: "";
}

.fa-keynote:before {
  content: "";
}

.fa-child-combatant:before, .fa-child-rifle:before {
  content: "";
}

.fa-gun:before {
  content: "";
}

.fa-phone-square:before, .fa-square-phone:before {
  content: "";
}

.fa-hat-beach:before {
  content: "";
}

.fa-add:before, .fa-plus:before {
  content: "+";
}

.fa-expand:before {
  content: "";
}

.fa-computer:before {
  content: "";
}

.fa-fort:before {
  content: "";
}

.fa-cloud-check:before {
  content: "";
}

.fa-close:before, .fa-multiply:before, .fa-remove:before, .fa-times:before, .fa-xmark:before {
  content: "";
}

.fa-face-smirking:before {
  content: "";
}

.fa-arrows-up-down-left-right:before, .fa-arrows:before {
  content: "";
}

.fa-chalkboard-teacher:before, .fa-chalkboard-user:before {
  content: "";
}

.fa-rhombus:before {
  content: "";
}

.fa-claw-marks:before {
  content: "";
}

.fa-peso-sign:before {
  content: "";
}

.fa-face-smile-tongue:before {
  content: "";
}

.fa-cart-circle-xmark:before {
  content: "";
}

.fa-building-shield:before {
  content: "";
}

.fa-circle-phone-flip:before, .fa-phone-circle-alt:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-users-line:before {
  content: "";
}

.fa-quote-left-alt:before, .fa-quote-left:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-key-skeleton:before {
  content: "";
}

.fa-trash-arrow-up:before, .fa-trash-restore:before {
  content: "";
}

.fa-arrow-down-up-lock:before {
  content: "";
}

.fa-arrow-down-to-bracket:before {
  content: "";
}

.fa-lines-leaning:before {
  content: "";
}

.fa-square-q:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-icons-alt:before, .fa-symbols:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-flask-gear:before {
  content: "";
}

.fa-highlighter-line:before {
  content: "";
}

.fa-bracket-left:before, .fa-bracket-square:before, .fa-bracket:before {
  content: "[";
}

.fa-island-tree-palm:before, .fa-island-tropical:before {
  content: "";
}

.fa-arrow-from-left:before, .fa-arrow-right-from-line:before {
  content: "";
}

.fa-h2:before {
  content: "";
}

.fa-equals:before {
  content: "=";
}

.fa-cake-slice:before, .fa-shortcake:before {
  content: "";
}

.fa-peanut:before {
  content: "";
}

.fa-wrench-simple:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-tally-2:before {
  content: "";
}

.fa-ils:before, .fa-shekel-sign:before, .fa-shekel:before, .fa-sheqel-sign:before, .fa-sheqel:before {
  content: "";
}

.fa-cars:before {
  content: "";
}

.fa-axe-battle:before {
  content: "";
}

.fa-user-hair-long:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-arrow-left-from-arc:before {
  content: "";
}

.fa-file-circle-info:before {
  content: "";
}

.fa-face-disappointed:before {
  content: "";
}

.fa-lasso-sparkles:before {
  content: "";
}

.fa-clock-eleven:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-siren-on:before {
  content: "";
}

.fa-clock-ten:before {
  content: "";
}

.fa-candle-holder:before {
  content: "";
}

.fa-video-arrow-down-left:before {
  content: "";
}

.fa-photo-film:before, .fa-photo-video:before {
  content: "";
}

.fa-floppy-disk-circle-arrow-right:before, .fa-save-circle-arrow-right:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-planet-moon:before {
  content: "";
}

.fa-face-eyes-xmarks:before {
  content: "";
}

.fa-chart-scatter:before {
  content: "";
}

.fa-display-arrow-down:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-arrow-trend-up:before {
  content: "";
}

.fa-plug-circle-minus:before {
  content: "";
}

.fa-olive-branch:before {
  content: "";
}

.fa-angle:before {
  content: "";
}

.fa-vacuum-robot:before {
  content: "";
}

.fa-sign-hanging:before, .fa-sign:before {
  content: "";
}

.fa-square-divide:before {
  content: "";
}

.fa-signal-stream-slash:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-eye-dropper-half:before {
  content: "";
}

.fa-store-lock:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-cloud-bolt-sun:before, .fa-thunderstorm-sun:before {
  content: "";
}

.fa-camera-slash:before {
  content: "";
}

.fa-comment-quote:before {
  content: "";
}

.fa-tablet-android:before, .fa-tablet:before {
  content: "";
}

.fa-school-flag:before {
  content: "";
}

.fa-message-code:before {
  content: "";
}

.fa-glass-half-empty:before, .fa-glass-half-full:before, .fa-glass-half:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-comment-alt-minus:before, .fa-message-minus:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-dinosaur:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-chain-horizontal-slash:before, .fa-link-horizontal-slash:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-nose:before {
  content: "";
}

.fa-arrow-left-to-arc:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-clouds:before {
  content: "";
}

.fa-money-bill-simple:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-table-pivot:before {
  content: "";
}

.fa-filter-slash:before {
  content: "";
}

.fa-trash-can-arrow-turn-left:before, .fa-trash-can-undo:before, .fa-trash-undo-alt:before {
  content: "";
}

.fa-notdef:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-person-to-door:before {
  content: "";
}

.fa-turntable:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-signal-1:before, .fa-signal-weak:before {
  content: "";
}

.fa-clock-five:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-car-alt:before, .fa-car-rear:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-computer-classic:before {
  content: "";
}

.fa-frame:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-battery-2:before, .fa-battery-quarter:before {
  content: "";
}

.fa-ellipsis-h-alt:before, .fa-ellipsis-stroke:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-baby-carriage:before, .fa-carriage-baby:before {
  content: "";
}

.fa-face-expressionless:before {
  content: "";
}

.fa-down-to-dotted-line:before {
  content: "";
}

.fa-cloud-music:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-cloud-minus:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-shield-minus:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-car-tilt:before {
  content: "";
}

.fa-gauge-circle-minus:before {
  content: "";
}

.fa-brightness-low:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-percent:before, .fa-percentage:before {
  content: "%";
}

.fa-truck-moving:before {
  content: "";
}

.fa-glass-water-droplet:before {
  content: "";
}

.fa-conveyor-belt:before {
  content: "";
}

.fa-location-check:before, .fa-map-marker-check:before {
  content: "";
}

.fa-coin-vertical:before {
  content: "";
}

.fa-display:before {
  content: "";
}

.fa-person-sign:before {
  content: "";
}

.fa-face-smile:before, .fa-smile:before {
  content: "";
}

.fa-phone-hangup:before {
  content: "";
}

.fa-signature-slash:before {
  content: "";
}

.fa-thumb-tack:before, .fa-thumbtack:before {
  content: "";
}

.fa-wheat-slash:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-clouds-sun:before {
  content: "";
}

.fa-person-praying:before, .fa-pray:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-face-vomit:before {
  content: "";
}

.fa-speakers:before {
  content: "";
}

.fa-teletype-answer:before, .fa-tty-answer:before {
  content: "";
}

.fa-mug-tea-saucer:before {
  content: "";
}

.fa-diagram-lean-canvas:before {
  content: "";
}

.fa-alt:before {
  content: "";
}

.fa-dial-med-high:before, .fa-dial:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-circle-trash:before, .fa-trash-circle:before {
  content: "";
}

.fa-rotate:before, .fa-sync-alt:before {
  content: "";
}

.fa-circle-quarters:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-tower-control:before {
  content: "";
}

.fa-arrow-up-triangle-square:before, .fa-sort-shapes-up:before {
  content: "";
}

.fa-whale:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-party-horn:before {
  content: "";
}

.fa-cogs:before, .fa-gears:before {
  content: "";
}

.fa-sun-alt:before, .fa-sun-bright:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-conveyor-belt-arm:before {
  content: "";
}

.fa-lock-keyhole-open:before, .fa-lock-open-alt:before {
  content: "";
}

.fa-box-fragile:before, .fa-square-fragile:before, .fa-square-wine-glass-crack:before {
  content: "";
}

.fa-arrow-up-right-dots:before {
  content: "";
}

.fa-square-n:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-face-grin-hearts:before, .fa-grin-hearts:before {
  content: "";
}

.fa-meter:before {
  content: "";
}

.fa-mandolin:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-transgender-alt:before, .fa-transgender:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-up-from-bracket:before {
  content: "";
}

.fa-knife-kitchen:before {
  content: "";
}

.fa-border-right:before {
  content: "";
}

.fa-arrow-turn-down:before, .fa-level-down:before {
  content: "";
}

.fa-spade:before {
  content: "";
}

.fa-card-spade:before {
  content: "";
}

.fa-line-columns:before {
  content: "";
}

.fa-arrow-right-to-line:before, .fa-arrow-to-right:before {
  content: "";
}

.fa-person-falling-burst:before {
  content: "";
}

.fa-flag-pennant:before, .fa-pennant:before {
  content: "";
}

.fa-conveyor-belt-empty:before {
  content: "";
}

.fa-user-group-simple:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-ticket-alt:before, .fa-ticket-simple:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-angle-double-left:before, .fa-angles-left:before {
  content: "";
}

.fa-camcorder:before, .fa-video-handheld:before {
  content: "";
}

.fa-pancakes:before {
  content: "";
}

.fa-album-circle-user:before {
  content: "";
}

.fa-subtitles-slash:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-dice-d10:before {
  content: "";
}

.fa-fireplace:before {
  content: "";
}

.fa-browser:before {
  content: "";
}

.fa-pen-paintbrush:before, .fa-pencil-paintbrush:before {
  content: "";
}

.fa-fish-cooked:before {
  content: "";
}

.fa-chair-office:before {
  content: "";
}

.fa-nesting-dolls:before {
  content: "";
}

.fa-clock-rotate-left:before, .fa-history:before {
  content: "";
}

.fa-trumpet:before {
  content: "";
}

.fa-face-grin-beam-sweat:before, .fa-grin-beam-sweat:before {
  content: "";
}

.fa-fire-smoke:before {
  content: "";
}

.fa-phone-missed:before {
  content: "";
}

.fa-arrow-right-from-file:before, .fa-file-export:before {
  content: "";
}

.fa-shield-blank:before, .fa-shield:before {
  content: "";
}

.fa-arrow-up-short-wide:before, .fa-sort-amount-up-alt:before {
  content: "";
}

.fa-arrows-repeat-1:before, .fa-repeat-1-alt:before {
  content: "";
}

.fa-gun-slash:before {
  content: "";
}

.fa-avocado:before {
  content: "";
}

.fa-binary:before {
  content: "";
}

.fa-glasses-alt:before, .fa-glasses-round:before {
  content: "";
}

.fa-phone-plus:before {
  content: "";
}

.fa-ditto:before {
  content: "\"";
}

.fa-person-seat:before {
  content: "";
}

.fa-house-medical:before {
  content: "";
}

.fa-golf-ball-tee:before, .fa-golf-ball:before {
  content: "";
}

.fa-chevron-circle-left:before, .fa-circle-chevron-left:before {
  content: "";
}

.fa-house-chimney-window:before {
  content: "";
}

.fa-scythe:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-ban-parking:before, .fa-parking-circle-slash:before {
  content: "";
}

.fa-tent-arrow-turn-left:before {
  content: "";
}

.fa-face-diagonal-mouth:before {
  content: "";
}

.fa-diagram-cells:before {
  content: "";
}

.fa-cricket-bat-ball:before, .fa-cricket:before {
  content: "";
}

.fa-tents:before {
  content: "";
}

.fa-magic:before, .fa-wand-magic:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-pen-line:before {
  content: "";
}

.fa-atom-alt:before, .fa-atom-simple:before {
  content: "";
}

.fa-ampersand:before {
  content: "&";
}

.fa-carrot:before {
  content: "";
}

.fa-arrow-from-bottom:before, .fa-arrow-up-from-line:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-pen-slash:before {
  content: "";
}

.fa-wine-glass-alt:before, .fa-wine-glass-empty:before {
  content: "";
}

.fa-square-star:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-send-backward:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-compass-slash:before {
  content: "";
}

.fa-clock-one:before {
  content: "";
}

.fa-file-music:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-biking:before, .fa-person-biking:before {
  content: "";
}

.fa-display-chart-up-circle-currency:before {
  content: "";
}

.fa-skeleton:before {
  content: "";
}

.fa-circle-g:before {
  content: "";
}

.fa-circle-arrow-up-left:before {
  content: "";
}

.fa-coin-blank:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-vacuum:before {
  content: "";
}

.fa-shield-heart:before {
  content: "";
}

.fa-card-heart:before {
  content: "";
}

.fa-lightbulb-cfl-on:before {
  content: "";
}

.fa-melon:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-earth-oceania:before, .fa-globe-oceania:before {
  content: "";
}

.fa-container-storage:before {
  content: "";
}

.fa-face-pouting:before {
  content: "";
}

.fa-square-xmark:before, .fa-times-square:before, .fa-xmark-square:before {
  content: "";
}

.fa-exploding-head:before, .fa-face-explode:before {
  content: "";
}

.fa-hashtag:before {
  content: "#";
}

.fa-expand-alt:before, .fa-up-right-and-down-left-from-center:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-t:before {
  content: "T";
}

.fa-transformer-bolt:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-chart-column:before {
  content: "";
}

.fa-cassette-vhs:before, .fa-vhs:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-vial-circle-check:before {
  content: "";
}

.fa-chimney:before {
  content: "";
}

.fa-object-intersect:before {
  content: "";
}

.fa-person-arrow-down-to-line:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-block-brick:before, .fa-wall-brick:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-bags-shopping:before {
  content: "";
}

.fa-paragraph-left:before, .fa-paragraph-rtl:before {
  content: "";
}

.fa-person-walking-luggage:before {
  content: "";
}

.fa-caravan-alt:before, .fa-caravan-simple:before {
  content: "";
}

.fa-turtle:before {
  content: "";
}

.fa-pencil-mechanical:before {
  content: "";
}

.fa-arrows-alt-v:before, .fa-up-down:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-booth-curtain:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-box-heart:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-user-doctor-message:before, .fa-user-md-chat:before {
  content: "";
}

.fa-bahai:before, .fa-haykal:before {
  content: "";
}

.fa-lighthouse:before {
  content: "";
}

.fa-amp-guitar:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-volume-slash:before {
  content: "";
}

.fa-border-bottom:before {
  content: "";
}

.fa-wifi-1:before, .fa-wifi-weak:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-circle-plus:before, .fa-plus-circle:before {
  content: "";
}

.fa-face-grin-tongue-wink:before, .fa-grin-tongue-wink:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-plug-circle-exclamation:before {
  content: "";
}

.fa-chain-broken:before, .fa-chain-slash:before, .fa-link-slash:before, .fa-unlink:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-person-walking-arrow-loop-left:before {
  content: "";
}

.fa-arrow-up-z-a:before, .fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-fire-alt:before, .fa-fire-flame-curved:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-file-circle-plus:before {
  content: "";
}

.fa-delete-right:before {
  content: "";
}

.fa-book-quran:before, .fa-quran:before {
  content: "";
}

.fa-circle-quarter:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-function:before {
  content: "";
}

.fa-angry:before, .fa-face-angry:before {
  content: "";
}

.fa-people-simple:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-arrow-trend-down:before {
  content: "";
}

.fa-feed:before, .fa-rss:before {
  content: "";
}

.fa-face-monocle:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-balance-scale:before, .fa-scale-balanced:before {
  content: "";
}

.fa-calendar-lines:before, .fa-calendar-note:before {
  content: "";
}

.fa-arrow-down-big-small:before, .fa-sort-size-down:before {
  content: "";
}

.fa-gauge-simple-high:before, .fa-tachometer-fast:before, .fa-tachometer:before {
  content: "";
}

.fa-do-not-enter:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-dice-d8:before {
  content: "";
}

.fa-desktop-alt:before, .fa-desktop:before {
  content: "";
}

.fa-m:before {
  content: "M";
}

.fa-grip-dots-vertical:before {
  content: "";
}

.fa-face-viewfinder:before {
  content: "";
}

.fa-creemee:before, .fa-soft-serve:before {
  content: "";
}

.fa-h5:before {
  content: "";
}

.fa-hand-back-point-down:before {
  content: "";
}

.fa-table-list:before, .fa-th-list:before {
  content: "";
}

.fa-comment-sms:before, .fa-sms:before {
  content: "";
}

.fa-rectangle-landscape:before, .fa-rectangle:before {
  content: "";
}

.fa-clipboard-list-check:before {
  content: "";
}

.fa-turkey:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-ice-skate:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-battery-4:before, .fa-battery-three-quarters:before {
  content: "";
}

.fa-tomato:before {
  content: "";
}

.fa-sword-laser:before {
  content: "";
}

.fa-house-circle-check:before {
  content: "";
}

.fa-buildings:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-cart-flatbed-boxes:before, .fa-dolly-flatbed-alt:before {
  content: "";
}

.fa-diagram-successor:before {
  content: "";
}

.fa-truck-arrow-right:before {
  content: "";
}

.fa-square-w:before {
  content: "";
}

.fa-arrows-split-up-and-left:before {
  content: "";
}

.fa-lamp:before {
  content: "";
}

.fa-airplay:before {
  content: "";
}

.fa-fist-raised:before, .fa-hand-fist:before {
  content: "";
}

.fa-shield-quartered:before {
  content: "";
}

.fa-slash-forward:before {
  content: "/";
}

.fa-location-pen:before, .fa-map-marker-edit:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-pot-food:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-person-falling:before {
  content: "";
}

.fa-image-portrait:before, .fa-portrait:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-rug:before {
  content: "";
}

.fa-print-slash:before {
  content: "";
}

.fa-earth-europe:before, .fa-globe-europe:before {
  content: "";
}

.fa-cart-flatbed-suitcase:before, .fa-luggage-cart:before {
  content: "";
}

.fa-hand-back-point-ribbon:before {
  content: "";
}

.fa-rectangle-times:before, .fa-rectangle-xmark:before, .fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-tire-rugged:before {
  content: "";
}

.fa-lightbulb-dollar:before {
  content: "";
}

.fa-cowbell:before {
  content: "";
}

.fa-baht-sign:before {
  content: "";
}

.fa-corner:before {
  content: "";
}

.fa-chevron-double-right:before, .fa-chevrons-right:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-journal-whills:before, .fa-journal-whills:before {
  content: "";
}

.fa-inhaler:before {
  content: "";
}

.fa-handcuffs:before {
  content: "";
}

.fa-snake:before {
  content: "";
}

.fa-exclamation-triangle:before, .fa-triangle-exclamation:before, .fa-warning:before {
  content: "";
}

.fa-note-medical:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-down-left:before {
  content: "";
}

.fa-arrow-turn-right:before, .fa-mail-forward:before, .fa-share:before {
  content: "";
}

.fa-face-thinking:before {
  content: "";
}

.fa-turn-down-right:before {
  content: "";
}

.fa-bottle-droplet:before {
  content: "";
}

.fa-mask-face:before {
  content: "";
}

.fa-hill-rockslide:before {
  content: "";
}

.fa-scanner-keyboard:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-grid-horizontal:before {
  content: "";
}

.fa-comment-alt-dollar:before, .fa-message-dollar:before {
  content: "";
}

.fa-exchange-alt:before, .fa-right-left:before {
  content: "";
}

.fa-columns-3:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-road-circle-exclamation:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-hand-holding-box:before {
  content: "";
}

.fa-input-text:before {
  content: "";
}

.fa-window-alt:before, .fa-window-flip:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-scanner-gun:before, .fa-scanner:before {
  content: "";
}

.fa-tire:before {
  content: "";
}

.fa-engine:before {
  content: "";
}

.fa-money-bill-1-wave:before, .fa-money-bill-wave-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-hands:before, .fa-sign-language:before, .fa-signing:before {
  content: "";
}

.fa-caret-circle-right:before, .fa-circle-caret-right:before {
  content: "";
}

.fa-wheat:before {
  content: "";
}

.fa-file-spreadsheet:before {
  content: "";
}

.fa-audio-description-slash:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-ladder-water:before, .fa-swimming-pool:before, .fa-water-ladder:before {
  content: "";
}

.fa-arrows-up-down:before, .fa-arrows-v:before {
  content: "";
}

.fa-chess-pawn-alt:before, .fa-chess-pawn-piece:before {
  content: "";
}

.fa-face-grimace:before, .fa-grimace:before {
  content: "";
}

.fa-wheelchair-alt:before, .fa-wheelchair-move:before {
  content: "";
}

.fa-level-down-alt:before, .fa-turn-down:before {
  content: "";
}

.fa-square-s:before {
  content: "";
}

.fa-barcode-alt:before, .fa-rectangle-barcode:before {
  content: "";
}

.fa-person-walking-arrow-right:before {
  content: "";
}

.fa-envelope-square:before, .fa-square-envelope:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-unicorn:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-pompebled:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-watch-smart:before {
  content: "";
}

.fa-book-user:before {
  content: "";
}

.fa-sensor-cloud:before, .fa-sensor-smoke:before {
  content: "";
}

.fa-clapperboard-play:before {
  content: "";
}

.fa-band-aid:before, .fa-bandage:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-circle-xmark:before, .fa-times-circle:before, .fa-xmark-circle:before {
  content: "";
}

.fa-circle-4:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-album-collection:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-earth-asia:before, .fa-globe-asia:before {
  content: "";
}

.fa-id-card-alt:before, .fa-id-card-clip:before {
  content: "";
}

.fa-magnifying-glass-plus:before, .fa-search-plus:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-cloud-showers:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-onion:before {
  content: "";
}

.fa-clock-twelve-thirty:before {
  content: "";
}

.fa-arrow-down-to-dotted-line:before {
  content: "";
}

.fa-allergies:before, .fa-hand-dots:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-rectangle-wide:before {
  content: "";
}

.fa-comment-arrow-up:before {
  content: "";
}

.fa-garlic:before {
  content: "";
}

.fa-coffee:before, .fa-mug-saucer:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-tree-decorated:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-calendar-heart:before {
  content: "";
}

.fa-magnifying-glass-minus:before, .fa-search-minus:before {
  content: "";
}

.fa-flower:before {
  content: "";
}

.fa-arrow-down-from-arc:before {
  content: "";
}

.fa-right-left-large:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-circles-overlap:before {
  content: "";
}

.fa-user-alt:before, .fa-user-large:before {
  content: "";
}

.fa-starship-freighter:before {
  content: "";
}

.fa-train-tram:before {
  content: "";
}

.fa-bridge-suspension:before {
  content: "";
}

.fa-trash-check:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-boombox:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-shield-exclamation:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-grip-dots:before {
  content: "";
}

.fa-comment-exclamation:before {
  content: "";
}

.fa-pen-swirl:before {
  content: "";
}

.fa-falafel:before {
  content: "";
}

.fa-circle-2:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-jar:before {
  content: "";
}

.fa-gramophone:before {
  content: "";
}

.fa-dice-d12:before {
  content: "";
}

.fa-note-sticky:before, .fa-sticky-note:before {
  content: "";
}

.fa-arrow-alt-down:before, .fa-down:before {
  content: "";
}

.fa-100:before, .fa-hundred-points:before {
  content: "";
}

.fa-paperclip-vertical:before {
  content: "";
}

.fa-wind-circle-exclamation:before, .fa-wind-warning:before {
  content: "";
}

.fa-location-pin-slash:before, .fa-map-marker-slash:before {
  content: "";
}

.fa-face-sad-sweat:before {
  content: "";
}

.fa-bug-slash:before {
  content: "";
}

.fa-cupcake:before {
  content: "";
}

.fa-light-switch-off:before {
  content: "";
}

.fa-toggle-large-off:before {
  content: "";
}

.fa-pen-fancy-slash:before {
  content: "";
}

.fa-truck-container:before {
  content: "";
}

.fa-boot:before {
  content: "";
}

.fa-arrow-up-from-water-pump:before {
  content: "";
}

.fa-file-check:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-cards-blank:before {
  content: "";
}

.fa-circle-3:before {
  content: "";
}

.fa-bench-tree:before {
  content: "";
}

.fa-keyboard-brightness-low:before {
  content: "";
}

.fa-ski-boot-ski:before {
  content: "";
}

.fa-brain-circuit:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-block-brick-fire:before, .fa-firewall:before {
  content: "";
}

.fa-face-sad-tear:before, .fa-sad-tear:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-tent-arrows-down:before {
  content: "";
}

.fa-exclamation:before {
  content: "!";
}

.fa-arrows-spin:before {
  content: "";
}

.fa-face-smile-relaxed:before {
  content: "";
}

.fa-comment-times:before, .fa-comment-xmark:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-try:before, .fa-turkish-lira-sign:before, .fa-turkish-lira:before {
  content: "";
}

.fa-face-nose-steam:before {
  content: "";
}

.fa-circle-waveform-lines:before, .fa-waveform-circle:before {
  content: "";
}

.fa-dollar-sign:before, .fa-dollar:before, .fa-usd:before {
  content: "$";
}

.fa-ferris-wheel:before {
  content: "";
}

.fa-computer-speaker:before {
  content: "";
}

.fa-skull-cow:before {
  content: "";
}

.fa-x:before {
  content: "X";
}

.fa-magnifying-glass-dollar:before, .fa-search-dollar:before {
  content: "";
}

.fa-users-cog:before, .fa-users-gear:before {
  content: "";
}

.fa-person-military-pointing:before {
  content: "";
}

.fa-bank:before, .fa-building-columns:before, .fa-institution:before, .fa-museum:before, .fa-university:before {
  content: "";
}

.fa-circle-t:before {
  content: "";
}

.fa-sack:before {
  content: "";
}

.fa-grid-2:before {
  content: "";
}

.fa-camera-cctv:before, .fa-cctv:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-trowel:before {
  content: "";
}

.fa-horizontal-rule:before {
  content: "";
}

.fa-bed-alt:before, .fa-bed-front:before {
  content: "";
}

.fa-d:before {
  content: "D";
}

.fa-stapler:before {
  content: "";
}

.fa-masks-theater:before, .fa-theater-masks:before {
  content: "";
}

.fa-kip-sign:before {
  content: "";
}

.fa-face-woozy:before {
  content: "";
}

.fa-cloud-question:before {
  content: "";
}

.fa-pineapple:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-gallery-thumbnails:before {
  content: "";
}

.fa-circle-j:before {
  content: "";
}

.fa-eyes:before {
  content: "";
}

.fa-handshake-alt:before, .fa-handshake-simple:before {
  content: "";
}

.fa-file-caret-up:before, .fa-page-caret-up:before {
  content: "";
}

.fa-fighter-jet:before, .fa-jet-fighter:before {
  content: "";
}

.fa-comet:before {
  content: "";
}

.fa-share-alt-square:before, .fa-square-share-nodes:before {
  content: "";
}

.fa-shield-keyhole:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-plus-minus:before {
  content: "";
}

.fa-sliders-v-square:before, .fa-square-sliders-vertical:before {
  content: "";
}

.fa-video-camera:before, .fa-video:before {
  content: "";
}

.fa-comment-middle-alt:before, .fa-message-middle:before {
  content: "";
}

.fa-graduation-cap:before, .fa-mortar-board:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-person-circle-check:before {
  content: "";
}

.fa-square-z:before {
  content: "";
}

.fa-comment-alt-text:before, .fa-message-text:before {
  content: "";
}

.fa-level-up-alt:before, .fa-turn-up:before {
  content: "";
}

.fa-sr-only, .fa-sr-only-focusable:not(:focus), .sr-only, .sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:host, :root {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.ac3983a6.woff2") format("woff2"), url("fa-brands-400.de996382.ttf") format("truetype");
}

.fa-brands, .fab {
  font-weight: 400;
}

.fa-monero:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-js-square:before, .fa-square-js:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-reddit-square:before, .fa-square-reddit:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-square-font-awesome:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-instagram-square:before, .fa-square-instagram:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-hacker-news-square:before, .fa-square-hacker-news:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-threads:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-snapchat-square:before, .fa-square-snapchat:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-font-awesome-alt:before, .fa-square-font-awesome-stroke:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-square-viadeo:before, .fa-viadeo-square:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-dribbble-square:before, .fa-square-dribbble:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-debian:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-square-twitter:before, .fa-twitter-square:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-golang:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-square-youtube:before, .fa-youtube-square:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-rendact:before, .fa-wpressr:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-nfc-directional:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-meta:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-shoelace:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-square-steam:before, .fa-steam-square:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-square-vimeo:before, .fa-vimeo-square:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-font-awesome-flag:before, .fa-font-awesome-logo-full:before, .fa-font-awesome:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-padlet:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-github-square:before, .fa-square-github:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-square-threads:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-gitlab-square:before, .fa-square-gitlab:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-odnoklassniki-square:before, .fa-square-odnoklassniki:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-hashnode:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-pinterest-square:before, .fa-square-pinterest:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-nfc-symbol:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-google-plus-square:before, .fa-square-google-plus:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-square-xing:before, .fa-xing-square:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-bilibili:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-x-twitter:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-42-group:before, .fa-innosoft:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-pied-piper-square:before, .fa-square-pied-piper:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-facebook-square:before, .fa-square-facebook:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-lastfm-square:before, .fa-square-lastfm:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-cmplid:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-screenpal:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-wirsindhandwerk:before, .fa-wsh:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-snapchat-ghost:before, .fa-snapchat:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-behance-square:before, .fa-square-behance:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-space-awesome:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-git-square:before, .fa-square-git:before {
  content: "";
}

.fa-square-tumblr:before, .fa-tumblr-square:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-bots:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-stubber:before {
  content: "";
}

.fa-telegram-plane:before, .fa-telegram:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-odysee:before {
  content: "";
}

.fa-square-whatsapp:before, .fa-whatsapp-square:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-slack-hash:before, .fa-slack:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-square-x-twitter:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-medium-m:before, .fa-medium:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-sitrox:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-pix:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

:host, :root {
  --fa-style-family-duotone: "Font Awesome 6 Duotone";
  --fa-font-duotone: normal 900 1em / 1 "Font Awesome 6 Duotone";
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-duotone-900.995705c3.woff2") format("woff2"), url("fa-duotone-900.cfd0cff7.ttf") format("truetype");
}

.fa-duotone, .fad {
  letter-spacing: normal;
  font-weight: 900;
  position: relative;
}

.fa-duotone:before, .fad:before {
  color: var(--fa-primary-color, inherit);
  opacity: var(--fa-primary-opacity, 1);
  position: absolute;
}

.fa-duotone:after, .fad:after {
  color: var(--fa-secondary-color, inherit);
}

.fa-duotone.fa-swap-opacity:before, .fa-duotone:after, .fa-swap-opacity .fa-duotone:before, .fa-swap-opacity .fad:before, .fad.fa-swap-opacity:before, .fad:after {
  opacity: var(--fa-secondary-opacity, .4);
}

.fa-duotone.fa-swap-opacity:after, .fa-swap-opacity .fa-duotone:after, .fa-swap-opacity .fad:after, .fad.fa-swap-opacity:after {
  opacity: var(--fa-primary-opacity, 1);
}

.fa-duotone.fa-inverse, .fad.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x, .fad.fa-stack-1x, .fad.fa-stack-2x {
  position: absolute;
}

.fa-duotone.fa-0:after, .fad.fa-0:after {
  content: "00";
}

.fa-duotone.fa-1:after, .fad.fa-1:after {
  content: "11";
}

.fa-duotone.fa-2:after, .fad.fa-2:after {
  content: "22";
}

.fa-duotone.fa-3:after, .fad.fa-3:after {
  content: "33";
}

.fa-duotone.fa-4:after, .fad.fa-4:after {
  content: "44";
}

.fa-duotone.fa-5:after, .fad.fa-5:after {
  content: "55";
}

.fa-duotone.fa-6:after, .fad.fa-6:after {
  content: "66";
}

.fa-duotone.fa-7:after, .fad.fa-7:after {
  content: "77";
}

.fa-duotone.fa-8:after, .fad.fa-8:after {
  content: "88";
}

.fa-duotone.fa-9:after, .fad.fa-9:after {
  content: "99";
}

.fa-duotone.fa-fill-drip:after, .fad.fa-fill-drip:after {
  content: "";
}

.fa-duotone.fa-arrows-to-circle:after, .fad.fa-arrows-to-circle:after {
  content: "";
}

.fa-duotone.fa-chevron-circle-right:after, .fa-duotone.fa-circle-chevron-right:after, .fad.fa-chevron-circle-right:after, .fad.fa-circle-chevron-right:after {
  content: "";
}

.fa-duotone.fa-wagon-covered:after, .fad.fa-wagon-covered:after {
  content: "";
}

.fa-duotone.fa-line-height:after, .fad.fa-line-height:after {
  content: "";
}

.fa-duotone.fa-bagel:after, .fad.fa-bagel:after {
  content: "";
}

.fa-duotone.fa-transporter-7:after, .fad.fa-transporter-7:after {
  content: "";
}

.fa-duotone.fa-at:after, .fad.fa-at:after {
  content: "@@";
}

.fa-duotone.fa-rectangles-mixed:after, .fad.fa-rectangles-mixed:after {
  content: "";
}

.fa-duotone.fa-phone-arrow-up-right:after, .fa-duotone.fa-phone-arrow-up:after, .fa-duotone.fa-phone-outgoing:after, .fad.fa-phone-arrow-up-right:after, .fad.fa-phone-arrow-up:after, .fad.fa-phone-outgoing:after {
  content: "";
}

.fa-duotone.fa-trash-alt:after, .fa-duotone.fa-trash-can:after, .fad.fa-trash-alt:after, .fad.fa-trash-can:after {
  content: "";
}

.fa-duotone.fa-circle-l:after, .fad.fa-circle-l:after {
  content: "";
}

.fa-duotone.fa-head-side-goggles:after, .fa-duotone.fa-head-vr:after, .fad.fa-head-side-goggles:after, .fad.fa-head-vr:after {
  content: "";
}

.fa-duotone.fa-text-height:after, .fad.fa-text-height:after {
  content: "";
}

.fa-duotone.fa-user-times:after, .fa-duotone.fa-user-xmark:after, .fad.fa-user-times:after, .fad.fa-user-xmark:after {
  content: "";
}

.fa-duotone.fa-face-hand-yawn:after, .fad.fa-face-hand-yawn:after {
  content: "";
}

.fa-duotone.fa-gauge-simple-min:after, .fa-duotone.fa-tachometer-slowest:after, .fad.fa-gauge-simple-min:after, .fad.fa-tachometer-slowest:after {
  content: "";
}

.fa-duotone.fa-stethoscope:after, .fad.fa-stethoscope:after {
  content: "";
}

.fa-duotone.fa-coffin:after, .fad.fa-coffin:after {
  content: "";
}

.fa-duotone.fa-comment-alt:after, .fa-duotone.fa-message:after, .fad.fa-comment-alt:after, .fad.fa-message:after {
  content: "";
}

.fa-duotone.fa-bowl-salad:after, .fa-duotone.fa-salad:after, .fad.fa-bowl-salad:after, .fad.fa-salad:after {
  content: "";
}

.fa-duotone.fa-info:after, .fad.fa-info:after {
  content: "";
}

.fa-duotone.fa-robot-astromech:after, .fad.fa-robot-astromech:after {
  content: "";
}

.fa-duotone.fa-ring-diamond:after, .fad.fa-ring-diamond:after {
  content: "";
}

.fa-duotone.fa-fondue-pot:after, .fad.fa-fondue-pot:after {
  content: "";
}

.fa-duotone.fa-theta:after, .fad.fa-theta:after {
  content: "";
}

.fa-duotone.fa-face-hand-peeking:after, .fad.fa-face-hand-peeking:after {
  content: "";
}

.fa-duotone.fa-square-user:after, .fad.fa-square-user:after {
  content: "";
}

.fa-duotone.fa-compress-alt:after, .fa-duotone.fa-down-left-and-up-right-to-center:after, .fad.fa-compress-alt:after, .fad.fa-down-left-and-up-right-to-center:after {
  content: "";
}

.fa-duotone.fa-explosion:after, .fad.fa-explosion:after {
  content: "";
}

.fa-duotone.fa-file-alt:after, .fa-duotone.fa-file-lines:after, .fa-duotone.fa-file-text:after, .fad.fa-file-alt:after, .fad.fa-file-lines:after, .fad.fa-file-text:after {
  content: "";
}

.fa-duotone.fa-wave-square:after, .fad.fa-wave-square:after {
  content: "";
}

.fa-duotone.fa-ring:after, .fad.fa-ring:after {
  content: "";
}

.fa-duotone.fa-building-un:after, .fad.fa-building-un:after {
  content: "";
}

.fa-duotone.fa-dice-three:after, .fad.fa-dice-three:after {
  content: "";
}

.fa-duotone.fa-tire-pressure-warning:after, .fad.fa-tire-pressure-warning:after {
  content: "";
}

.fa-duotone.fa-wifi-2:after, .fa-duotone.fa-wifi-fair:after, .fad.fa-wifi-2:after, .fad.fa-wifi-fair:after {
  content: "";
}

.fa-duotone.fa-calendar-alt:after, .fa-duotone.fa-calendar-days:after, .fad.fa-calendar-alt:after, .fad.fa-calendar-days:after {
  content: "";
}

.fa-duotone.fa-mp3-player:after, .fad.fa-mp3-player:after {
  content: "";
}

.fa-duotone.fa-anchor-circle-check:after, .fad.fa-anchor-circle-check:after {
  content: "";
}

.fa-duotone.fa-tally-4:after, .fad.fa-tally-4:after {
  content: "";
}

.fa-duotone.fa-rectangle-history:after, .fad.fa-rectangle-history:after {
  content: "";
}

.fa-duotone.fa-building-circle-arrow-right:after, .fad.fa-building-circle-arrow-right:after {
  content: "";
}

.fa-duotone.fa-volleyball-ball:after, .fa-duotone.fa-volleyball:after, .fad.fa-volleyball-ball:after, .fad.fa-volleyball:after {
  content: "";
}

.fa-duotone.fa-sun-haze:after, .fad.fa-sun-haze:after {
  content: "";
}

.fa-duotone.fa-text-size:after, .fad.fa-text-size:after {
  content: "";
}

.fa-duotone.fa-ufo:after, .fad.fa-ufo:after {
  content: "";
}

.fa-duotone.fa-fork:after, .fa-duotone.fa-utensil-fork:after, .fad.fa-fork:after, .fad.fa-utensil-fork:after {
  content: "";
}

.fa-duotone.fa-arrows-up-to-line:after, .fad.fa-arrows-up-to-line:after {
  content: "";
}

.fa-duotone.fa-mobile-signal:after, .fad.fa-mobile-signal:after {
  content: "";
}

.fa-duotone.fa-barcode-scan:after, .fad.fa-barcode-scan:after {
  content: "";
}

.fa-duotone.fa-sort-desc:after, .fa-duotone.fa-sort-down:after, .fad.fa-sort-desc:after, .fad.fa-sort-down:after {
  content: "";
}

.fa-duotone.fa-folder-arrow-down:after, .fa-duotone.fa-folder-download:after, .fad.fa-folder-arrow-down:after, .fad.fa-folder-download:after {
  content: "";
}

.fa-duotone.fa-circle-minus:after, .fa-duotone.fa-minus-circle:after, .fad.fa-circle-minus:after, .fad.fa-minus-circle:after {
  content: "";
}

.fa-duotone.fa-face-icicles:after, .fad.fa-face-icicles:after {
  content: "";
}

.fa-duotone.fa-shovel:after, .fad.fa-shovel:after {
  content: "";
}

.fa-duotone.fa-door-open:after, .fad.fa-door-open:after {
  content: "";
}

.fa-duotone.fa-films:after, .fad.fa-films:after {
  content: "";
}

.fa-duotone.fa-right-from-bracket:after, .fa-duotone.fa-sign-out-alt:after, .fad.fa-right-from-bracket:after, .fad.fa-sign-out-alt:after {
  content: "";
}

.fa-duotone.fa-face-glasses:after, .fad.fa-face-glasses:after {
  content: "";
}

.fa-duotone.fa-nfc:after, .fad.fa-nfc:after {
  content: "";
}

.fa-duotone.fa-atom:after, .fad.fa-atom:after {
  content: "";
}

.fa-duotone.fa-soap:after, .fad.fa-soap:after {
  content: "";
}

.fa-duotone.fa-heart-music-camera-bolt:after, .fa-duotone.fa-icons:after, .fad.fa-heart-music-camera-bolt:after, .fad.fa-icons:after {
  content: "";
}

.fa-duotone.fa-microphone-alt-slash:after, .fa-duotone.fa-microphone-lines-slash:after, .fad.fa-microphone-alt-slash:after, .fad.fa-microphone-lines-slash:after {
  content: "";
}

.fa-duotone.fa-closed-captioning-slash:after, .fad.fa-closed-captioning-slash:after {
  content: "";
}

.fa-duotone.fa-calculator-alt:after, .fa-duotone.fa-calculator-simple:after, .fad.fa-calculator-alt:after, .fad.fa-calculator-simple:after {
  content: "";
}

.fa-duotone.fa-bridge-circle-check:after, .fad.fa-bridge-circle-check:after {
  content: "";
}

.fa-duotone.fa-sliders-up:after, .fa-duotone.fa-sliders-v:after, .fad.fa-sliders-up:after, .fad.fa-sliders-v:after {
  content: "";
}

.fa-duotone.fa-location-minus:after, .fa-duotone.fa-map-marker-minus:after, .fad.fa-location-minus:after, .fad.fa-map-marker-minus:after {
  content: "";
}

.fa-duotone.fa-pump-medical:after, .fad.fa-pump-medical:after {
  content: "";
}

.fa-duotone.fa-fingerprint:after, .fad.fa-fingerprint:after {
  content: "";
}

.fa-duotone.fa-ski-boot:after, .fad.fa-ski-boot:after {
  content: "";
}

.fa-duotone.fa-rectangle-sd:after, .fa-duotone.fa-standard-definition:after, .fad.fa-rectangle-sd:after, .fad.fa-standard-definition:after {
  content: "";
}

.fa-duotone.fa-h1:after, .fad.fa-h1:after {
  content: "";
}

.fa-duotone.fa-hand-point-right:after, .fad.fa-hand-point-right:after {
  content: "";
}

.fa-duotone.fa-magnifying-glass-location:after, .fa-duotone.fa-search-location:after, .fad.fa-magnifying-glass-location:after, .fad.fa-search-location:after {
  content: "";
}

.fa-duotone.fa-message-bot:after, .fad.fa-message-bot:after {
  content: "";
}

.fa-duotone.fa-forward-step:after, .fa-duotone.fa-step-forward:after, .fad.fa-forward-step:after, .fad.fa-step-forward:after {
  content: "";
}

.fa-duotone.fa-face-smile-beam:after, .fa-duotone.fa-smile-beam:after, .fad.fa-face-smile-beam:after, .fad.fa-smile-beam:after {
  content: "";
}

.fa-duotone.fa-light-ceiling:after, .fad.fa-light-ceiling:after {
  content: "";
}

.fa-duotone.fa-comment-alt-exclamation:after, .fa-duotone.fa-message-exclamation:after, .fad.fa-comment-alt-exclamation:after, .fad.fa-message-exclamation:after {
  content: "";
}

.fa-duotone.fa-bowl-scoop:after, .fa-duotone.fa-bowl-shaved-ice:after, .fad.fa-bowl-scoop:after, .fad.fa-bowl-shaved-ice:after {
  content: "";
}

.fa-duotone.fa-square-x:after, .fad.fa-square-x:after {
  content: "";
}

.fa-duotone.fa-utility-pole-double:after, .fad.fa-utility-pole-double:after {
  content: "";
}

.fa-duotone.fa-flag-checkered:after, .fad.fa-flag-checkered:after {
  content: "";
}

.fa-duotone.fa-chevron-double-up:after, .fa-duotone.fa-chevrons-up:after, .fad.fa-chevron-double-up:after, .fad.fa-chevrons-up:after {
  content: "";
}

.fa-duotone.fa-football-ball:after, .fa-duotone.fa-football:after, .fad.fa-football-ball:after, .fad.fa-football:after {
  content: "";
}

.fa-duotone.fa-user-vneck:after, .fad.fa-user-vneck:after {
  content: "";
}

.fa-duotone.fa-school-circle-exclamation:after, .fad.fa-school-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-crop:after, .fad.fa-crop:after {
  content: "";
}

.fa-duotone.fa-angle-double-down:after, .fa-duotone.fa-angles-down:after, .fad.fa-angle-double-down:after, .fad.fa-angles-down:after {
  content: "";
}

.fa-duotone.fa-users-rectangle:after, .fad.fa-users-rectangle:after {
  content: "";
}

.fa-duotone.fa-people-roof:after, .fad.fa-people-roof:after {
  content: "";
}

.fa-duotone.fa-arrow-square-right:after, .fa-duotone.fa-square-arrow-right:after, .fad.fa-arrow-square-right:after, .fad.fa-square-arrow-right:after {
  content: "";
}

.fa-duotone.fa-location-plus:after, .fa-duotone.fa-map-marker-plus:after, .fad.fa-location-plus:after, .fad.fa-map-marker-plus:after {
  content: "";
}

.fa-duotone.fa-lightbulb-exclamation-on:after, .fad.fa-lightbulb-exclamation-on:after {
  content: "";
}

.fa-duotone.fa-people-line:after, .fad.fa-people-line:after {
  content: "";
}

.fa-duotone.fa-beer-mug-empty:after, .fa-duotone.fa-beer:after, .fad.fa-beer-mug-empty:after, .fad.fa-beer:after {
  content: "";
}

.fa-duotone.fa-crate-empty:after, .fad.fa-crate-empty:after {
  content: "";
}

.fa-duotone.fa-diagram-predecessor:after, .fad.fa-diagram-predecessor:after {
  content: "";
}

.fa-duotone.fa-transporter:after, .fad.fa-transporter:after {
  content: "";
}

.fa-duotone.fa-calendar-circle-user:after, .fad.fa-calendar-circle-user:after {
  content: "";
}

.fa-duotone.fa-arrow-up-long:after, .fa-duotone.fa-long-arrow-up:after, .fad.fa-arrow-up-long:after, .fad.fa-long-arrow-up:after {
  content: "";
}

.fa-duotone.fa-person-carry-box:after, .fa-duotone.fa-person-carry:after, .fad.fa-person-carry-box:after, .fad.fa-person-carry:after {
  content: "";
}

.fa-duotone.fa-burn:after, .fa-duotone.fa-fire-flame-simple:after, .fad.fa-burn:after, .fad.fa-fire-flame-simple:after {
  content: "";
}

.fa-duotone.fa-male:after, .fa-duotone.fa-person:after, .fad.fa-male:after, .fad.fa-person:after {
  content: "";
}

.fa-duotone.fa-laptop:after, .fad.fa-laptop:after {
  content: "";
}

.fa-duotone.fa-file-csv:after, .fad.fa-file-csv:after {
  content: "";
}

.fa-duotone.fa-menorah:after, .fad.fa-menorah:after {
  content: "";
}

.fa-duotone.fa-union:after, .fad.fa-union:after {
  content: "";
}

.fa-duotone.fa-chevron-double-left:after, .fa-duotone.fa-chevrons-left:after, .fad.fa-chevron-double-left:after, .fad.fa-chevrons-left:after {
  content: "";
}

.fa-duotone.fa-circle-heart:after, .fa-duotone.fa-heart-circle:after, .fad.fa-circle-heart:after, .fad.fa-heart-circle:after {
  content: "";
}

.fa-duotone.fa-truck-plane:after, .fad.fa-truck-plane:after {
  content: "";
}

.fa-duotone.fa-record-vinyl:after, .fad.fa-record-vinyl:after {
  content: "";
}

.fa-duotone.fa-bring-forward:after, .fad.fa-bring-forward:after {
  content: "";
}

.fa-duotone.fa-square-p:after, .fad.fa-square-p:after {
  content: "";
}

.fa-duotone.fa-face-grin-stars:after, .fa-duotone.fa-grin-stars:after, .fad.fa-face-grin-stars:after, .fad.fa-grin-stars:after {
  content: "";
}

.fa-duotone.fa-sigma:after, .fad.fa-sigma:after {
  content: "";
}

.fa-duotone.fa-camera-movie:after, .fad.fa-camera-movie:after {
  content: "";
}

.fa-duotone.fa-bong:after, .fad.fa-bong:after {
  content: "";
}

.fa-duotone.fa-clarinet:after, .fad.fa-clarinet:after {
  content: "";
}

.fa-duotone.fa-truck-flatbed:after, .fad.fa-truck-flatbed:after {
  content: "";
}

.fa-duotone.fa-pastafarianism:after, .fa-duotone.fa-spaghetti-monster-flying:after, .fad.fa-pastafarianism:after, .fad.fa-spaghetti-monster-flying:after {
  content: "";
}

.fa-duotone.fa-arrow-down-up-across-line:after, .fad.fa-arrow-down-up-across-line:after {
  content: "";
}

.fa-duotone.fa-leaf-heart:after, .fad.fa-leaf-heart:after {
  content: "";
}

.fa-duotone.fa-house-building:after, .fad.fa-house-building:after {
  content: "";
}

.fa-duotone.fa-cheese-swiss:after, .fad.fa-cheese-swiss:after {
  content: "";
}

.fa-duotone.fa-spoon:after, .fa-duotone.fa-utensil-spoon:after, .fad.fa-spoon:after, .fad.fa-utensil-spoon:after {
  content: "";
}

.fa-duotone.fa-jar-wheat:after, .fad.fa-jar-wheat:after {
  content: "";
}

.fa-duotone.fa-envelopes-bulk:after, .fa-duotone.fa-mail-bulk:after, .fad.fa-envelopes-bulk:after, .fad.fa-mail-bulk:after {
  content: "";
}

.fa-duotone.fa-file-circle-exclamation:after, .fad.fa-file-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-bow-arrow:after, .fad.fa-bow-arrow:after {
  content: "";
}

.fa-duotone.fa-cart-xmark:after, .fad.fa-cart-xmark:after {
  content: "";
}

.fa-duotone.fa-hexagon-xmark:after, .fa-duotone.fa-times-hexagon:after, .fa-duotone.fa-xmark-hexagon:after, .fad.fa-hexagon-xmark:after, .fad.fa-times-hexagon:after, .fad.fa-xmark-hexagon:after {
  content: "";
}

.fa-duotone.fa-circle-h:after, .fa-duotone.fa-hospital-symbol:after, .fad.fa-circle-h:after, .fad.fa-hospital-symbol:after {
  content: "";
}

.fa-duotone.fa-merge:after, .fad.fa-merge:after {
  content: "";
}

.fa-duotone.fa-pager:after, .fad.fa-pager:after {
  content: "";
}

.fa-duotone.fa-cart-minus:after, .fad.fa-cart-minus:after {
  content: "";
}

.fa-duotone.fa-address-book:after, .fa-duotone.fa-contact-book:after, .fad.fa-address-book:after, .fad.fa-contact-book:after {
  content: "";
}

.fa-duotone.fa-pan-frying:after, .fad.fa-pan-frying:after {
  content: "";
}

.fa-duotone.fa-grid-3:after, .fa-duotone.fa-grid:after, .fad.fa-grid-3:after, .fad.fa-grid:after {
  content: "";
}

.fa-duotone.fa-football-helmet:after, .fad.fa-football-helmet:after {
  content: "";
}

.fa-duotone.fa-hand-love:after, .fad.fa-hand-love:after {
  content: "";
}

.fa-duotone.fa-trees:after, .fad.fa-trees:after {
  content: "";
}

.fa-duotone.fa-strikethrough:after, .fad.fa-strikethrough:after {
  content: "";
}

.fa-duotone.fa-page:after, .fad.fa-page:after {
  content: "";
}

.fa-duotone.fa-k:after, .fad.fa-k:after {
  content: "KK";
}

.fa-duotone.fa-diagram-previous:after, .fad.fa-diagram-previous:after {
  content: "";
}

.fa-duotone.fa-gauge-min:after, .fa-duotone.fa-tachometer-alt-slowest:after, .fad.fa-gauge-min:after, .fad.fa-tachometer-alt-slowest:after {
  content: "";
}

.fa-duotone.fa-folder-grid:after, .fad.fa-folder-grid:after {
  content: "";
}

.fa-duotone.fa-eggplant:after, .fad.fa-eggplant:after {
  content: "";
}

.fa-duotone.fa-ram:after, .fad.fa-ram:after {
  content: "";
}

.fa-duotone.fa-landmark-flag:after, .fad.fa-landmark-flag:after {
  content: "";
}

.fa-duotone.fa-lips:after, .fad.fa-lips:after {
  content: "";
}

.fa-duotone.fa-pencil-alt:after, .fa-duotone.fa-pencil:after, .fad.fa-pencil-alt:after, .fad.fa-pencil:after {
  content: "";
}

.fa-duotone.fa-backward:after, .fad.fa-backward:after {
  content: "";
}

.fa-duotone.fa-caret-right:after, .fad.fa-caret-right:after {
  content: "";
}

.fa-duotone.fa-comments:after, .fad.fa-comments:after {
  content: "";
}

.fa-duotone.fa-file-clipboard:after, .fa-duotone.fa-paste:after, .fad.fa-file-clipboard:after, .fad.fa-paste:after {
  content: "";
}

.fa-duotone.fa-desktop-arrow-down:after, .fad.fa-desktop-arrow-down:after {
  content: "";
}

.fa-duotone.fa-code-pull-request:after, .fad.fa-code-pull-request:after {
  content: "";
}

.fa-duotone.fa-pumpkin:after, .fad.fa-pumpkin:after {
  content: "";
}

.fa-duotone.fa-clipboard-list:after, .fad.fa-clipboard-list:after {
  content: "";
}

.fa-duotone.fa-pen-field:after, .fad.fa-pen-field:after {
  content: "";
}

.fa-duotone.fa-blueberries:after, .fad.fa-blueberries:after {
  content: "";
}

.fa-duotone.fa-truck-loading:after, .fa-duotone.fa-truck-ramp-box:after, .fad.fa-truck-loading:after, .fad.fa-truck-ramp-box:after {
  content: "";
}

.fa-duotone.fa-note:after, .fad.fa-note:after {
  content: "";
}

.fa-duotone.fa-arrow-down-to-square:after, .fad.fa-arrow-down-to-square:after {
  content: "";
}

.fa-duotone.fa-user-check:after, .fad.fa-user-check:after {
  content: "";
}

.fa-duotone.fa-cloud-xmark:after, .fad.fa-cloud-xmark:after {
  content: "";
}

.fa-duotone.fa-vial-virus:after, .fad.fa-vial-virus:after {
  content: "";
}

.fa-duotone.fa-book-alt:after, .fa-duotone.fa-book-blank:after, .fad.fa-book-alt:after, .fad.fa-book-blank:after {
  content: "";
}

.fa-duotone.fa-golf-flag-hole:after, .fad.fa-golf-flag-hole:after {
  content: "";
}

.fa-duotone.fa-comment-alt-arrow-down:after, .fa-duotone.fa-message-arrow-down:after, .fad.fa-comment-alt-arrow-down:after, .fad.fa-message-arrow-down:after {
  content: "";
}

.fa-duotone.fa-face-unamused:after, .fad.fa-face-unamused:after {
  content: "";
}

.fa-duotone.fa-sheet-plastic:after, .fad.fa-sheet-plastic:after {
  content: "";
}

.fa-duotone.fa-circle-9:after, .fad.fa-circle-9:after {
  content: "";
}

.fa-duotone.fa-blog:after, .fad.fa-blog:after {
  content: "";
}

.fa-duotone.fa-user-ninja:after, .fad.fa-user-ninja:after {
  content: "";
}

.fa-duotone.fa-pencil-slash:after, .fad.fa-pencil-slash:after {
  content: "";
}

.fa-duotone.fa-bowling-pins:after, .fad.fa-bowling-pins:after {
  content: "";
}

.fa-duotone.fa-person-arrow-up-from-line:after, .fad.fa-person-arrow-up-from-line:after {
  content: "";
}

.fa-duotone.fa-down-right:after, .fad.fa-down-right:after {
  content: "";
}

.fa-duotone.fa-scroll-torah:after, .fa-duotone.fa-torah:after, .fad.fa-scroll-torah:after, .fad.fa-torah:after {
  content: "";
}

.fa-duotone.fa-webhook:after, .fad.fa-webhook:after {
  content: "";
}

.fa-duotone.fa-blinds-open:after, .fad.fa-blinds-open:after {
  content: "";
}

.fa-duotone.fa-fence:after, .fad.fa-fence:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-up:after, .fa-duotone.fa-up:after, .fad.fa-arrow-alt-up:after, .fad.fa-up:after {
  content: "";
}

.fa-duotone.fa-broom-ball:after, .fa-duotone.fa-quidditch-broom-ball:after, .fa-duotone.fa-quidditch:after, .fad.fa-broom-ball:after, .fad.fa-quidditch-broom-ball:after, .fad.fa-quidditch:after {
  content: "";
}

.fa-duotone.fa-drumstick:after, .fad.fa-drumstick:after {
  content: "";
}

.fa-duotone.fa-square-v:after, .fad.fa-square-v:after {
  content: "";
}

.fa-duotone.fa-face-awesome:after, .fa-duotone.fa-gave-dandy:after, .fad.fa-face-awesome:after, .fad.fa-gave-dandy:after {
  content: "";
}

.fa-duotone.fa-dial-off:after, .fad.fa-dial-off:after {
  content: "";
}

.fa-duotone.fa-toggle-off:after, .fad.fa-toggle-off:after {
  content: "";
}

.fa-duotone.fa-face-smile-horns:after, .fad.fa-face-smile-horns:after {
  content: "";
}

.fa-duotone.fa-archive:after, .fa-duotone.fa-box-archive:after, .fad.fa-archive:after, .fad.fa-box-archive:after {
  content: "";
}

.fa-duotone.fa-grapes:after, .fad.fa-grapes:after {
  content: "";
}

.fa-duotone.fa-person-drowning:after, .fad.fa-person-drowning:after {
  content: "";
}

.fa-duotone.fa-dial-max:after, .fad.fa-dial-max:after {
  content: "";
}

.fa-duotone.fa-circle-m:after, .fad.fa-circle-m:after {
  content: "";
}

.fa-duotone.fa-calendar-image:after, .fad.fa-calendar-image:after {
  content: "";
}

.fa-duotone.fa-caret-circle-down:after, .fa-duotone.fa-circle-caret-down:after, .fad.fa-caret-circle-down:after, .fad.fa-circle-caret-down:after {
  content: "";
}

.fa-duotone.fa-arrow-down-9-1:after, .fa-duotone.fa-sort-numeric-desc:after, .fa-duotone.fa-sort-numeric-down-alt:after, .fad.fa-arrow-down-9-1:after, .fad.fa-sort-numeric-desc:after, .fad.fa-sort-numeric-down-alt:after {
  content: "";
}

.fa-duotone.fa-face-grin-tongue-squint:after, .fa-duotone.fa-grin-tongue-squint:after, .fad.fa-face-grin-tongue-squint:after, .fad.fa-grin-tongue-squint:after {
  content: "";
}

.fa-duotone.fa-shish-kebab:after, .fad.fa-shish-kebab:after {
  content: "";
}

.fa-duotone.fa-spray-can:after, .fad.fa-spray-can:after {
  content: "";
}

.fa-duotone.fa-alarm-snooze:after, .fad.fa-alarm-snooze:after {
  content: "";
}

.fa-duotone.fa-scarecrow:after, .fad.fa-scarecrow:after {
  content: "";
}

.fa-duotone.fa-truck-monster:after, .fad.fa-truck-monster:after {
  content: "";
}

.fa-duotone.fa-gift-card:after, .fad.fa-gift-card:after {
  content: "";
}

.fa-duotone.fa-w:after, .fad.fa-w:after {
  content: "WW";
}

.fa-duotone.fa-code-pull-request-draft:after, .fad.fa-code-pull-request-draft:after {
  content: "";
}

.fa-duotone.fa-square-b:after, .fad.fa-square-b:after {
  content: "";
}

.fa-duotone.fa-elephant:after, .fad.fa-elephant:after {
  content: "";
}

.fa-duotone.fa-earth-africa:after, .fa-duotone.fa-globe-africa:after, .fad.fa-earth-africa:after, .fad.fa-globe-africa:after {
  content: "";
}

.fa-duotone.fa-rainbow:after, .fad.fa-rainbow:after {
  content: "";
}

.fa-duotone.fa-circle-notch:after, .fad.fa-circle-notch:after {
  content: "";
}

.fa-duotone.fa-tablet-alt:after, .fa-duotone.fa-tablet-screen-button:after, .fad.fa-tablet-alt:after, .fad.fa-tablet-screen-button:after {
  content: "";
}

.fa-duotone.fa-paw:after, .fad.fa-paw:after {
  content: "";
}

.fa-duotone.fa-message-question:after, .fad.fa-message-question:after {
  content: "";
}

.fa-duotone.fa-cloud:after, .fad.fa-cloud:after {
  content: "";
}

.fa-duotone.fa-trowel-bricks:after, .fad.fa-trowel-bricks:after {
  content: "";
}

.fa-duotone.fa-square-3:after, .fad.fa-square-3:after {
  content: "";
}

.fa-duotone.fa-face-flushed:after, .fa-duotone.fa-flushed:after, .fad.fa-face-flushed:after, .fad.fa-flushed:after {
  content: "";
}

.fa-duotone.fa-hospital-user:after, .fad.fa-hospital-user:after {
  content: "";
}

.fa-duotone.fa-microwave:after, .fad.fa-microwave:after {
  content: "";
}

.fa-duotone.fa-chf-sign:after, .fad.fa-chf-sign:after {
  content: "";
}

.fa-duotone.fa-tent-arrow-left-right:after, .fad.fa-tent-arrow-left-right:after {
  content: "";
}

.fa-duotone.fa-cart-circle-arrow-up:after, .fad.fa-cart-circle-arrow-up:after {
  content: "";
}

.fa-duotone.fa-trash-clock:after, .fad.fa-trash-clock:after {
  content: "";
}

.fa-duotone.fa-gavel:after, .fa-duotone.fa-legal:after, .fad.fa-gavel:after, .fad.fa-legal:after {
  content: "";
}

.fa-duotone.fa-sprinkler-ceiling:after, .fad.fa-sprinkler-ceiling:after {
  content: "";
}

.fa-duotone.fa-browsers:after, .fad.fa-browsers:after {
  content: "";
}

.fa-duotone.fa-trillium:after, .fad.fa-trillium:after {
  content: "";
}

.fa-duotone.fa-music-slash:after, .fad.fa-music-slash:after {
  content: "";
}

.fa-duotone.fa-truck-ramp:after, .fad.fa-truck-ramp:after {
  content: "";
}

.fa-duotone.fa-binoculars:after, .fad.fa-binoculars:after {
  content: "";
}

.fa-duotone.fa-microphone-slash:after, .fad.fa-microphone-slash:after {
  content: "";
}

.fa-duotone.fa-box-tissue:after, .fad.fa-box-tissue:after {
  content: "";
}

.fa-duotone.fa-circle-c:after, .fad.fa-circle-c:after {
  content: "";
}

.fa-duotone.fa-star-christmas:after, .fad.fa-star-christmas:after {
  content: "";
}

.fa-duotone.fa-chart-bullet:after, .fad.fa-chart-bullet:after {
  content: "";
}

.fa-duotone.fa-motorcycle:after, .fad.fa-motorcycle:after {
  content: "";
}

.fa-duotone.fa-tree-christmas:after, .fad.fa-tree-christmas:after {
  content: "";
}

.fa-duotone.fa-tire-flat:after, .fad.fa-tire-flat:after {
  content: "";
}

.fa-duotone.fa-sunglasses:after, .fad.fa-sunglasses:after {
  content: "";
}

.fa-duotone.fa-badge:after, .fad.fa-badge:after {
  content: "";
}

.fa-duotone.fa-comment-alt-edit:after, .fa-duotone.fa-message-edit:after, .fa-duotone.fa-message-pen:after, .fad.fa-comment-alt-edit:after, .fad.fa-message-edit:after, .fad.fa-message-pen:after {
  content: "";
}

.fa-duotone.fa-bell-concierge:after, .fa-duotone.fa-concierge-bell:after, .fad.fa-bell-concierge:after, .fad.fa-concierge-bell:after {
  content: "";
}

.fa-duotone.fa-pen-ruler:after, .fa-duotone.fa-pencil-ruler:after, .fad.fa-pen-ruler:after, .fad.fa-pencil-ruler:after {
  content: "";
}

.fa-duotone.fa-arrow-progress:after, .fad.fa-arrow-progress:after {
  content: "";
}

.fa-duotone.fa-chess-rook-alt:after, .fa-duotone.fa-chess-rook-piece:after, .fad.fa-chess-rook-alt:after, .fad.fa-chess-rook-piece:after {
  content: "";
}

.fa-duotone.fa-square-root:after, .fad.fa-square-root:after {
  content: "";
}

.fa-duotone.fa-album-collection-circle-plus:after, .fad.fa-album-collection-circle-plus:after {
  content: "";
}

.fa-duotone.fa-people-arrows-left-right:after, .fa-duotone.fa-people-arrows:after, .fad.fa-people-arrows-left-right:after, .fad.fa-people-arrows:after {
  content: "";
}

.fa-duotone.fa-face-angry-horns:after, .fad.fa-face-angry-horns:after {
  content: "";
}

.fa-duotone.fa-mars-and-venus-burst:after, .fad.fa-mars-and-venus-burst:after {
  content: "";
}

.fa-duotone.fa-tombstone:after, .fad.fa-tombstone:after {
  content: "";
}

.fa-duotone.fa-caret-square-right:after, .fa-duotone.fa-square-caret-right:after, .fad.fa-caret-square-right:after, .fad.fa-square-caret-right:after {
  content: "";
}

.fa-duotone.fa-cut:after, .fa-duotone.fa-scissors:after, .fad.fa-cut:after, .fad.fa-scissors:after {
  content: "";
}

.fa-duotone.fa-list-music:after, .fad.fa-list-music:after {
  content: "";
}

.fa-duotone.fa-sun-plant-wilt:after, .fad.fa-sun-plant-wilt:after {
  content: "";
}

.fa-duotone.fa-toilets-portable:after, .fad.fa-toilets-portable:after {
  content: "";
}

.fa-duotone.fa-hockey-puck:after, .fad.fa-hockey-puck:after {
  content: "";
}

.fa-duotone.fa-mustache:after, .fad.fa-mustache:after {
  content: "";
}

.fa-duotone.fa-hyphen:after, .fad.fa-hyphen:after {
  content: "--";
}

.fa-duotone.fa-table:after, .fad.fa-table:after {
  content: "";
}

.fa-duotone.fa-user-chef:after, .fad.fa-user-chef:after {
  content: "";
}

.fa-duotone.fa-comment-alt-image:after, .fa-duotone.fa-message-image:after, .fad.fa-comment-alt-image:after, .fad.fa-message-image:after {
  content: "";
}

.fa-duotone.fa-users-medical:after, .fad.fa-users-medical:after {
  content: "";
}

.fa-duotone.fa-sensor-alert:after, .fa-duotone.fa-sensor-triangle-exclamation:after, .fad.fa-sensor-alert:after, .fad.fa-sensor-triangle-exclamation:after {
  content: "";
}

.fa-duotone.fa-magnifying-glass-arrow-right:after, .fad.fa-magnifying-glass-arrow-right:after {
  content: "";
}

.fa-duotone.fa-digital-tachograph:after, .fa-duotone.fa-tachograph-digital:after, .fad.fa-digital-tachograph:after, .fad.fa-tachograph-digital:after {
  content: "";
}

.fa-duotone.fa-face-mask:after, .fad.fa-face-mask:after {
  content: "";
}

.fa-duotone.fa-pickleball:after, .fad.fa-pickleball:after {
  content: "";
}

.fa-duotone.fa-star-sharp-half:after, .fad.fa-star-sharp-half:after {
  content: "";
}

.fa-duotone.fa-users-slash:after, .fad.fa-users-slash:after {
  content: "";
}

.fa-duotone.fa-clover:after, .fad.fa-clover:after {
  content: "";
}

.fa-duotone.fa-meat:after, .fad.fa-meat:after {
  content: "";
}

.fa-duotone.fa-mail-reply:after, .fa-duotone.fa-reply:after, .fad.fa-mail-reply:after, .fad.fa-reply:after {
  content: "";
}

.fa-duotone.fa-star-and-crescent:after, .fad.fa-star-and-crescent:after {
  content: "";
}

.fa-duotone.fa-empty-set:after, .fad.fa-empty-set:after {
  content: "";
}

.fa-duotone.fa-house-fire:after, .fad.fa-house-fire:after {
  content: "";
}

.fa-duotone.fa-minus-square:after, .fa-duotone.fa-square-minus:after, .fad.fa-minus-square:after, .fad.fa-square-minus:after {
  content: "";
}

.fa-duotone.fa-helicopter:after, .fad.fa-helicopter:after {
  content: "";
}

.fa-duotone.fa-bird:after, .fad.fa-bird:after {
  content: "";
}

.fa-duotone.fa-compass:after, .fad.fa-compass:after {
  content: "";
}

.fa-duotone.fa-caret-square-down:after, .fa-duotone.fa-square-caret-down:after, .fad.fa-caret-square-down:after, .fad.fa-square-caret-down:after {
  content: "";
}

.fa-duotone.fa-heart-half-alt:after, .fa-duotone.fa-heart-half-stroke:after, .fad.fa-heart-half-alt:after, .fad.fa-heart-half-stroke:after {
  content: "";
}

.fa-duotone.fa-file-circle-question:after, .fad.fa-file-circle-question:after {
  content: "";
}

.fa-duotone.fa-laptop-code:after, .fad.fa-laptop-code:after {
  content: "";
}

.fa-duotone.fa-joystick:after, .fad.fa-joystick:after {
  content: "";
}

.fa-duotone.fa-grill-fire:after, .fad.fa-grill-fire:after {
  content: "";
}

.fa-duotone.fa-rectangle-vertical-history:after, .fad.fa-rectangle-vertical-history:after {
  content: "";
}

.fa-duotone.fa-swatchbook:after, .fad.fa-swatchbook:after {
  content: "";
}

.fa-duotone.fa-prescription-bottle:after, .fad.fa-prescription-bottle:after {
  content: "";
}

.fa-duotone.fa-bars:after, .fa-duotone.fa-navicon:after, .fad.fa-bars:after, .fad.fa-navicon:after {
  content: "";
}

.fa-duotone.fa-keyboard-left:after, .fad.fa-keyboard-left:after {
  content: "";
}

.fa-duotone.fa-people-group:after, .fad.fa-people-group:after {
  content: "";
}

.fa-duotone.fa-hourglass-3:after, .fa-duotone.fa-hourglass-end:after, .fad.fa-hourglass-3:after, .fad.fa-hourglass-end:after {
  content: "";
}

.fa-duotone.fa-heart-broken:after, .fa-duotone.fa-heart-crack:after, .fad.fa-heart-broken:after, .fad.fa-heart-crack:after {
  content: "";
}

.fa-duotone.fa-face-beam-hand-over-mouth:after, .fad.fa-face-beam-hand-over-mouth:after {
  content: "";
}

.fa-duotone.fa-droplet-percent:after, .fa-duotone.fa-humidity:after, .fad.fa-droplet-percent:after, .fad.fa-humidity:after {
  content: "";
}

.fa-duotone.fa-external-link-square-alt:after, .fa-duotone.fa-square-up-right:after, .fad.fa-external-link-square-alt:after, .fad.fa-square-up-right:after {
  content: "";
}

.fa-duotone.fa-face-kiss-beam:after, .fa-duotone.fa-kiss-beam:after, .fad.fa-face-kiss-beam:after, .fad.fa-kiss-beam:after {
  content: "";
}

.fa-duotone.fa-corn:after, .fad.fa-corn:after {
  content: "";
}

.fa-duotone.fa-roller-coaster:after, .fad.fa-roller-coaster:after {
  content: "";
}

.fa-duotone.fa-photo-film-music:after, .fad.fa-photo-film-music:after {
  content: "";
}

.fa-duotone.fa-radar:after, .fad.fa-radar:after {
  content: "";
}

.fa-duotone.fa-sickle:after, .fad.fa-sickle:after {
  content: "";
}

.fa-duotone.fa-film:after, .fad.fa-film:after {
  content: "";
}

.fa-duotone.fa-coconut:after, .fad.fa-coconut:after {
  content: "";
}

.fa-duotone.fa-ruler-horizontal:after, .fad.fa-ruler-horizontal:after {
  content: "";
}

.fa-duotone.fa-shield-cross:after, .fad.fa-shield-cross:after {
  content: "";
}

.fa-duotone.fa-cassette-tape:after, .fad.fa-cassette-tape:after {
  content: "";
}

.fa-duotone.fa-square-terminal:after, .fad.fa-square-terminal:after {
  content: "";
}

.fa-duotone.fa-people-robbery:after, .fad.fa-people-robbery:after {
  content: "";
}

.fa-duotone.fa-lightbulb:after, .fad.fa-lightbulb:after {
  content: "";
}

.fa-duotone.fa-caret-left:after, .fad.fa-caret-left:after {
  content: "";
}

.fa-duotone.fa-comment-middle:after, .fad.fa-comment-middle:after {
  content: "";
}

.fa-duotone.fa-trash-can-list:after, .fad.fa-trash-can-list:after {
  content: "";
}

.fa-duotone.fa-block:after, .fad.fa-block:after {
  content: "";
}

.fa-duotone.fa-circle-exclamation:after, .fa-duotone.fa-exclamation-circle:after, .fad.fa-circle-exclamation:after, .fad.fa-exclamation-circle:after {
  content: "";
}

.fa-duotone.fa-school-circle-xmark:after, .fad.fa-school-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-arrow-right-from-bracket:after, .fa-duotone.fa-sign-out:after, .fad.fa-arrow-right-from-bracket:after, .fad.fa-sign-out:after {
  content: "";
}

.fa-duotone.fa-face-frown-slight:after, .fad.fa-face-frown-slight:after {
  content: "";
}

.fa-duotone.fa-chevron-circle-down:after, .fa-duotone.fa-circle-chevron-down:after, .fad.fa-chevron-circle-down:after, .fad.fa-circle-chevron-down:after {
  content: "";
}

.fa-duotone.fa-sidebar-flip:after, .fad.fa-sidebar-flip:after {
  content: "";
}

.fa-duotone.fa-unlock-alt:after, .fa-duotone.fa-unlock-keyhole:after, .fad.fa-unlock-alt:after, .fad.fa-unlock-keyhole:after {
  content: "";
}

.fa-duotone.fa-temperature-list:after, .fad.fa-temperature-list:after {
  content: "";
}

.fa-duotone.fa-cloud-showers-heavy:after, .fad.fa-cloud-showers-heavy:after {
  content: "";
}

.fa-duotone.fa-headphones-alt:after, .fa-duotone.fa-headphones-simple:after, .fad.fa-headphones-alt:after, .fad.fa-headphones-simple:after {
  content: "";
}

.fa-duotone.fa-sitemap:after, .fad.fa-sitemap:after {
  content: "";
}

.fa-duotone.fa-pipe-section:after, .fad.fa-pipe-section:after {
  content: "";
}

.fa-duotone.fa-space-station-moon-alt:after, .fa-duotone.fa-space-station-moon-construction:after, .fad.fa-space-station-moon-alt:after, .fad.fa-space-station-moon-construction:after {
  content: "";
}

.fa-duotone.fa-circle-dollar-to-slot:after, .fa-duotone.fa-donate:after, .fad.fa-circle-dollar-to-slot:after, .fad.fa-donate:after {
  content: "";
}

.fa-duotone.fa-memory:after, .fad.fa-memory:after {
  content: "";
}

.fa-duotone.fa-face-sleeping:after, .fad.fa-face-sleeping:after {
  content: "";
}

.fa-duotone.fa-road-spikes:after, .fad.fa-road-spikes:after {
  content: "";
}

.fa-duotone.fa-fire-burner:after, .fad.fa-fire-burner:after {
  content: "";
}

.fa-duotone.fa-squirrel:after, .fad.fa-squirrel:after {
  content: "";
}

.fa-duotone.fa-arrow-to-top:after, .fa-duotone.fa-arrow-up-to-line:after, .fad.fa-arrow-to-top:after, .fad.fa-arrow-up-to-line:after {
  content: "";
}

.fa-duotone.fa-flag:after, .fad.fa-flag:after {
  content: "";
}

.fa-duotone.fa-face-cowboy-hat:after, .fad.fa-face-cowboy-hat:after {
  content: "";
}

.fa-duotone.fa-hanukiah:after, .fad.fa-hanukiah:after {
  content: "";
}

.fa-duotone.fa-chart-scatter-3d:after, .fad.fa-chart-scatter-3d:after {
  content: "";
}

.fa-duotone.fa-display-chart-up:after, .fad.fa-display-chart-up:after {
  content: "";
}

.fa-duotone.fa-square-code:after, .fad.fa-square-code:after {
  content: "";
}

.fa-duotone.fa-feather:after, .fad.fa-feather:after {
  content: "";
}

.fa-duotone.fa-volume-down:after, .fa-duotone.fa-volume-low:after, .fad.fa-volume-down:after, .fad.fa-volume-low:after {
  content: "";
}

.fa-duotone.fa-times-to-slot:after, .fa-duotone.fa-vote-nay:after, .fa-duotone.fa-xmark-to-slot:after, .fad.fa-times-to-slot:after, .fad.fa-vote-nay:after, .fad.fa-xmark-to-slot:after {
  content: "";
}

.fa-duotone.fa-box-alt:after, .fa-duotone.fa-box-taped:after, .fad.fa-box-alt:after, .fad.fa-box-taped:after {
  content: "";
}

.fa-duotone.fa-comment-slash:after, .fad.fa-comment-slash:after {
  content: "";
}

.fa-duotone.fa-swords:after, .fad.fa-swords:after {
  content: "";
}

.fa-duotone.fa-cloud-sun-rain:after, .fad.fa-cloud-sun-rain:after {
  content: "";
}

.fa-duotone.fa-album:after, .fad.fa-album:after {
  content: "";
}

.fa-duotone.fa-circle-n:after, .fad.fa-circle-n:after {
  content: "";
}

.fa-duotone.fa-compress:after, .fad.fa-compress:after {
  content: "";
}

.fa-duotone.fa-wheat-alt:after, .fa-duotone.fa-wheat-awn:after, .fad.fa-wheat-alt:after, .fad.fa-wheat-awn:after {
  content: "";
}

.fa-duotone.fa-ankh:after, .fad.fa-ankh:after {
  content: "";
}

.fa-duotone.fa-hands-holding-child:after, .fad.fa-hands-holding-child:after {
  content: "";
}

.fa-duotone.fa-asterisk:after, .fad.fa-asterisk:after {
  content: "**";
}

.fa-duotone.fa-key-skeleton-left-right:after, .fad.fa-key-skeleton-left-right:after {
  content: "";
}

.fa-duotone.fa-comment-lines:after, .fad.fa-comment-lines:after {
  content: "";
}

.fa-duotone.fa-luchador-mask:after, .fa-duotone.fa-luchador:after, .fa-duotone.fa-mask-luchador:after, .fad.fa-luchador-mask:after, .fad.fa-luchador:after, .fad.fa-mask-luchador:after {
  content: "";
}

.fa-duotone.fa-check-square:after, .fa-duotone.fa-square-check:after, .fad.fa-check-square:after, .fad.fa-square-check:after {
  content: "";
}

.fa-duotone.fa-shredder:after, .fad.fa-shredder:after {
  content: "";
}

.fa-duotone.fa-book-open-alt:after, .fa-duotone.fa-book-open-cover:after, .fad.fa-book-open-alt:after, .fad.fa-book-open-cover:after {
  content: "";
}

.fa-duotone.fa-sandwich:after, .fad.fa-sandwich:after {
  content: "";
}

.fa-duotone.fa-peseta-sign:after, .fad.fa-peseta-sign:after {
  content: "";
}

.fa-duotone.fa-parking-slash:after, .fa-duotone.fa-square-parking-slash:after, .fad.fa-parking-slash:after, .fad.fa-square-parking-slash:after {
  content: "";
}

.fa-duotone.fa-train-tunnel:after, .fad.fa-train-tunnel:after {
  content: "";
}

.fa-duotone.fa-header:after, .fa-duotone.fa-heading:after, .fad.fa-header:after, .fad.fa-heading:after {
  content: "";
}

.fa-duotone.fa-ghost:after, .fad.fa-ghost:after {
  content: "";
}

.fa-duotone.fa-face-anguished:after, .fad.fa-face-anguished:after {
  content: "";
}

.fa-duotone.fa-hockey-sticks:after, .fad.fa-hockey-sticks:after {
  content: "";
}

.fa-duotone.fa-abacus:after, .fad.fa-abacus:after {
  content: "";
}

.fa-duotone.fa-film-alt:after, .fa-duotone.fa-film-simple:after, .fad.fa-film-alt:after, .fad.fa-film-simple:after {
  content: "";
}

.fa-duotone.fa-list-squares:after, .fa-duotone.fa-list:after, .fad.fa-list-squares:after, .fad.fa-list:after {
  content: "";
}

.fa-duotone.fa-tree-palm:after, .fad.fa-tree-palm:after {
  content: "";
}

.fa-duotone.fa-phone-square-alt:after, .fa-duotone.fa-square-phone-flip:after, .fad.fa-phone-square-alt:after, .fad.fa-square-phone-flip:after {
  content: "";
}

.fa-duotone.fa-cart-plus:after, .fad.fa-cart-plus:after {
  content: "";
}

.fa-duotone.fa-gamepad:after, .fad.fa-gamepad:after {
  content: "";
}

.fa-duotone.fa-border-center-v:after, .fad.fa-border-center-v:after {
  content: "";
}

.fa-duotone.fa-circle-dot:after, .fa-duotone.fa-dot-circle:after, .fad.fa-circle-dot:after, .fad.fa-dot-circle:after {
  content: "";
}

.fa-duotone.fa-clipboard-medical:after, .fad.fa-clipboard-medical:after {
  content: "";
}

.fa-duotone.fa-dizzy:after, .fa-duotone.fa-face-dizzy:after, .fad.fa-dizzy:after, .fad.fa-face-dizzy:after {
  content: "";
}

.fa-duotone.fa-egg:after, .fad.fa-egg:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-to-top:after, .fa-duotone.fa-up-to-line:after, .fad.fa-arrow-alt-to-top:after, .fad.fa-up-to-line:after {
  content: "";
}

.fa-duotone.fa-house-medical-circle-xmark:after, .fad.fa-house-medical-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-watch-fitness:after, .fad.fa-watch-fitness:after {
  content: "";
}

.fa-duotone.fa-clock-nine-thirty:after, .fad.fa-clock-nine-thirty:after {
  content: "";
}

.fa-duotone.fa-campground:after, .fad.fa-campground:after {
  content: "";
}

.fa-duotone.fa-folder-plus:after, .fad.fa-folder-plus:after {
  content: "";
}

.fa-duotone.fa-jug:after, .fad.fa-jug:after {
  content: "";
}

.fa-duotone.fa-futbol-ball:after, .fa-duotone.fa-futbol:after, .fa-duotone.fa-soccer-ball:after, .fad.fa-futbol-ball:after, .fad.fa-futbol:after, .fad.fa-soccer-ball:after {
  content: "";
}

.fa-duotone.fa-snow-blowing:after, .fad.fa-snow-blowing:after {
  content: "";
}

.fa-duotone.fa-paint-brush:after, .fa-duotone.fa-paintbrush:after, .fad.fa-paint-brush:after, .fad.fa-paintbrush:after {
  content: "";
}

.fa-duotone.fa-lock:after, .fad.fa-lock:after {
  content: "";
}

.fa-duotone.fa-arrow-down-from-line:after, .fa-duotone.fa-arrow-from-top:after, .fad.fa-arrow-down-from-line:after, .fad.fa-arrow-from-top:after {
  content: "";
}

.fa-duotone.fa-gas-pump:after, .fad.fa-gas-pump:after {
  content: "";
}

.fa-duotone.fa-signal-alt-slash:after, .fa-duotone.fa-signal-bars-slash:after, .fad.fa-signal-alt-slash:after, .fad.fa-signal-bars-slash:after {
  content: "";
}

.fa-duotone.fa-monkey:after, .fad.fa-monkey:after {
  content: "";
}

.fa-duotone.fa-pro:after, .fa-duotone.fa-rectangle-pro:after, .fad.fa-pro:after, .fad.fa-rectangle-pro:after {
  content: "";
}

.fa-duotone.fa-house-night:after, .fad.fa-house-night:after {
  content: "";
}

.fa-duotone.fa-hot-tub-person:after, .fa-duotone.fa-hot-tub:after, .fad.fa-hot-tub-person:after, .fad.fa-hot-tub:after {
  content: "";
}

.fa-duotone.fa-globe-pointer:after, .fad.fa-globe-pointer:after {
  content: "";
}

.fa-duotone.fa-blanket:after, .fad.fa-blanket:after {
  content: "";
}

.fa-duotone.fa-map-location:after, .fa-duotone.fa-map-marked:after, .fad.fa-map-location:after, .fad.fa-map-marked:after {
  content: "";
}

.fa-duotone.fa-house-flood-water:after, .fad.fa-house-flood-water:after {
  content: "";
}

.fa-duotone.fa-comments-question-check:after, .fad.fa-comments-question-check:after {
  content: "";
}

.fa-duotone.fa-tree:after, .fad.fa-tree:after {
  content: "";
}

.fa-duotone.fa-arrows-cross:after, .fad.fa-arrows-cross:after {
  content: "";
}

.fa-duotone.fa-backpack:after, .fad.fa-backpack:after {
  content: "";
}

.fa-duotone.fa-square-small:after, .fad.fa-square-small:after {
  content: "";
}

.fa-duotone.fa-folder-arrow-up:after, .fa-duotone.fa-folder-upload:after, .fad.fa-folder-arrow-up:after, .fad.fa-folder-upload:after {
  content: "";
}

.fa-duotone.fa-bridge-lock:after, .fad.fa-bridge-lock:after {
  content: "";
}

.fa-duotone.fa-crosshairs-simple:after, .fad.fa-crosshairs-simple:after {
  content: "";
}

.fa-duotone.fa-sack-dollar:after, .fad.fa-sack-dollar:after {
  content: "";
}

.fa-duotone.fa-edit:after, .fa-duotone.fa-pen-to-square:after, .fad.fa-edit:after, .fad.fa-pen-to-square:after {
  content: "";
}

.fa-duotone.fa-sliders-h-square:after, .fa-duotone.fa-square-sliders:after, .fad.fa-sliders-h-square:after, .fad.fa-square-sliders:after {
  content: "";
}

.fa-duotone.fa-car-side:after, .fad.fa-car-side:after {
  content: "";
}

.fa-duotone.fa-comment-middle-top-alt:after, .fa-duotone.fa-message-middle-top:after, .fad.fa-comment-middle-top-alt:after, .fad.fa-message-middle-top:after {
  content: "";
}

.fa-duotone.fa-lightbulb-on:after, .fad.fa-lightbulb-on:after {
  content: "";
}

.fa-duotone.fa-knife:after, .fa-duotone.fa-utensil-knife:after, .fad.fa-knife:after, .fad.fa-utensil-knife:after {
  content: "";
}

.fa-duotone.fa-share-alt:after, .fa-duotone.fa-share-nodes:after, .fad.fa-share-alt:after, .fad.fa-share-nodes:after {
  content: "";
}

.fa-duotone.fa-display-chart-up-circle-dollar:after, .fad.fa-display-chart-up-circle-dollar:after {
  content: "";
}

.fa-duotone.fa-wave-sine:after, .fad.fa-wave-sine:after {
  content: "";
}

.fa-duotone.fa-heart-circle-minus:after, .fad.fa-heart-circle-minus:after {
  content: "";
}

.fa-duotone.fa-circle-w:after, .fad.fa-circle-w:after {
  content: "";
}

.fa-duotone.fa-calendar-circle:after, .fa-duotone.fa-circle-calendar:after, .fad.fa-calendar-circle:after, .fad.fa-circle-calendar:after {
  content: "";
}

.fa-duotone.fa-hourglass-2:after, .fa-duotone.fa-hourglass-half:after, .fad.fa-hourglass-2:after, .fad.fa-hourglass-half:after {
  content: "";
}

.fa-duotone.fa-microscope:after, .fad.fa-microscope:after {
  content: "";
}

.fa-duotone.fa-sunset:after, .fad.fa-sunset:after {
  content: "";
}

.fa-duotone.fa-sink:after, .fad.fa-sink:after {
  content: "";
}

.fa-duotone.fa-calendar-exclamation:after, .fad.fa-calendar-exclamation:after {
  content: "";
}

.fa-duotone.fa-truck-container-empty:after, .fad.fa-truck-container-empty:after {
  content: "";
}

.fa-duotone.fa-hand-heart:after, .fad.fa-hand-heart:after {
  content: "";
}

.fa-duotone.fa-bag-shopping:after, .fa-duotone.fa-shopping-bag:after, .fad.fa-bag-shopping:after, .fad.fa-shopping-bag:after {
  content: "";
}

.fa-duotone.fa-arrow-down-z-a:after, .fa-duotone.fa-sort-alpha-desc:after, .fa-duotone.fa-sort-alpha-down-alt:after, .fad.fa-arrow-down-z-a:after, .fad.fa-sort-alpha-desc:after, .fad.fa-sort-alpha-down-alt:after {
  content: "";
}

.fa-duotone.fa-mitten:after, .fad.fa-mitten:after {
  content: "";
}

.fa-duotone.fa-reply-clock:after, .fa-duotone.fa-reply-time:after, .fad.fa-reply-clock:after, .fad.fa-reply-time:after {
  content: "";
}

.fa-duotone.fa-person-rays:after, .fad.fa-person-rays:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-right:after, .fa-duotone.fa-right:after, .fad.fa-arrow-alt-right:after, .fad.fa-right:after {
  content: "";
}

.fa-duotone.fa-circle-f:after, .fad.fa-circle-f:after {
  content: "";
}

.fa-duotone.fa-users:after, .fad.fa-users:after {
  content: "";
}

.fa-duotone.fa-face-pleading:after, .fad.fa-face-pleading:after {
  content: "";
}

.fa-duotone.fa-eye-slash:after, .fad.fa-eye-slash:after {
  content: "";
}

.fa-duotone.fa-flask-vial:after, .fad.fa-flask-vial:after {
  content: "";
}

.fa-duotone.fa-police-box:after, .fad.fa-police-box:after {
  content: "";
}

.fa-duotone.fa-cucumber:after, .fad.fa-cucumber:after {
  content: "";
}

.fa-duotone.fa-head-side-brain:after, .fad.fa-head-side-brain:after {
  content: "";
}

.fa-duotone.fa-hand-paper:after, .fa-duotone.fa-hand:after, .fad.fa-hand-paper:after, .fad.fa-hand:after {
  content: "";
}

.fa-duotone.fa-biking-mountain:after, .fa-duotone.fa-person-biking-mountain:after, .fad.fa-biking-mountain:after, .fad.fa-person-biking-mountain:after {
  content: "";
}

.fa-duotone.fa-utensils-slash:after, .fad.fa-utensils-slash:after {
  content: "";
}

.fa-duotone.fa-print-magnifying-glass:after, .fa-duotone.fa-print-search:after, .fad.fa-print-magnifying-glass:after, .fad.fa-print-search:after {
  content: "";
}

.fa-duotone.fa-folder-bookmark:after, .fad.fa-folder-bookmark:after {
  content: "";
}

.fa-duotone.fa-om:after, .fad.fa-om:after {
  content: "";
}

.fa-duotone.fa-pi:after, .fad.fa-pi:after {
  content: "";
}

.fa-duotone.fa-flask-potion:after, .fa-duotone.fa-flask-round-potion:after, .fad.fa-flask-potion:after, .fad.fa-flask-round-potion:after {
  content: "";
}

.fa-duotone.fa-face-shush:after, .fad.fa-face-shush:after {
  content: "";
}

.fa-duotone.fa-worm:after, .fad.fa-worm:after {
  content: "";
}

.fa-duotone.fa-house-circle-xmark:after, .fad.fa-house-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-plug:after, .fad.fa-plug:after {
  content: "";
}

.fa-duotone.fa-calendar-circle-exclamation:after, .fad.fa-calendar-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-square-i:after, .fad.fa-square-i:after {
  content: "";
}

.fa-duotone.fa-chevron-up:after, .fad.fa-chevron-up:after {
  content: "";
}

.fa-duotone.fa-face-saluting:after, .fad.fa-face-saluting:after {
  content: "";
}

.fa-duotone.fa-gauge-simple-low:after, .fa-duotone.fa-tachometer-slow:after, .fad.fa-gauge-simple-low:after, .fad.fa-tachometer-slow:after {
  content: "";
}

.fa-duotone.fa-face-persevering:after, .fad.fa-face-persevering:after {
  content: "";
}

.fa-duotone.fa-camera-circle:after, .fa-duotone.fa-circle-camera:after, .fad.fa-camera-circle:after, .fad.fa-circle-camera:after {
  content: "";
}

.fa-duotone.fa-hand-spock:after, .fad.fa-hand-spock:after {
  content: "";
}

.fa-duotone.fa-spider-web:after, .fad.fa-spider-web:after {
  content: "";
}

.fa-duotone.fa-circle-microphone:after, .fa-duotone.fa-microphone-circle:after, .fad.fa-circle-microphone:after, .fad.fa-microphone-circle:after {
  content: "";
}

.fa-duotone.fa-book-arrow-up:after, .fad.fa-book-arrow-up:after {
  content: "";
}

.fa-duotone.fa-popsicle:after, .fad.fa-popsicle:after {
  content: "";
}

.fa-duotone.fa-command:after, .fad.fa-command:after {
  content: "";
}

.fa-duotone.fa-blinds:after, .fad.fa-blinds:after {
  content: "";
}

.fa-duotone.fa-stopwatch:after, .fad.fa-stopwatch:after {
  content: "";
}

.fa-duotone.fa-saxophone:after, .fad.fa-saxophone:after {
  content: "";
}

.fa-duotone.fa-square-2:after, .fad.fa-square-2:after {
  content: "";
}

.fa-duotone.fa-field-hockey-stick-ball:after, .fa-duotone.fa-field-hockey:after, .fad.fa-field-hockey-stick-ball:after, .fad.fa-field-hockey:after {
  content: "";
}

.fa-duotone.fa-arrow-up-square-triangle:after, .fa-duotone.fa-sort-shapes-up-alt:after, .fad.fa-arrow-up-square-triangle:after, .fad.fa-sort-shapes-up-alt:after {
  content: "";
}

.fa-duotone.fa-face-scream:after, .fad.fa-face-scream:after {
  content: "";
}

.fa-duotone.fa-square-m:after, .fad.fa-square-m:after {
  content: "";
}

.fa-duotone.fa-camera-web:after, .fa-duotone.fa-webcam:after, .fad.fa-camera-web:after, .fad.fa-webcam:after {
  content: "";
}

.fa-duotone.fa-comment-arrow-down:after, .fad.fa-comment-arrow-down:after {
  content: "";
}

.fa-duotone.fa-lightbulb-cfl:after, .fad.fa-lightbulb-cfl:after {
  content: "";
}

.fa-duotone.fa-window-frame-open:after, .fad.fa-window-frame-open:after {
  content: "";
}

.fa-duotone.fa-face-kiss:after, .fa-duotone.fa-kiss:after, .fad.fa-face-kiss:after, .fad.fa-kiss:after {
  content: "";
}

.fa-duotone.fa-bridge-circle-xmark:after, .fad.fa-bridge-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-period:after, .fad.fa-period:after {
  content: "..";
}

.fa-duotone.fa-face-grin-tongue:after, .fa-duotone.fa-grin-tongue:after, .fad.fa-face-grin-tongue:after, .fad.fa-grin-tongue:after {
  content: "";
}

.fa-duotone.fa-up-to-dotted-line:after, .fad.fa-up-to-dotted-line:after {
  content: "";
}

.fa-duotone.fa-thought-bubble:after, .fad.fa-thought-bubble:after {
  content: "";
}

.fa-duotone.fa-skeleton-ribs:after, .fad.fa-skeleton-ribs:after {
  content: "";
}

.fa-duotone.fa-raygun:after, .fad.fa-raygun:after {
  content: "";
}

.fa-duotone.fa-flute:after, .fad.fa-flute:after {
  content: "";
}

.fa-duotone.fa-acorn:after, .fad.fa-acorn:after {
  content: "";
}

.fa-duotone.fa-video-arrow-up-right:after, .fad.fa-video-arrow-up-right:after {
  content: "";
}

.fa-duotone.fa-grate-droplet:after, .fad.fa-grate-droplet:after {
  content: "";
}

.fa-duotone.fa-seal-exclamation:after, .fad.fa-seal-exclamation:after {
  content: "";
}

.fa-duotone.fa-chess-bishop:after, .fad.fa-chess-bishop:after {
  content: "";
}

.fa-duotone.fa-message-sms:after, .fad.fa-message-sms:after {
  content: "";
}

.fa-duotone.fa-coffee-beans:after, .fad.fa-coffee-beans:after {
  content: "";
}

.fa-duotone.fa-hat-witch:after, .fad.fa-hat-witch:after {
  content: "";
}

.fa-duotone.fa-face-grin-wink:after, .fa-duotone.fa-grin-wink:after, .fad.fa-face-grin-wink:after, .fad.fa-grin-wink:after {
  content: "";
}

.fa-duotone.fa-clock-three-thirty:after, .fad.fa-clock-three-thirty:after {
  content: "";
}

.fa-duotone.fa-deaf:after, .fa-duotone.fa-deafness:after, .fa-duotone.fa-ear-deaf:after, .fa-duotone.fa-hard-of-hearing:after, .fad.fa-deaf:after, .fad.fa-deafness:after, .fad.fa-ear-deaf:after, .fad.fa-hard-of-hearing:after {
  content: "";
}

.fa-duotone.fa-alarm-clock:after, .fad.fa-alarm-clock:after {
  content: "";
}

.fa-duotone.fa-eclipse:after, .fad.fa-eclipse:after {
  content: "";
}

.fa-duotone.fa-face-relieved:after, .fad.fa-face-relieved:after {
  content: "";
}

.fa-duotone.fa-road-circle-check:after, .fad.fa-road-circle-check:after {
  content: "";
}

.fa-duotone.fa-dice-five:after, .fad.fa-dice-five:after {
  content: "";
}

.fa-duotone.fa-minus-octagon:after, .fa-duotone.fa-octagon-minus:after, .fad.fa-minus-octagon:after, .fad.fa-octagon-minus:after {
  content: "";
}

.fa-duotone.fa-rss-square:after, .fa-duotone.fa-square-rss:after, .fad.fa-rss-square:after, .fad.fa-square-rss:after {
  content: "";
}

.fa-duotone.fa-face-zany:after, .fad.fa-face-zany:after {
  content: "";
}

.fa-duotone.fa-tricycle:after, .fad.fa-tricycle:after {
  content: "";
}

.fa-duotone.fa-land-mine-on:after, .fad.fa-land-mine-on:after {
  content: "";
}

.fa-duotone.fa-square-arrow-up-left:after, .fad.fa-square-arrow-up-left:after {
  content: "";
}

.fa-duotone.fa-i-cursor:after, .fad.fa-i-cursor:after {
  content: "";
}

.fa-duotone.fa-chart-mixed-up-circle-dollar:after, .fad.fa-chart-mixed-up-circle-dollar:after {
  content: "";
}

.fa-duotone.fa-salt-shaker:after, .fad.fa-salt-shaker:after {
  content: "";
}

.fa-duotone.fa-stamp:after, .fad.fa-stamp:after {
  content: "";
}

.fa-duotone.fa-file-plus:after, .fad.fa-file-plus:after {
  content: "";
}

.fa-duotone.fa-draw-square:after, .fad.fa-draw-square:after {
  content: "";
}

.fa-duotone.fa-toilet-paper-reverse-slash:after, .fa-duotone.fa-toilet-paper-under-slash:after, .fad.fa-toilet-paper-reverse-slash:after, .fad.fa-toilet-paper-under-slash:after {
  content: "";
}

.fa-duotone.fa-stairs:after, .fad.fa-stairs:after {
  content: "";
}

.fa-duotone.fa-drone-alt:after, .fa-duotone.fa-drone-front:after, .fad.fa-drone-alt:after, .fad.fa-drone-front:after {
  content: "";
}

.fa-duotone.fa-glass-empty:after, .fad.fa-glass-empty:after {
  content: "";
}

.fa-duotone.fa-dial-high:after, .fad.fa-dial-high:after {
  content: "";
}

.fa-duotone.fa-user-construction:after, .fa-duotone.fa-user-hard-hat:after, .fa-duotone.fa-user-helmet-safety:after, .fad.fa-user-construction:after, .fad.fa-user-hard-hat:after, .fad.fa-user-helmet-safety:after {
  content: "";
}

.fa-duotone.fa-i:after, .fad.fa-i:after {
  content: "II";
}

.fa-duotone.fa-hryvnia-sign:after, .fa-duotone.fa-hryvnia:after, .fad.fa-hryvnia-sign:after, .fad.fa-hryvnia:after {
  content: "";
}

.fa-duotone.fa-arrow-down-left-and-arrow-up-right-to-center:after, .fad.fa-arrow-down-left-and-arrow-up-right-to-center:after {
  content: "";
}

.fa-duotone.fa-pills:after, .fad.fa-pills:after {
  content: "";
}

.fa-duotone.fa-face-grin-wide:after, .fa-duotone.fa-grin-alt:after, .fad.fa-face-grin-wide:after, .fad.fa-grin-alt:after {
  content: "";
}

.fa-duotone.fa-tooth:after, .fad.fa-tooth:after {
  content: "";
}

.fa-duotone.fa-basketball-hoop:after, .fad.fa-basketball-hoop:after {
  content: "";
}

.fa-duotone.fa-objects-align-bottom:after, .fad.fa-objects-align-bottom:after {
  content: "";
}

.fa-duotone.fa-v:after, .fad.fa-v:after {
  content: "VV";
}

.fa-duotone.fa-sparkles:after, .fad.fa-sparkles:after {
  content: "";
}

.fa-duotone.fa-squid:after, .fad.fa-squid:after {
  content: "";
}

.fa-duotone.fa-leafy-green:after, .fad.fa-leafy-green:after {
  content: "";
}

.fa-duotone.fa-circle-arrow-up-right:after, .fad.fa-circle-arrow-up-right:after {
  content: "";
}

.fa-duotone.fa-calendars:after, .fad.fa-calendars:after {
  content: "";
}

.fa-duotone.fa-bangladeshi-taka-sign:after, .fad.fa-bangladeshi-taka-sign:after {
  content: "";
}

.fa-duotone.fa-bicycle:after, .fad.fa-bicycle:after {
  content: "";
}

.fa-duotone.fa-hammer-war:after, .fad.fa-hammer-war:after {
  content: "";
}

.fa-duotone.fa-circle-d:after, .fad.fa-circle-d:after {
  content: "";
}

.fa-duotone.fa-spider-black-widow:after, .fad.fa-spider-black-widow:after {
  content: "";
}

.fa-duotone.fa-rod-asclepius:after, .fa-duotone.fa-rod-snake:after, .fa-duotone.fa-staff-aesculapius:after, .fa-duotone.fa-staff-snake:after, .fad.fa-rod-asclepius:after, .fad.fa-rod-snake:after, .fad.fa-staff-aesculapius:after, .fad.fa-staff-snake:after {
  content: "";
}

.fa-duotone.fa-pear:after, .fad.fa-pear:after {
  content: "";
}

.fa-duotone.fa-head-side-cough-slash:after, .fad.fa-head-side-cough-slash:after {
  content: "";
}

.fa-duotone.fa-triangle:after, .fad.fa-triangle:after {
  content: "";
}

.fa-duotone.fa-apartment:after, .fad.fa-apartment:after {
  content: "";
}

.fa-duotone.fa-ambulance:after, .fa-duotone.fa-truck-medical:after, .fad.fa-ambulance:after, .fad.fa-truck-medical:after {
  content: "";
}

.fa-duotone.fa-pepper:after, .fad.fa-pepper:after {
  content: "";
}

.fa-duotone.fa-piano:after, .fad.fa-piano:after {
  content: "";
}

.fa-duotone.fa-gun-squirt:after, .fad.fa-gun-squirt:after {
  content: "";
}

.fa-duotone.fa-wheat-awn-circle-exclamation:after, .fad.fa-wheat-awn-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-snowman:after, .fad.fa-snowman:after {
  content: "";
}

.fa-duotone.fa-user-alien:after, .fad.fa-user-alien:after {
  content: "";
}

.fa-duotone.fa-shield-check:after, .fad.fa-shield-check:after {
  content: "";
}

.fa-duotone.fa-mortar-pestle:after, .fad.fa-mortar-pestle:after {
  content: "";
}

.fa-duotone.fa-road-barrier:after, .fad.fa-road-barrier:after {
  content: "";
}

.fa-duotone.fa-chart-candlestick:after, .fad.fa-chart-candlestick:after {
  content: "";
}

.fa-duotone.fa-briefcase-blank:after, .fad.fa-briefcase-blank:after {
  content: "";
}

.fa-duotone.fa-school:after, .fad.fa-school:after {
  content: "";
}

.fa-duotone.fa-igloo:after, .fad.fa-igloo:after {
  content: "";
}

.fa-duotone.fa-bracket-round:after, .fa-duotone.fa-parenthesis:after, .fad.fa-bracket-round:after, .fad.fa-parenthesis:after {
  content: "((";
}

.fa-duotone.fa-joint:after, .fad.fa-joint:after {
  content: "";
}

.fa-duotone.fa-horse-saddle:after, .fad.fa-horse-saddle:after {
  content: "";
}

.fa-duotone.fa-mug-marshmallows:after, .fad.fa-mug-marshmallows:after {
  content: "";
}

.fa-duotone.fa-filters:after, .fad.fa-filters:after {
  content: "";
}

.fa-duotone.fa-bell-on:after, .fad.fa-bell-on:after {
  content: "";
}

.fa-duotone.fa-angle-right:after, .fad.fa-angle-right:after {
  content: "";
}

.fa-duotone.fa-dial-med:after, .fad.fa-dial-med:after {
  content: "";
}

.fa-duotone.fa-horse:after, .fad.fa-horse:after {
  content: "";
}

.fa-duotone.fa-q:after, .fad.fa-q:after {
  content: "QQ";
}

.fa-duotone.fa-monitor-heart-rate:after, .fa-duotone.fa-monitor-waveform:after, .fad.fa-monitor-heart-rate:after, .fad.fa-monitor-waveform:after {
  content: "";
}

.fa-duotone.fa-link-simple:after, .fad.fa-link-simple:after {
  content: "";
}

.fa-duotone.fa-whistle:after, .fad.fa-whistle:after {
  content: "";
}

.fa-duotone.fa-g:after, .fad.fa-g:after {
  content: "GG";
}

.fa-duotone.fa-fragile:after, .fa-duotone.fa-wine-glass-crack:after, .fad.fa-fragile:after, .fad.fa-wine-glass-crack:after {
  content: "";
}

.fa-duotone.fa-slot-machine:after, .fad.fa-slot-machine:after {
  content: "";
}

.fa-duotone.fa-notes-medical:after, .fad.fa-notes-medical:after {
  content: "";
}

.fa-duotone.fa-car-wash:after, .fad.fa-car-wash:after {
  content: "";
}

.fa-duotone.fa-escalator:after, .fad.fa-escalator:after {
  content: "";
}

.fa-duotone.fa-comment-image:after, .fad.fa-comment-image:after {
  content: "";
}

.fa-duotone.fa-temperature-2:after, .fa-duotone.fa-temperature-half:after, .fa-duotone.fa-thermometer-2:after, .fa-duotone.fa-thermometer-half:after, .fad.fa-temperature-2:after, .fad.fa-temperature-half:after, .fad.fa-thermometer-2:after, .fad.fa-thermometer-half:after {
  content: "";
}

.fa-duotone.fa-dong-sign:after, .fad.fa-dong-sign:after {
  content: "";
}

.fa-duotone.fa-donut:after, .fa-duotone.fa-doughnut:after, .fad.fa-donut:after, .fad.fa-doughnut:after {
  content: "";
}

.fa-duotone.fa-capsules:after, .fad.fa-capsules:after {
  content: "";
}

.fa-duotone.fa-poo-bolt:after, .fa-duotone.fa-poo-storm:after, .fad.fa-poo-bolt:after, .fad.fa-poo-storm:after {
  content: "";
}

.fa-duotone.fa-tally-1:after, .fad.fa-tally-1:after {
  content: "";
}

.fa-duotone.fa-face-frown-open:after, .fa-duotone.fa-frown-open:after, .fad.fa-face-frown-open:after, .fad.fa-frown-open:after {
  content: "";
}

.fa-duotone.fa-square-dashed:after, .fad.fa-square-dashed:after {
  content: "";
}

.fa-duotone.fa-square-j:after, .fad.fa-square-j:after {
  content: "";
}

.fa-duotone.fa-hand-point-up:after, .fad.fa-hand-point-up:after {
  content: "";
}

.fa-duotone.fa-money-bill:after, .fad.fa-money-bill:after {
  content: "";
}

.fa-duotone.fa-arrow-up-big-small:after, .fa-duotone.fa-sort-size-up:after, .fad.fa-arrow-up-big-small:after, .fad.fa-sort-size-up:after {
  content: "";
}

.fa-duotone.fa-barcode-read:after, .fad.fa-barcode-read:after {
  content: "";
}

.fa-duotone.fa-baguette:after, .fad.fa-baguette:after {
  content: "";
}

.fa-duotone.fa-bowl-soft-serve:after, .fad.fa-bowl-soft-serve:after {
  content: "";
}

.fa-duotone.fa-face-holding-back-tears:after, .fad.fa-face-holding-back-tears:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-square-up:after, .fa-duotone.fa-square-up:after, .fad.fa-arrow-alt-square-up:after, .fad.fa-square-up:after {
  content: "";
}

.fa-duotone.fa-subway-tunnel:after, .fa-duotone.fa-train-subway-tunnel:after, .fad.fa-subway-tunnel:after, .fad.fa-train-subway-tunnel:after {
  content: "";
}

.fa-duotone.fa-exclamation-square:after, .fa-duotone.fa-square-exclamation:after, .fad.fa-exclamation-square:after, .fad.fa-square-exclamation:after {
  content: "";
}

.fa-duotone.fa-semicolon:after, .fad.fa-semicolon:after {
  content: ";;";
}

.fa-duotone.fa-bookmark:after, .fad.fa-bookmark:after {
  content: "";
}

.fa-duotone.fa-fan-table:after, .fad.fa-fan-table:after {
  content: "";
}

.fa-duotone.fa-align-justify:after, .fad.fa-align-justify:after {
  content: "";
}

.fa-duotone.fa-battery-1:after, .fa-duotone.fa-battery-low:after, .fad.fa-battery-1:after, .fad.fa-battery-low:after {
  content: "";
}

.fa-duotone.fa-credit-card-front:after, .fad.fa-credit-card-front:after {
  content: "";
}

.fa-duotone.fa-brain-arrow-curved-right:after, .fa-duotone.fa-mind-share:after, .fad.fa-brain-arrow-curved-right:after, .fad.fa-mind-share:after {
  content: "";
}

.fa-duotone.fa-umbrella-beach:after, .fad.fa-umbrella-beach:after {
  content: "";
}

.fa-duotone.fa-helmet-un:after, .fad.fa-helmet-un:after {
  content: "";
}

.fa-duotone.fa-location-smile:after, .fa-duotone.fa-map-marker-smile:after, .fad.fa-location-smile:after, .fad.fa-map-marker-smile:after {
  content: "";
}

.fa-duotone.fa-arrow-left-to-line:after, .fa-duotone.fa-arrow-to-left:after, .fad.fa-arrow-left-to-line:after, .fad.fa-arrow-to-left:after {
  content: "";
}

.fa-duotone.fa-bullseye:after, .fad.fa-bullseye:after {
  content: "";
}

.fa-duotone.fa-nigiri:after, .fa-duotone.fa-sushi:after, .fad.fa-nigiri:after, .fad.fa-sushi:after {
  content: "";
}

.fa-duotone.fa-comment-alt-captions:after, .fa-duotone.fa-message-captions:after, .fad.fa-comment-alt-captions:after, .fad.fa-message-captions:after {
  content: "";
}

.fa-duotone.fa-trash-list:after, .fad.fa-trash-list:after {
  content: "";
}

.fa-duotone.fa-bacon:after, .fad.fa-bacon:after {
  content: "";
}

.fa-duotone.fa-option:after, .fad.fa-option:after {
  content: "";
}

.fa-duotone.fa-raccoon:after, .fad.fa-raccoon:after {
  content: "";
}

.fa-duotone.fa-hand-point-down:after, .fad.fa-hand-point-down:after {
  content: "";
}

.fa-duotone.fa-arrow-up-from-bracket:after, .fad.fa-arrow-up-from-bracket:after {
  content: "";
}

.fa-duotone.fa-head-side-gear:after, .fad.fa-head-side-gear:after {
  content: "";
}

.fa-duotone.fa-trash-plus:after, .fad.fa-trash-plus:after {
  content: "";
}

.fa-duotone.fa-objects-align-top:after, .fad.fa-objects-align-top:after {
  content: "";
}

.fa-duotone.fa-folder-blank:after, .fa-duotone.fa-folder:after, .fad.fa-folder-blank:after, .fad.fa-folder:after {
  content: "";
}

.fa-duotone.fa-face-anxious-sweat:after, .fad.fa-face-anxious-sweat:after {
  content: "";
}

.fa-duotone.fa-credit-card-blank:after, .fad.fa-credit-card-blank:after {
  content: "";
}

.fa-duotone.fa-file-medical-alt:after, .fa-duotone.fa-file-waveform:after, .fad.fa-file-medical-alt:after, .fad.fa-file-waveform:after {
  content: "";
}

.fa-duotone.fa-microchip-ai:after, .fad.fa-microchip-ai:after {
  content: "";
}

.fa-duotone.fa-mug:after, .fad.fa-mug:after {
  content: "";
}

.fa-duotone.fa-plane-up-slash:after, .fad.fa-plane-up-slash:after {
  content: "";
}

.fa-duotone.fa-radiation:after, .fad.fa-radiation:after {
  content: "";
}

.fa-duotone.fa-pen-circle:after, .fad.fa-pen-circle:after {
  content: "";
}

.fa-duotone.fa-bag-seedling:after, .fad.fa-bag-seedling:after {
  content: "";
}

.fa-duotone.fa-chart-simple:after, .fad.fa-chart-simple:after {
  content: "";
}

.fa-duotone.fa-crutches:after, .fad.fa-crutches:after {
  content: "";
}

.fa-duotone.fa-circle-parking:after, .fa-duotone.fa-parking-circle:after, .fad.fa-circle-parking:after, .fad.fa-parking-circle:after {
  content: "";
}

.fa-duotone.fa-mars-stroke:after, .fad.fa-mars-stroke:after {
  content: "";
}

.fa-duotone.fa-leaf-oak:after, .fad.fa-leaf-oak:after {
  content: "";
}

.fa-duotone.fa-square-bolt:after, .fad.fa-square-bolt:after {
  content: "";
}

.fa-duotone.fa-vial:after, .fad.fa-vial:after {
  content: "";
}

.fa-duotone.fa-dashboard:after, .fa-duotone.fa-gauge-med:after, .fa-duotone.fa-gauge:after, .fa-duotone.fa-tachometer-alt-average:after, .fad.fa-dashboard:after, .fad.fa-gauge-med:after, .fad.fa-gauge:after, .fad.fa-tachometer-alt-average:after {
  content: "";
}

.fa-duotone.fa-magic-wand-sparkles:after, .fa-duotone.fa-wand-magic-sparkles:after, .fad.fa-magic-wand-sparkles:after, .fad.fa-wand-magic-sparkles:after {
  content: "";
}

.fa-duotone.fa-lambda:after, .fad.fa-lambda:after {
  content: "";
}

.fa-duotone.fa-e:after, .fad.fa-e:after {
  content: "EE";
}

.fa-duotone.fa-pizza:after, .fad.fa-pizza:after {
  content: "";
}

.fa-duotone.fa-bowl-chopsticks-noodles:after, .fad.fa-bowl-chopsticks-noodles:after {
  content: "";
}

.fa-duotone.fa-h3:after, .fad.fa-h3:after {
  content: "";
}

.fa-duotone.fa-pen-alt:after, .fa-duotone.fa-pen-clip:after, .fad.fa-pen-alt:after, .fad.fa-pen-clip:after {
  content: "";
}

.fa-duotone.fa-bridge-circle-exclamation:after, .fad.fa-bridge-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-badge-percent:after, .fad.fa-badge-percent:after {
  content: "";
}

.fa-duotone.fa-user:after, .fad.fa-user:after {
  content: "";
}

.fa-duotone.fa-sensor:after, .fad.fa-sensor:after {
  content: "";
}

.fa-duotone.fa-comma:after, .fad.fa-comma:after {
  content: ",,";
}

.fa-duotone.fa-school-circle-check:after, .fad.fa-school-circle-check:after {
  content: "";
}

.fa-duotone.fa-toilet-paper-reverse:after, .fa-duotone.fa-toilet-paper-under:after, .fad.fa-toilet-paper-reverse:after, .fad.fa-toilet-paper-under:after {
  content: "";
}

.fa-duotone.fa-light-emergency:after, .fad.fa-light-emergency:after {
  content: "";
}

.fa-duotone.fa-arrow-down-to-arc:after, .fad.fa-arrow-down-to-arc:after {
  content: "";
}

.fa-duotone.fa-dumpster:after, .fad.fa-dumpster:after {
  content: "";
}

.fa-duotone.fa-shuttle-van:after, .fa-duotone.fa-van-shuttle:after, .fad.fa-shuttle-van:after, .fad.fa-van-shuttle:after {
  content: "";
}

.fa-duotone.fa-building-user:after, .fad.fa-building-user:after {
  content: "";
}

.fa-duotone.fa-light-switch:after, .fad.fa-light-switch:after {
  content: "";
}

.fa-duotone.fa-caret-square-left:after, .fa-duotone.fa-square-caret-left:after, .fad.fa-caret-square-left:after, .fad.fa-square-caret-left:after {
  content: "";
}

.fa-duotone.fa-highlighter:after, .fad.fa-highlighter:after {
  content: "";
}

.fa-duotone.fa-heart-rate:after, .fa-duotone.fa-wave-pulse:after, .fad.fa-heart-rate:after, .fad.fa-wave-pulse:after {
  content: "";
}

.fa-duotone.fa-key:after, .fad.fa-key:after {
  content: "";
}

.fa-duotone.fa-hat-santa:after, .fad.fa-hat-santa:after {
  content: "";
}

.fa-duotone.fa-tamale:after, .fad.fa-tamale:after {
  content: "";
}

.fa-duotone.fa-box-check:after, .fad.fa-box-check:after {
  content: "";
}

.fa-duotone.fa-bullhorn:after, .fad.fa-bullhorn:after {
  content: "";
}

.fa-duotone.fa-steak:after, .fad.fa-steak:after {
  content: "";
}

.fa-duotone.fa-location-crosshairs-slash:after, .fa-duotone.fa-location-slash:after, .fad.fa-location-crosshairs-slash:after, .fad.fa-location-slash:after {
  content: "";
}

.fa-duotone.fa-person-dolly:after, .fad.fa-person-dolly:after {
  content: "";
}

.fa-duotone.fa-globe:after, .fad.fa-globe:after {
  content: "";
}

.fa-duotone.fa-synagogue:after, .fad.fa-synagogue:after {
  content: "";
}

.fa-duotone.fa-file-chart-column:after, .fa-duotone.fa-file-chart-line:after, .fad.fa-file-chart-column:after, .fad.fa-file-chart-line:after {
  content: "";
}

.fa-duotone.fa-person-half-dress:after, .fad.fa-person-half-dress:after {
  content: "";
}

.fa-duotone.fa-folder-image:after, .fad.fa-folder-image:after {
  content: "";
}

.fa-duotone.fa-calendar-edit:after, .fa-duotone.fa-calendar-pen:after, .fad.fa-calendar-edit:after, .fad.fa-calendar-pen:after {
  content: "";
}

.fa-duotone.fa-road-bridge:after, .fad.fa-road-bridge:after {
  content: "";
}

.fa-duotone.fa-face-smile-tear:after, .fad.fa-face-smile-tear:after {
  content: "";
}

.fa-duotone.fa-comment-alt-plus:after, .fa-duotone.fa-message-plus:after, .fad.fa-comment-alt-plus:after, .fad.fa-message-plus:after {
  content: "";
}

.fa-duotone.fa-location-arrow:after, .fad.fa-location-arrow:after {
  content: "";
}

.fa-duotone.fa-c:after, .fad.fa-c:after {
  content: "CC";
}

.fa-duotone.fa-tablet-button:after, .fad.fa-tablet-button:after {
  content: "";
}

.fa-duotone.fa-person-dress-fairy:after, .fad.fa-person-dress-fairy:after {
  content: "";
}

.fa-duotone.fa-rectangle-history-circle-user:after, .fad.fa-rectangle-history-circle-user:after {
  content: "";
}

.fa-duotone.fa-building-lock:after, .fad.fa-building-lock:after {
  content: "";
}

.fa-duotone.fa-chart-line-up:after, .fad.fa-chart-line-up:after {
  content: "";
}

.fa-duotone.fa-mailbox:after, .fad.fa-mailbox:after {
  content: "";
}

.fa-duotone.fa-truck-bolt:after, .fad.fa-truck-bolt:after {
  content: "";
}

.fa-duotone.fa-pizza-slice:after, .fad.fa-pizza-slice:after {
  content: "";
}

.fa-duotone.fa-money-bill-wave:after, .fad.fa-money-bill-wave:after {
  content: "";
}

.fa-duotone.fa-area-chart:after, .fa-duotone.fa-chart-area:after, .fad.fa-area-chart:after, .fad.fa-chart-area:after {
  content: "";
}

.fa-duotone.fa-house-flag:after, .fad.fa-house-flag:after {
  content: "";
}

.fa-duotone.fa-circle-three-quarters-stroke:after, .fad.fa-circle-three-quarters-stroke:after {
  content: "";
}

.fa-duotone.fa-person-circle-minus:after, .fad.fa-person-circle-minus:after {
  content: "";
}

.fa-duotone.fa-scalpel:after, .fad.fa-scalpel:after {
  content: "";
}

.fa-duotone.fa-ban:after, .fa-duotone.fa-cancel:after, .fad.fa-ban:after, .fad.fa-cancel:after {
  content: "";
}

.fa-duotone.fa-bell-exclamation:after, .fad.fa-bell-exclamation:after {
  content: "";
}

.fa-duotone.fa-bookmark-circle:after, .fa-duotone.fa-circle-bookmark:after, .fad.fa-bookmark-circle:after, .fad.fa-circle-bookmark:after {
  content: "";
}

.fa-duotone.fa-egg-fried:after, .fad.fa-egg-fried:after {
  content: "";
}

.fa-duotone.fa-face-weary:after, .fad.fa-face-weary:after {
  content: "";
}

.fa-duotone.fa-uniform-martial-arts:after, .fad.fa-uniform-martial-arts:after {
  content: "";
}

.fa-duotone.fa-camera-rotate:after, .fad.fa-camera-rotate:after {
  content: "";
}

.fa-duotone.fa-sun-dust:after, .fad.fa-sun-dust:after {
  content: "";
}

.fa-duotone.fa-comment-text:after, .fad.fa-comment-text:after {
  content: "";
}

.fa-duotone.fa-air-freshener:after, .fa-duotone.fa-spray-can-sparkles:after, .fad.fa-air-freshener:after, .fad.fa-spray-can-sparkles:after {
  content: "";
}

.fa-duotone.fa-signal-alt-4:after, .fa-duotone.fa-signal-alt:after, .fa-duotone.fa-signal-bars-strong:after, .fa-duotone.fa-signal-bars:after, .fad.fa-signal-alt-4:after, .fad.fa-signal-alt:after, .fad.fa-signal-bars-strong:after, .fad.fa-signal-bars:after {
  content: "";
}

.fa-duotone.fa-diamond-exclamation:after, .fad.fa-diamond-exclamation:after {
  content: "";
}

.fa-duotone.fa-star:after, .fad.fa-star:after {
  content: "";
}

.fa-duotone.fa-dial-min:after, .fad.fa-dial-min:after {
  content: "";
}

.fa-duotone.fa-repeat:after, .fad.fa-repeat:after {
  content: "";
}

.fa-duotone.fa-cross:after, .fad.fa-cross:after {
  content: "";
}

.fa-duotone.fa-file-caret-down:after, .fa-duotone.fa-page-caret-down:after, .fad.fa-file-caret-down:after, .fad.fa-page-caret-down:after {
  content: "";
}

.fa-duotone.fa-box:after, .fad.fa-box:after {
  content: "";
}

.fa-duotone.fa-venus-mars:after, .fad.fa-venus-mars:after {
  content: "";
}

.fa-duotone.fa-clock-seven-thirty:after, .fad.fa-clock-seven-thirty:after {
  content: "";
}

.fa-duotone.fa-arrow-pointer:after, .fa-duotone.fa-mouse-pointer:after, .fad.fa-arrow-pointer:after, .fad.fa-mouse-pointer:after {
  content: "";
}

.fa-duotone.fa-clock-four-thirty:after, .fad.fa-clock-four-thirty:after {
  content: "";
}

.fa-duotone.fa-signal-alt-3:after, .fa-duotone.fa-signal-bars-good:after, .fad.fa-signal-alt-3:after, .fad.fa-signal-bars-good:after {
  content: "";
}

.fa-duotone.fa-cactus:after, .fad.fa-cactus:after {
  content: "";
}

.fa-duotone.fa-lightbulb-gear:after, .fad.fa-lightbulb-gear:after {
  content: "";
}

.fa-duotone.fa-expand-arrows-alt:after, .fa-duotone.fa-maximize:after, .fad.fa-expand-arrows-alt:after, .fad.fa-maximize:after {
  content: "";
}

.fa-duotone.fa-charging-station:after, .fad.fa-charging-station:after {
  content: "";
}

.fa-duotone.fa-shapes:after, .fa-duotone.fa-triangle-circle-square:after, .fad.fa-shapes:after, .fad.fa-triangle-circle-square:after {
  content: "";
}

.fa-duotone.fa-plane-tail:after, .fad.fa-plane-tail:after {
  content: "";
}

.fa-duotone.fa-gauge-simple-max:after, .fa-duotone.fa-tachometer-fastest:after, .fad.fa-gauge-simple-max:after, .fad.fa-tachometer-fastest:after {
  content: "";
}

.fa-duotone.fa-circle-u:after, .fad.fa-circle-u:after {
  content: "";
}

.fa-duotone.fa-shield-slash:after, .fad.fa-shield-slash:after {
  content: "";
}

.fa-duotone.fa-phone-square-down:after, .fa-duotone.fa-square-phone-hangup:after, .fad.fa-phone-square-down:after, .fad.fa-square-phone-hangup:after {
  content: "";
}

.fa-duotone.fa-arrow-up-left:after, .fad.fa-arrow-up-left:after {
  content: "";
}

.fa-duotone.fa-transporter-1:after, .fad.fa-transporter-1:after {
  content: "";
}

.fa-duotone.fa-peanuts:after, .fad.fa-peanuts:after {
  content: "";
}

.fa-duotone.fa-random:after, .fa-duotone.fa-shuffle:after, .fad.fa-random:after, .fad.fa-shuffle:after {
  content: "";
}

.fa-duotone.fa-person-running:after, .fa-duotone.fa-running:after, .fad.fa-person-running:after, .fad.fa-running:after {
  content: "";
}

.fa-duotone.fa-mobile-retro:after, .fad.fa-mobile-retro:after {
  content: "";
}

.fa-duotone.fa-grip-lines-vertical:after, .fad.fa-grip-lines-vertical:after {
  content: "";
}

.fa-duotone.fa-bin-bottles-recycle:after, .fad.fa-bin-bottles-recycle:after {
  content: "";
}

.fa-duotone.fa-arrow-up-from-square:after, .fad.fa-arrow-up-from-square:after {
  content: "";
}

.fa-duotone.fa-file-dashed-line:after, .fa-duotone.fa-page-break:after, .fad.fa-file-dashed-line:after, .fad.fa-page-break:after {
  content: "";
}

.fa-duotone.fa-bracket-curly-right:after, .fad.fa-bracket-curly-right:after {
  content: "}}";
}

.fa-duotone.fa-spider:after, .fad.fa-spider:after {
  content: "";
}

.fa-duotone.fa-clock-three:after, .fad.fa-clock-three:after {
  content: "";
}

.fa-duotone.fa-hands-bound:after, .fad.fa-hands-bound:after {
  content: "";
}

.fa-duotone.fa-scalpel-line-dashed:after, .fa-duotone.fa-scalpel-path:after, .fad.fa-scalpel-line-dashed:after, .fad.fa-scalpel-path:after {
  content: "";
}

.fa-duotone.fa-file-invoice-dollar:after, .fad.fa-file-invoice-dollar:after {
  content: "";
}

.fa-duotone.fa-pipe-smoking:after, .fad.fa-pipe-smoking:after {
  content: "";
}

.fa-duotone.fa-face-astonished:after, .fad.fa-face-astonished:after {
  content: "";
}

.fa-duotone.fa-window:after, .fad.fa-window:after {
  content: "";
}

.fa-duotone.fa-plane-circle-exclamation:after, .fad.fa-plane-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-ear:after, .fad.fa-ear:after {
  content: "";
}

.fa-duotone.fa-file-lock:after, .fad.fa-file-lock:after {
  content: "";
}

.fa-duotone.fa-diagram-venn:after, .fad.fa-diagram-venn:after {
  content: "";
}

.fa-duotone.fa-x-ray:after, .fad.fa-x-ray:after {
  content: "";
}

.fa-duotone.fa-goal-net:after, .fad.fa-goal-net:after {
  content: "";
}

.fa-duotone.fa-coffin-cross:after, .fad.fa-coffin-cross:after {
  content: "";
}

.fa-duotone.fa-spell-check:after, .fad.fa-spell-check:after {
  content: "";
}

.fa-duotone.fa-location-xmark:after, .fa-duotone.fa-map-marker-times:after, .fa-duotone.fa-map-marker-xmark:after, .fad.fa-location-xmark:after, .fad.fa-map-marker-times:after, .fad.fa-map-marker-xmark:after {
  content: "";
}

.fa-duotone.fa-circle-quarter-stroke:after, .fad.fa-circle-quarter-stroke:after {
  content: "";
}

.fa-duotone.fa-lasso:after, .fad.fa-lasso:after {
  content: "";
}

.fa-duotone.fa-slash:after, .fad.fa-slash:after {
  content: "";
}

.fa-duotone.fa-person-to-portal:after, .fa-duotone.fa-portal-enter:after, .fad.fa-person-to-portal:after, .fad.fa-portal-enter:after {
  content: "";
}

.fa-duotone.fa-calendar-star:after, .fad.fa-calendar-star:after {
  content: "";
}

.fa-duotone.fa-computer-mouse:after, .fa-duotone.fa-mouse:after, .fad.fa-computer-mouse:after, .fad.fa-mouse:after {
  content: "";
}

.fa-duotone.fa-arrow-right-to-bracket:after, .fa-duotone.fa-sign-in:after, .fad.fa-arrow-right-to-bracket:after, .fad.fa-sign-in:after {
  content: "";
}

.fa-duotone.fa-pegasus:after, .fad.fa-pegasus:after {
  content: "";
}

.fa-duotone.fa-files-medical:after, .fad.fa-files-medical:after {
  content: "";
}

.fa-duotone.fa-nfc-lock:after, .fad.fa-nfc-lock:after {
  content: "";
}

.fa-duotone.fa-person-ski-lift:after, .fa-duotone.fa-ski-lift:after, .fad.fa-person-ski-lift:after, .fad.fa-ski-lift:after {
  content: "";
}

.fa-duotone.fa-square-6:after, .fad.fa-square-6:after {
  content: "";
}

.fa-duotone.fa-shop-slash:after, .fa-duotone.fa-store-alt-slash:after, .fad.fa-shop-slash:after, .fad.fa-store-alt-slash:after {
  content: "";
}

.fa-duotone.fa-wind-turbine:after, .fad.fa-wind-turbine:after {
  content: "";
}

.fa-duotone.fa-sliders-simple:after, .fad.fa-sliders-simple:after {
  content: "";
}

.fa-duotone.fa-grid-round:after, .fad.fa-grid-round:after {
  content: "";
}

.fa-duotone.fa-badge-sheriff:after, .fad.fa-badge-sheriff:after {
  content: "";
}

.fa-duotone.fa-server:after, .fad.fa-server:after {
  content: "";
}

.fa-duotone.fa-virus-covid-slash:after, .fad.fa-virus-covid-slash:after {
  content: "";
}

.fa-duotone.fa-intersection:after, .fad.fa-intersection:after {
  content: "";
}

.fa-duotone.fa-shop-lock:after, .fad.fa-shop-lock:after {
  content: "";
}

.fa-duotone.fa-family:after, .fad.fa-family:after {
  content: "";
}

.fa-duotone.fa-hourglass-1:after, .fa-duotone.fa-hourglass-start:after, .fad.fa-hourglass-1:after, .fad.fa-hourglass-start:after {
  content: "";
}

.fa-duotone.fa-user-hair-buns:after, .fad.fa-user-hair-buns:after {
  content: "";
}

.fa-duotone.fa-blender-phone:after, .fad.fa-blender-phone:after {
  content: "";
}

.fa-duotone.fa-hourglass-clock:after, .fad.fa-hourglass-clock:after {
  content: "";
}

.fa-duotone.fa-person-seat-reclined:after, .fad.fa-person-seat-reclined:after {
  content: "";
}

.fa-duotone.fa-paper-plane-alt:after, .fa-duotone.fa-paper-plane-top:after, .fa-duotone.fa-send:after, .fad.fa-paper-plane-alt:after, .fad.fa-paper-plane-top:after, .fad.fa-send:after {
  content: "";
}

.fa-duotone.fa-comment-alt-arrow-up:after, .fa-duotone.fa-message-arrow-up:after, .fad.fa-comment-alt-arrow-up:after, .fad.fa-message-arrow-up:after {
  content: "";
}

.fa-duotone.fa-lightbulb-exclamation:after, .fad.fa-lightbulb-exclamation:after {
  content: "";
}

.fa-duotone.fa-layer-group-minus:after, .fa-duotone.fa-layer-minus:after, .fad.fa-layer-group-minus:after, .fad.fa-layer-minus:after {
  content: "";
}

.fa-duotone.fa-chart-pie-simple-circle-currency:after, .fad.fa-chart-pie-simple-circle-currency:after {
  content: "";
}

.fa-duotone.fa-circle-e:after, .fad.fa-circle-e:after {
  content: "";
}

.fa-duotone.fa-building-wheat:after, .fad.fa-building-wheat:after {
  content: "";
}

.fa-duotone.fa-gauge-max:after, .fa-duotone.fa-tachometer-alt-fastest:after, .fad.fa-gauge-max:after, .fad.fa-tachometer-alt-fastest:after {
  content: "";
}

.fa-duotone.fa-person-breastfeeding:after, .fad.fa-person-breastfeeding:after {
  content: "";
}

.fa-duotone.fa-apostrophe:after, .fad.fa-apostrophe:after {
  content: "''";
}

.fa-duotone.fa-fire-hydrant:after, .fad.fa-fire-hydrant:after {
  content: "";
}

.fa-duotone.fa-right-to-bracket:after, .fa-duotone.fa-sign-in-alt:after, .fad.fa-right-to-bracket:after, .fad.fa-sign-in-alt:after {
  content: "";
}

.fa-duotone.fa-video-plus:after, .fad.fa-video-plus:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-square-right:after, .fa-duotone.fa-square-right:after, .fad.fa-arrow-alt-square-right:after, .fad.fa-square-right:after {
  content: "";
}

.fa-duotone.fa-comment-smile:after, .fad.fa-comment-smile:after {
  content: "";
}

.fa-duotone.fa-venus:after, .fad.fa-venus:after {
  content: "";
}

.fa-duotone.fa-passport:after, .fad.fa-passport:after {
  content: "";
}

.fa-duotone.fa-inbox-arrow-down:after, .fa-duotone.fa-inbox-in:after, .fad.fa-inbox-arrow-down:after, .fad.fa-inbox-in:after {
  content: "";
}

.fa-duotone.fa-heart-pulse:after, .fa-duotone.fa-heartbeat:after, .fad.fa-heart-pulse:after, .fad.fa-heartbeat:after {
  content: "";
}

.fa-duotone.fa-circle-8:after, .fad.fa-circle-8:after {
  content: "";
}

.fa-duotone.fa-clouds-moon:after, .fad.fa-clouds-moon:after {
  content: "";
}

.fa-duotone.fa-clock-ten-thirty:after, .fad.fa-clock-ten-thirty:after {
  content: "";
}

.fa-duotone.fa-people-carry-box:after, .fa-duotone.fa-people-carry:after, .fad.fa-people-carry-box:after, .fad.fa-people-carry:after {
  content: "";
}

.fa-duotone.fa-folder-user:after, .fad.fa-folder-user:after {
  content: "";
}

.fa-duotone.fa-trash-can-xmark:after, .fad.fa-trash-can-xmark:after {
  content: "";
}

.fa-duotone.fa-temperature-high:after, .fad.fa-temperature-high:after {
  content: "";
}

.fa-duotone.fa-microchip:after, .fad.fa-microchip:after {
  content: "";
}

.fa-duotone.fa-left-long-to-line:after, .fad.fa-left-long-to-line:after {
  content: "";
}

.fa-duotone.fa-crown:after, .fad.fa-crown:after {
  content: "";
}

.fa-duotone.fa-weight-hanging:after, .fad.fa-weight-hanging:after {
  content: "";
}

.fa-duotone.fa-xmarks-lines:after, .fad.fa-xmarks-lines:after {
  content: "";
}

.fa-duotone.fa-file-prescription:after, .fad.fa-file-prescription:after {
  content: "";
}

.fa-duotone.fa-calendar-range:after, .fad.fa-calendar-range:after {
  content: "";
}

.fa-duotone.fa-flower-daffodil:after, .fad.fa-flower-daffodil:after {
  content: "";
}

.fa-duotone.fa-hand-back-point-up:after, .fad.fa-hand-back-point-up:after {
  content: "";
}

.fa-duotone.fa-weight-scale:after, .fa-duotone.fa-weight:after, .fad.fa-weight-scale:after, .fad.fa-weight:after {
  content: "";
}

.fa-duotone.fa-arrow-up-to-arc:after, .fad.fa-arrow-up-to-arc:after {
  content: "";
}

.fa-duotone.fa-star-exclamation:after, .fad.fa-star-exclamation:after {
  content: "";
}

.fa-duotone.fa-books:after, .fad.fa-books:after {
  content: "";
}

.fa-duotone.fa-user-friends:after, .fa-duotone.fa-user-group:after, .fad.fa-user-friends:after, .fad.fa-user-group:after {
  content: "";
}

.fa-duotone.fa-arrow-up-a-z:after, .fa-duotone.fa-sort-alpha-up:after, .fad.fa-arrow-up-a-z:after, .fad.fa-sort-alpha-up:after {
  content: "";
}

.fa-duotone.fa-layer-group-plus:after, .fa-duotone.fa-layer-plus:after, .fad.fa-layer-group-plus:after, .fad.fa-layer-plus:after {
  content: "";
}

.fa-duotone.fa-play-pause:after, .fad.fa-play-pause:after {
  content: "";
}

.fa-duotone.fa-block-question:after, .fad.fa-block-question:after {
  content: "";
}

.fa-duotone.fa-snooze:after, .fa-duotone.fa-zzz:after, .fad.fa-snooze:after, .fad.fa-zzz:after {
  content: "";
}

.fa-duotone.fa-scanner-image:after, .fad.fa-scanner-image:after {
  content: "";
}

.fa-duotone.fa-tv-retro:after, .fad.fa-tv-retro:after {
  content: "";
}

.fa-duotone.fa-square-t:after, .fad.fa-square-t:after {
  content: "";
}

.fa-duotone.fa-barn-silo:after, .fa-duotone.fa-farm:after, .fad.fa-barn-silo:after, .fad.fa-farm:after {
  content: "";
}

.fa-duotone.fa-chess-knight:after, .fad.fa-chess-knight:after {
  content: "";
}

.fa-duotone.fa-bars-sort:after, .fad.fa-bars-sort:after {
  content: "";
}

.fa-duotone.fa-palette-boxes:after, .fa-duotone.fa-pallet-alt:after, .fa-duotone.fa-pallet-boxes:after, .fad.fa-palette-boxes:after, .fad.fa-pallet-alt:after, .fad.fa-pallet-boxes:after {
  content: "";
}

.fa-duotone.fa-face-laugh-squint:after, .fa-duotone.fa-laugh-squint:after, .fad.fa-face-laugh-squint:after, .fad.fa-laugh-squint:after {
  content: "";
}

.fa-duotone.fa-code-simple:after, .fad.fa-code-simple:after {
  content: "";
}

.fa-duotone.fa-bolt-slash:after, .fad.fa-bolt-slash:after {
  content: "";
}

.fa-duotone.fa-panel-fire:after, .fad.fa-panel-fire:after {
  content: "";
}

.fa-duotone.fa-binary-circle-check:after, .fad.fa-binary-circle-check:after {
  content: "";
}

.fa-duotone.fa-comment-minus:after, .fad.fa-comment-minus:after {
  content: "";
}

.fa-duotone.fa-burrito:after, .fad.fa-burrito:after {
  content: "";
}

.fa-duotone.fa-violin:after, .fad.fa-violin:after {
  content: "";
}

.fa-duotone.fa-objects-column:after, .fad.fa-objects-column:after {
  content: "";
}

.fa-duotone.fa-chevron-square-down:after, .fa-duotone.fa-square-chevron-down:after, .fad.fa-chevron-square-down:after, .fad.fa-square-chevron-down:after {
  content: "";
}

.fa-duotone.fa-comment-plus:after, .fad.fa-comment-plus:after {
  content: "";
}

.fa-duotone.fa-triangle-instrument:after, .fa-duotone.fa-triangle-music:after, .fad.fa-triangle-instrument:after, .fad.fa-triangle-music:after {
  content: "";
}

.fa-duotone.fa-wheelchair:after, .fad.fa-wheelchair:after {
  content: "";
}

.fa-duotone.fa-user-pilot-tie:after, .fad.fa-user-pilot-tie:after {
  content: "";
}

.fa-duotone.fa-piano-keyboard:after, .fad.fa-piano-keyboard:after {
  content: "";
}

.fa-duotone.fa-bed-empty:after, .fad.fa-bed-empty:after {
  content: "";
}

.fa-duotone.fa-arrow-circle-up:after, .fa-duotone.fa-circle-arrow-up:after, .fad.fa-arrow-circle-up:after, .fad.fa-circle-arrow-up:after {
  content: "";
}

.fa-duotone.fa-toggle-on:after, .fad.fa-toggle-on:after {
  content: "";
}

.fa-duotone.fa-rectangle-portrait:after, .fa-duotone.fa-rectangle-vertical:after, .fad.fa-rectangle-portrait:after, .fad.fa-rectangle-vertical:after {
  content: "";
}

.fa-duotone.fa-person-walking:after, .fa-duotone.fa-walking:after, .fad.fa-person-walking:after, .fad.fa-walking:after {
  content: "";
}

.fa-duotone.fa-l:after, .fad.fa-l:after {
  content: "LL";
}

.fa-duotone.fa-signal-stream:after, .fad.fa-signal-stream:after {
  content: "";
}

.fa-duotone.fa-down-to-bracket:after, .fad.fa-down-to-bracket:after {
  content: "";
}

.fa-duotone.fa-circle-z:after, .fad.fa-circle-z:after {
  content: "";
}

.fa-duotone.fa-stars:after, .fad.fa-stars:after {
  content: "";
}

.fa-duotone.fa-fire:after, .fad.fa-fire:after {
  content: "";
}

.fa-duotone.fa-bed-pulse:after, .fa-duotone.fa-procedures:after, .fad.fa-bed-pulse:after, .fad.fa-procedures:after {
  content: "";
}

.fa-duotone.fa-house-day:after, .fad.fa-house-day:after {
  content: "";
}

.fa-duotone.fa-shuttle-space:after, .fa-duotone.fa-space-shuttle:after, .fad.fa-shuttle-space:after, .fad.fa-space-shuttle:after {
  content: "";
}

.fa-duotone.fa-shirt-long-sleeve:after, .fad.fa-shirt-long-sleeve:after {
  content: "";
}

.fa-duotone.fa-chart-pie-alt:after, .fa-duotone.fa-chart-pie-simple:after, .fad.fa-chart-pie-alt:after, .fad.fa-chart-pie-simple:after {
  content: "";
}

.fa-duotone.fa-face-laugh:after, .fa-duotone.fa-laugh:after, .fad.fa-face-laugh:after, .fad.fa-laugh:after {
  content: "";
}

.fa-duotone.fa-folder-open:after, .fad.fa-folder-open:after {
  content: "";
}

.fa-duotone.fa-album-collection-circle-user:after, .fad.fa-album-collection-circle-user:after {
  content: "";
}

.fa-duotone.fa-candy:after, .fad.fa-candy:after {
  content: "";
}

.fa-duotone.fa-bowl-hot:after, .fa-duotone.fa-soup:after, .fad.fa-bowl-hot:after, .fad.fa-soup:after {
  content: "";
}

.fa-duotone.fa-flatbread:after, .fad.fa-flatbread:after {
  content: "";
}

.fa-duotone.fa-heart-circle-plus:after, .fad.fa-heart-circle-plus:after {
  content: "";
}

.fa-duotone.fa-code-fork:after, .fad.fa-code-fork:after {
  content: "";
}

.fa-duotone.fa-city:after, .fad.fa-city:after {
  content: "";
}

.fa-duotone.fa-signal-alt-1:after, .fa-duotone.fa-signal-bars-weak:after, .fad.fa-signal-alt-1:after, .fad.fa-signal-bars-weak:after {
  content: "";
}

.fa-duotone.fa-microphone-alt:after, .fa-duotone.fa-microphone-lines:after, .fad.fa-microphone-alt:after, .fad.fa-microphone-lines:after {
  content: "";
}

.fa-duotone.fa-clock-twelve:after, .fad.fa-clock-twelve:after {
  content: "";
}

.fa-duotone.fa-pepper-hot:after, .fad.fa-pepper-hot:after {
  content: "";
}

.fa-duotone.fa-citrus-slice:after, .fad.fa-citrus-slice:after {
  content: "";
}

.fa-duotone.fa-sheep:after, .fad.fa-sheep:after {
  content: "";
}

.fa-duotone.fa-unlock:after, .fad.fa-unlock:after {
  content: "";
}

.fa-duotone.fa-colon-sign:after, .fad.fa-colon-sign:after {
  content: "";
}

.fa-duotone.fa-headset:after, .fad.fa-headset:after {
  content: "";
}

.fa-duotone.fa-badger-honey:after, .fad.fa-badger-honey:after {
  content: "";
}

.fa-duotone.fa-h4:after, .fad.fa-h4:after {
  content: "";
}

.fa-duotone.fa-store-slash:after, .fad.fa-store-slash:after {
  content: "";
}

.fa-duotone.fa-road-circle-xmark:after, .fad.fa-road-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-signal-slash:after, .fad.fa-signal-slash:after {
  content: "";
}

.fa-duotone.fa-user-minus:after, .fad.fa-user-minus:after {
  content: "";
}

.fa-duotone.fa-mars-stroke-up:after, .fa-duotone.fa-mars-stroke-v:after, .fad.fa-mars-stroke-up:after, .fad.fa-mars-stroke-v:after {
  content: "";
}

.fa-duotone.fa-champagne-glasses:after, .fa-duotone.fa-glass-cheers:after, .fad.fa-champagne-glasses:after, .fad.fa-glass-cheers:after {
  content: "";
}

.fa-duotone.fa-taco:after, .fad.fa-taco:after {
  content: "";
}

.fa-duotone.fa-hexagon-plus:after, .fa-duotone.fa-plus-hexagon:after, .fad.fa-hexagon-plus:after, .fad.fa-plus-hexagon:after {
  content: "";
}

.fa-duotone.fa-clipboard:after, .fad.fa-clipboard:after {
  content: "";
}

.fa-duotone.fa-house-circle-exclamation:after, .fad.fa-house-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-file-arrow-up:after, .fa-duotone.fa-file-upload:after, .fad.fa-file-arrow-up:after, .fad.fa-file-upload:after {
  content: "";
}

.fa-duotone.fa-wifi-3:after, .fa-duotone.fa-wifi-strong:after, .fa-duotone.fa-wifi:after, .fad.fa-wifi-3:after, .fad.fa-wifi-strong:after, .fad.fa-wifi:after {
  content: "";
}

.fa-duotone.fa-comments-alt:after, .fa-duotone.fa-messages:after, .fad.fa-comments-alt:after, .fad.fa-messages:after {
  content: "";
}

.fa-duotone.fa-bath:after, .fa-duotone.fa-bathtub:after, .fad.fa-bath:after, .fad.fa-bathtub:after {
  content: "";
}

.fa-duotone.fa-umbrella-alt:after, .fa-duotone.fa-umbrella-simple:after, .fad.fa-umbrella-alt:after, .fad.fa-umbrella-simple:after {
  content: "";
}

.fa-duotone.fa-rectangle-history-circle-plus:after, .fad.fa-rectangle-history-circle-plus:after {
  content: "";
}

.fa-duotone.fa-underline:after, .fad.fa-underline:after {
  content: "";
}

.fa-duotone.fa-prescription-bottle-pill:after, .fad.fa-prescription-bottle-pill:after {
  content: "";
}

.fa-duotone.fa-user-edit:after, .fa-duotone.fa-user-pen:after, .fad.fa-user-edit:after, .fad.fa-user-pen:after {
  content: "";
}

.fa-duotone.fa-binary-slash:after, .fad.fa-binary-slash:after {
  content: "";
}

.fa-duotone.fa-square-o:after, .fad.fa-square-o:after {
  content: "";
}

.fa-duotone.fa-signature:after, .fad.fa-signature:after {
  content: "";
}

.fa-duotone.fa-stroopwafel:after, .fad.fa-stroopwafel:after {
  content: "";
}

.fa-duotone.fa-bold:after, .fad.fa-bold:after {
  content: "";
}

.fa-duotone.fa-anchor-lock:after, .fad.fa-anchor-lock:after {
  content: "";
}

.fa-duotone.fa-building-ngo:after, .fad.fa-building-ngo:after {
  content: "";
}

.fa-duotone.fa-transporter-3:after, .fad.fa-transporter-3:after {
  content: "";
}

.fa-duotone.fa-engine-exclamation:after, .fa-duotone.fa-engine-warning:after, .fad.fa-engine-exclamation:after, .fad.fa-engine-warning:after {
  content: "";
}

.fa-duotone.fa-circle-down-right:after, .fad.fa-circle-down-right:after {
  content: "";
}

.fa-duotone.fa-square-k:after, .fad.fa-square-k:after {
  content: "";
}

.fa-duotone.fa-manat-sign:after, .fad.fa-manat-sign:after {
  content: "";
}

.fa-duotone.fa-money-check-edit:after, .fa-duotone.fa-money-check-pen:after, .fad.fa-money-check-edit:after, .fad.fa-money-check-pen:after {
  content: "";
}

.fa-duotone.fa-not-equal:after, .fad.fa-not-equal:after {
  content: "";
}

.fa-duotone.fa-border-style:after, .fa-duotone.fa-border-top-left:after, .fad.fa-border-style:after, .fad.fa-border-top-left:after {
  content: "";
}

.fa-duotone.fa-map-location-dot:after, .fa-duotone.fa-map-marked-alt:after, .fad.fa-map-location-dot:after, .fad.fa-map-marked-alt:after {
  content: "";
}

.fa-duotone.fa-tilde:after, .fad.fa-tilde:after {
  content: "~~";
}

.fa-duotone.fa-jedi:after, .fad.fa-jedi:after {
  content: "";
}

.fa-duotone.fa-poll:after, .fa-duotone.fa-square-poll-vertical:after, .fad.fa-poll:after, .fad.fa-square-poll-vertical:after {
  content: "";
}

.fa-duotone.fa-arrow-down-square-triangle:after, .fa-duotone.fa-sort-shapes-down-alt:after, .fad.fa-arrow-down-square-triangle:after, .fad.fa-sort-shapes-down-alt:after {
  content: "";
}

.fa-duotone.fa-mug-hot:after, .fad.fa-mug-hot:after {
  content: "";
}

.fa-duotone.fa-dog-leashed:after, .fad.fa-dog-leashed:after {
  content: "";
}

.fa-duotone.fa-battery-car:after, .fa-duotone.fa-car-battery:after, .fad.fa-battery-car:after, .fad.fa-car-battery:after {
  content: "";
}

.fa-duotone.fa-face-downcast-sweat:after, .fad.fa-face-downcast-sweat:after {
  content: "";
}

.fa-duotone.fa-mailbox-flag-up:after, .fad.fa-mailbox-flag-up:after {
  content: "";
}

.fa-duotone.fa-memo-circle-info:after, .fad.fa-memo-circle-info:after {
  content: "";
}

.fa-duotone.fa-gift:after, .fad.fa-gift:after {
  content: "";
}

.fa-duotone.fa-dice-two:after, .fad.fa-dice-two:after {
  content: "";
}

.fa-duotone.fa-volume-medium:after, .fa-duotone.fa-volume:after, .fad.fa-volume-medium:after, .fad.fa-volume:after {
  content: "";
}

.fa-duotone.fa-transporter-5:after, .fad.fa-transporter-5:after {
  content: "";
}

.fa-duotone.fa-gauge-circle-bolt:after, .fad.fa-gauge-circle-bolt:after {
  content: "";
}

.fa-duotone.fa-coin-front:after, .fad.fa-coin-front:after {
  content: "";
}

.fa-duotone.fa-file-slash:after, .fad.fa-file-slash:after {
  content: "";
}

.fa-duotone.fa-message-arrow-up-right:after, .fad.fa-message-arrow-up-right:after {
  content: "";
}

.fa-duotone.fa-treasure-chest:after, .fad.fa-treasure-chest:after {
  content: "";
}

.fa-duotone.fa-chess-queen:after, .fad.fa-chess-queen:after {
  content: "";
}

.fa-duotone.fa-paint-brush-alt:after, .fa-duotone.fa-paint-brush-fine:after, .fa-duotone.fa-paintbrush-alt:after, .fa-duotone.fa-paintbrush-fine:after, .fad.fa-paint-brush-alt:after, .fad.fa-paint-brush-fine:after, .fad.fa-paintbrush-alt:after, .fad.fa-paintbrush-fine:after {
  content: "";
}

.fa-duotone.fa-glasses:after, .fad.fa-glasses:after {
  content: "";
}

.fa-duotone.fa-hood-cloak:after, .fad.fa-hood-cloak:after {
  content: "";
}

.fa-duotone.fa-square-quote:after, .fad.fa-square-quote:after {
  content: "";
}

.fa-duotone.fa-up-left:after, .fad.fa-up-left:after {
  content: "";
}

.fa-duotone.fa-bring-front:after, .fad.fa-bring-front:after {
  content: "";
}

.fa-duotone.fa-chess-board:after, .fad.fa-chess-board:after {
  content: "";
}

.fa-duotone.fa-burger-cheese:after, .fa-duotone.fa-cheeseburger:after, .fad.fa-burger-cheese:after, .fad.fa-cheeseburger:after {
  content: "";
}

.fa-duotone.fa-building-circle-check:after, .fad.fa-building-circle-check:after {
  content: "";
}

.fa-duotone.fa-repeat-1:after, .fad.fa-repeat-1:after {
  content: "";
}

.fa-duotone.fa-arrow-down-to-line:after, .fa-duotone.fa-arrow-to-bottom:after, .fad.fa-arrow-down-to-line:after, .fad.fa-arrow-to-bottom:after {
  content: "";
}

.fa-duotone.fa-grid-5:after, .fad.fa-grid-5:after {
  content: "";
}

.fa-duotone.fa-swap-arrows:after, .fad.fa-swap-arrows:after {
  content: "";
}

.fa-duotone.fa-right-long-to-line:after, .fad.fa-right-long-to-line:after {
  content: "";
}

.fa-duotone.fa-person-chalkboard:after, .fad.fa-person-chalkboard:after {
  content: "";
}

.fa-duotone.fa-mars-stroke-h:after, .fa-duotone.fa-mars-stroke-right:after, .fad.fa-mars-stroke-h:after, .fad.fa-mars-stroke-right:after {
  content: "";
}

.fa-duotone.fa-hand-back-fist:after, .fa-duotone.fa-hand-rock:after, .fad.fa-hand-back-fist:after, .fad.fa-hand-rock:after {
  content: "";
}

.fa-duotone.fa-grid-round-5:after, .fad.fa-grid-round-5:after {
  content: "";
}

.fa-duotone.fa-tally-5:after, .fa-duotone.fa-tally:after, .fad.fa-tally-5:after, .fad.fa-tally:after {
  content: "";
}

.fa-duotone.fa-caret-square-up:after, .fa-duotone.fa-square-caret-up:after, .fad.fa-caret-square-up:after, .fad.fa-square-caret-up:after {
  content: "";
}

.fa-duotone.fa-cloud-showers-water:after, .fad.fa-cloud-showers-water:after {
  content: "";
}

.fa-duotone.fa-bar-chart:after, .fa-duotone.fa-chart-bar:after, .fad.fa-bar-chart:after, .fad.fa-chart-bar:after {
  content: "";
}

.fa-duotone.fa-hands-bubbles:after, .fa-duotone.fa-hands-wash:after, .fad.fa-hands-bubbles:after, .fad.fa-hands-wash:after {
  content: "";
}

.fa-duotone.fa-less-than-equal:after, .fad.fa-less-than-equal:after {
  content: "";
}

.fa-duotone.fa-train:after, .fad.fa-train:after {
  content: "";
}

.fa-duotone.fa-up-from-dotted-line:after, .fad.fa-up-from-dotted-line:after {
  content: "";
}

.fa-duotone.fa-eye-low-vision:after, .fa-duotone.fa-low-vision:after, .fad.fa-eye-low-vision:after, .fad.fa-low-vision:after {
  content: "";
}

.fa-duotone.fa-traffic-light-go:after, .fad.fa-traffic-light-go:after {
  content: "";
}

.fa-duotone.fa-face-exhaling:after, .fad.fa-face-exhaling:after {
  content: "";
}

.fa-duotone.fa-sensor-fire:after, .fad.fa-sensor-fire:after {
  content: "";
}

.fa-duotone.fa-user-unlock:after, .fad.fa-user-unlock:after {
  content: "";
}

.fa-duotone.fa-hexagon-divide:after, .fad.fa-hexagon-divide:after {
  content: "";
}

.fa-duotone.fa-00:after, .fad.fa-00:after {
  content: "";
}

.fa-duotone.fa-crow:after, .fad.fa-crow:after {
  content: "";
}

.fa-duotone.fa-betamax:after, .fa-duotone.fa-cassette-betamax:after, .fad.fa-betamax:after, .fad.fa-cassette-betamax:after {
  content: "";
}

.fa-duotone.fa-sailboat:after, .fad.fa-sailboat:after {
  content: "";
}

.fa-duotone.fa-window-restore:after, .fad.fa-window-restore:after {
  content: "";
}

.fa-duotone.fa-nfc-magnifying-glass:after, .fad.fa-nfc-magnifying-glass:after {
  content: "";
}

.fa-duotone.fa-file-binary:after, .fad.fa-file-binary:after {
  content: "";
}

.fa-duotone.fa-circle-v:after, .fad.fa-circle-v:after {
  content: "";
}

.fa-duotone.fa-plus-square:after, .fa-duotone.fa-square-plus:after, .fad.fa-plus-square:after, .fad.fa-square-plus:after {
  content: "";
}

.fa-duotone.fa-bowl-scoops:after, .fad.fa-bowl-scoops:after {
  content: "";
}

.fa-duotone.fa-mistletoe:after, .fad.fa-mistletoe:after {
  content: "";
}

.fa-duotone.fa-custard:after, .fad.fa-custard:after {
  content: "";
}

.fa-duotone.fa-lacrosse-stick:after, .fad.fa-lacrosse-stick:after {
  content: "";
}

.fa-duotone.fa-hockey-mask:after, .fad.fa-hockey-mask:after {
  content: "";
}

.fa-duotone.fa-sunrise:after, .fad.fa-sunrise:after {
  content: "";
}

.fa-duotone.fa-subtitles:after, .fad.fa-subtitles:after {
  content: "";
}

.fa-duotone.fa-panel-ews:after, .fad.fa-panel-ews:after {
  content: "";
}

.fa-duotone.fa-torii-gate:after, .fad.fa-torii-gate:after {
  content: "";
}

.fa-duotone.fa-cloud-exclamation:after, .fad.fa-cloud-exclamation:after {
  content: "";
}

.fa-duotone.fa-comment-alt-lines:after, .fa-duotone.fa-message-lines:after, .fad.fa-comment-alt-lines:after, .fad.fa-message-lines:after {
  content: "";
}

.fa-duotone.fa-frog:after, .fad.fa-frog:after {
  content: "";
}

.fa-duotone.fa-bucket:after, .fad.fa-bucket:after {
  content: "";
}

.fa-duotone.fa-floppy-disk-pen:after, .fad.fa-floppy-disk-pen:after {
  content: "";
}

.fa-duotone.fa-image:after, .fad.fa-image:after {
  content: "";
}

.fa-duotone.fa-window-frame:after, .fad.fa-window-frame:after {
  content: "";
}

.fa-duotone.fa-microphone:after, .fad.fa-microphone:after {
  content: "";
}

.fa-duotone.fa-cow:after, .fad.fa-cow:after {
  content: "";
}

.fa-duotone.fa-file-zip:after, .fad.fa-file-zip:after {
  content: "";
}

.fa-duotone.fa-square-ring:after, .fad.fa-square-ring:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-from-top:after, .fa-duotone.fa-down-from-line:after, .fad.fa-arrow-alt-from-top:after, .fad.fa-down-from-line:after {
  content: "";
}

.fa-duotone.fa-caret-up:after, .fad.fa-caret-up:after {
  content: "";
}

.fa-duotone.fa-shield-times:after, .fa-duotone.fa-shield-xmark:after, .fad.fa-shield-times:after, .fad.fa-shield-xmark:after {
  content: "";
}

.fa-duotone.fa-screwdriver:after, .fad.fa-screwdriver:after {
  content: "";
}

.fa-duotone.fa-circle-sort-down:after, .fa-duotone.fa-sort-circle-down:after, .fad.fa-circle-sort-down:after, .fad.fa-sort-circle-down:after {
  content: "";
}

.fa-duotone.fa-folder-closed:after, .fad.fa-folder-closed:after {
  content: "";
}

.fa-duotone.fa-house-tsunami:after, .fad.fa-house-tsunami:after {
  content: "";
}

.fa-duotone.fa-square-nfi:after, .fad.fa-square-nfi:after {
  content: "";
}

.fa-duotone.fa-forklift:after, .fad.fa-forklift:after {
  content: "";
}

.fa-duotone.fa-arrow-up-from-ground-water:after, .fad.fa-arrow-up-from-ground-water:after {
  content: "";
}

.fa-duotone.fa-bracket-square-right:after, .fad.fa-bracket-square-right:after {
  content: "]]";
}

.fa-duotone.fa-glass-martini-alt:after, .fa-duotone.fa-martini-glass:after, .fad.fa-glass-martini-alt:after, .fad.fa-martini-glass:after {
  content: "";
}

.fa-duotone.fa-rotate-back:after, .fa-duotone.fa-rotate-backward:after, .fa-duotone.fa-rotate-left:after, .fa-duotone.fa-undo-alt:after, .fad.fa-rotate-back:after, .fad.fa-rotate-backward:after, .fad.fa-rotate-left:after, .fad.fa-undo-alt:after {
  content: "";
}

.fa-duotone.fa-columns:after, .fa-duotone.fa-table-columns:after, .fad.fa-columns:after, .fad.fa-table-columns:after {
  content: "";
}

.fa-duotone.fa-square-a:after, .fad.fa-square-a:after {
  content: "";
}

.fa-duotone.fa-tick:after, .fad.fa-tick:after {
  content: "";
}

.fa-duotone.fa-lemon:after, .fad.fa-lemon:after {
  content: "";
}

.fa-duotone.fa-head-side-mask:after, .fad.fa-head-side-mask:after {
  content: "";
}

.fa-duotone.fa-handshake:after, .fad.fa-handshake:after {
  content: "";
}

.fa-duotone.fa-gem:after, .fad.fa-gem:after {
  content: "";
}

.fa-duotone.fa-dolly-box:after, .fa-duotone.fa-dolly:after, .fad.fa-dolly-box:after, .fad.fa-dolly:after {
  content: "";
}

.fa-duotone.fa-smoking:after, .fad.fa-smoking:after {
  content: "";
}

.fa-duotone.fa-compress-arrows-alt:after, .fa-duotone.fa-minimize:after, .fad.fa-compress-arrows-alt:after, .fad.fa-minimize:after {
  content: "";
}

.fa-duotone.fa-refrigerator:after, .fad.fa-refrigerator:after {
  content: "";
}

.fa-duotone.fa-monument:after, .fad.fa-monument:after {
  content: "";
}

.fa-duotone.fa-octagon-xmark:after, .fa-duotone.fa-times-octagon:after, .fa-duotone.fa-xmark-octagon:after, .fad.fa-octagon-xmark:after, .fad.fa-times-octagon:after, .fad.fa-xmark-octagon:after {
  content: "";
}

.fa-duotone.fa-align-slash:after, .fad.fa-align-slash:after {
  content: "";
}

.fa-duotone.fa-snowplow:after, .fad.fa-snowplow:after {
  content: "";
}

.fa-duotone.fa-angle-double-right:after, .fa-duotone.fa-angles-right:after, .fad.fa-angle-double-right:after, .fad.fa-angles-right:after {
  content: "";
}

.fa-duotone.fa-truck-couch:after, .fa-duotone.fa-truck-ramp-couch:after, .fad.fa-truck-couch:after, .fad.fa-truck-ramp-couch:after {
  content: "";
}

.fa-duotone.fa-cannabis:after, .fad.fa-cannabis:after {
  content: "";
}

.fa-duotone.fa-circle-play:after, .fa-duotone.fa-play-circle:after, .fad.fa-circle-play:after, .fad.fa-play-circle:after {
  content: "";
}

.fa-duotone.fa-arrow-up-right-and-arrow-down-left-from-center:after, .fad.fa-arrow-up-right-and-arrow-down-left-from-center:after {
  content: "";
}

.fa-duotone.fa-tablets:after, .fad.fa-tablets:after {
  content: "";
}

.fa-duotone.fa-360-degrees:after, .fad.fa-360-degrees:after {
  content: "";
}

.fa-duotone.fa-ethernet:after, .fad.fa-ethernet:after {
  content: "";
}

.fa-duotone.fa-eur:after, .fa-duotone.fa-euro-sign:after, .fa-duotone.fa-euro:after, .fad.fa-eur:after, .fad.fa-euro-sign:after, .fad.fa-euro:after {
  content: "";
}

.fa-duotone.fa-chair:after, .fad.fa-chair:after {
  content: "";
}

.fa-duotone.fa-check-circle:after, .fa-duotone.fa-circle-check:after, .fad.fa-check-circle:after, .fad.fa-circle-check:after {
  content: "";
}

.fa-duotone.fa-square-dashed-circle-plus:after, .fad.fa-square-dashed-circle-plus:after {
  content: "";
}

.fa-duotone.fa-money-simple-from-bracket:after, .fad.fa-money-simple-from-bracket:after {
  content: "";
}

.fa-duotone.fa-bat:after, .fad.fa-bat:after {
  content: "";
}

.fa-duotone.fa-circle-stop:after, .fa-duotone.fa-stop-circle:after, .fad.fa-circle-stop:after, .fad.fa-stop-circle:after {
  content: "";
}

.fa-duotone.fa-head-side-headphones:after, .fad.fa-head-side-headphones:after {
  content: "";
}

.fa-duotone.fa-phone-rotary:after, .fad.fa-phone-rotary:after {
  content: "";
}

.fa-duotone.fa-compass-drafting:after, .fa-duotone.fa-drafting-compass:after, .fad.fa-compass-drafting:after, .fad.fa-drafting-compass:after {
  content: "";
}

.fa-duotone.fa-plate-wheat:after, .fad.fa-plate-wheat:after {
  content: "";
}

.fa-duotone.fa-calendar-circle-minus:after, .fad.fa-calendar-circle-minus:after {
  content: "";
}

.fa-duotone.fa-chopsticks:after, .fad.fa-chopsticks:after {
  content: "";
}

.fa-duotone.fa-car-mechanic:after, .fa-duotone.fa-car-wrench:after, .fad.fa-car-mechanic:after, .fad.fa-car-wrench:after {
  content: "";
}

.fa-duotone.fa-icicles:after, .fad.fa-icicles:after {
  content: "";
}

.fa-duotone.fa-person-shelter:after, .fad.fa-person-shelter:after {
  content: "";
}

.fa-duotone.fa-neuter:after, .fad.fa-neuter:after {
  content: "";
}

.fa-duotone.fa-id-badge:after, .fad.fa-id-badge:after {
  content: "";
}

.fa-duotone.fa-kazoo:after, .fad.fa-kazoo:after {
  content: "";
}

.fa-duotone.fa-marker:after, .fad.fa-marker:after {
  content: "";
}

.fa-duotone.fa-bin-bottles:after, .fad.fa-bin-bottles:after {
  content: "";
}

.fa-duotone.fa-face-laugh-beam:after, .fa-duotone.fa-laugh-beam:after, .fad.fa-face-laugh-beam:after, .fad.fa-laugh-beam:after {
  content: "";
}

.fa-duotone.fa-square-arrow-down-left:after, .fad.fa-square-arrow-down-left:after {
  content: "";
}

.fa-duotone.fa-battery-bolt:after, .fad.fa-battery-bolt:after {
  content: "";
}

.fa-duotone.fa-tree-large:after, .fad.fa-tree-large:after {
  content: "";
}

.fa-duotone.fa-helicopter-symbol:after, .fad.fa-helicopter-symbol:after {
  content: "";
}

.fa-duotone.fa-aperture:after, .fad.fa-aperture:after {
  content: "";
}

.fa-duotone.fa-universal-access:after, .fad.fa-universal-access:after {
  content: "";
}

.fa-duotone.fa-gear-complex:after, .fad.fa-gear-complex:after {
  content: "";
}

.fa-duotone.fa-file-magnifying-glass:after, .fa-duotone.fa-file-search:after, .fad.fa-file-magnifying-glass:after, .fad.fa-file-search:after {
  content: "";
}

.fa-duotone.fa-up-right:after, .fad.fa-up-right:after {
  content: "";
}

.fa-duotone.fa-chevron-circle-up:after, .fa-duotone.fa-circle-chevron-up:after, .fad.fa-chevron-circle-up:after, .fad.fa-circle-chevron-up:after {
  content: "";
}

.fa-duotone.fa-user-police:after, .fad.fa-user-police:after {
  content: "";
}

.fa-duotone.fa-lari-sign:after, .fad.fa-lari-sign:after {
  content: "";
}

.fa-duotone.fa-volcano:after, .fad.fa-volcano:after {
  content: "";
}

.fa-duotone.fa-teddy-bear:after, .fad.fa-teddy-bear:after {
  content: "";
}

.fa-duotone.fa-stocking:after, .fad.fa-stocking:after {
  content: "";
}

.fa-duotone.fa-person-walking-dashed-line-arrow-right:after, .fad.fa-person-walking-dashed-line-arrow-right:after {
  content: "";
}

.fa-duotone.fa-image-slash:after, .fad.fa-image-slash:after {
  content: "";
}

.fa-duotone.fa-mask-snorkel:after, .fad.fa-mask-snorkel:after {
  content: "";
}

.fa-duotone.fa-smoke:after, .fad.fa-smoke:after {
  content: "";
}

.fa-duotone.fa-gbp:after, .fa-duotone.fa-pound-sign:after, .fa-duotone.fa-sterling-sign:after, .fad.fa-gbp:after, .fad.fa-pound-sign:after, .fad.fa-sterling-sign:after {
  content: "";
}

.fa-duotone.fa-battery-exclamation:after, .fad.fa-battery-exclamation:after {
  content: "";
}

.fa-duotone.fa-viruses:after, .fad.fa-viruses:after {
  content: "";
}

.fa-duotone.fa-square-person-confined:after, .fad.fa-square-person-confined:after {
  content: "";
}

.fa-duotone.fa-user-tie:after, .fad.fa-user-tie:after {
  content: "";
}

.fa-duotone.fa-arrow-down-long:after, .fa-duotone.fa-long-arrow-down:after, .fad.fa-arrow-down-long:after, .fad.fa-long-arrow-down:after {
  content: "";
}

.fa-duotone.fa-tent-arrow-down-to-line:after, .fad.fa-tent-arrow-down-to-line:after {
  content: "";
}

.fa-duotone.fa-certificate:after, .fad.fa-certificate:after {
  content: "";
}

.fa-duotone.fa-crystal-ball:after, .fad.fa-crystal-ball:after {
  content: "";
}

.fa-duotone.fa-mail-reply-all:after, .fa-duotone.fa-reply-all:after, .fad.fa-mail-reply-all:after, .fad.fa-reply-all:after {
  content: "";
}

.fa-duotone.fa-suitcase:after, .fad.fa-suitcase:after {
  content: "";
}

.fa-duotone.fa-person-skating:after, .fa-duotone.fa-skating:after, .fad.fa-person-skating:after, .fad.fa-skating:after {
  content: "";
}

.fa-duotone.fa-star-shooting:after, .fad.fa-star-shooting:after {
  content: "";
}

.fa-duotone.fa-binary-lock:after, .fad.fa-binary-lock:after {
  content: "";
}

.fa-duotone.fa-filter-circle-dollar:after, .fa-duotone.fa-funnel-dollar:after, .fad.fa-filter-circle-dollar:after, .fad.fa-funnel-dollar:after {
  content: "";
}

.fa-duotone.fa-camera-retro:after, .fad.fa-camera-retro:after {
  content: "";
}

.fa-duotone.fa-arrow-circle-down:after, .fa-duotone.fa-circle-arrow-down:after, .fad.fa-arrow-circle-down:after, .fad.fa-circle-arrow-down:after {
  content: "";
}

.fa-duotone.fa-comment-edit:after, .fa-duotone.fa-comment-pen:after, .fad.fa-comment-edit:after, .fad.fa-comment-pen:after {
  content: "";
}

.fa-duotone.fa-arrow-right-to-file:after, .fa-duotone.fa-file-import:after, .fad.fa-arrow-right-to-file:after, .fad.fa-file-import:after {
  content: "";
}

.fa-duotone.fa-banjo:after, .fad.fa-banjo:after {
  content: "";
}

.fa-duotone.fa-external-link-square:after, .fa-duotone.fa-square-arrow-up-right:after, .fad.fa-external-link-square:after, .fad.fa-square-arrow-up-right:after {
  content: "";
}

.fa-duotone.fa-light-emergency-on:after, .fad.fa-light-emergency-on:after {
  content: "";
}

.fa-duotone.fa-kerning:after, .fad.fa-kerning:after {
  content: "";
}

.fa-duotone.fa-box-open:after, .fad.fa-box-open:after {
  content: "";
}

.fa-duotone.fa-square-f:after, .fad.fa-square-f:after {
  content: "";
}

.fa-duotone.fa-scroll:after, .fad.fa-scroll:after {
  content: "";
}

.fa-duotone.fa-spa:after, .fad.fa-spa:after {
  content: "";
}

.fa-duotone.fa-arrow-from-right:after, .fa-duotone.fa-arrow-left-from-line:after, .fad.fa-arrow-from-right:after, .fad.fa-arrow-left-from-line:after {
  content: "";
}

.fa-duotone.fa-strawberry:after, .fad.fa-strawberry:after {
  content: "";
}

.fa-duotone.fa-location-pin-lock:after, .fad.fa-location-pin-lock:after {
  content: "";
}

.fa-duotone.fa-pause:after, .fad.fa-pause:after {
  content: "";
}

.fa-duotone.fa-clock-eight-thirty:after, .fad.fa-clock-eight-thirty:after {
  content: "";
}

.fa-duotone.fa-plane-alt:after, .fa-duotone.fa-plane-engines:after, .fad.fa-plane-alt:after, .fad.fa-plane-engines:after {
  content: "";
}

.fa-duotone.fa-hill-avalanche:after, .fad.fa-hill-avalanche:after {
  content: "";
}

.fa-duotone.fa-temperature-0:after, .fa-duotone.fa-temperature-empty:after, .fa-duotone.fa-thermometer-0:after, .fa-duotone.fa-thermometer-empty:after, .fad.fa-temperature-0:after, .fad.fa-temperature-empty:after, .fad.fa-thermometer-0:after, .fad.fa-thermometer-empty:after {
  content: "";
}

.fa-duotone.fa-bomb:after, .fad.fa-bomb:after {
  content: "";
}

.fa-duotone.fa-gauge-low:after, .fa-duotone.fa-tachometer-alt-slow:after, .fad.fa-gauge-low:after, .fad.fa-tachometer-alt-slow:after {
  content: "";
}

.fa-duotone.fa-registered:after, .fad.fa-registered:after {
  content: "";
}

.fa-duotone.fa-trash-can-plus:after, .fad.fa-trash-can-plus:after {
  content: "";
}

.fa-duotone.fa-address-card:after, .fa-duotone.fa-contact-card:after, .fa-duotone.fa-vcard:after, .fad.fa-address-card:after, .fad.fa-contact-card:after, .fad.fa-vcard:after {
  content: "";
}

.fa-duotone.fa-balance-scale-right:after, .fa-duotone.fa-scale-unbalanced-flip:after, .fad.fa-balance-scale-right:after, .fad.fa-scale-unbalanced-flip:after {
  content: "";
}

.fa-duotone.fa-globe-snow:after, .fad.fa-globe-snow:after {
  content: "";
}

.fa-duotone.fa-subscript:after, .fad.fa-subscript:after {
  content: "";
}

.fa-duotone.fa-diamond-turn-right:after, .fa-duotone.fa-directions:after, .fad.fa-diamond-turn-right:after, .fad.fa-directions:after {
  content: "";
}

.fa-duotone.fa-integral:after, .fad.fa-integral:after {
  content: "";
}

.fa-duotone.fa-burst:after, .fad.fa-burst:after {
  content: "";
}

.fa-duotone.fa-house-laptop:after, .fa-duotone.fa-laptop-house:after, .fad.fa-house-laptop:after, .fad.fa-laptop-house:after {
  content: "";
}

.fa-duotone.fa-face-tired:after, .fa-duotone.fa-tired:after, .fad.fa-face-tired:after, .fad.fa-tired:after {
  content: "";
}

.fa-duotone.fa-money-bills:after, .fad.fa-money-bills:after {
  content: "";
}

.fa-duotone.fa-blinds-raised:after, .fad.fa-blinds-raised:after {
  content: "";
}

.fa-duotone.fa-smog:after, .fad.fa-smog:after {
  content: "";
}

.fa-duotone.fa-ufo-beam:after, .fad.fa-ufo-beam:after {
  content: "";
}

.fa-duotone.fa-caret-circle-up:after, .fa-duotone.fa-circle-caret-up:after, .fad.fa-caret-circle-up:after, .fad.fa-circle-caret-up:after {
  content: "";
}

.fa-duotone.fa-user-vneck-hair-long:after, .fad.fa-user-vneck-hair-long:after {
  content: "";
}

.fa-duotone.fa-square-a-lock:after, .fad.fa-square-a-lock:after {
  content: "";
}

.fa-duotone.fa-crutch:after, .fad.fa-crutch:after {
  content: "";
}

.fa-duotone.fa-gas-pump-slash:after, .fad.fa-gas-pump-slash:after {
  content: "";
}

.fa-duotone.fa-cloud-arrow-up:after, .fa-duotone.fa-cloud-upload-alt:after, .fa-duotone.fa-cloud-upload:after, .fad.fa-cloud-arrow-up:after, .fad.fa-cloud-upload-alt:after, .fad.fa-cloud-upload:after {
  content: "";
}

.fa-duotone.fa-palette:after, .fad.fa-palette:after {
  content: "";
}

.fa-duotone.fa-transporter-4:after, .fad.fa-transporter-4:after {
  content: "";
}

.fa-duotone.fa-chart-mixed-up-circle-currency:after, .fad.fa-chart-mixed-up-circle-currency:after {
  content: "";
}

.fa-duotone.fa-objects-align-right:after, .fad.fa-objects-align-right:after {
  content: "";
}

.fa-duotone.fa-arrows-turn-right:after, .fad.fa-arrows-turn-right:after {
  content: "";
}

.fa-duotone.fa-vest:after, .fad.fa-vest:after {
  content: "";
}

.fa-duotone.fa-pig:after, .fad.fa-pig:after {
  content: "";
}

.fa-duotone.fa-inbox-full:after, .fad.fa-inbox-full:after {
  content: "";
}

.fa-duotone.fa-circle-envelope:after, .fa-duotone.fa-envelope-circle:after, .fad.fa-circle-envelope:after, .fad.fa-envelope-circle:after {
  content: "";
}

.fa-duotone.fa-construction:after, .fa-duotone.fa-triangle-person-digging:after, .fad.fa-construction:after, .fad.fa-triangle-person-digging:after {
  content: "";
}

.fa-duotone.fa-ferry:after, .fad.fa-ferry:after {
  content: "";
}

.fa-duotone.fa-bullseye-arrow:after, .fad.fa-bullseye-arrow:after {
  content: "";
}

.fa-duotone.fa-arrows-down-to-people:after, .fad.fa-arrows-down-to-people:after {
  content: "";
}

.fa-duotone.fa-seedling:after, .fa-duotone.fa-sprout:after, .fad.fa-seedling:after, .fad.fa-sprout:after {
  content: "";
}

.fa-duotone.fa-clock-seven:after, .fad.fa-clock-seven:after {
  content: "";
}

.fa-duotone.fa-arrows-alt-h:after, .fa-duotone.fa-left-right:after, .fad.fa-arrows-alt-h:after, .fad.fa-left-right:after {
  content: "";
}

.fa-duotone.fa-boxes-packing:after, .fad.fa-boxes-packing:after {
  content: "";
}

.fa-duotone.fa-arrow-circle-left:after, .fa-duotone.fa-circle-arrow-left:after, .fad.fa-arrow-circle-left:after, .fad.fa-circle-arrow-left:after {
  content: "";
}

.fa-duotone.fa-flashlight:after, .fad.fa-flashlight:after {
  content: "";
}

.fa-duotone.fa-group-arrows-rotate:after, .fad.fa-group-arrows-rotate:after {
  content: "";
}

.fa-duotone.fa-bowl-food:after, .fad.fa-bowl-food:after {
  content: "";
}

.fa-duotone.fa-square-9:after, .fad.fa-square-9:after {
  content: "";
}

.fa-duotone.fa-candy-cane:after, .fad.fa-candy-cane:after {
  content: "";
}

.fa-duotone.fa-arrow-down-wide-short:after, .fa-duotone.fa-sort-amount-asc:after, .fa-duotone.fa-sort-amount-down:after, .fad.fa-arrow-down-wide-short:after, .fad.fa-sort-amount-asc:after, .fad.fa-sort-amount-down:after {
  content: "";
}

.fa-duotone.fa-dollar-square:after, .fa-duotone.fa-square-dollar:after, .fa-duotone.fa-usd-square:after, .fad.fa-dollar-square:after, .fad.fa-square-dollar:after, .fad.fa-usd-square:after {
  content: "";
}

.fa-duotone.fa-phone-arrow-right:after, .fad.fa-phone-arrow-right:after {
  content: "";
}

.fa-duotone.fa-hand-holding-seedling:after, .fad.fa-hand-holding-seedling:after {
  content: "";
}

.fa-duotone.fa-comment-alt-check:after, .fa-duotone.fa-message-check:after, .fad.fa-comment-alt-check:after, .fad.fa-message-check:after {
  content: "";
}

.fa-duotone.fa-cloud-bolt:after, .fa-duotone.fa-thunderstorm:after, .fad.fa-cloud-bolt:after, .fad.fa-thunderstorm:after {
  content: "";
}

.fa-duotone.fa-chart-line-up-down:after, .fad.fa-chart-line-up-down:after {
  content: "";
}

.fa-duotone.fa-remove-format:after, .fa-duotone.fa-text-slash:after, .fad.fa-remove-format:after, .fad.fa-text-slash:after {
  content: "";
}

.fa-duotone.fa-watch:after, .fad.fa-watch:after {
  content: "";
}

.fa-duotone.fa-circle-down-left:after, .fad.fa-circle-down-left:after {
  content: "";
}

.fa-duotone.fa-text:after, .fad.fa-text:after {
  content: "";
}

.fa-duotone.fa-projector:after, .fad.fa-projector:after {
  content: "";
}

.fa-duotone.fa-face-smile-wink:after, .fa-duotone.fa-smile-wink:after, .fad.fa-face-smile-wink:after, .fad.fa-smile-wink:after {
  content: "";
}

.fa-duotone.fa-tombstone-alt:after, .fa-duotone.fa-tombstone-blank:after, .fad.fa-tombstone-alt:after, .fad.fa-tombstone-blank:after {
  content: "";
}

.fa-duotone.fa-chess-king-alt:after, .fa-duotone.fa-chess-king-piece:after, .fad.fa-chess-king-alt:after, .fad.fa-chess-king-piece:after {
  content: "";
}

.fa-duotone.fa-circle-6:after, .fad.fa-circle-6:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-left:after, .fa-duotone.fa-left:after, .fad.fa-arrow-alt-left:after, .fad.fa-left:after {
  content: "";
}

.fa-duotone.fa-file-word:after, .fad.fa-file-word:after {
  content: "";
}

.fa-duotone.fa-file-powerpoint:after, .fad.fa-file-powerpoint:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-square-down:after, .fa-duotone.fa-square-down:after, .fad.fa-arrow-alt-square-down:after, .fad.fa-square-down:after {
  content: "";
}

.fa-duotone.fa-objects-align-center-vertical:after, .fad.fa-objects-align-center-vertical:after {
  content: "";
}

.fa-duotone.fa-arrows-h:after, .fa-duotone.fa-arrows-left-right:after, .fad.fa-arrows-h:after, .fad.fa-arrows-left-right:after {
  content: "";
}

.fa-duotone.fa-house-lock:after, .fad.fa-house-lock:after {
  content: "";
}

.fa-duotone.fa-cloud-arrow-down:after, .fa-duotone.fa-cloud-download-alt:after, .fa-duotone.fa-cloud-download:after, .fad.fa-cloud-arrow-down:after, .fad.fa-cloud-download-alt:after, .fad.fa-cloud-download:after {
  content: "";
}

.fa-duotone.fa-wreath:after, .fad.fa-wreath:after {
  content: "";
}

.fa-duotone.fa-children:after, .fad.fa-children:after {
  content: "";
}

.fa-duotone.fa-meter-droplet:after, .fad.fa-meter-droplet:after {
  content: "";
}

.fa-duotone.fa-blackboard:after, .fa-duotone.fa-chalkboard:after, .fad.fa-blackboard:after, .fad.fa-chalkboard:after {
  content: "";
}

.fa-duotone.fa-user-alt-slash:after, .fa-duotone.fa-user-large-slash:after, .fad.fa-user-alt-slash:after, .fad.fa-user-large-slash:after {
  content: "";
}

.fa-duotone.fa-signal-4:after, .fa-duotone.fa-signal-strong:after, .fad.fa-signal-4:after, .fad.fa-signal-strong:after {
  content: "";
}

.fa-duotone.fa-lollipop:after, .fa-duotone.fa-lollypop:after, .fad.fa-lollipop:after, .fad.fa-lollypop:after {
  content: "";
}

.fa-duotone.fa-list-tree:after, .fad.fa-list-tree:after {
  content: "";
}

.fa-duotone.fa-envelope-open:after, .fad.fa-envelope-open:after {
  content: "";
}

.fa-duotone.fa-draw-circle:after, .fad.fa-draw-circle:after {
  content: "";
}

.fa-duotone.fa-cat-space:after, .fad.fa-cat-space:after {
  content: "";
}

.fa-duotone.fa-handshake-alt-slash:after, .fa-duotone.fa-handshake-simple-slash:after, .fad.fa-handshake-alt-slash:after, .fad.fa-handshake-simple-slash:after {
  content: "";
}

.fa-duotone.fa-rabbit-fast:after, .fa-duotone.fa-rabbit-running:after, .fad.fa-rabbit-fast:after, .fad.fa-rabbit-running:after {
  content: "";
}

.fa-duotone.fa-memo-pad:after, .fad.fa-memo-pad:after {
  content: "";
}

.fa-duotone.fa-mattress-pillow:after, .fad.fa-mattress-pillow:after {
  content: "";
}

.fa-duotone.fa-alarm-plus:after, .fad.fa-alarm-plus:after {
  content: "";
}

.fa-duotone.fa-alicorn:after, .fad.fa-alicorn:after {
  content: "";
}

.fa-duotone.fa-comment-question:after, .fad.fa-comment-question:after {
  content: "";
}

.fa-duotone.fa-gingerbread-man:after, .fad.fa-gingerbread-man:after {
  content: "";
}

.fa-duotone.fa-guarani-sign:after, .fad.fa-guarani-sign:after {
  content: "";
}

.fa-duotone.fa-burger-fries:after, .fad.fa-burger-fries:after {
  content: "";
}

.fa-duotone.fa-mug-tea:after, .fad.fa-mug-tea:after {
  content: "";
}

.fa-duotone.fa-border-top:after, .fad.fa-border-top:after {
  content: "";
}

.fa-duotone.fa-arrows-rotate:after, .fa-duotone.fa-refresh:after, .fa-duotone.fa-sync:after, .fad.fa-arrows-rotate:after, .fad.fa-refresh:after, .fad.fa-sync:after {
  content: "";
}

.fa-duotone.fa-book-circle:after, .fa-duotone.fa-circle-book-open:after, .fad.fa-book-circle:after, .fad.fa-circle-book-open:after {
  content: "";
}

.fa-duotone.fa-arrows-to-dotted-line:after, .fad.fa-arrows-to-dotted-line:after {
  content: "";
}

.fa-duotone.fa-fire-extinguisher:after, .fad.fa-fire-extinguisher:after {
  content: "";
}

.fa-duotone.fa-garage-open:after, .fad.fa-garage-open:after {
  content: "";
}

.fa-duotone.fa-shelves-empty:after, .fad.fa-shelves-empty:after {
  content: "";
}

.fa-duotone.fa-cruzeiro-sign:after, .fad.fa-cruzeiro-sign:after {
  content: "";
}

.fa-duotone.fa-watch-apple:after, .fad.fa-watch-apple:after {
  content: "";
}

.fa-duotone.fa-watch-calculator:after, .fad.fa-watch-calculator:after {
  content: "";
}

.fa-duotone.fa-list-dropdown:after, .fad.fa-list-dropdown:after {
  content: "";
}

.fa-duotone.fa-cabinet-filing:after, .fad.fa-cabinet-filing:after {
  content: "";
}

.fa-duotone.fa-burger-soda:after, .fad.fa-burger-soda:after {
  content: "";
}

.fa-duotone.fa-arrow-square-up:after, .fa-duotone.fa-square-arrow-up:after, .fad.fa-arrow-square-up:after, .fad.fa-square-arrow-up:after {
  content: "";
}

.fa-duotone.fa-greater-than-equal:after, .fad.fa-greater-than-equal:after {
  content: "";
}

.fa-duotone.fa-pallet-box:after, .fad.fa-pallet-box:after {
  content: "";
}

.fa-duotone.fa-face-confounded:after, .fad.fa-face-confounded:after {
  content: "";
}

.fa-duotone.fa-shield-alt:after, .fa-duotone.fa-shield-halved:after, .fad.fa-shield-alt:after, .fad.fa-shield-halved:after {
  content: "";
}

.fa-duotone.fa-truck-plow:after, .fad.fa-truck-plow:after {
  content: "";
}

.fa-duotone.fa-atlas:after, .fa-duotone.fa-book-atlas:after, .fad.fa-atlas:after, .fad.fa-book-atlas:after {
  content: "";
}

.fa-duotone.fa-virus:after, .fad.fa-virus:after {
  content: "";
}

.fa-duotone.fa-grid-round-2:after, .fad.fa-grid-round-2:after {
  content: "";
}

.fa-duotone.fa-comment-middle-top:after, .fad.fa-comment-middle-top:after {
  content: "";
}

.fa-duotone.fa-envelope-circle-check:after, .fad.fa-envelope-circle-check:after {
  content: "";
}

.fa-duotone.fa-layer-group:after, .fad.fa-layer-group:after {
  content: "";
}

.fa-duotone.fa-restroom-simple:after, .fad.fa-restroom-simple:after {
  content: "";
}

.fa-duotone.fa-arrows-to-dot:after, .fad.fa-arrows-to-dot:after {
  content: "";
}

.fa-duotone.fa-border-outer:after, .fad.fa-border-outer:after {
  content: "";
}

.fa-duotone.fa-hashtag-lock:after, .fad.fa-hashtag-lock:after {
  content: "";
}

.fa-duotone.fa-clock-two-thirty:after, .fad.fa-clock-two-thirty:after {
  content: "";
}

.fa-duotone.fa-archway:after, .fad.fa-archway:after {
  content: "";
}

.fa-duotone.fa-heart-circle-check:after, .fad.fa-heart-circle-check:after {
  content: "";
}

.fa-duotone.fa-house-chimney-crack:after, .fa-duotone.fa-house-damage:after, .fad.fa-house-chimney-crack:after, .fad.fa-house-damage:after {
  content: "";
}

.fa-duotone.fa-file-archive:after, .fa-duotone.fa-file-zipper:after, .fad.fa-file-archive:after, .fad.fa-file-zipper:after {
  content: "";
}

.fa-duotone.fa-heart-half:after, .fad.fa-heart-half:after {
  content: "";
}

.fa-duotone.fa-comment-check:after, .fad.fa-comment-check:after {
  content: "";
}

.fa-duotone.fa-square:after, .fad.fa-square:after {
  content: "";
}

.fa-duotone.fa-memo:after, .fad.fa-memo:after {
  content: "";
}

.fa-duotone.fa-glass-martini:after, .fa-duotone.fa-martini-glass-empty:after, .fad.fa-glass-martini:after, .fad.fa-martini-glass-empty:after {
  content: "";
}

.fa-duotone.fa-couch:after, .fad.fa-couch:after {
  content: "";
}

.fa-duotone.fa-cedi-sign:after, .fad.fa-cedi-sign:after {
  content: "";
}

.fa-duotone.fa-italic:after, .fad.fa-italic:after {
  content: "";
}

.fa-duotone.fa-glass-citrus:after, .fad.fa-glass-citrus:after {
  content: "";
}

.fa-duotone.fa-calendar-lines-pen:after, .fad.fa-calendar-lines-pen:after {
  content: "";
}

.fa-duotone.fa-church:after, .fad.fa-church:after {
  content: "";
}

.fa-duotone.fa-person-snowmobiling:after, .fa-duotone.fa-snowmobile:after, .fad.fa-person-snowmobiling:after, .fad.fa-snowmobile:after {
  content: "";
}

.fa-duotone.fa-face-hushed:after, .fad.fa-face-hushed:after {
  content: "";
}

.fa-duotone.fa-comments-dollar:after, .fad.fa-comments-dollar:after {
  content: "";
}

.fa-duotone.fa-pickaxe:after, .fad.fa-pickaxe:after {
  content: "";
}

.fa-duotone.fa-link-simple-slash:after, .fad.fa-link-simple-slash:after {
  content: "";
}

.fa-duotone.fa-democrat:after, .fad.fa-democrat:after {
  content: "";
}

.fa-duotone.fa-face-confused:after, .fad.fa-face-confused:after {
  content: "";
}

.fa-duotone.fa-pinball:after, .fad.fa-pinball:after {
  content: "";
}

.fa-duotone.fa-z:after, .fad.fa-z:after {
  content: "ZZ";
}

.fa-duotone.fa-person-skiing:after, .fa-duotone.fa-skiing:after, .fad.fa-person-skiing:after, .fad.fa-skiing:after {
  content: "";
}

.fa-duotone.fa-deer:after, .fad.fa-deer:after {
  content: "";
}

.fa-duotone.fa-input-pipe:after, .fad.fa-input-pipe:after {
  content: "";
}

.fa-duotone.fa-road-lock:after, .fad.fa-road-lock:after {
  content: "";
}

.fa-duotone.fa-a:after, .fad.fa-a:after {
  content: "AA";
}

.fa-duotone.fa-bookmark-slash:after, .fad.fa-bookmark-slash:after {
  content: "";
}

.fa-duotone.fa-temperature-arrow-down:after, .fa-duotone.fa-temperature-down:after, .fad.fa-temperature-arrow-down:after, .fad.fa-temperature-down:after {
  content: "";
}

.fa-duotone.fa-mace:after, .fad.fa-mace:after {
  content: "";
}

.fa-duotone.fa-feather-alt:after, .fa-duotone.fa-feather-pointed:after, .fad.fa-feather-alt:after, .fad.fa-feather-pointed:after {
  content: "";
}

.fa-duotone.fa-sausage:after, .fad.fa-sausage:after {
  content: "";
}

.fa-duotone.fa-trash-can-clock:after, .fad.fa-trash-can-clock:after {
  content: "";
}

.fa-duotone.fa-p:after, .fad.fa-p:after {
  content: "PP";
}

.fa-duotone.fa-broom-wide:after, .fad.fa-broom-wide:after {
  content: "";
}

.fa-duotone.fa-snowflake:after, .fad.fa-snowflake:after {
  content: "";
}

.fa-duotone.fa-stomach:after, .fad.fa-stomach:after {
  content: "";
}

.fa-duotone.fa-newspaper:after, .fad.fa-newspaper:after {
  content: "";
}

.fa-duotone.fa-ad:after, .fa-duotone.fa-rectangle-ad:after, .fad.fa-ad:after, .fad.fa-rectangle-ad:after {
  content: "";
}

.fa-duotone.fa-guitar-electric:after, .fad.fa-guitar-electric:after {
  content: "";
}

.fa-duotone.fa-arrow-turn-down-right:after, .fad.fa-arrow-turn-down-right:after {
  content: "";
}

.fa-duotone.fa-moon-cloud:after, .fad.fa-moon-cloud:after {
  content: "";
}

.fa-duotone.fa-bread-slice-butter:after, .fad.fa-bread-slice-butter:after {
  content: "";
}

.fa-duotone.fa-arrow-circle-right:after, .fa-duotone.fa-circle-arrow-right:after, .fad.fa-arrow-circle-right:after, .fad.fa-circle-arrow-right:after {
  content: "";
}

.fa-duotone.fa-user-group-crown:after, .fa-duotone.fa-users-crown:after, .fad.fa-user-group-crown:after, .fad.fa-users-crown:after {
  content: "";
}

.fa-duotone.fa-circle-i:after, .fad.fa-circle-i:after {
  content: "";
}

.fa-duotone.fa-toilet-paper-check:after, .fad.fa-toilet-paper-check:after {
  content: "";
}

.fa-duotone.fa-filter-circle-xmark:after, .fad.fa-filter-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-locust:after, .fad.fa-locust:after {
  content: "";
}

.fa-duotone.fa-sort:after, .fa-duotone.fa-unsorted:after, .fad.fa-sort:after, .fad.fa-unsorted:after {
  content: "";
}

.fa-duotone.fa-list-1-2:after, .fa-duotone.fa-list-numeric:after, .fa-duotone.fa-list-ol:after, .fad.fa-list-1-2:after, .fad.fa-list-numeric:after, .fad.fa-list-ol:after {
  content: "";
}

.fa-duotone.fa-chart-waterfall:after, .fad.fa-chart-waterfall:after {
  content: "";
}

.fa-duotone.fa-sparkle:after, .fad.fa-sparkle:after {
  content: "";
}

.fa-duotone.fa-face-party:after, .fad.fa-face-party:after {
  content: "";
}

.fa-duotone.fa-kidneys:after, .fad.fa-kidneys:after {
  content: "";
}

.fa-duotone.fa-wifi-exclamation:after, .fad.fa-wifi-exclamation:after {
  content: "";
}

.fa-duotone.fa-chart-network:after, .fad.fa-chart-network:after {
  content: "";
}

.fa-duotone.fa-person-dress-burst:after, .fad.fa-person-dress-burst:after {
  content: "";
}

.fa-duotone.fa-dice-d4:after, .fad.fa-dice-d4:after {
  content: "";
}

.fa-duotone.fa-money-check-alt:after, .fa-duotone.fa-money-check-dollar:after, .fad.fa-money-check-alt:after, .fad.fa-money-check-dollar:after {
  content: "";
}

.fa-duotone.fa-vector-square:after, .fad.fa-vector-square:after {
  content: "";
}

.fa-duotone.fa-bread-slice:after, .fad.fa-bread-slice:after {
  content: "";
}

.fa-duotone.fa-language:after, .fad.fa-language:after {
  content: "";
}

.fa-duotone.fa-wheat-awn-slash:after, .fad.fa-wheat-awn-slash:after {
  content: "";
}

.fa-duotone.fa-face-kiss-wink-heart:after, .fa-duotone.fa-kiss-wink-heart:after, .fad.fa-face-kiss-wink-heart:after, .fad.fa-kiss-wink-heart:after {
  content: "";
}

.fa-duotone.fa-dagger:after, .fad.fa-dagger:after {
  content: "";
}

.fa-duotone.fa-podium:after, .fad.fa-podium:after {
  content: "";
}

.fa-duotone.fa-memo-circle-check:after, .fad.fa-memo-circle-check:after {
  content: "";
}

.fa-duotone.fa-route-highway:after, .fad.fa-route-highway:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-to-bottom:after, .fa-duotone.fa-down-to-line:after, .fad.fa-arrow-alt-to-bottom:after, .fad.fa-down-to-line:after {
  content: "";
}

.fa-duotone.fa-filter:after, .fad.fa-filter:after {
  content: "";
}

.fa-duotone.fa-square-g:after, .fad.fa-square-g:after {
  content: "";
}

.fa-duotone.fa-circle-phone:after, .fa-duotone.fa-phone-circle:after, .fad.fa-circle-phone:after, .fad.fa-phone-circle:after {
  content: "";
}

.fa-duotone.fa-clipboard-prescription:after, .fad.fa-clipboard-prescription:after {
  content: "";
}

.fa-duotone.fa-user-nurse-hair:after, .fad.fa-user-nurse-hair:after {
  content: "";
}

.fa-duotone.fa-question:after, .fad.fa-question:after {
  content: "??";
}

.fa-duotone.fa-file-signature:after, .fad.fa-file-signature:after {
  content: "";
}

.fa-duotone.fa-toggle-large-on:after, .fad.fa-toggle-large-on:after {
  content: "";
}

.fa-duotone.fa-arrows-alt:after, .fa-duotone.fa-up-down-left-right:after, .fad.fa-arrows-alt:after, .fad.fa-up-down-left-right:after {
  content: "";
}

.fa-duotone.fa-dryer-alt:after, .fa-duotone.fa-dryer-heat:after, .fad.fa-dryer-alt:after, .fad.fa-dryer-heat:after {
  content: "";
}

.fa-duotone.fa-house-chimney-user:after, .fad.fa-house-chimney-user:after {
  content: "";
}

.fa-duotone.fa-hand-holding-heart:after, .fad.fa-hand-holding-heart:after {
  content: "";
}

.fa-duotone.fa-arrow-up-small-big:after, .fa-duotone.fa-sort-size-up-alt:after, .fad.fa-arrow-up-small-big:after, .fad.fa-sort-size-up-alt:after {
  content: "";
}

.fa-duotone.fa-train-track:after, .fad.fa-train-track:after {
  content: "";
}

.fa-duotone.fa-puzzle-piece:after, .fad.fa-puzzle-piece:after {
  content: "";
}

.fa-duotone.fa-money-check:after, .fad.fa-money-check:after {
  content: "";
}

.fa-duotone.fa-star-half-alt:after, .fa-duotone.fa-star-half-stroke:after, .fad.fa-star-half-alt:after, .fad.fa-star-half-stroke:after {
  content: "";
}

.fa-duotone.fa-file-exclamation:after, .fad.fa-file-exclamation:after {
  content: "";
}

.fa-duotone.fa-code:after, .fad.fa-code:after {
  content: "";
}

.fa-duotone.fa-glass-whiskey:after, .fa-duotone.fa-whiskey-glass:after, .fad.fa-glass-whiskey:after, .fad.fa-whiskey-glass:after {
  content: "";
}

.fa-duotone.fa-moon-stars:after, .fad.fa-moon-stars:after {
  content: "";
}

.fa-duotone.fa-building-circle-exclamation:after, .fad.fa-building-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-clothes-hanger:after, .fad.fa-clothes-hanger:after {
  content: "";
}

.fa-duotone.fa-mobile-iphone:after, .fa-duotone.fa-mobile-notch:after, .fad.fa-mobile-iphone:after, .fad.fa-mobile-notch:after {
  content: "";
}

.fa-duotone.fa-magnifying-glass-chart:after, .fad.fa-magnifying-glass-chart:after {
  content: "";
}

.fa-duotone.fa-arrow-up-right-from-square:after, .fa-duotone.fa-external-link:after, .fad.fa-arrow-up-right-from-square:after, .fad.fa-external-link:after {
  content: "";
}

.fa-duotone.fa-cubes-stacked:after, .fad.fa-cubes-stacked:after {
  content: "";
}

.fa-duotone.fa-images-user:after, .fad.fa-images-user:after {
  content: "";
}

.fa-duotone.fa-krw:after, .fa-duotone.fa-won-sign:after, .fa-duotone.fa-won:after, .fad.fa-krw:after, .fad.fa-won-sign:after, .fad.fa-won:after {
  content: "";
}

.fa-duotone.fa-image-polaroid-user:after, .fad.fa-image-polaroid-user:after {
  content: "";
}

.fa-duotone.fa-virus-covid:after, .fad.fa-virus-covid:after {
  content: "";
}

.fa-duotone.fa-square-ellipsis:after, .fad.fa-square-ellipsis:after {
  content: "";
}

.fa-duotone.fa-pie:after, .fad.fa-pie:after {
  content: "";
}

.fa-duotone.fa-chess-knight-alt:after, .fa-duotone.fa-chess-knight-piece:after, .fad.fa-chess-knight-alt:after, .fad.fa-chess-knight-piece:after {
  content: "";
}

.fa-duotone.fa-austral-sign:after, .fad.fa-austral-sign:after {
  content: "";
}

.fa-duotone.fa-cloud-plus:after, .fad.fa-cloud-plus:after {
  content: "";
}

.fa-duotone.fa-f:after, .fad.fa-f:after {
  content: "FF";
}

.fa-duotone.fa-leaf:after, .fad.fa-leaf:after {
  content: "";
}

.fa-duotone.fa-bed-bunk:after, .fad.fa-bed-bunk:after {
  content: "";
}

.fa-duotone.fa-road:after, .fad.fa-road:after {
  content: "";
}

.fa-duotone.fa-cab:after, .fa-duotone.fa-taxi:after, .fad.fa-cab:after, .fad.fa-taxi:after {
  content: "";
}

.fa-duotone.fa-person-circle-plus:after, .fad.fa-person-circle-plus:after {
  content: "";
}

.fa-duotone.fa-chart-pie:after, .fa-duotone.fa-pie-chart:after, .fad.fa-chart-pie:after, .fad.fa-pie-chart:after {
  content: "";
}

.fa-duotone.fa-bolt-lightning:after, .fad.fa-bolt-lightning:after {
  content: "";
}

.fa-duotone.fa-clock-eight:after, .fad.fa-clock-eight:after {
  content: "";
}

.fa-duotone.fa-sack-xmark:after, .fad.fa-sack-xmark:after {
  content: "";
}

.fa-duotone.fa-file-excel:after, .fad.fa-file-excel:after {
  content: "";
}

.fa-duotone.fa-file-contract:after, .fad.fa-file-contract:after {
  content: "";
}

.fa-duotone.fa-fish-fins:after, .fad.fa-fish-fins:after {
  content: "";
}

.fa-duotone.fa-circle-q:after, .fad.fa-circle-q:after {
  content: "";
}

.fa-duotone.fa-building-flag:after, .fad.fa-building-flag:after {
  content: "";
}

.fa-duotone.fa-face-grin-beam:after, .fa-duotone.fa-grin-beam:after, .fad.fa-face-grin-beam:after, .fad.fa-grin-beam:after {
  content: "";
}

.fa-duotone.fa-object-ungroup:after, .fad.fa-object-ungroup:after {
  content: "";
}

.fa-duotone.fa-face-disguise:after, .fad.fa-face-disguise:after {
  content: "";
}

.fa-duotone.fa-circle-arrow-down-right:after, .fad.fa-circle-arrow-down-right:after {
  content: "";
}

.fa-duotone.fa-alien-8bit:after, .fa-duotone.fa-alien-monster:after, .fad.fa-alien-8bit:after, .fad.fa-alien-monster:after {
  content: "";
}

.fa-duotone.fa-hand-point-ribbon:after, .fad.fa-hand-point-ribbon:after {
  content: "";
}

.fa-duotone.fa-poop:after, .fad.fa-poop:after {
  content: "";
}

.fa-duotone.fa-object-exclude:after, .fad.fa-object-exclude:after {
  content: "";
}

.fa-duotone.fa-telescope:after, .fad.fa-telescope:after {
  content: "";
}

.fa-duotone.fa-location-pin:after, .fa-duotone.fa-map-marker:after, .fad.fa-location-pin:after, .fad.fa-map-marker:after {
  content: "";
}

.fa-duotone.fa-square-list:after, .fad.fa-square-list:after {
  content: "";
}

.fa-duotone.fa-kaaba:after, .fad.fa-kaaba:after {
  content: "";
}

.fa-duotone.fa-toilet-paper:after, .fad.fa-toilet-paper:after {
  content: "";
}

.fa-duotone.fa-hard-hat:after, .fa-duotone.fa-hat-hard:after, .fa-duotone.fa-helmet-safety:after, .fad.fa-hard-hat:after, .fad.fa-hat-hard:after, .fad.fa-helmet-safety:after {
  content: "";
}

.fa-duotone.fa-comment-code:after, .fad.fa-comment-code:after {
  content: "";
}

.fa-duotone.fa-sim-cards:after, .fad.fa-sim-cards:after {
  content: "";
}

.fa-duotone.fa-starship:after, .fad.fa-starship:after {
  content: "";
}

.fa-duotone.fa-eject:after, .fad.fa-eject:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-circle-right:after, .fa-duotone.fa-circle-right:after, .fad.fa-arrow-alt-circle-right:after, .fad.fa-circle-right:after {
  content: "";
}

.fa-duotone.fa-plane-circle-check:after, .fad.fa-plane-circle-check:after {
  content: "";
}

.fa-duotone.fa-seal:after, .fad.fa-seal:after {
  content: "";
}

.fa-duotone.fa-user-cowboy:after, .fad.fa-user-cowboy:after {
  content: "";
}

.fa-duotone.fa-hexagon-vertical-nft:after, .fad.fa-hexagon-vertical-nft:after {
  content: "";
}

.fa-duotone.fa-face-rolling-eyes:after, .fa-duotone.fa-meh-rolling-eyes:after, .fad.fa-face-rolling-eyes:after, .fad.fa-meh-rolling-eyes:after {
  content: "";
}

.fa-duotone.fa-bread-loaf:after, .fad.fa-bread-loaf:after {
  content: "";
}

.fa-duotone.fa-rings-wedding:after, .fad.fa-rings-wedding:after {
  content: "";
}

.fa-duotone.fa-object-group:after, .fad.fa-object-group:after {
  content: "";
}

.fa-duotone.fa-french-fries:after, .fad.fa-french-fries:after {
  content: "";
}

.fa-duotone.fa-chart-line:after, .fa-duotone.fa-line-chart:after, .fad.fa-chart-line:after, .fad.fa-line-chart:after {
  content: "";
}

.fa-duotone.fa-calendar-arrow-down:after, .fa-duotone.fa-calendar-download:after, .fad.fa-calendar-arrow-down:after, .fad.fa-calendar-download:after {
  content: "";
}

.fa-duotone.fa-send-back:after, .fad.fa-send-back:after {
  content: "";
}

.fa-duotone.fa-mask-ventilator:after, .fad.fa-mask-ventilator:after {
  content: "";
}

.fa-duotone.fa-signature-lock:after, .fad.fa-signature-lock:after {
  content: "";
}

.fa-duotone.fa-arrow-right:after, .fad.fa-arrow-right:after {
  content: "";
}

.fa-duotone.fa-map-signs:after, .fa-duotone.fa-signs-post:after, .fad.fa-map-signs:after, .fad.fa-signs-post:after {
  content: "";
}

.fa-duotone.fa-octagon-plus:after, .fa-duotone.fa-plus-octagon:after, .fad.fa-octagon-plus:after, .fad.fa-plus-octagon:after {
  content: "";
}

.fa-duotone.fa-cash-register:after, .fad.fa-cash-register:after {
  content: "";
}

.fa-duotone.fa-person-circle-question:after, .fad.fa-person-circle-question:after {
  content: "";
}

.fa-duotone.fa-melon-slice:after, .fad.fa-melon-slice:after {
  content: "";
}

.fa-duotone.fa-space-station-moon:after, .fad.fa-space-station-moon:after {
  content: "";
}

.fa-duotone.fa-comment-alt-smile:after, .fa-duotone.fa-message-smile:after, .fad.fa-comment-alt-smile:after, .fad.fa-message-smile:after {
  content: "";
}

.fa-duotone.fa-cup-straw:after, .fad.fa-cup-straw:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-from-right:after, .fa-duotone.fa-left-from-line:after, .fad.fa-arrow-alt-from-right:after, .fad.fa-left-from-line:after {
  content: "";
}

.fa-duotone.fa-h:after, .fad.fa-h:after {
  content: "HH";
}

.fa-duotone.fa-basket-shopping-simple:after, .fa-duotone.fa-shopping-basket-alt:after, .fad.fa-basket-shopping-simple:after, .fad.fa-shopping-basket-alt:after {
  content: "";
}

.fa-duotone.fa-hands-heart:after, .fa-duotone.fa-hands-holding-heart:after, .fad.fa-hands-heart:after, .fad.fa-hands-holding-heart:after {
  content: "";
}

.fa-duotone.fa-clock-nine:after, .fad.fa-clock-nine:after {
  content: "";
}

.fa-duotone.fa-tarp:after, .fad.fa-tarp:after {
  content: "";
}

.fa-duotone.fa-face-sleepy:after, .fad.fa-face-sleepy:after {
  content: "";
}

.fa-duotone.fa-hand-horns:after, .fad.fa-hand-horns:after {
  content: "";
}

.fa-duotone.fa-screwdriver-wrench:after, .fa-duotone.fa-tools:after, .fad.fa-screwdriver-wrench:after, .fad.fa-tools:after {
  content: "";
}

.fa-duotone.fa-arrows-to-eye:after, .fad.fa-arrows-to-eye:after {
  content: "";
}

.fa-duotone.fa-circle-three-quarters:after, .fad.fa-circle-three-quarters:after {
  content: "";
}

.fa-duotone.fa-trophy-alt:after, .fa-duotone.fa-trophy-star:after, .fad.fa-trophy-alt:after, .fad.fa-trophy-star:after {
  content: "";
}

.fa-duotone.fa-plug-circle-bolt:after, .fad.fa-plug-circle-bolt:after {
  content: "";
}

.fa-duotone.fa-face-thermometer:after, .fad.fa-face-thermometer:after {
  content: "";
}

.fa-duotone.fa-grid-round-4:after, .fad.fa-grid-round-4:after {
  content: "";
}

.fa-duotone.fa-shirt-running:after, .fad.fa-shirt-running:after {
  content: "";
}

.fa-duotone.fa-book-circle-arrow-up:after, .fad.fa-book-circle-arrow-up:after {
  content: "";
}

.fa-duotone.fa-face-nauseated:after, .fad.fa-face-nauseated:after {
  content: "";
}

.fa-duotone.fa-heart:after, .fad.fa-heart:after {
  content: "";
}

.fa-duotone.fa-file-chart-pie:after, .fad.fa-file-chart-pie:after {
  content: "";
}

.fa-duotone.fa-mars-and-venus:after, .fad.fa-mars-and-venus:after {
  content: "";
}

.fa-duotone.fa-home-user:after, .fa-duotone.fa-house-user:after, .fad.fa-home-user:after, .fad.fa-house-user:after {
  content: "";
}

.fa-duotone.fa-circle-arrow-down-left:after, .fad.fa-circle-arrow-down-left:after {
  content: "";
}

.fa-duotone.fa-dumpster-fire:after, .fad.fa-dumpster-fire:after {
  content: "";
}

.fa-duotone.fa-hexagon-minus:after, .fa-duotone.fa-minus-hexagon:after, .fad.fa-hexagon-minus:after, .fad.fa-minus-hexagon:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-to-left:after, .fa-duotone.fa-left-to-line:after, .fad.fa-arrow-alt-to-left:after, .fad.fa-left-to-line:after {
  content: "";
}

.fa-duotone.fa-house-crack:after, .fad.fa-house-crack:after {
  content: "";
}

.fa-duotone.fa-paw-alt:after, .fa-duotone.fa-paw-simple:after, .fad.fa-paw-alt:after, .fad.fa-paw-simple:after {
  content: "";
}

.fa-duotone.fa-arrow-left-long-to-line:after, .fad.fa-arrow-left-long-to-line:after {
  content: "";
}

.fa-duotone.fa-brackets-round:after, .fa-duotone.fa-parentheses:after, .fad.fa-brackets-round:after, .fad.fa-parentheses:after {
  content: "";
}

.fa-duotone.fa-cocktail:after, .fa-duotone.fa-martini-glass-citrus:after, .fad.fa-cocktail:after, .fad.fa-martini-glass-citrus:after {
  content: "";
}

.fa-duotone.fa-user-shakespeare:after, .fad.fa-user-shakespeare:after {
  content: "";
}

.fa-duotone.fa-arrow-right-to-arc:after, .fad.fa-arrow-right-to-arc:after {
  content: "";
}

.fa-duotone.fa-face-surprise:after, .fa-duotone.fa-surprise:after, .fad.fa-face-surprise:after, .fad.fa-surprise:after {
  content: "";
}

.fa-duotone.fa-bottle-water:after, .fad.fa-bottle-water:after {
  content: "";
}

.fa-duotone.fa-circle-pause:after, .fa-duotone.fa-pause-circle:after, .fad.fa-circle-pause:after, .fad.fa-pause-circle:after {
  content: "";
}

.fa-duotone.fa-gauge-circle-plus:after, .fad.fa-gauge-circle-plus:after {
  content: "";
}

.fa-duotone.fa-folders:after, .fad.fa-folders:after {
  content: "";
}

.fa-duotone.fa-angel:after, .fad.fa-angel:after {
  content: "";
}

.fa-duotone.fa-value-absolute:after, .fad.fa-value-absolute:after {
  content: "";
}

.fa-duotone.fa-rabbit:after, .fad.fa-rabbit:after {
  content: "";
}

.fa-duotone.fa-toilet-paper-slash:after, .fad.fa-toilet-paper-slash:after {
  content: "";
}

.fa-duotone.fa-circle-euro:after, .fad.fa-circle-euro:after {
  content: "";
}

.fa-duotone.fa-apple-alt:after, .fa-duotone.fa-apple-whole:after, .fad.fa-apple-alt:after, .fad.fa-apple-whole:after {
  content: "";
}

.fa-duotone.fa-kitchen-set:after, .fad.fa-kitchen-set:after {
  content: "";
}

.fa-duotone.fa-diamond-half:after, .fad.fa-diamond-half:after {
  content: "";
}

.fa-duotone.fa-lock-alt:after, .fa-duotone.fa-lock-keyhole:after, .fad.fa-lock-alt:after, .fad.fa-lock-keyhole:after {
  content: "";
}

.fa-duotone.fa-r:after, .fad.fa-r:after {
  content: "RR";
}

.fa-duotone.fa-temperature-1:after, .fa-duotone.fa-temperature-quarter:after, .fa-duotone.fa-thermometer-1:after, .fa-duotone.fa-thermometer-quarter:after, .fad.fa-temperature-1:after, .fad.fa-temperature-quarter:after, .fad.fa-thermometer-1:after, .fad.fa-thermometer-quarter:after {
  content: "";
}

.fa-duotone.fa-info-square:after, .fa-duotone.fa-square-info:after, .fad.fa-info-square:after, .fad.fa-square-info:after {
  content: "";
}

.fa-duotone.fa-wifi-slash:after, .fad.fa-wifi-slash:after {
  content: "";
}

.fa-duotone.fa-toilet-paper-xmark:after, .fad.fa-toilet-paper-xmark:after {
  content: "";
}

.fa-duotone.fa-hands-holding-dollar:after, .fa-duotone.fa-hands-usd:after, .fad.fa-hands-holding-dollar:after, .fad.fa-hands-usd:after {
  content: "";
}

.fa-duotone.fa-cube:after, .fad.fa-cube:after {
  content: "";
}

.fa-duotone.fa-arrow-down-triangle-square:after, .fa-duotone.fa-sort-shapes-down:after, .fad.fa-arrow-down-triangle-square:after, .fad.fa-sort-shapes-down:after {
  content: "";
}

.fa-duotone.fa-bitcoin-sign:after, .fad.fa-bitcoin-sign:after {
  content: "";
}

.fa-duotone.fa-shutters:after, .fad.fa-shutters:after {
  content: "";
}

.fa-duotone.fa-shield-dog:after, .fad.fa-shield-dog:after {
  content: "";
}

.fa-duotone.fa-solar-panel:after, .fad.fa-solar-panel:after {
  content: "";
}

.fa-duotone.fa-lock-open:after, .fad.fa-lock-open:after {
  content: "";
}

.fa-duotone.fa-table-tree:after, .fad.fa-table-tree:after {
  content: "";
}

.fa-duotone.fa-house-chimney-heart:after, .fad.fa-house-chimney-heart:after {
  content: "";
}

.fa-duotone.fa-tally-3:after, .fad.fa-tally-3:after {
  content: "";
}

.fa-duotone.fa-elevator:after, .fad.fa-elevator:after {
  content: "";
}

.fa-duotone.fa-money-bill-transfer:after, .fad.fa-money-bill-transfer:after {
  content: "";
}

.fa-duotone.fa-money-bill-trend-up:after, .fad.fa-money-bill-trend-up:after {
  content: "";
}

.fa-duotone.fa-house-flood-water-circle-arrow-right:after, .fad.fa-house-flood-water-circle-arrow-right:after {
  content: "";
}

.fa-duotone.fa-poll-h:after, .fa-duotone.fa-square-poll-horizontal:after, .fad.fa-poll-h:after, .fad.fa-square-poll-horizontal:after {
  content: "";
}

.fa-duotone.fa-circle:after, .fad.fa-circle:after {
  content: "";
}

.fa-duotone.fa-cart-circle-exclamation:after, .fad.fa-cart-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-sword:after, .fad.fa-sword:after {
  content: "";
}

.fa-duotone.fa-backward-fast:after, .fa-duotone.fa-fast-backward:after, .fad.fa-backward-fast:after, .fad.fa-fast-backward:after {
  content: "";
}

.fa-duotone.fa-recycle:after, .fad.fa-recycle:after {
  content: "";
}

.fa-duotone.fa-user-astronaut:after, .fad.fa-user-astronaut:after {
  content: "";
}

.fa-duotone.fa-interrobang:after, .fad.fa-interrobang:after {
  content: "";
}

.fa-duotone.fa-plane-slash:after, .fad.fa-plane-slash:after {
  content: "";
}

.fa-duotone.fa-circle-dashed:after, .fad.fa-circle-dashed:after {
  content: "";
}

.fa-duotone.fa-trademark:after, .fad.fa-trademark:after {
  content: "";
}

.fa-duotone.fa-basketball-ball:after, .fa-duotone.fa-basketball:after, .fad.fa-basketball-ball:after, .fad.fa-basketball:after {
  content: "";
}

.fa-duotone.fa-fork-knife:after, .fa-duotone.fa-utensils-alt:after, .fad.fa-fork-knife:after, .fad.fa-utensils-alt:after {
  content: "";
}

.fa-duotone.fa-satellite-dish:after, .fad.fa-satellite-dish:after {
  content: "";
}

.fa-duotone.fa-badge-check:after, .fad.fa-badge-check:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-circle-up:after, .fa-duotone.fa-circle-up:after, .fad.fa-arrow-alt-circle-up:after, .fad.fa-circle-up:after {
  content: "";
}

.fa-duotone.fa-slider:after, .fad.fa-slider:after {
  content: "";
}

.fa-duotone.fa-mobile-alt:after, .fa-duotone.fa-mobile-screen-button:after, .fad.fa-mobile-alt:after, .fad.fa-mobile-screen-button:after {
  content: "";
}

.fa-duotone.fa-clock-one-thirty:after, .fad.fa-clock-one-thirty:after {
  content: "";
}

.fa-duotone.fa-inbox-arrow-up:after, .fa-duotone.fa-inbox-out:after, .fad.fa-inbox-arrow-up:after, .fad.fa-inbox-out:after {
  content: "";
}

.fa-duotone.fa-cloud-slash:after, .fad.fa-cloud-slash:after {
  content: "";
}

.fa-duotone.fa-volume-high:after, .fa-duotone.fa-volume-up:after, .fad.fa-volume-high:after, .fad.fa-volume-up:after {
  content: "";
}

.fa-duotone.fa-users-rays:after, .fad.fa-users-rays:after {
  content: "";
}

.fa-duotone.fa-wallet:after, .fad.fa-wallet:after {
  content: "";
}

.fa-duotone.fa-octagon-check:after, .fad.fa-octagon-check:after {
  content: "";
}

.fa-duotone.fa-flatbread-stuffed:after, .fad.fa-flatbread-stuffed:after {
  content: "";
}

.fa-duotone.fa-clipboard-check:after, .fad.fa-clipboard-check:after {
  content: "";
}

.fa-duotone.fa-cart-circle-plus:after, .fad.fa-cart-circle-plus:after {
  content: "";
}

.fa-duotone.fa-shipping-timed:after, .fa-duotone.fa-truck-clock:after, .fad.fa-shipping-timed:after, .fad.fa-truck-clock:after {
  content: "";
}

.fa-duotone.fa-pool-8-ball:after, .fad.fa-pool-8-ball:after {
  content: "";
}

.fa-duotone.fa-file-audio:after, .fad.fa-file-audio:after {
  content: "";
}

.fa-duotone.fa-turn-down-left:after, .fad.fa-turn-down-left:after {
  content: "";
}

.fa-duotone.fa-lock-hashtag:after, .fad.fa-lock-hashtag:after {
  content: "";
}

.fa-duotone.fa-chart-radar:after, .fad.fa-chart-radar:after {
  content: "";
}

.fa-duotone.fa-staff:after, .fad.fa-staff:after {
  content: "";
}

.fa-duotone.fa-burger:after, .fa-duotone.fa-hamburger:after, .fad.fa-burger:after, .fad.fa-hamburger:after {
  content: "";
}

.fa-duotone.fa-utility-pole:after, .fad.fa-utility-pole:after {
  content: "";
}

.fa-duotone.fa-transporter-6:after, .fad.fa-transporter-6:after {
  content: "";
}

.fa-duotone.fa-wrench:after, .fad.fa-wrench:after {
  content: "";
}

.fa-duotone.fa-bugs:after, .fad.fa-bugs:after {
  content: "";
}

.fa-duotone.fa-vector-polygon:after, .fad.fa-vector-polygon:after {
  content: "";
}

.fa-duotone.fa-diagram-nested:after, .fad.fa-diagram-nested:after {
  content: "";
}

.fa-duotone.fa-rupee-sign:after, .fa-duotone.fa-rupee:after, .fad.fa-rupee-sign:after, .fad.fa-rupee:after {
  content: "";
}

.fa-duotone.fa-file-image:after, .fad.fa-file-image:after {
  content: "";
}

.fa-duotone.fa-circle-question:after, .fa-duotone.fa-question-circle:after, .fad.fa-circle-question:after, .fad.fa-question-circle:after {
  content: "";
}

.fa-duotone.fa-image-user:after, .fad.fa-image-user:after {
  content: "";
}

.fa-duotone.fa-buoy:after, .fad.fa-buoy:after {
  content: "";
}

.fa-duotone.fa-plane-departure:after, .fad.fa-plane-departure:after {
  content: "";
}

.fa-duotone.fa-handshake-slash:after, .fad.fa-handshake-slash:after {
  content: "";
}

.fa-duotone.fa-book-bookmark:after, .fad.fa-book-bookmark:after {
  content: "";
}

.fa-duotone.fa-border-center-h:after, .fad.fa-border-center-h:after {
  content: "";
}

.fa-duotone.fa-can-food:after, .fad.fa-can-food:after {
  content: "";
}

.fa-duotone.fa-typewriter:after, .fad.fa-typewriter:after {
  content: "";
}

.fa-duotone.fa-arrow-right-from-arc:after, .fad.fa-arrow-right-from-arc:after {
  content: "";
}

.fa-duotone.fa-circle-k:after, .fad.fa-circle-k:after {
  content: "";
}

.fa-duotone.fa-face-hand-over-mouth:after, .fad.fa-face-hand-over-mouth:after {
  content: "";
}

.fa-duotone.fa-popcorn:after, .fad.fa-popcorn:after {
  content: "";
}

.fa-duotone.fa-house-flood:after, .fa-duotone.fa-house-water:after, .fad.fa-house-flood:after, .fad.fa-house-water:after {
  content: "";
}

.fa-duotone.fa-object-subtract:after, .fad.fa-object-subtract:after {
  content: "";
}

.fa-duotone.fa-code-branch:after, .fad.fa-code-branch:after {
  content: "";
}

.fa-duotone.fa-warehouse-alt:after, .fa-duotone.fa-warehouse-full:after, .fad.fa-warehouse-alt:after, .fad.fa-warehouse-full:after {
  content: "";
}

.fa-duotone.fa-hat-cowboy:after, .fad.fa-hat-cowboy:after {
  content: "";
}

.fa-duotone.fa-bridge:after, .fad.fa-bridge:after {
  content: "";
}

.fa-duotone.fa-phone-alt:after, .fa-duotone.fa-phone-flip:after, .fad.fa-phone-alt:after, .fad.fa-phone-flip:after {
  content: "";
}

.fa-duotone.fa-arrow-down-from-dotted-line:after, .fad.fa-arrow-down-from-dotted-line:after {
  content: "";
}

.fa-duotone.fa-file-doc:after, .fad.fa-file-doc:after {
  content: "";
}

.fa-duotone.fa-square-quarters:after, .fad.fa-square-quarters:after {
  content: "";
}

.fa-duotone.fa-truck-front:after, .fad.fa-truck-front:after {
  content: "";
}

.fa-duotone.fa-cat:after, .fad.fa-cat:after {
  content: "";
}

.fa-duotone.fa-trash-xmark:after, .fad.fa-trash-xmark:after {
  content: "";
}

.fa-duotone.fa-caret-circle-left:after, .fa-duotone.fa-circle-caret-left:after, .fad.fa-caret-circle-left:after, .fad.fa-circle-caret-left:after {
  content: "";
}

.fa-duotone.fa-files:after, .fad.fa-files:after {
  content: "";
}

.fa-duotone.fa-anchor-circle-exclamation:after, .fad.fa-anchor-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-face-clouds:after, .fad.fa-face-clouds:after {
  content: "";
}

.fa-duotone.fa-user-crown:after, .fad.fa-user-crown:after {
  content: "";
}

.fa-duotone.fa-truck-field:after, .fad.fa-truck-field:after {
  content: "";
}

.fa-duotone.fa-route:after, .fad.fa-route:after {
  content: "";
}

.fa-duotone.fa-cart-circle-check:after, .fad.fa-cart-circle-check:after {
  content: "";
}

.fa-duotone.fa-clipboard-question:after, .fad.fa-clipboard-question:after {
  content: "";
}

.fa-duotone.fa-panorama:after, .fad.fa-panorama:after {
  content: "";
}

.fa-duotone.fa-comment-medical:after, .fad.fa-comment-medical:after {
  content: "";
}

.fa-duotone.fa-teeth-open:after, .fad.fa-teeth-open:after {
  content: "";
}

.fa-duotone.fa-user-tie-hair-long:after, .fad.fa-user-tie-hair-long:after {
  content: "";
}

.fa-duotone.fa-file-circle-minus:after, .fad.fa-file-circle-minus:after {
  content: "";
}

.fa-duotone.fa-head-side-medical:after, .fad.fa-head-side-medical:after {
  content: "";
}

.fa-duotone.fa-tags:after, .fad.fa-tags:after {
  content: "";
}

.fa-duotone.fa-wine-glass:after, .fad.fa-wine-glass:after {
  content: "";
}

.fa-duotone.fa-fast-forward:after, .fa-duotone.fa-forward-fast:after, .fad.fa-fast-forward:after, .fad.fa-forward-fast:after {
  content: "";
}

.fa-duotone.fa-face-meh-blank:after, .fa-duotone.fa-meh-blank:after, .fad.fa-face-meh-blank:after, .fad.fa-meh-blank:after {
  content: "";
}

.fa-duotone.fa-user-robot:after, .fad.fa-user-robot:after {
  content: "";
}

.fa-duotone.fa-parking:after, .fa-duotone.fa-square-parking:after, .fad.fa-parking:after, .fad.fa-square-parking:after {
  content: "";
}

.fa-duotone.fa-card-diamond:after, .fad.fa-card-diamond:after {
  content: "";
}

.fa-duotone.fa-face-zipper:after, .fad.fa-face-zipper:after {
  content: "";
}

.fa-duotone.fa-face-raised-eyebrow:after, .fad.fa-face-raised-eyebrow:after {
  content: "";
}

.fa-duotone.fa-house-signal:after, .fad.fa-house-signal:after {
  content: "";
}

.fa-duotone.fa-chevron-square-up:after, .fa-duotone.fa-square-chevron-up:after, .fad.fa-chevron-square-up:after, .fad.fa-square-chevron-up:after {
  content: "";
}

.fa-duotone.fa-bars-progress:after, .fa-duotone.fa-tasks-alt:after, .fad.fa-bars-progress:after, .fad.fa-tasks-alt:after {
  content: "";
}

.fa-duotone.fa-faucet-drip:after, .fad.fa-faucet-drip:after {
  content: "";
}

.fa-duotone.fa-arrows-to-line:after, .fad.fa-arrows-to-line:after {
  content: "";
}

.fa-duotone.fa-dolphin:after, .fad.fa-dolphin:after {
  content: "";
}

.fa-duotone.fa-arrow-up-right:after, .fad.fa-arrow-up-right:after {
  content: "";
}

.fa-duotone.fa-circle-r:after, .fad.fa-circle-r:after {
  content: "";
}

.fa-duotone.fa-cart-flatbed:after, .fa-duotone.fa-dolly-flatbed:after, .fad.fa-cart-flatbed:after, .fad.fa-dolly-flatbed:after {
  content: "";
}

.fa-duotone.fa-ban-smoking:after, .fa-duotone.fa-smoking-ban:after, .fad.fa-ban-smoking:after, .fad.fa-smoking-ban:after {
  content: "";
}

.fa-duotone.fa-circle-sort-up:after, .fa-duotone.fa-sort-circle-up:after, .fad.fa-circle-sort-up:after, .fad.fa-sort-circle-up:after {
  content: "";
}

.fa-duotone.fa-terminal:after, .fad.fa-terminal:after {
  content: "";
}

.fa-duotone.fa-mobile-button:after, .fad.fa-mobile-button:after {
  content: "";
}

.fa-duotone.fa-house-medical-flag:after, .fad.fa-house-medical-flag:after {
  content: "";
}

.fa-duotone.fa-basket-shopping:after, .fa-duotone.fa-shopping-basket:after, .fad.fa-basket-shopping:after, .fad.fa-shopping-basket:after {
  content: "";
}

.fa-duotone.fa-tape:after, .fad.fa-tape:after {
  content: "";
}

.fa-duotone.fa-chestnut:after, .fad.fa-chestnut:after {
  content: "";
}

.fa-duotone.fa-bus-alt:after, .fa-duotone.fa-bus-simple:after, .fad.fa-bus-alt:after, .fad.fa-bus-simple:after {
  content: "";
}

.fa-duotone.fa-eye:after, .fad.fa-eye:after {
  content: "";
}

.fa-duotone.fa-face-sad-cry:after, .fa-duotone.fa-sad-cry:after, .fad.fa-face-sad-cry:after, .fad.fa-sad-cry:after {
  content: "";
}

.fa-duotone.fa-heat:after, .fad.fa-heat:after {
  content: "";
}

.fa-duotone.fa-ticket-airline:after, .fad.fa-ticket-airline:after {
  content: "";
}

.fa-duotone.fa-boot-heeled:after, .fad.fa-boot-heeled:after {
  content: "";
}

.fa-duotone.fa-arrows-minimize:after, .fa-duotone.fa-compress-arrows:after, .fad.fa-arrows-minimize:after, .fad.fa-compress-arrows:after {
  content: "";
}

.fa-duotone.fa-audio-description:after, .fad.fa-audio-description:after {
  content: "";
}

.fa-duotone.fa-person-military-to-person:after, .fad.fa-person-military-to-person:after {
  content: "";
}

.fa-duotone.fa-file-shield:after, .fad.fa-file-shield:after {
  content: "";
}

.fa-duotone.fa-hexagon:after, .fad.fa-hexagon:after {
  content: "";
}

.fa-duotone.fa-manhole:after, .fad.fa-manhole:after {
  content: "";
}

.fa-duotone.fa-user-slash:after, .fad.fa-user-slash:after {
  content: "";
}

.fa-duotone.fa-pen:after, .fad.fa-pen:after {
  content: "";
}

.fa-duotone.fa-tower-observation:after, .fad.fa-tower-observation:after {
  content: "";
}

.fa-duotone.fa-floppy-disks:after, .fad.fa-floppy-disks:after {
  content: "";
}

.fa-duotone.fa-toilet-paper-blank-under:after, .fa-duotone.fa-toilet-paper-reverse-alt:after, .fad.fa-toilet-paper-blank-under:after, .fad.fa-toilet-paper-reverse-alt:after {
  content: "";
}

.fa-duotone.fa-file-code:after, .fad.fa-file-code:after {
  content: "";
}

.fa-duotone.fa-signal-5:after, .fa-duotone.fa-signal-perfect:after, .fa-duotone.fa-signal:after, .fad.fa-signal-5:after, .fad.fa-signal-perfect:after, .fad.fa-signal:after {
  content: "";
}

.fa-duotone.fa-pump:after, .fad.fa-pump:after {
  content: "";
}

.fa-duotone.fa-bus:after, .fad.fa-bus:after {
  content: "";
}

.fa-duotone.fa-heart-circle-xmark:after, .fad.fa-heart-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-arrow-up-left-from-circle:after, .fad.fa-arrow-up-left-from-circle:after {
  content: "";
}

.fa-duotone.fa-home-lg:after, .fa-duotone.fa-house-chimney:after, .fad.fa-home-lg:after, .fad.fa-house-chimney:after {
  content: "";
}

.fa-duotone.fa-window-maximize:after, .fad.fa-window-maximize:after {
  content: "";
}

.fa-duotone.fa-dryer:after, .fad.fa-dryer:after {
  content: "";
}

.fa-duotone.fa-face-frown:after, .fa-duotone.fa-frown:after, .fad.fa-face-frown:after, .fad.fa-frown:after {
  content: "";
}

.fa-duotone.fa-chess-bishop-alt:after, .fa-duotone.fa-chess-bishop-piece:after, .fad.fa-chess-bishop-alt:after, .fad.fa-chess-bishop-piece:after {
  content: "";
}

.fa-duotone.fa-shirt-tank-top:after, .fad.fa-shirt-tank-top:after {
  content: "";
}

.fa-duotone.fa-diploma:after, .fa-duotone.fa-scroll-ribbon:after, .fad.fa-diploma:after, .fad.fa-scroll-ribbon:after {
  content: "";
}

.fa-duotone.fa-screencast:after, .fad.fa-screencast:after {
  content: "";
}

.fa-duotone.fa-walker:after, .fad.fa-walker:after {
  content: "";
}

.fa-duotone.fa-prescription:after, .fad.fa-prescription:after {
  content: "";
}

.fa-duotone.fa-shop:after, .fa-duotone.fa-store-alt:after, .fad.fa-shop:after, .fad.fa-store-alt:after {
  content: "";
}

.fa-duotone.fa-floppy-disk:after, .fa-duotone.fa-save:after, .fad.fa-floppy-disk:after, .fad.fa-save:after {
  content: "";
}

.fa-duotone.fa-vihara:after, .fad.fa-vihara:after {
  content: "";
}

.fa-duotone.fa-face-kiss-closed-eyes:after, .fad.fa-face-kiss-closed-eyes:after {
  content: "";
}

.fa-duotone.fa-balance-scale-left:after, .fa-duotone.fa-scale-unbalanced:after, .fad.fa-balance-scale-left:after, .fad.fa-scale-unbalanced:after {
  content: "";
}

.fa-duotone.fa-file-user:after, .fad.fa-file-user:after {
  content: "";
}

.fa-duotone.fa-user-police-tie:after, .fad.fa-user-police-tie:after {
  content: "";
}

.fa-duotone.fa-face-tongue-money:after, .fad.fa-face-tongue-money:after {
  content: "";
}

.fa-duotone.fa-tennis-ball:after, .fad.fa-tennis-ball:after {
  content: "";
}

.fa-duotone.fa-square-l:after, .fad.fa-square-l:after {
  content: "";
}

.fa-duotone.fa-sort-asc:after, .fa-duotone.fa-sort-up:after, .fad.fa-sort-asc:after, .fad.fa-sort-up:after {
  content: "";
}

.fa-duotone.fa-calendar-arrow-up:after, .fa-duotone.fa-calendar-upload:after, .fad.fa-calendar-arrow-up:after, .fad.fa-calendar-upload:after {
  content: "";
}

.fa-duotone.fa-comment-dots:after, .fa-duotone.fa-commenting:after, .fad.fa-comment-dots:after, .fad.fa-commenting:after {
  content: "";
}

.fa-duotone.fa-plant-wilt:after, .fad.fa-plant-wilt:after {
  content: "";
}

.fa-duotone.fa-scarf:after, .fad.fa-scarf:after {
  content: "";
}

.fa-duotone.fa-album-circle-plus:after, .fad.fa-album-circle-plus:after {
  content: "";
}

.fa-duotone.fa-user-nurse-hair-long:after, .fad.fa-user-nurse-hair-long:after {
  content: "";
}

.fa-duotone.fa-diamond:after, .fad.fa-diamond:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-square-left:after, .fa-duotone.fa-square-left:after, .fad.fa-arrow-alt-square-left:after, .fad.fa-square-left:after {
  content: "";
}

.fa-duotone.fa-face-grin-squint:after, .fa-duotone.fa-grin-squint:after, .fad.fa-face-grin-squint:after, .fad.fa-grin-squint:after {
  content: "";
}

.fa-duotone.fa-circle-ellipsis-vertical:after, .fad.fa-circle-ellipsis-vertical:after {
  content: "";
}

.fa-duotone.fa-hand-holding-dollar:after, .fa-duotone.fa-hand-holding-usd:after, .fad.fa-hand-holding-dollar:after, .fad.fa-hand-holding-usd:after {
  content: "";
}

.fa-duotone.fa-grid-dividers:after, .fad.fa-grid-dividers:after {
  content: "";
}

.fa-duotone.fa-bacterium:after, .fad.fa-bacterium:after {
  content: "";
}

.fa-duotone.fa-hand-pointer:after, .fad.fa-hand-pointer:after {
  content: "";
}

.fa-duotone.fa-drum-steelpan:after, .fad.fa-drum-steelpan:after {
  content: "";
}

.fa-duotone.fa-hand-scissors:after, .fad.fa-hand-scissors:after {
  content: "";
}

.fa-duotone.fa-hands-praying:after, .fa-duotone.fa-praying-hands:after, .fad.fa-hands-praying:after, .fad.fa-praying-hands:after {
  content: "";
}

.fa-duotone.fa-face-pensive:after, .fad.fa-face-pensive:after {
  content: "";
}

.fa-duotone.fa-user-music:after, .fad.fa-user-music:after {
  content: "";
}

.fa-duotone.fa-arrow-right-rotate:after, .fa-duotone.fa-arrow-rotate-forward:after, .fa-duotone.fa-arrow-rotate-right:after, .fa-duotone.fa-redo:after, .fad.fa-arrow-right-rotate:after, .fad.fa-arrow-rotate-forward:after, .fad.fa-arrow-rotate-right:after, .fad.fa-redo:after {
  content: "";
}

.fa-duotone.fa-comments-alt-dollar:after, .fa-duotone.fa-messages-dollar:after, .fad.fa-comments-alt-dollar:after, .fad.fa-messages-dollar:after {
  content: "";
}

.fa-duotone.fa-sensor-on:after, .fad.fa-sensor-on:after {
  content: "";
}

.fa-duotone.fa-balloon:after, .fad.fa-balloon:after {
  content: "";
}

.fa-duotone.fa-biohazard:after, .fad.fa-biohazard:after {
  content: "";
}

.fa-duotone.fa-chess-queen-alt:after, .fa-duotone.fa-chess-queen-piece:after, .fad.fa-chess-queen-alt:after, .fad.fa-chess-queen-piece:after {
  content: "";
}

.fa-duotone.fa-location-crosshairs:after, .fa-duotone.fa-location:after, .fad.fa-location-crosshairs:after, .fad.fa-location:after {
  content: "";
}

.fa-duotone.fa-mars-double:after, .fad.fa-mars-double:after {
  content: "";
}

.fa-duotone.fa-house-leave:after, .fa-duotone.fa-house-person-depart:after, .fa-duotone.fa-house-person-leave:after, .fad.fa-house-leave:after, .fad.fa-house-person-depart:after, .fad.fa-house-person-leave:after {
  content: "";
}

.fa-duotone.fa-ruler-triangle:after, .fad.fa-ruler-triangle:after {
  content: "";
}

.fa-duotone.fa-card-club:after, .fad.fa-card-club:after {
  content: "";
}

.fa-duotone.fa-child-dress:after, .fad.fa-child-dress:after {
  content: "";
}

.fa-duotone.fa-users-between-lines:after, .fad.fa-users-between-lines:after {
  content: "";
}

.fa-duotone.fa-lungs-virus:after, .fad.fa-lungs-virus:after {
  content: "";
}

.fa-duotone.fa-spinner-third:after, .fad.fa-spinner-third:after {
  content: "";
}

.fa-duotone.fa-face-grin-tears:after, .fa-duotone.fa-grin-tears:after, .fad.fa-face-grin-tears:after, .fad.fa-grin-tears:after {
  content: "";
}

.fa-duotone.fa-phone:after, .fad.fa-phone:after {
  content: "";
}

.fa-duotone.fa-computer-mouse-scrollwheel:after, .fa-duotone.fa-mouse-alt:after, .fad.fa-computer-mouse-scrollwheel:after, .fad.fa-mouse-alt:after {
  content: "";
}

.fa-duotone.fa-calendar-times:after, .fa-duotone.fa-calendar-xmark:after, .fad.fa-calendar-times:after, .fad.fa-calendar-xmark:after {
  content: "";
}

.fa-duotone.fa-child-reaching:after, .fad.fa-child-reaching:after {
  content: "";
}

.fa-duotone.fa-table-layout:after, .fad.fa-table-layout:after {
  content: "";
}

.fa-duotone.fa-narwhal:after, .fad.fa-narwhal:after {
  content: "";
}

.fa-duotone.fa-ramp-loading:after, .fad.fa-ramp-loading:after {
  content: "";
}

.fa-duotone.fa-calendar-circle-plus:after, .fad.fa-calendar-circle-plus:after {
  content: "";
}

.fa-duotone.fa-toothbrush:after, .fad.fa-toothbrush:after {
  content: "";
}

.fa-duotone.fa-border-inner:after, .fad.fa-border-inner:after {
  content: "";
}

.fa-duotone.fa-paw-claws:after, .fad.fa-paw-claws:after {
  content: "";
}

.fa-duotone.fa-kiwi-fruit:after, .fad.fa-kiwi-fruit:after {
  content: "";
}

.fa-duotone.fa-traffic-light-slow:after, .fad.fa-traffic-light-slow:after {
  content: "";
}

.fa-duotone.fa-rectangle-code:after, .fad.fa-rectangle-code:after {
  content: "";
}

.fa-duotone.fa-head-side-virus:after, .fad.fa-head-side-virus:after {
  content: "";
}

.fa-duotone.fa-keyboard-brightness:after, .fad.fa-keyboard-brightness:after {
  content: "";
}

.fa-duotone.fa-books-medical:after, .fad.fa-books-medical:after {
  content: "";
}

.fa-duotone.fa-lightbulb-slash:after, .fad.fa-lightbulb-slash:after {
  content: "";
}

.fa-duotone.fa-home-blank:after, .fa-duotone.fa-house-blank:after, .fad.fa-home-blank:after, .fad.fa-house-blank:after {
  content: "";
}

.fa-duotone.fa-square-5:after, .fad.fa-square-5:after {
  content: "";
}

.fa-duotone.fa-heart-square:after, .fa-duotone.fa-square-heart:after, .fad.fa-heart-square:after, .fad.fa-square-heart:after {
  content: "";
}

.fa-duotone.fa-puzzle:after, .fad.fa-puzzle:after {
  content: "";
}

.fa-duotone.fa-user-cog:after, .fa-duotone.fa-user-gear:after, .fad.fa-user-cog:after, .fad.fa-user-gear:after {
  content: "";
}

.fa-duotone.fa-pipe-circle-check:after, .fad.fa-pipe-circle-check:after {
  content: "";
}

.fa-duotone.fa-arrow-up-1-9:after, .fa-duotone.fa-sort-numeric-up:after, .fad.fa-arrow-up-1-9:after, .fad.fa-sort-numeric-up:after {
  content: "";
}

.fa-duotone.fa-octagon-exclamation:after, .fad.fa-octagon-exclamation:after {
  content: "";
}

.fa-duotone.fa-dial-low:after, .fad.fa-dial-low:after {
  content: "";
}

.fa-duotone.fa-door-closed:after, .fad.fa-door-closed:after {
  content: "";
}

.fa-duotone.fa-laptop-mobile:after, .fa-duotone.fa-phone-laptop:after, .fad.fa-laptop-mobile:after, .fad.fa-phone-laptop:after {
  content: "";
}

.fa-duotone.fa-conveyor-belt-alt:after, .fa-duotone.fa-conveyor-belt-boxes:after, .fad.fa-conveyor-belt-alt:after, .fad.fa-conveyor-belt-boxes:after {
  content: "";
}

.fa-duotone.fa-shield-virus:after, .fad.fa-shield-virus:after {
  content: "";
}

.fa-duotone.fa-starfighter-alt-advanced:after, .fa-duotone.fa-starfighter-twin-ion-engine-advanced:after, .fad.fa-starfighter-alt-advanced:after, .fad.fa-starfighter-twin-ion-engine-advanced:after {
  content: "";
}

.fa-duotone.fa-dice-six:after, .fad.fa-dice-six:after {
  content: "";
}

.fa-duotone.fa-starfighter-alt:after, .fa-duotone.fa-starfighter-twin-ion-engine:after, .fad.fa-starfighter-alt:after, .fad.fa-starfighter-twin-ion-engine:after {
  content: "";
}

.fa-duotone.fa-rocket-launch:after, .fad.fa-rocket-launch:after {
  content: "";
}

.fa-duotone.fa-mosquito-net:after, .fad.fa-mosquito-net:after {
  content: "";
}

.fa-duotone.fa-vent-damper:after, .fad.fa-vent-damper:after {
  content: "";
}

.fa-duotone.fa-bridge-water:after, .fad.fa-bridge-water:after {
  content: "";
}

.fa-duotone.fa-ban-bug:after, .fa-duotone.fa-debug:after, .fad.fa-ban-bug:after, .fad.fa-debug:after {
  content: "";
}

.fa-duotone.fa-person-booth:after, .fad.fa-person-booth:after {
  content: "";
}

.fa-duotone.fa-text-width:after, .fad.fa-text-width:after {
  content: "";
}

.fa-duotone.fa-garage-car:after, .fad.fa-garage-car:after {
  content: "";
}

.fa-duotone.fa-square-kanban:after, .fad.fa-square-kanban:after {
  content: "";
}

.fa-duotone.fa-hat-wizard:after, .fad.fa-hat-wizard:after {
  content: "";
}

.fa-duotone.fa-pen-fancy:after, .fad.fa-pen-fancy:after {
  content: "";
}

.fa-duotone.fa-coffee-pot:after, .fad.fa-coffee-pot:after {
  content: "";
}

.fa-duotone.fa-mouse-field:after, .fad.fa-mouse-field:after {
  content: "";
}

.fa-duotone.fa-digging:after, .fa-duotone.fa-person-digging:after, .fad.fa-digging:after, .fad.fa-person-digging:after {
  content: "";
}

.fa-duotone.fa-shower-alt:after, .fa-duotone.fa-shower-down:after, .fad.fa-shower-alt:after, .fad.fa-shower-down:after {
  content: "";
}

.fa-duotone.fa-box-circle-check:after, .fad.fa-box-circle-check:after {
  content: "";
}

.fa-duotone.fa-brightness:after, .fad.fa-brightness:after {
  content: "";
}

.fa-duotone.fa-car-side-bolt:after, .fad.fa-car-side-bolt:after {
  content: "";
}

.fa-duotone.fa-ornament:after, .fad.fa-ornament:after {
  content: "";
}

.fa-duotone.fa-phone-arrow-down-left:after, .fa-duotone.fa-phone-arrow-down:after, .fa-duotone.fa-phone-incoming:after, .fad.fa-phone-arrow-down-left:after, .fad.fa-phone-arrow-down:after, .fad.fa-phone-incoming:after {
  content: "";
}

.fa-duotone.fa-cloud-word:after, .fad.fa-cloud-word:after {
  content: "";
}

.fa-duotone.fa-hand-fingers-crossed:after, .fad.fa-hand-fingers-crossed:after {
  content: "";
}

.fa-duotone.fa-trash:after, .fad.fa-trash:after {
  content: "";
}

.fa-duotone.fa-gauge-simple-med:after, .fa-duotone.fa-gauge-simple:after, .fa-duotone.fa-tachometer-average:after, .fad.fa-gauge-simple-med:after, .fad.fa-gauge-simple:after, .fad.fa-tachometer-average:after {
  content: "";
}

.fa-duotone.fa-arrow-down-small-big:after, .fa-duotone.fa-sort-size-down-alt:after, .fad.fa-arrow-down-small-big:after, .fad.fa-sort-size-down-alt:after {
  content: "";
}

.fa-duotone.fa-book-medical:after, .fad.fa-book-medical:after {
  content: "";
}

.fa-duotone.fa-face-melting:after, .fad.fa-face-melting:after {
  content: "";
}

.fa-duotone.fa-poo:after, .fad.fa-poo:after {
  content: "";
}

.fa-duotone.fa-pen-alt-slash:after, .fa-duotone.fa-pen-clip-slash:after, .fad.fa-pen-alt-slash:after, .fad.fa-pen-clip-slash:after {
  content: "";
}

.fa-duotone.fa-quote-right-alt:after, .fa-duotone.fa-quote-right:after, .fad.fa-quote-right-alt:after, .fad.fa-quote-right:after {
  content: "";
}

.fa-duotone.fa-scroll-old:after, .fad.fa-scroll-old:after {
  content: "";
}

.fa-duotone.fa-guitars:after, .fad.fa-guitars:after {
  content: "";
}

.fa-duotone.fa-phone-xmark:after, .fad.fa-phone-xmark:after {
  content: "";
}

.fa-duotone.fa-hose:after, .fad.fa-hose:after {
  content: "";
}

.fa-duotone.fa-clock-six:after, .fad.fa-clock-six:after {
  content: "";
}

.fa-duotone.fa-shirt:after, .fa-duotone.fa-t-shirt:after, .fa-duotone.fa-tshirt:after, .fad.fa-shirt:after, .fad.fa-t-shirt:after, .fad.fa-tshirt:after {
  content: "";
}

.fa-duotone.fa-billboard:after, .fad.fa-billboard:after {
  content: "";
}

.fa-duotone.fa-square-r:after, .fad.fa-square-r:after {
  content: "";
}

.fa-duotone.fa-cubes:after, .fad.fa-cubes:after {
  content: "";
}

.fa-duotone.fa-envelope-open-dollar:after, .fad.fa-envelope-open-dollar:after {
  content: "";
}

.fa-duotone.fa-divide:after, .fad.fa-divide:after {
  content: "";
}

.fa-duotone.fa-sun-cloud:after, .fad.fa-sun-cloud:after {
  content: "";
}

.fa-duotone.fa-lamp-floor:after, .fad.fa-lamp-floor:after {
  content: "";
}

.fa-duotone.fa-square-7:after, .fad.fa-square-7:after {
  content: "";
}

.fa-duotone.fa-tenge-sign:after, .fa-duotone.fa-tenge:after, .fad.fa-tenge-sign:after, .fad.fa-tenge:after {
  content: "";
}

.fa-duotone.fa-headphones:after, .fad.fa-headphones:after {
  content: "";
}

.fa-duotone.fa-hands-holding:after, .fad.fa-hands-holding:after {
  content: "";
}

.fa-duotone.fa-campfire:after, .fad.fa-campfire:after {
  content: "";
}

.fa-duotone.fa-circle-ampersand:after, .fad.fa-circle-ampersand:after {
  content: "";
}

.fa-duotone.fa-snowflakes:after, .fad.fa-snowflakes:after {
  content: "";
}

.fa-duotone.fa-hands-clapping:after, .fad.fa-hands-clapping:after {
  content: "";
}

.fa-duotone.fa-republican:after, .fad.fa-republican:after {
  content: "";
}

.fa-duotone.fa-leaf-maple:after, .fad.fa-leaf-maple:after {
  content: "";
}

.fa-duotone.fa-arrow-left:after, .fad.fa-arrow-left:after {
  content: "";
}

.fa-duotone.fa-person-circle-xmark:after, .fad.fa-person-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-ruler:after, .fad.fa-ruler:after {
  content: "";
}

.fa-duotone.fa-cup-straw-swoosh:after, .fad.fa-cup-straw-swoosh:after {
  content: "";
}

.fa-duotone.fa-temperature-hot:after, .fa-duotone.fa-temperature-sun:after, .fad.fa-temperature-hot:after, .fad.fa-temperature-sun:after {
  content: "";
}

.fa-duotone.fa-align-left:after, .fad.fa-align-left:after {
  content: "";
}

.fa-duotone.fa-dice-d6:after, .fad.fa-dice-d6:after {
  content: "";
}

.fa-duotone.fa-restroom:after, .fad.fa-restroom:after {
  content: "";
}

.fa-duotone.fa-high-definition:after, .fa-duotone.fa-rectangle-hd:after, .fad.fa-high-definition:after, .fad.fa-rectangle-hd:after {
  content: "";
}

.fa-duotone.fa-j:after, .fad.fa-j:after {
  content: "JJ";
}

.fa-duotone.fa-galaxy:after, .fad.fa-galaxy:after {
  content: "";
}

.fa-duotone.fa-users-viewfinder:after, .fad.fa-users-viewfinder:after {
  content: "";
}

.fa-duotone.fa-file-video:after, .fad.fa-file-video:after {
  content: "";
}

.fa-duotone.fa-cherries:after, .fad.fa-cherries:after {
  content: "";
}

.fa-duotone.fa-external-link-alt:after, .fa-duotone.fa-up-right-from-square:after, .fad.fa-external-link-alt:after, .fad.fa-up-right-from-square:after {
  content: "";
}

.fa-duotone.fa-circle-sort:after, .fa-duotone.fa-sort-circle:after, .fad.fa-circle-sort:after, .fad.fa-sort-circle:after {
  content: "";
}

.fa-duotone.fa-table-cells:after, .fa-duotone.fa-th:after, .fad.fa-table-cells:after, .fad.fa-th:after {
  content: "";
}

.fa-duotone.fa-file-pdf:after, .fad.fa-file-pdf:after {
  content: "";
}

.fa-duotone.fa-siren:after, .fad.fa-siren:after {
  content: "";
}

.fa-duotone.fa-arrow-up-to-dotted-line:after, .fad.fa-arrow-up-to-dotted-line:after {
  content: "";
}

.fa-duotone.fa-image-landscape:after, .fa-duotone.fa-landscape:after, .fad.fa-image-landscape:after, .fad.fa-landscape:after {
  content: "";
}

.fa-duotone.fa-tank-water:after, .fad.fa-tank-water:after {
  content: "";
}

.fa-duotone.fa-curling-stone:after, .fa-duotone.fa-curling:after, .fad.fa-curling-stone:after, .fad.fa-curling:after {
  content: "";
}

.fa-duotone.fa-gamepad-alt:after, .fa-duotone.fa-gamepad-modern:after, .fad.fa-gamepad-alt:after, .fad.fa-gamepad-modern:after {
  content: "";
}

.fa-duotone.fa-messages-question:after, .fad.fa-messages-question:after {
  content: "";
}

.fa-duotone.fa-bible:after, .fa-duotone.fa-book-bible:after, .fad.fa-bible:after, .fad.fa-book-bible:after {
  content: "";
}

.fa-duotone.fa-o:after, .fad.fa-o:after {
  content: "OO";
}

.fa-duotone.fa-medkit:after, .fa-duotone.fa-suitcase-medical:after, .fad.fa-medkit:after, .fad.fa-suitcase-medical:after {
  content: "";
}

.fa-duotone.fa-briefcase-arrow-right:after, .fad.fa-briefcase-arrow-right:after {
  content: "";
}

.fa-duotone.fa-expand-wide:after, .fad.fa-expand-wide:after {
  content: "";
}

.fa-duotone.fa-clock-eleven-thirty:after, .fad.fa-clock-eleven-thirty:after {
  content: "";
}

.fa-duotone.fa-rv:after, .fad.fa-rv:after {
  content: "";
}

.fa-duotone.fa-user-secret:after, .fad.fa-user-secret:after {
  content: "";
}

.fa-duotone.fa-otter:after, .fad.fa-otter:after {
  content: "";
}

.fa-duotone.fa-dreidel:after, .fad.fa-dreidel:after {
  content: "";
}

.fa-duotone.fa-female:after, .fa-duotone.fa-person-dress:after, .fad.fa-female:after, .fad.fa-person-dress:after {
  content: "";
}

.fa-duotone.fa-comment-dollar:after, .fad.fa-comment-dollar:after {
  content: "";
}

.fa-duotone.fa-briefcase-clock:after, .fa-duotone.fa-business-time:after, .fad.fa-briefcase-clock:after, .fad.fa-business-time:after {
  content: "";
}

.fa-duotone.fa-flower-tulip:after, .fad.fa-flower-tulip:after {
  content: "";
}

.fa-duotone.fa-people-pants-simple:after, .fad.fa-people-pants-simple:after {
  content: "";
}

.fa-duotone.fa-cloud-drizzle:after, .fad.fa-cloud-drizzle:after {
  content: "";
}

.fa-duotone.fa-table-cells-large:after, .fa-duotone.fa-th-large:after, .fad.fa-table-cells-large:after, .fad.fa-th-large:after {
  content: "";
}

.fa-duotone.fa-book-tanakh:after, .fa-duotone.fa-tanakh:after, .fad.fa-book-tanakh:after, .fad.fa-tanakh:after {
  content: "";
}

.fa-duotone.fa-solar-system:after, .fad.fa-solar-system:after {
  content: "";
}

.fa-duotone.fa-seal-question:after, .fad.fa-seal-question:after {
  content: "";
}

.fa-duotone.fa-phone-volume:after, .fa-duotone.fa-volume-control-phone:after, .fad.fa-phone-volume:after, .fad.fa-volume-control-phone:after {
  content: "";
}

.fa-duotone.fa-disc-drive:after, .fad.fa-disc-drive:after {
  content: "";
}

.fa-duotone.fa-hat-cowboy-side:after, .fad.fa-hat-cowboy-side:after {
  content: "";
}

.fa-duotone.fa-rows:after, .fa-duotone.fa-table-rows:after, .fad.fa-rows:after, .fad.fa-table-rows:after {
  content: "";
}

.fa-duotone.fa-location-exclamation:after, .fa-duotone.fa-map-marker-exclamation:after, .fad.fa-location-exclamation:after, .fad.fa-map-marker-exclamation:after {
  content: "";
}

.fa-duotone.fa-face-fearful:after, .fad.fa-face-fearful:after {
  content: "";
}

.fa-duotone.fa-clipboard-user:after, .fad.fa-clipboard-user:after {
  content: "";
}

.fa-duotone.fa-bus-school:after, .fad.fa-bus-school:after {
  content: "";
}

.fa-duotone.fa-film-slash:after, .fad.fa-film-slash:after {
  content: "";
}

.fa-duotone.fa-square-arrow-down-right:after, .fad.fa-square-arrow-down-right:after {
  content: "";
}

.fa-duotone.fa-book-sparkles:after, .fa-duotone.fa-book-spells:after, .fad.fa-book-sparkles:after, .fad.fa-book-spells:after {
  content: "";
}

.fa-duotone.fa-washer:after, .fa-duotone.fa-washing-machine:after, .fad.fa-washer:after, .fad.fa-washing-machine:after {
  content: "";
}

.fa-duotone.fa-child:after, .fad.fa-child:after {
  content: "";
}

.fa-duotone.fa-lira-sign:after, .fad.fa-lira-sign:after {
  content: "";
}

.fa-duotone.fa-user-visor:after, .fad.fa-user-visor:after {
  content: "";
}

.fa-duotone.fa-file-plus-minus:after, .fad.fa-file-plus-minus:after {
  content: "";
}

.fa-duotone.fa-chess-clock-alt:after, .fa-duotone.fa-chess-clock-flip:after, .fad.fa-chess-clock-alt:after, .fad.fa-chess-clock-flip:after {
  content: "";
}

.fa-duotone.fa-satellite:after, .fad.fa-satellite:after {
  content: "";
}

.fa-duotone.fa-plane-lock:after, .fad.fa-plane-lock:after {
  content: "";
}

.fa-duotone.fa-steering-wheel:after, .fad.fa-steering-wheel:after {
  content: "";
}

.fa-duotone.fa-tag:after, .fad.fa-tag:after {
  content: "";
}

.fa-duotone.fa-stretcher:after, .fad.fa-stretcher:after {
  content: "";
}

.fa-duotone.fa-book-law:after, .fa-duotone.fa-book-section:after, .fad.fa-book-law:after, .fad.fa-book-section:after {
  content: "";
}

.fa-duotone.fa-inboxes:after, .fad.fa-inboxes:after {
  content: "";
}

.fa-duotone.fa-coffee-bean:after, .fad.fa-coffee-bean:after {
  content: "";
}

.fa-duotone.fa-circle-yen:after, .fad.fa-circle-yen:after {
  content: "";
}

.fa-duotone.fa-brackets-curly:after, .fad.fa-brackets-curly:after {
  content: "";
}

.fa-duotone.fa-ellipsis-stroke-vertical:after, .fa-duotone.fa-ellipsis-v-alt:after, .fad.fa-ellipsis-stroke-vertical:after, .fad.fa-ellipsis-v-alt:after {
  content: "";
}

.fa-duotone.fa-comment:after, .fad.fa-comment:after {
  content: "";
}

.fa-duotone.fa-square-1:after, .fad.fa-square-1:after {
  content: "";
}

.fa-duotone.fa-birthday-cake:after, .fa-duotone.fa-cake-candles:after, .fa-duotone.fa-cake:after, .fad.fa-birthday-cake:after, .fad.fa-cake-candles:after, .fad.fa-cake:after {
  content: "";
}

.fa-duotone.fa-head-side:after, .fad.fa-head-side:after {
  content: "";
}

.fa-duotone.fa-envelope:after, .fad.fa-envelope:after {
  content: "";
}

.fa-duotone.fa-dolly-empty:after, .fad.fa-dolly-empty:after {
  content: "";
}

.fa-duotone.fa-face-tissue:after, .fad.fa-face-tissue:after {
  content: "";
}

.fa-duotone.fa-angle-double-up:after, .fa-duotone.fa-angles-up:after, .fad.fa-angle-double-up:after, .fad.fa-angles-up:after {
  content: "";
}

.fa-duotone.fa-bin-recycle:after, .fad.fa-bin-recycle:after {
  content: "";
}

.fa-duotone.fa-paperclip:after, .fad.fa-paperclip:after {
  content: "";
}

.fa-duotone.fa-chart-line-down:after, .fad.fa-chart-line-down:after {
  content: "";
}

.fa-duotone.fa-arrow-right-to-city:after, .fad.fa-arrow-right-to-city:after {
  content: "";
}

.fa-duotone.fa-lock-a:after, .fad.fa-lock-a:after {
  content: "";
}

.fa-duotone.fa-ribbon:after, .fad.fa-ribbon:after {
  content: "";
}

.fa-duotone.fa-lungs:after, .fad.fa-lungs:after {
  content: "";
}

.fa-duotone.fa-person-pinball:after, .fad.fa-person-pinball:after {
  content: "";
}

.fa-duotone.fa-arrow-up-9-1:after, .fa-duotone.fa-sort-numeric-up-alt:after, .fad.fa-arrow-up-9-1:after, .fad.fa-sort-numeric-up-alt:after {
  content: "";
}

.fa-duotone.fa-apple-core:after, .fad.fa-apple-core:after {
  content: "";
}

.fa-duotone.fa-circle-y:after, .fad.fa-circle-y:after {
  content: "";
}

.fa-duotone.fa-h6:after, .fad.fa-h6:after {
  content: "";
}

.fa-duotone.fa-litecoin-sign:after, .fad.fa-litecoin-sign:after {
  content: "";
}

.fa-duotone.fa-circle-small:after, .fad.fa-circle-small:after {
  content: "";
}

.fa-duotone.fa-border-none:after, .fad.fa-border-none:after {
  content: "";
}

.fa-duotone.fa-arrow-turn-down-left:after, .fad.fa-arrow-turn-down-left:after {
  content: "";
}

.fa-duotone.fa-circle-nodes:after, .fad.fa-circle-nodes:after {
  content: "";
}

.fa-duotone.fa-parachute-box:after, .fad.fa-parachute-box:after {
  content: "";
}

.fa-duotone.fa-comment-alt-medical:after, .fa-duotone.fa-message-medical:after, .fad.fa-comment-alt-medical:after, .fad.fa-message-medical:after {
  content: "";
}

.fa-duotone.fa-rugby-ball:after, .fad.fa-rugby-ball:after {
  content: "";
}

.fa-duotone.fa-comment-music:after, .fad.fa-comment-music:after {
  content: "";
}

.fa-duotone.fa-indent:after, .fad.fa-indent:after {
  content: "";
}

.fa-duotone.fa-tree-alt:after, .fa-duotone.fa-tree-deciduous:after, .fad.fa-tree-alt:after, .fad.fa-tree-deciduous:after {
  content: "";
}

.fa-duotone.fa-puzzle-piece-alt:after, .fa-duotone.fa-puzzle-piece-simple:after, .fad.fa-puzzle-piece-alt:after, .fad.fa-puzzle-piece-simple:after {
  content: "";
}

.fa-duotone.fa-truck-field-un:after, .fad.fa-truck-field-un:after {
  content: "";
}

.fa-duotone.fa-nfc-trash:after, .fad.fa-nfc-trash:after {
  content: "";
}

.fa-duotone.fa-hourglass-empty:after, .fa-duotone.fa-hourglass:after, .fad.fa-hourglass-empty:after, .fad.fa-hourglass:after {
  content: "";
}

.fa-duotone.fa-mountain:after, .fad.fa-mountain:after {
  content: "";
}

.fa-duotone.fa-file-times:after, .fa-duotone.fa-file-xmark:after, .fad.fa-file-times:after, .fad.fa-file-xmark:after {
  content: "";
}

.fa-duotone.fa-home-heart:after, .fa-duotone.fa-house-heart:after, .fad.fa-home-heart:after, .fad.fa-house-heart:after {
  content: "";
}

.fa-duotone.fa-house-chimney-blank:after, .fad.fa-house-chimney-blank:after {
  content: "";
}

.fa-duotone.fa-meter-bolt:after, .fad.fa-meter-bolt:after {
  content: "";
}

.fa-duotone.fa-user-doctor:after, .fa-duotone.fa-user-md:after, .fad.fa-user-doctor:after, .fad.fa-user-md:after {
  content: "";
}

.fa-duotone.fa-slash-back:after, .fad.fa-slash-back:after {
  content: "\\\\";
}

.fa-duotone.fa-circle-info:after, .fa-duotone.fa-info-circle:after, .fad.fa-circle-info:after, .fad.fa-info-circle:after {
  content: "";
}

.fa-duotone.fa-fishing-rod:after, .fad.fa-fishing-rod:after {
  content: "";
}

.fa-duotone.fa-hammer-crash:after, .fad.fa-hammer-crash:after {
  content: "";
}

.fa-duotone.fa-message-heart:after, .fad.fa-message-heart:after {
  content: "";
}

.fa-duotone.fa-cloud-meatball:after, .fad.fa-cloud-meatball:after {
  content: "";
}

.fa-duotone.fa-camera-polaroid:after, .fad.fa-camera-polaroid:after {
  content: "";
}

.fa-duotone.fa-camera-alt:after, .fa-duotone.fa-camera:after, .fad.fa-camera-alt:after, .fad.fa-camera:after {
  content: "";
}

.fa-duotone.fa-square-virus:after, .fad.fa-square-virus:after {
  content: "";
}

.fa-duotone.fa-cart-arrow-up:after, .fad.fa-cart-arrow-up:after {
  content: "";
}

.fa-duotone.fa-meteor:after, .fad.fa-meteor:after {
  content: "";
}

.fa-duotone.fa-car-on:after, .fad.fa-car-on:after {
  content: "";
}

.fa-duotone.fa-sleigh:after, .fad.fa-sleigh:after {
  content: "";
}

.fa-duotone.fa-arrow-down-1-9:after, .fa-duotone.fa-sort-numeric-asc:after, .fa-duotone.fa-sort-numeric-down:after, .fad.fa-arrow-down-1-9:after, .fad.fa-sort-numeric-asc:after, .fad.fa-sort-numeric-down:after {
  content: "";
}

.fa-duotone.fa-buoy-mooring:after, .fad.fa-buoy-mooring:after {
  content: "";
}

.fa-duotone.fa-square-4:after, .fad.fa-square-4:after {
  content: "";
}

.fa-duotone.fa-hand-holding-droplet:after, .fa-duotone.fa-hand-holding-water:after, .fad.fa-hand-holding-droplet:after, .fad.fa-hand-holding-water:after {
  content: "";
}

.fa-duotone.fa-tricycle-adult:after, .fad.fa-tricycle-adult:after {
  content: "";
}

.fa-duotone.fa-waveform:after, .fad.fa-waveform:after {
  content: "";
}

.fa-duotone.fa-water:after, .fad.fa-water:after {
  content: "";
}

.fa-duotone.fa-star-sharp-half-alt:after, .fa-duotone.fa-star-sharp-half-stroke:after, .fad.fa-star-sharp-half-alt:after, .fad.fa-star-sharp-half-stroke:after {
  content: "";
}

.fa-duotone.fa-nfc-signal:after, .fad.fa-nfc-signal:after {
  content: "";
}

.fa-duotone.fa-plane-prop:after, .fad.fa-plane-prop:after {
  content: "";
}

.fa-duotone.fa-calendar-check:after, .fad.fa-calendar-check:after {
  content: "";
}

.fa-duotone.fa-clock-desk:after, .fad.fa-clock-desk:after {
  content: "";
}

.fa-duotone.fa-calendar-clock:after, .fa-duotone.fa-calendar-time:after, .fad.fa-calendar-clock:after, .fad.fa-calendar-time:after {
  content: "";
}

.fa-duotone.fa-braille:after, .fad.fa-braille:after {
  content: "";
}

.fa-duotone.fa-prescription-bottle-alt:after, .fa-duotone.fa-prescription-bottle-medical:after, .fad.fa-prescription-bottle-alt:after, .fad.fa-prescription-bottle-medical:after {
  content: "";
}

.fa-duotone.fa-plate-utensils:after, .fad.fa-plate-utensils:after {
  content: "";
}

.fa-duotone.fa-family-pants:after, .fad.fa-family-pants:after {
  content: "";
}

.fa-duotone.fa-hose-reel:after, .fad.fa-hose-reel:after {
  content: "";
}

.fa-duotone.fa-house-window:after, .fad.fa-house-window:after {
  content: "";
}

.fa-duotone.fa-landmark:after, .fad.fa-landmark:after {
  content: "";
}

.fa-duotone.fa-truck:after, .fad.fa-truck:after {
  content: "";
}

.fa-duotone.fa-crosshairs:after, .fad.fa-crosshairs:after {
  content: "";
}

.fa-duotone.fa-cloud-rainbow:after, .fad.fa-cloud-rainbow:after {
  content: "";
}

.fa-duotone.fa-person-cane:after, .fad.fa-person-cane:after {
  content: "";
}

.fa-duotone.fa-alien:after, .fad.fa-alien:after {
  content: "";
}

.fa-duotone.fa-tent:after, .fad.fa-tent:after {
  content: "";
}

.fa-duotone.fa-laptop-binary:after, .fad.fa-laptop-binary:after {
  content: "";
}

.fa-duotone.fa-vest-patches:after, .fad.fa-vest-patches:after {
  content: "";
}

.fa-duotone.fa-people-dress-simple:after, .fad.fa-people-dress-simple:after {
  content: "";
}

.fa-duotone.fa-check-double:after, .fad.fa-check-double:after {
  content: "";
}

.fa-duotone.fa-arrow-down-a-z:after, .fa-duotone.fa-sort-alpha-asc:after, .fa-duotone.fa-sort-alpha-down:after, .fad.fa-arrow-down-a-z:after, .fad.fa-sort-alpha-asc:after, .fad.fa-sort-alpha-down:after {
  content: "";
}

.fa-duotone.fa-bowling-ball-pin:after, .fad.fa-bowling-ball-pin:after {
  content: "";
}

.fa-duotone.fa-bell-school-slash:after, .fad.fa-bell-school-slash:after {
  content: "";
}

.fa-duotone.fa-plus-large:after, .fad.fa-plus-large:after {
  content: "";
}

.fa-duotone.fa-money-bill-wheat:after, .fad.fa-money-bill-wheat:after {
  content: "";
}

.fa-duotone.fa-camera-viewfinder:after, .fa-duotone.fa-screenshot:after, .fad.fa-camera-viewfinder:after, .fad.fa-screenshot:after {
  content: "";
}

.fa-duotone.fa-comment-alt-music:after, .fa-duotone.fa-message-music:after, .fad.fa-comment-alt-music:after, .fad.fa-message-music:after {
  content: "";
}

.fa-duotone.fa-car-building:after, .fad.fa-car-building:after {
  content: "";
}

.fa-duotone.fa-border-bottom-right:after, .fa-duotone.fa-border-style-alt:after, .fad.fa-border-bottom-right:after, .fad.fa-border-style-alt:after {
  content: "";
}

.fa-duotone.fa-octagon:after, .fad.fa-octagon:after {
  content: "";
}

.fa-duotone.fa-comment-arrow-up-right:after, .fad.fa-comment-arrow-up-right:after {
  content: "";
}

.fa-duotone.fa-octagon-divide:after, .fad.fa-octagon-divide:after {
  content: "";
}

.fa-duotone.fa-cookie:after, .fad.fa-cookie:after {
  content: "";
}

.fa-duotone.fa-arrow-left-rotate:after, .fa-duotone.fa-arrow-rotate-back:after, .fa-duotone.fa-arrow-rotate-backward:after, .fa-duotone.fa-arrow-rotate-left:after, .fa-duotone.fa-undo:after, .fad.fa-arrow-left-rotate:after, .fad.fa-arrow-rotate-back:after, .fad.fa-arrow-rotate-backward:after, .fad.fa-arrow-rotate-left:after, .fad.fa-undo:after {
  content: "";
}

.fa-duotone.fa-tv-music:after, .fad.fa-tv-music:after {
  content: "";
}

.fa-duotone.fa-hard-drive:after, .fa-duotone.fa-hdd:after, .fad.fa-hard-drive:after, .fad.fa-hdd:after {
  content: "";
}

.fa-duotone.fa-reel:after, .fad.fa-reel:after {
  content: "";
}

.fa-duotone.fa-face-grin-squint-tears:after, .fa-duotone.fa-grin-squint-tears:after, .fad.fa-face-grin-squint-tears:after, .fad.fa-grin-squint-tears:after {
  content: "";
}

.fa-duotone.fa-dumbbell:after, .fad.fa-dumbbell:after {
  content: "";
}

.fa-duotone.fa-list-alt:after, .fa-duotone.fa-rectangle-list:after, .fad.fa-list-alt:after, .fad.fa-rectangle-list:after {
  content: "";
}

.fa-duotone.fa-tarp-droplet:after, .fad.fa-tarp-droplet:after {
  content: "";
}

.fa-duotone.fa-alarm-exclamation:after, .fad.fa-alarm-exclamation:after {
  content: "";
}

.fa-duotone.fa-house-medical-circle-check:after, .fad.fa-house-medical-circle-check:after {
  content: "";
}

.fa-duotone.fa-traffic-cone:after, .fad.fa-traffic-cone:after {
  content: "";
}

.fa-duotone.fa-grate:after, .fad.fa-grate:after {
  content: "";
}

.fa-duotone.fa-arrow-down-right:after, .fad.fa-arrow-down-right:after {
  content: "";
}

.fa-duotone.fa-person-skiing-nordic:after, .fa-duotone.fa-skiing-nordic:after, .fad.fa-person-skiing-nordic:after, .fad.fa-skiing-nordic:after {
  content: "";
}

.fa-duotone.fa-calendar-plus:after, .fad.fa-calendar-plus:after {
  content: "";
}

.fa-duotone.fa-person-from-portal:after, .fa-duotone.fa-portal-exit:after, .fad.fa-person-from-portal:after, .fad.fa-portal-exit:after {
  content: "";
}

.fa-duotone.fa-plane-arrival:after, .fad.fa-plane-arrival:after {
  content: "";
}

.fa-duotone.fa-cowbell-circle-plus:after, .fa-duotone.fa-cowbell-more:after, .fad.fa-cowbell-circle-plus:after, .fad.fa-cowbell-more:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-circle-left:after, .fa-duotone.fa-circle-left:after, .fad.fa-arrow-alt-circle-left:after, .fad.fa-circle-left:after {
  content: "";
}

.fa-duotone.fa-distribute-spacing-vertical:after, .fad.fa-distribute-spacing-vertical:after {
  content: "";
}

.fa-duotone.fa-signal-alt-2:after, .fa-duotone.fa-signal-bars-fair:after, .fad.fa-signal-alt-2:after, .fad.fa-signal-bars-fair:after {
  content: "";
}

.fa-duotone.fa-sportsball:after, .fad.fa-sportsball:after {
  content: "";
}

.fa-duotone.fa-game-console-handheld-crank:after, .fad.fa-game-console-handheld-crank:after {
  content: "";
}

.fa-duotone.fa-subway:after, .fa-duotone.fa-train-subway:after, .fad.fa-subway:after, .fad.fa-train-subway:after {
  content: "";
}

.fa-duotone.fa-chart-gantt:after, .fad.fa-chart-gantt:after {
  content: "";
}

.fa-duotone.fa-face-smile-upside-down:after, .fad.fa-face-smile-upside-down:after {
  content: "";
}

.fa-duotone.fa-ball-pile:after, .fad.fa-ball-pile:after {
  content: "";
}

.fa-duotone.fa-badge-dollar:after, .fad.fa-badge-dollar:after {
  content: "";
}

.fa-duotone.fa-money-bills-alt:after, .fa-duotone.fa-money-bills-simple:after, .fad.fa-money-bills-alt:after, .fad.fa-money-bills-simple:after {
  content: "";
}

.fa-duotone.fa-list-timeline:after, .fad.fa-list-timeline:after {
  content: "";
}

.fa-duotone.fa-indian-rupee-sign:after, .fa-duotone.fa-indian-rupee:after, .fa-duotone.fa-inr:after, .fad.fa-indian-rupee-sign:after, .fad.fa-indian-rupee:after, .fad.fa-inr:after {
  content: "";
}

.fa-duotone.fa-crop-alt:after, .fa-duotone.fa-crop-simple:after, .fad.fa-crop-alt:after, .fad.fa-crop-simple:after {
  content: "";
}

.fa-duotone.fa-money-bill-1:after, .fa-duotone.fa-money-bill-alt:after, .fad.fa-money-bill-1:after, .fad.fa-money-bill-alt:after {
  content: "";
}

.fa-duotone.fa-left-long:after, .fa-duotone.fa-long-arrow-alt-left:after, .fad.fa-left-long:after, .fad.fa-long-arrow-alt-left:after {
  content: "";
}

.fa-duotone.fa-keyboard-down:after, .fad.fa-keyboard-down:after {
  content: "";
}

.fa-duotone.fa-circle-up-right:after, .fad.fa-circle-up-right:after {
  content: "";
}

.fa-duotone.fa-cloud-bolt-moon:after, .fa-duotone.fa-thunderstorm-moon:after, .fad.fa-cloud-bolt-moon:after, .fad.fa-thunderstorm-moon:after {
  content: "";
}

.fa-duotone.fa-dna:after, .fad.fa-dna:after {
  content: "";
}

.fa-duotone.fa-virus-slash:after, .fad.fa-virus-slash:after {
  content: "";
}

.fa-duotone.fa-bracket-round-right:after, .fad.fa-bracket-round-right:after {
  content: "))";
}

.fa-duotone.fa-circle-sterling:after, .fad.fa-circle-sterling:after {
  content: "";
}

.fa-duotone.fa-circle-5:after, .fad.fa-circle-5:after {
  content: "";
}

.fa-duotone.fa-minus:after, .fa-duotone.fa-subtract:after, .fad.fa-minus:after, .fad.fa-subtract:after {
  content: "";
}

.fa-duotone.fa-fire-flame:after, .fa-duotone.fa-flame:after, .fad.fa-fire-flame:after, .fad.fa-flame:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-to-right:after, .fa-duotone.fa-right-to-line:after, .fad.fa-arrow-alt-to-right:after, .fad.fa-right-to-line:after {
  content: "";
}

.fa-duotone.fa-gif:after, .fad.fa-gif:after {
  content: "";
}

.fa-duotone.fa-chess:after, .fad.fa-chess:after {
  content: "";
}

.fa-duotone.fa-trash-slash:after, .fad.fa-trash-slash:after {
  content: "";
}

.fa-duotone.fa-arrow-left-long:after, .fa-duotone.fa-long-arrow-left:after, .fad.fa-arrow-left-long:after, .fad.fa-long-arrow-left:after {
  content: "";
}

.fa-duotone.fa-plug-circle-check:after, .fad.fa-plug-circle-check:after {
  content: "";
}

.fa-duotone.fa-font-case:after, .fad.fa-font-case:after {
  content: "";
}

.fa-duotone.fa-street-view:after, .fad.fa-street-view:after {
  content: "";
}

.fa-duotone.fa-arrow-down-left:after, .fad.fa-arrow-down-left:after {
  content: "";
}

.fa-duotone.fa-franc-sign:after, .fad.fa-franc-sign:after {
  content: "";
}

.fa-duotone.fa-flask-poison:after, .fa-duotone.fa-flask-round-poison:after, .fad.fa-flask-poison:after, .fad.fa-flask-round-poison:after {
  content: "";
}

.fa-duotone.fa-volume-off:after, .fad.fa-volume-off:after {
  content: "";
}

.fa-duotone.fa-book-circle-arrow-right:after, .fad.fa-book-circle-arrow-right:after {
  content: "";
}

.fa-duotone.fa-chart-user:after, .fa-duotone.fa-user-chart:after, .fad.fa-chart-user:after, .fad.fa-user-chart:after {
  content: "";
}

.fa-duotone.fa-american-sign-language-interpreting:after, .fa-duotone.fa-asl-interpreting:after, .fa-duotone.fa-hands-american-sign-language-interpreting:after, .fa-duotone.fa-hands-asl-interpreting:after, .fad.fa-american-sign-language-interpreting:after, .fad.fa-asl-interpreting:after, .fad.fa-hands-american-sign-language-interpreting:after, .fad.fa-hands-asl-interpreting:after {
  content: "";
}

.fa-duotone.fa-presentation-screen:after, .fa-duotone.fa-presentation:after, .fad.fa-presentation-screen:after, .fad.fa-presentation:after {
  content: "";
}

.fa-duotone.fa-circle-bolt:after, .fad.fa-circle-bolt:after {
  content: "";
}

.fa-duotone.fa-face-smile-halo:after, .fad.fa-face-smile-halo:after {
  content: "";
}

.fa-duotone.fa-cart-circle-arrow-down:after, .fad.fa-cart-circle-arrow-down:after {
  content: "";
}

.fa-duotone.fa-house-person-arrive:after, .fa-duotone.fa-house-person-return:after, .fa-duotone.fa-house-return:after, .fad.fa-house-person-arrive:after, .fad.fa-house-person-return:after, .fad.fa-house-return:after {
  content: "";
}

.fa-duotone.fa-comment-alt-times:after, .fa-duotone.fa-message-times:after, .fa-duotone.fa-message-xmark:after, .fad.fa-comment-alt-times:after, .fad.fa-message-times:after, .fad.fa-message-xmark:after {
  content: "";
}

.fa-duotone.fa-file-award:after, .fa-duotone.fa-file-certificate:after, .fad.fa-file-award:after, .fad.fa-file-certificate:after {
  content: "";
}

.fa-duotone.fa-user-doctor-hair-long:after, .fad.fa-user-doctor-hair-long:after {
  content: "";
}

.fa-duotone.fa-camera-home:after, .fa-duotone.fa-camera-security:after, .fad.fa-camera-home:after, .fad.fa-camera-security:after {
  content: "";
}

.fa-duotone.fa-cog:after, .fa-duotone.fa-gear:after, .fad.fa-cog:after, .fad.fa-gear:after {
  content: "";
}

.fa-duotone.fa-droplet-slash:after, .fa-duotone.fa-tint-slash:after, .fad.fa-droplet-slash:after, .fad.fa-tint-slash:after {
  content: "";
}

.fa-duotone.fa-book-heart:after, .fad.fa-book-heart:after {
  content: "";
}

.fa-duotone.fa-mosque:after, .fad.fa-mosque:after {
  content: "";
}

.fa-duotone.fa-duck:after, .fad.fa-duck:after {
  content: "";
}

.fa-duotone.fa-mosquito:after, .fad.fa-mosquito:after {
  content: "";
}

.fa-duotone.fa-star-of-david:after, .fad.fa-star-of-david:after {
  content: "";
}

.fa-duotone.fa-flag-alt:after, .fa-duotone.fa-flag-swallowtail:after, .fad.fa-flag-alt:after, .fad.fa-flag-swallowtail:after {
  content: "";
}

.fa-duotone.fa-person-military-rifle:after, .fad.fa-person-military-rifle:after {
  content: "";
}

.fa-duotone.fa-car-garage:after, .fad.fa-car-garage:after {
  content: "";
}

.fa-duotone.fa-cart-shopping:after, .fa-duotone.fa-shopping-cart:after, .fad.fa-cart-shopping:after, .fad.fa-shopping-cart:after {
  content: "";
}

.fa-duotone.fa-book-font:after, .fad.fa-book-font:after {
  content: "";
}

.fa-duotone.fa-shield-plus:after, .fad.fa-shield-plus:after {
  content: "";
}

.fa-duotone.fa-vials:after, .fad.fa-vials:after {
  content: "";
}

.fa-duotone.fa-eye-dropper-full:after, .fad.fa-eye-dropper-full:after {
  content: "";
}

.fa-duotone.fa-distribute-spacing-horizontal:after, .fad.fa-distribute-spacing-horizontal:after {
  content: "";
}

.fa-duotone.fa-tablet-rugged:after, .fad.fa-tablet-rugged:after {
  content: "";
}

.fa-duotone.fa-temperature-frigid:after, .fa-duotone.fa-temperature-snow:after, .fad.fa-temperature-frigid:after, .fad.fa-temperature-snow:after {
  content: "";
}

.fa-duotone.fa-moped:after, .fad.fa-moped:after {
  content: "";
}

.fa-duotone.fa-face-smile-plus:after, .fa-duotone.fa-smile-plus:after, .fad.fa-face-smile-plus:after, .fad.fa-smile-plus:after {
  content: "";
}

.fa-duotone.fa-radio-alt:after, .fa-duotone.fa-radio-tuner:after, .fad.fa-radio-alt:after, .fad.fa-radio-tuner:after {
  content: "";
}

.fa-duotone.fa-face-swear:after, .fad.fa-face-swear:after {
  content: "";
}

.fa-duotone.fa-water-arrow-down:after, .fa-duotone.fa-water-lower:after, .fad.fa-water-arrow-down:after, .fad.fa-water-lower:after {
  content: "";
}

.fa-duotone.fa-scanner-touchscreen:after, .fad.fa-scanner-touchscreen:after {
  content: "";
}

.fa-duotone.fa-circle-7:after, .fad.fa-circle-7:after {
  content: "";
}

.fa-duotone.fa-plug-circle-plus:after, .fad.fa-plug-circle-plus:after {
  content: "";
}

.fa-duotone.fa-person-ski-jumping:after, .fa-duotone.fa-ski-jump:after, .fad.fa-person-ski-jumping:after, .fad.fa-ski-jump:after {
  content: "";
}

.fa-duotone.fa-place-of-worship:after, .fad.fa-place-of-worship:after {
  content: "";
}

.fa-duotone.fa-water-arrow-up:after, .fa-duotone.fa-water-rise:after, .fad.fa-water-arrow-up:after, .fad.fa-water-rise:after {
  content: "";
}

.fa-duotone.fa-waveform-lines:after, .fa-duotone.fa-waveform-path:after, .fad.fa-waveform-lines:after, .fad.fa-waveform-path:after {
  content: "";
}

.fa-duotone.fa-split:after, .fad.fa-split:after {
  content: "";
}

.fa-duotone.fa-film-canister:after, .fa-duotone.fa-film-cannister:after, .fad.fa-film-canister:after, .fad.fa-film-cannister:after {
  content: "";
}

.fa-duotone.fa-folder-times:after, .fa-duotone.fa-folder-xmark:after, .fad.fa-folder-times:after, .fad.fa-folder-xmark:after {
  content: "";
}

.fa-duotone.fa-toilet-paper-alt:after, .fa-duotone.fa-toilet-paper-blank:after, .fad.fa-toilet-paper-alt:after, .fad.fa-toilet-paper-blank:after {
  content: "";
}

.fa-duotone.fa-tablet-android-alt:after, .fa-duotone.fa-tablet-screen:after, .fad.fa-tablet-android-alt:after, .fad.fa-tablet-screen:after {
  content: "";
}

.fa-duotone.fa-hexagon-vertical-nft-slanted:after, .fad.fa-hexagon-vertical-nft-slanted:after {
  content: "";
}

.fa-duotone.fa-folder-music:after, .fad.fa-folder-music:after {
  content: "";
}

.fa-duotone.fa-desktop-medical:after, .fa-duotone.fa-display-medical:after, .fad.fa-desktop-medical:after, .fad.fa-display-medical:after {
  content: "";
}

.fa-duotone.fa-share-all:after, .fad.fa-share-all:after {
  content: "";
}

.fa-duotone.fa-peapod:after, .fad.fa-peapod:after {
  content: "";
}

.fa-duotone.fa-chess-clock:after, .fad.fa-chess-clock:after {
  content: "";
}

.fa-duotone.fa-axe:after, .fad.fa-axe:after {
  content: "";
}

.fa-duotone.fa-square-d:after, .fad.fa-square-d:after {
  content: "";
}

.fa-duotone.fa-grip-vertical:after, .fad.fa-grip-vertical:after {
  content: "";
}

.fa-duotone.fa-mobile-signal-out:after, .fad.fa-mobile-signal-out:after {
  content: "";
}

.fa-duotone.fa-arrow-turn-up:after, .fa-duotone.fa-level-up:after, .fad.fa-arrow-turn-up:after, .fad.fa-level-up:after {
  content: "";
}

.fa-duotone.fa-u:after, .fad.fa-u:after {
  content: "UU";
}

.fa-duotone.fa-arrow-up-from-dotted-line:after, .fad.fa-arrow-up-from-dotted-line:after {
  content: "";
}

.fa-duotone.fa-square-root-alt:after, .fa-duotone.fa-square-root-variable:after, .fad.fa-square-root-alt:after, .fad.fa-square-root-variable:after {
  content: "";
}

.fa-duotone.fa-light-switch-on:after, .fad.fa-light-switch-on:after {
  content: "";
}

.fa-duotone.fa-arrow-down-arrow-up:after, .fa-duotone.fa-sort-alt:after, .fad.fa-arrow-down-arrow-up:after, .fad.fa-sort-alt:after {
  content: "";
}

.fa-duotone.fa-raindrops:after, .fad.fa-raindrops:after {
  content: "";
}

.fa-duotone.fa-dash:after, .fa-duotone.fa-minus-large:after, .fad.fa-dash:after, .fad.fa-minus-large:after {
  content: "";
}

.fa-duotone.fa-clock-four:after, .fa-duotone.fa-clock:after, .fad.fa-clock-four:after, .fad.fa-clock:after {
  content: "";
}

.fa-duotone.fa-input-numeric:after, .fad.fa-input-numeric:after {
  content: "";
}

.fa-duotone.fa-truck-tow:after, .fad.fa-truck-tow:after {
  content: "";
}

.fa-duotone.fa-backward-step:after, .fa-duotone.fa-step-backward:after, .fad.fa-backward-step:after, .fad.fa-step-backward:after {
  content: "";
}

.fa-duotone.fa-pallet:after, .fad.fa-pallet:after {
  content: "";
}

.fa-duotone.fa-car-bolt:after, .fad.fa-car-bolt:after {
  content: "";
}

.fa-duotone.fa-arrows-maximize:after, .fa-duotone.fa-expand-arrows:after, .fad.fa-arrows-maximize:after, .fad.fa-expand-arrows:after {
  content: "";
}

.fa-duotone.fa-faucet:after, .fad.fa-faucet:after {
  content: "";
}

.fa-duotone.fa-cloud-sleet:after, .fad.fa-cloud-sleet:after {
  content: "";
}

.fa-duotone.fa-lamp-street:after, .fad.fa-lamp-street:after {
  content: "";
}

.fa-duotone.fa-list-radio:after, .fad.fa-list-radio:after {
  content: "";
}

.fa-duotone.fa-pen-nib-slash:after, .fad.fa-pen-nib-slash:after {
  content: "";
}

.fa-duotone.fa-baseball-bat-ball:after, .fad.fa-baseball-bat-ball:after {
  content: "";
}

.fa-duotone.fa-square-up-left:after, .fad.fa-square-up-left:after {
  content: "";
}

.fa-duotone.fa-overline:after, .fad.fa-overline:after {
  content: "";
}

.fa-duotone.fa-s:after, .fad.fa-s:after {
  content: "SS";
}

.fa-duotone.fa-timeline:after, .fad.fa-timeline:after {
  content: "";
}

.fa-duotone.fa-keyboard:after, .fad.fa-keyboard:after {
  content: "";
}

.fa-duotone.fa-arrows-from-dotted-line:after, .fad.fa-arrows-from-dotted-line:after {
  content: "";
}

.fa-duotone.fa-usb-drive:after, .fad.fa-usb-drive:after {
  content: "";
}

.fa-duotone.fa-ballot:after, .fad.fa-ballot:after {
  content: "";
}

.fa-duotone.fa-caret-down:after, .fad.fa-caret-down:after {
  content: "";
}

.fa-duotone.fa-location-dot-slash:after, .fa-duotone.fa-map-marker-alt-slash:after, .fad.fa-location-dot-slash:after, .fad.fa-map-marker-alt-slash:after {
  content: "";
}

.fa-duotone.fa-cards:after, .fad.fa-cards:after {
  content: "";
}

.fa-duotone.fa-clinic-medical:after, .fa-duotone.fa-house-chimney-medical:after, .fad.fa-clinic-medical:after, .fad.fa-house-chimney-medical:after {
  content: "";
}

.fa-duotone.fa-boxing-glove:after, .fa-duotone.fa-glove-boxing:after, .fad.fa-boxing-glove:after, .fad.fa-glove-boxing:after {
  content: "";
}

.fa-duotone.fa-temperature-3:after, .fa-duotone.fa-temperature-three-quarters:after, .fa-duotone.fa-thermometer-3:after, .fa-duotone.fa-thermometer-three-quarters:after, .fad.fa-temperature-3:after, .fad.fa-temperature-three-quarters:after, .fad.fa-thermometer-3:after, .fad.fa-thermometer-three-quarters:after {
  content: "";
}

.fa-duotone.fa-bell-school:after, .fad.fa-bell-school:after {
  content: "";
}

.fa-duotone.fa-mobile-android-alt:after, .fa-duotone.fa-mobile-screen:after, .fad.fa-mobile-android-alt:after, .fad.fa-mobile-screen:after {
  content: "";
}

.fa-duotone.fa-plane-up:after, .fad.fa-plane-up:after {
  content: "";
}

.fa-duotone.fa-folder-heart:after, .fad.fa-folder-heart:after {
  content: "";
}

.fa-duotone.fa-circle-location-arrow:after, .fa-duotone.fa-location-circle:after, .fad.fa-circle-location-arrow:after, .fad.fa-location-circle:after {
  content: "";
}

.fa-duotone.fa-face-head-bandage:after, .fad.fa-face-head-bandage:after {
  content: "";
}

.fa-duotone.fa-maki-roll:after, .fa-duotone.fa-makizushi:after, .fa-duotone.fa-sushi-roll:after, .fad.fa-maki-roll:after, .fad.fa-makizushi:after, .fad.fa-sushi-roll:after {
  content: "";
}

.fa-duotone.fa-car-bump:after, .fad.fa-car-bump:after {
  content: "";
}

.fa-duotone.fa-piggy-bank:after, .fad.fa-piggy-bank:after {
  content: "";
}

.fa-duotone.fa-racquet:after, .fad.fa-racquet:after {
  content: "";
}

.fa-duotone.fa-car-mirrors:after, .fad.fa-car-mirrors:after {
  content: "";
}

.fa-duotone.fa-industry-alt:after, .fa-duotone.fa-industry-windows:after, .fad.fa-industry-alt:after, .fad.fa-industry-windows:after {
  content: "";
}

.fa-duotone.fa-bolt-auto:after, .fad.fa-bolt-auto:after {
  content: "";
}

.fa-duotone.fa-battery-3:after, .fa-duotone.fa-battery-half:after, .fad.fa-battery-3:after, .fad.fa-battery-half:after {
  content: "";
}

.fa-duotone.fa-flux-capacitor:after, .fad.fa-flux-capacitor:after {
  content: "";
}

.fa-duotone.fa-mountain-city:after, .fad.fa-mountain-city:after {
  content: "";
}

.fa-duotone.fa-coins:after, .fad.fa-coins:after {
  content: "";
}

.fa-duotone.fa-honey-pot:after, .fad.fa-honey-pot:after {
  content: "";
}

.fa-duotone.fa-olive:after, .fad.fa-olive:after {
  content: "";
}

.fa-duotone.fa-khanda:after, .fad.fa-khanda:after {
  content: "";
}

.fa-duotone.fa-filter-list:after, .fad.fa-filter-list:after {
  content: "";
}

.fa-duotone.fa-outlet:after, .fad.fa-outlet:after {
  content: "";
}

.fa-duotone.fa-sliders-h:after, .fa-duotone.fa-sliders:after, .fad.fa-sliders-h:after, .fad.fa-sliders:after {
  content: "";
}

.fa-duotone.fa-cauldron:after, .fad.fa-cauldron:after {
  content: "";
}

.fa-duotone.fa-people:after, .fad.fa-people:after {
  content: "";
}

.fa-duotone.fa-folder-tree:after, .fad.fa-folder-tree:after {
  content: "";
}

.fa-duotone.fa-network-wired:after, .fad.fa-network-wired:after {
  content: "";
}

.fa-duotone.fa-croissant:after, .fad.fa-croissant:after {
  content: "";
}

.fa-duotone.fa-map-pin:after, .fad.fa-map-pin:after {
  content: "";
}

.fa-duotone.fa-hamsa:after, .fad.fa-hamsa:after {
  content: "";
}

.fa-duotone.fa-cent-sign:after, .fad.fa-cent-sign:after {
  content: "";
}

.fa-duotone.fa-swords-laser:after, .fad.fa-swords-laser:after {
  content: "";
}

.fa-duotone.fa-flask:after, .fad.fa-flask:after {
  content: "";
}

.fa-duotone.fa-person-pregnant:after, .fad.fa-person-pregnant:after {
  content: "";
}

.fa-duotone.fa-square-u:after, .fad.fa-square-u:after {
  content: "";
}

.fa-duotone.fa-wand-sparkles:after, .fad.fa-wand-sparkles:after {
  content: "";
}

.fa-duotone.fa-router:after, .fad.fa-router:after {
  content: "";
}

.fa-duotone.fa-ellipsis-v:after, .fa-duotone.fa-ellipsis-vertical:after, .fad.fa-ellipsis-v:after, .fad.fa-ellipsis-vertical:after {
  content: "";
}

.fa-duotone.fa-sword-laser-alt:after, .fad.fa-sword-laser-alt:after {
  content: "";
}

.fa-duotone.fa-ticket:after, .fad.fa-ticket:after {
  content: "";
}

.fa-duotone.fa-power-off:after, .fad.fa-power-off:after {
  content: "";
}

.fa-duotone.fa-coin:after, .fad.fa-coin:after {
  content: "";
}

.fa-duotone.fa-laptop-slash:after, .fad.fa-laptop-slash:after {
  content: "";
}

.fa-duotone.fa-long-arrow-alt-right:after, .fa-duotone.fa-right-long:after, .fad.fa-long-arrow-alt-right:after, .fad.fa-right-long:after {
  content: "";
}

.fa-duotone.fa-circle-b:after, .fad.fa-circle-b:after {
  content: "";
}

.fa-duotone.fa-person-dress-simple:after, .fad.fa-person-dress-simple:after {
  content: "";
}

.fa-duotone.fa-pipe-collar:after, .fad.fa-pipe-collar:after {
  content: "";
}

.fa-duotone.fa-lights-holiday:after, .fad.fa-lights-holiday:after {
  content: "";
}

.fa-duotone.fa-citrus:after, .fad.fa-citrus:after {
  content: "";
}

.fa-duotone.fa-flag-usa:after, .fad.fa-flag-usa:after {
  content: "";
}

.fa-duotone.fa-laptop-file:after, .fad.fa-laptop-file:after {
  content: "";
}

.fa-duotone.fa-teletype:after, .fa-duotone.fa-tty:after, .fad.fa-teletype:after, .fad.fa-tty:after {
  content: "";
}

.fa-duotone.fa-chart-tree-map:after, .fad.fa-chart-tree-map:after {
  content: "";
}

.fa-duotone.fa-diagram-next:after, .fad.fa-diagram-next:after {
  content: "";
}

.fa-duotone.fa-person-rifle:after, .fad.fa-person-rifle:after {
  content: "";
}

.fa-duotone.fa-clock-five-thirty:after, .fad.fa-clock-five-thirty:after {
  content: "";
}

.fa-duotone.fa-pipe-valve:after, .fad.fa-pipe-valve:after {
  content: "";
}

.fa-duotone.fa-arrow-up-from-arc:after, .fad.fa-arrow-up-from-arc:after {
  content: "";
}

.fa-duotone.fa-face-spiral-eyes:after, .fad.fa-face-spiral-eyes:after {
  content: "";
}

.fa-duotone.fa-compress-wide:after, .fad.fa-compress-wide:after {
  content: "";
}

.fa-duotone.fa-circle-phone-hangup:after, .fa-duotone.fa-phone-circle-down:after, .fad.fa-circle-phone-hangup:after, .fad.fa-phone-circle-down:after {
  content: "";
}

.fa-duotone.fa-gear-complex-code:after, .fad.fa-gear-complex-code:after {
  content: "";
}

.fa-duotone.fa-house-medical-circle-exclamation:after, .fad.fa-house-medical-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-badminton:after, .fad.fa-badminton:after {
  content: "";
}

.fa-duotone.fa-closed-captioning:after, .fad.fa-closed-captioning:after {
  content: "";
}

.fa-duotone.fa-hiking:after, .fa-duotone.fa-person-hiking:after, .fad.fa-hiking:after, .fad.fa-person-hiking:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-from-left:after, .fa-duotone.fa-right-from-line:after, .fad.fa-arrow-alt-from-left:after, .fad.fa-right-from-line:after {
  content: "";
}

.fa-duotone.fa-venus-double:after, .fad.fa-venus-double:after {
  content: "";
}

.fa-duotone.fa-images:after, .fad.fa-images:after {
  content: "";
}

.fa-duotone.fa-calculator:after, .fad.fa-calculator:after {
  content: "";
}

.fa-duotone.fa-shuttlecock:after, .fad.fa-shuttlecock:after {
  content: "";
}

.fa-duotone.fa-user-hair:after, .fad.fa-user-hair:after {
  content: "";
}

.fa-duotone.fa-eye-evil:after, .fad.fa-eye-evil:after {
  content: "";
}

.fa-duotone.fa-people-pulling:after, .fad.fa-people-pulling:after {
  content: "";
}

.fa-duotone.fa-n:after, .fad.fa-n:after {
  content: "NN";
}

.fa-duotone.fa-swap:after, .fad.fa-swap:after {
  content: "";
}

.fa-duotone.fa-garage:after, .fad.fa-garage:after {
  content: "";
}

.fa-duotone.fa-cable-car:after, .fa-duotone.fa-tram:after, .fad.fa-cable-car:after, .fad.fa-tram:after {
  content: "";
}

.fa-duotone.fa-shovel-snow:after, .fad.fa-shovel-snow:after {
  content: "";
}

.fa-duotone.fa-cloud-rain:after, .fad.fa-cloud-rain:after {
  content: "";
}

.fa-duotone.fa-face-lying:after, .fad.fa-face-lying:after {
  content: "";
}

.fa-duotone.fa-sprinkler:after, .fad.fa-sprinkler:after {
  content: "";
}

.fa-duotone.fa-building-circle-xmark:after, .fad.fa-building-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-person-sledding:after, .fa-duotone.fa-sledding:after, .fad.fa-person-sledding:after, .fad.fa-sledding:after {
  content: "";
}

.fa-duotone.fa-game-console-handheld:after, .fad.fa-game-console-handheld:after {
  content: "";
}

.fa-duotone.fa-ship:after, .fad.fa-ship:after {
  content: "";
}

.fa-duotone.fa-clock-six-thirty:after, .fad.fa-clock-six-thirty:after {
  content: "";
}

.fa-duotone.fa-battery-slash:after, .fad.fa-battery-slash:after {
  content: "";
}

.fa-duotone.fa-tugrik-sign:after, .fad.fa-tugrik-sign:after {
  content: "";
}

.fa-duotone.fa-arrows-down-to-line:after, .fad.fa-arrows-down-to-line:after {
  content: "";
}

.fa-duotone.fa-download:after, .fad.fa-download:after {
  content: "";
}

.fa-duotone.fa-angles-up-down:after, .fad.fa-angles-up-down:after {
  content: "";
}

.fa-duotone.fa-inventory:after, .fa-duotone.fa-shelves:after, .fad.fa-inventory:after, .fad.fa-shelves:after {
  content: "";
}

.fa-duotone.fa-cloud-snow:after, .fad.fa-cloud-snow:after {
  content: "";
}

.fa-duotone.fa-face-grin:after, .fa-duotone.fa-grin:after, .fad.fa-face-grin:after, .fad.fa-grin:after {
  content: "";
}

.fa-duotone.fa-backspace:after, .fa-duotone.fa-delete-left:after, .fad.fa-backspace:after, .fad.fa-delete-left:after {
  content: "";
}

.fa-duotone.fa-oven:after, .fad.fa-oven:after {
  content: "";
}

.fa-duotone.fa-cloud-binary:after, .fad.fa-cloud-binary:after {
  content: "";
}

.fa-duotone.fa-eye-dropper-empty:after, .fa-duotone.fa-eye-dropper:after, .fa-duotone.fa-eyedropper:after, .fad.fa-eye-dropper-empty:after, .fad.fa-eye-dropper:after, .fad.fa-eyedropper:after {
  content: "";
}

.fa-duotone.fa-comment-captions:after, .fad.fa-comment-captions:after {
  content: "";
}

.fa-duotone.fa-comments-question:after, .fad.fa-comments-question:after {
  content: "";
}

.fa-duotone.fa-scribble:after, .fad.fa-scribble:after {
  content: "";
}

.fa-duotone.fa-rotate-exclamation:after, .fad.fa-rotate-exclamation:after {
  content: "";
}

.fa-duotone.fa-file-circle-check:after, .fad.fa-file-circle-check:after {
  content: "";
}

.fa-duotone.fa-glass:after, .fad.fa-glass:after {
  content: "";
}

.fa-duotone.fa-loader:after, .fad.fa-loader:after {
  content: "";
}

.fa-duotone.fa-forward:after, .fad.fa-forward:after {
  content: "";
}

.fa-duotone.fa-user-pilot:after, .fad.fa-user-pilot:after {
  content: "";
}

.fa-duotone.fa-mobile-android:after, .fa-duotone.fa-mobile-phone:after, .fa-duotone.fa-mobile:after, .fad.fa-mobile-android:after, .fad.fa-mobile-phone:after, .fad.fa-mobile:after {
  content: "";
}

.fa-duotone.fa-code-pull-request-closed:after, .fad.fa-code-pull-request-closed:after {
  content: "";
}

.fa-duotone.fa-face-meh:after, .fa-duotone.fa-meh:after, .fad.fa-face-meh:after, .fad.fa-meh:after {
  content: "";
}

.fa-duotone.fa-align-center:after, .fad.fa-align-center:after {
  content: "";
}

.fa-duotone.fa-book-dead:after, .fa-duotone.fa-book-skull:after, .fad.fa-book-dead:after, .fad.fa-book-skull:after {
  content: "";
}

.fa-duotone.fa-drivers-license:after, .fa-duotone.fa-id-card:after, .fad.fa-drivers-license:after, .fad.fa-id-card:after {
  content: "";
}

.fa-duotone.fa-face-dotted:after, .fad.fa-face-dotted:after {
  content: "";
}

.fa-duotone.fa-face-worried:after, .fad.fa-face-worried:after {
  content: "";
}

.fa-duotone.fa-dedent:after, .fa-duotone.fa-outdent:after, .fad.fa-dedent:after, .fad.fa-outdent:after {
  content: "";
}

.fa-duotone.fa-heart-circle-exclamation:after, .fad.fa-heart-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-home-alt:after, .fa-duotone.fa-home-lg-alt:after, .fa-duotone.fa-home:after, .fa-duotone.fa-house:after, .fad.fa-home-alt:after, .fad.fa-home-lg-alt:after, .fad.fa-home:after, .fad.fa-house:after {
  content: "";
}

.fa-duotone.fa-vector-circle:after, .fad.fa-vector-circle:after {
  content: "";
}

.fa-duotone.fa-car-circle-bolt:after, .fad.fa-car-circle-bolt:after {
  content: "";
}

.fa-duotone.fa-calendar-week:after, .fad.fa-calendar-week:after {
  content: "";
}

.fa-duotone.fa-flying-disc:after, .fad.fa-flying-disc:after {
  content: "";
}

.fa-duotone.fa-laptop-medical:after, .fad.fa-laptop-medical:after {
  content: "";
}

.fa-duotone.fa-square-down-right:after, .fad.fa-square-down-right:after {
  content: "";
}

.fa-duotone.fa-b:after, .fad.fa-b:after {
  content: "BB";
}

.fa-duotone.fa-seat-airline:after, .fad.fa-seat-airline:after {
  content: "";
}

.fa-duotone.fa-eclipse-alt:after, .fa-duotone.fa-moon-over-sun:after, .fad.fa-eclipse-alt:after, .fad.fa-moon-over-sun:after {
  content: "";
}

.fa-duotone.fa-pipe:after, .fad.fa-pipe:after {
  content: "||";
}

.fa-duotone.fa-file-medical:after, .fad.fa-file-medical:after {
  content: "";
}

.fa-duotone.fa-potato:after, .fad.fa-potato:after {
  content: "";
}

.fa-duotone.fa-dice-one:after, .fad.fa-dice-one:after {
  content: "";
}

.fa-duotone.fa-circle-a:after, .fad.fa-circle-a:after {
  content: "";
}

.fa-duotone.fa-helmet-battle:after, .fad.fa-helmet-battle:after {
  content: "";
}

.fa-duotone.fa-butter:after, .fad.fa-butter:after {
  content: "";
}

.fa-duotone.fa-blanket-fire:after, .fad.fa-blanket-fire:after {
  content: "";
}

.fa-duotone.fa-kiwi-bird:after, .fad.fa-kiwi-bird:after {
  content: "";
}

.fa-duotone.fa-castle:after, .fad.fa-castle:after {
  content: "";
}

.fa-duotone.fa-golf-club:after, .fad.fa-golf-club:after {
  content: "";
}

.fa-duotone.fa-arrow-right-arrow-left:after, .fa-duotone.fa-exchange:after, .fad.fa-arrow-right-arrow-left:after, .fad.fa-exchange:after {
  content: "";
}

.fa-duotone.fa-redo-alt:after, .fa-duotone.fa-rotate-forward:after, .fa-duotone.fa-rotate-right:after, .fad.fa-redo-alt:after, .fad.fa-rotate-forward:after, .fad.fa-rotate-right:after {
  content: "";
}

.fa-duotone.fa-cutlery:after, .fa-duotone.fa-utensils:after, .fad.fa-cutlery:after, .fad.fa-utensils:after {
  content: "";
}

.fa-duotone.fa-arrow-up-wide-short:after, .fa-duotone.fa-sort-amount-up:after, .fad.fa-arrow-up-wide-short:after, .fad.fa-sort-amount-up:after {
  content: "";
}

.fa-duotone.fa-chart-pie-simple-circle-dollar:after, .fad.fa-chart-pie-simple-circle-dollar:after {
  content: "";
}

.fa-duotone.fa-balloons:after, .fad.fa-balloons:after {
  content: "";
}

.fa-duotone.fa-mill-sign:after, .fad.fa-mill-sign:after {
  content: "";
}

.fa-duotone.fa-bowl-rice:after, .fad.fa-bowl-rice:after {
  content: "";
}

.fa-duotone.fa-timeline-arrow:after, .fad.fa-timeline-arrow:after {
  content: "";
}

.fa-duotone.fa-skull:after, .fad.fa-skull:after {
  content: "";
}

.fa-duotone.fa-game-board-alt:after, .fa-duotone.fa-game-board-simple:after, .fad.fa-game-board-alt:after, .fad.fa-game-board-simple:after {
  content: "";
}

.fa-duotone.fa-circle-video:after, .fa-duotone.fa-video-circle:after, .fad.fa-circle-video:after, .fad.fa-video-circle:after {
  content: "";
}

.fa-duotone.fa-chart-scatter-bubble:after, .fad.fa-chart-scatter-bubble:after {
  content: "";
}

.fa-duotone.fa-house-turret:after, .fad.fa-house-turret:after {
  content: "";
}

.fa-duotone.fa-banana:after, .fad.fa-banana:after {
  content: "";
}

.fa-duotone.fa-hand-holding-skull:after, .fad.fa-hand-holding-skull:after {
  content: "";
}

.fa-duotone.fa-people-dress:after, .fad.fa-people-dress:after {
  content: "";
}

.fa-duotone.fa-couch-small:after, .fa-duotone.fa-loveseat:after, .fad.fa-couch-small:after, .fad.fa-loveseat:after {
  content: "";
}

.fa-duotone.fa-broadcast-tower:after, .fa-duotone.fa-tower-broadcast:after, .fad.fa-broadcast-tower:after, .fad.fa-tower-broadcast:after {
  content: "";
}

.fa-duotone.fa-truck-pickup:after, .fad.fa-truck-pickup:after {
  content: "";
}

.fa-duotone.fa-block-quote:after, .fad.fa-block-quote:after {
  content: "";
}

.fa-duotone.fa-long-arrow-alt-up:after, .fa-duotone.fa-up-long:after, .fad.fa-long-arrow-alt-up:after, .fad.fa-up-long:after {
  content: "";
}

.fa-duotone.fa-stop:after, .fad.fa-stop:after {
  content: "";
}

.fa-duotone.fa-code-merge:after, .fad.fa-code-merge:after {
  content: "";
}

.fa-duotone.fa-money-check-dollar-pen:after, .fa-duotone.fa-money-check-edit-alt:after, .fad.fa-money-check-dollar-pen:after, .fad.fa-money-check-edit-alt:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-from-bottom:after, .fa-duotone.fa-up-from-line:after, .fad.fa-arrow-alt-from-bottom:after, .fad.fa-up-from-line:after {
  content: "";
}

.fa-duotone.fa-upload:after, .fad.fa-upload:after {
  content: "";
}

.fa-duotone.fa-hurricane:after, .fad.fa-hurricane:after {
  content: "";
}

.fa-duotone.fa-grid-round-2-plus:after, .fad.fa-grid-round-2-plus:after {
  content: "";
}

.fa-duotone.fa-people-pants:after, .fad.fa-people-pants:after {
  content: "";
}

.fa-duotone.fa-mound:after, .fad.fa-mound:after {
  content: "";
}

.fa-duotone.fa-windsock:after, .fad.fa-windsock:after {
  content: "";
}

.fa-duotone.fa-circle-half:after, .fad.fa-circle-half:after {
  content: "";
}

.fa-duotone.fa-brake-warning:after, .fad.fa-brake-warning:after {
  content: "";
}

.fa-duotone.fa-toilet-portable:after, .fad.fa-toilet-portable:after {
  content: "";
}

.fa-duotone.fa-compact-disc:after, .fad.fa-compact-disc:after {
  content: "";
}

.fa-duotone.fa-file-arrow-down:after, .fa-duotone.fa-file-download:after, .fad.fa-file-arrow-down:after, .fad.fa-file-download:after {
  content: "";
}

.fa-duotone.fa-sax-hot:after, .fa-duotone.fa-saxophone-fire:after, .fad.fa-sax-hot:after, .fad.fa-saxophone-fire:after {
  content: "";
}

.fa-duotone.fa-camera-web-slash:after, .fa-duotone.fa-webcam-slash:after, .fad.fa-camera-web-slash:after, .fad.fa-webcam-slash:after {
  content: "";
}

.fa-duotone.fa-folder-medical:after, .fad.fa-folder-medical:after {
  content: "";
}

.fa-duotone.fa-folder-cog:after, .fa-duotone.fa-folder-gear:after, .fad.fa-folder-cog:after, .fad.fa-folder-gear:after {
  content: "";
}

.fa-duotone.fa-hand-wave:after, .fad.fa-hand-wave:after {
  content: "";
}

.fa-duotone.fa-arrow-up-arrow-down:after, .fa-duotone.fa-sort-up-down:after, .fad.fa-arrow-up-arrow-down:after, .fad.fa-sort-up-down:after {
  content: "";
}

.fa-duotone.fa-caravan:after, .fad.fa-caravan:after {
  content: "";
}

.fa-duotone.fa-shield-cat:after, .fad.fa-shield-cat:after {
  content: "";
}

.fa-duotone.fa-comment-alt-slash:after, .fa-duotone.fa-message-slash:after, .fad.fa-comment-alt-slash:after, .fad.fa-message-slash:after {
  content: "";
}

.fa-duotone.fa-bolt:after, .fa-duotone.fa-zap:after, .fad.fa-bolt:after, .fad.fa-zap:after {
  content: "";
}

.fa-duotone.fa-trash-can-check:after, .fad.fa-trash-can-check:after {
  content: "";
}

.fa-duotone.fa-glass-water:after, .fad.fa-glass-water:after {
  content: "";
}

.fa-duotone.fa-oil-well:after, .fad.fa-oil-well:after {
  content: "";
}

.fa-duotone.fa-person-simple:after, .fad.fa-person-simple:after {
  content: "";
}

.fa-duotone.fa-vault:after, .fad.fa-vault:after {
  content: "";
}

.fa-duotone.fa-mars:after, .fad.fa-mars:after {
  content: "";
}

.fa-duotone.fa-toilet:after, .fad.fa-toilet:after {
  content: "";
}

.fa-duotone.fa-plane-circle-xmark:after, .fad.fa-plane-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-cny:after, .fa-duotone.fa-jpy:after, .fa-duotone.fa-rmb:after, .fa-duotone.fa-yen-sign:after, .fa-duotone.fa-yen:after, .fad.fa-cny:after, .fad.fa-jpy:after, .fad.fa-rmb:after, .fad.fa-yen-sign:after, .fad.fa-yen:after {
  content: "";
}

.fa-duotone.fa-gear-code:after, .fad.fa-gear-code:after {
  content: "";
}

.fa-duotone.fa-notes:after, .fad.fa-notes:after {
  content: "";
}

.fa-duotone.fa-rouble:after, .fa-duotone.fa-rub:after, .fa-duotone.fa-ruble-sign:after, .fa-duotone.fa-ruble:after, .fad.fa-rouble:after, .fad.fa-rub:after, .fad.fa-ruble-sign:after, .fad.fa-ruble:after {
  content: "";
}

.fa-duotone.fa-trash-arrow-turn-left:after, .fa-duotone.fa-trash-undo:after, .fad.fa-trash-arrow-turn-left:after, .fad.fa-trash-undo:after {
  content: "";
}

.fa-duotone.fa-champagne-glass:after, .fa-duotone.fa-glass-champagne:after, .fad.fa-champagne-glass:after, .fad.fa-glass-champagne:after {
  content: "";
}

.fa-duotone.fa-objects-align-center-horizontal:after, .fad.fa-objects-align-center-horizontal:after {
  content: "";
}

.fa-duotone.fa-sun:after, .fad.fa-sun:after {
  content: "";
}

.fa-duotone.fa-trash-alt-slash:after, .fa-duotone.fa-trash-can-slash:after, .fad.fa-trash-alt-slash:after, .fad.fa-trash-can-slash:after {
  content: "";
}

.fa-duotone.fa-screen-users:after, .fa-duotone.fa-users-class:after, .fad.fa-screen-users:after, .fad.fa-users-class:after {
  content: "";
}

.fa-duotone.fa-guitar:after, .fad.fa-guitar:after {
  content: "";
}

.fa-duotone.fa-arrow-square-left:after, .fa-duotone.fa-square-arrow-left:after, .fad.fa-arrow-square-left:after, .fad.fa-square-arrow-left:after {
  content: "";
}

.fa-duotone.fa-square-8:after, .fad.fa-square-8:after {
  content: "";
}

.fa-duotone.fa-face-smile-hearts:after, .fad.fa-face-smile-hearts:after {
  content: "";
}

.fa-duotone.fa-brackets-square:after, .fa-duotone.fa-brackets:after, .fad.fa-brackets-square:after, .fad.fa-brackets:after {
  content: "";
}

.fa-duotone.fa-laptop-arrow-down:after, .fad.fa-laptop-arrow-down:after {
  content: "";
}

.fa-duotone.fa-hockey-stick-puck:after, .fad.fa-hockey-stick-puck:after {
  content: "";
}

.fa-duotone.fa-house-tree:after, .fad.fa-house-tree:after {
  content: "";
}

.fa-duotone.fa-signal-2:after, .fa-duotone.fa-signal-fair:after, .fad.fa-signal-2:after, .fad.fa-signal-fair:after {
  content: "";
}

.fa-duotone.fa-face-laugh-wink:after, .fa-duotone.fa-laugh-wink:after, .fad.fa-face-laugh-wink:after, .fad.fa-laugh-wink:after {
  content: "";
}

.fa-duotone.fa-circle-dollar:after, .fa-duotone.fa-dollar-circle:after, .fa-duotone.fa-usd-circle:after, .fad.fa-circle-dollar:after, .fad.fa-dollar-circle:after, .fad.fa-usd-circle:after {
  content: "";
}

.fa-duotone.fa-horse-head:after, .fad.fa-horse-head:after {
  content: "";
}

.fa-duotone.fa-arrows-repeat:after, .fa-duotone.fa-repeat-alt:after, .fad.fa-arrows-repeat:after, .fad.fa-repeat-alt:after {
  content: "";
}

.fa-duotone.fa-bore-hole:after, .fad.fa-bore-hole:after {
  content: "";
}

.fa-duotone.fa-industry:after, .fad.fa-industry:after {
  content: "";
}

.fa-duotone.fa-image-polaroid:after, .fad.fa-image-polaroid:after {
  content: "";
}

.fa-duotone.fa-wave-triangle:after, .fad.fa-wave-triangle:after {
  content: "";
}

.fa-duotone.fa-person-running-fast:after, .fad.fa-person-running-fast:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-circle-down:after, .fa-duotone.fa-circle-down:after, .fad.fa-arrow-alt-circle-down:after, .fad.fa-circle-down:after {
  content: "";
}

.fa-duotone.fa-grill:after, .fad.fa-grill:after {
  content: "";
}

.fa-duotone.fa-arrows-turn-to-dots:after, .fad.fa-arrows-turn-to-dots:after {
  content: "";
}

.fa-duotone.fa-analytics:after, .fa-duotone.fa-chart-mixed:after, .fad.fa-analytics:after, .fad.fa-chart-mixed:after {
  content: "";
}

.fa-duotone.fa-florin-sign:after, .fad.fa-florin-sign:after {
  content: "";
}

.fa-duotone.fa-arrow-down-short-wide:after, .fa-duotone.fa-sort-amount-desc:after, .fa-duotone.fa-sort-amount-down-alt:after, .fad.fa-arrow-down-short-wide:after, .fad.fa-sort-amount-desc:after, .fad.fa-sort-amount-down-alt:after {
  content: "";
}

.fa-duotone.fa-less-than:after, .fad.fa-less-than:after {
  content: "<<";
}

.fa-duotone.fa-desktop-code:after, .fa-duotone.fa-display-code:after, .fad.fa-desktop-code:after, .fad.fa-display-code:after {
  content: "";
}

.fa-duotone.fa-face-drooling:after, .fad.fa-face-drooling:after {
  content: "";
}

.fa-duotone.fa-oil-temp:after, .fa-duotone.fa-oil-temperature:after, .fad.fa-oil-temp:after, .fad.fa-oil-temperature:after {
  content: "";
}

.fa-duotone.fa-question-square:after, .fa-duotone.fa-square-question:after, .fad.fa-question-square:after, .fad.fa-square-question:after {
  content: "";
}

.fa-duotone.fa-air-conditioner:after, .fad.fa-air-conditioner:after {
  content: "";
}

.fa-duotone.fa-angle-down:after, .fad.fa-angle-down:after {
  content: "";
}

.fa-duotone.fa-mountains:after, .fad.fa-mountains:after {
  content: "";
}

.fa-duotone.fa-omega:after, .fad.fa-omega:after {
  content: "";
}

.fa-duotone.fa-car-tunnel:after, .fad.fa-car-tunnel:after {
  content: "";
}

.fa-duotone.fa-person-dolly-empty:after, .fad.fa-person-dolly-empty:after {
  content: "";
}

.fa-duotone.fa-pan-food:after, .fad.fa-pan-food:after {
  content: "";
}

.fa-duotone.fa-head-side-cough:after, .fad.fa-head-side-cough:after {
  content: "";
}

.fa-duotone.fa-grip-lines:after, .fad.fa-grip-lines:after {
  content: "";
}

.fa-duotone.fa-thumbs-down:after, .fad.fa-thumbs-down:after {
  content: "";
}

.fa-duotone.fa-user-lock:after, .fad.fa-user-lock:after {
  content: "";
}

.fa-duotone.fa-arrow-right-long:after, .fa-duotone.fa-long-arrow-right:after, .fad.fa-arrow-right-long:after, .fad.fa-long-arrow-right:after {
  content: "";
}

.fa-duotone.fa-tickets-airline:after, .fad.fa-tickets-airline:after {
  content: "";
}

.fa-duotone.fa-anchor-circle-xmark:after, .fad.fa-anchor-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-ellipsis-h:after, .fa-duotone.fa-ellipsis:after, .fad.fa-ellipsis-h:after, .fad.fa-ellipsis:after {
  content: "";
}

.fa-duotone.fa-nfc-slash:after, .fad.fa-nfc-slash:after {
  content: "";
}

.fa-duotone.fa-chess-pawn:after, .fad.fa-chess-pawn:after {
  content: "";
}

.fa-duotone.fa-first-aid:after, .fa-duotone.fa-kit-medical:after, .fad.fa-first-aid:after, .fad.fa-kit-medical:after {
  content: "";
}

.fa-duotone.fa-grid-2-plus:after, .fad.fa-grid-2-plus:after {
  content: "";
}

.fa-duotone.fa-bells:after, .fad.fa-bells:after {
  content: "";
}

.fa-duotone.fa-person-through-window:after, .fad.fa-person-through-window:after {
  content: "";
}

.fa-duotone.fa-toolbox:after, .fad.fa-toolbox:after {
  content: "";
}

.fa-duotone.fa-envelope-badge:after, .fa-duotone.fa-envelope-dot:after, .fad.fa-envelope-badge:after, .fad.fa-envelope-dot:after {
  content: "";
}

.fa-duotone.fa-hands-holding-circle:after, .fad.fa-hands-holding-circle:after {
  content: "";
}

.fa-duotone.fa-bug:after, .fad.fa-bug:after {
  content: "";
}

.fa-duotone.fa-bowl-chopsticks:after, .fad.fa-bowl-chopsticks:after {
  content: "";
}

.fa-duotone.fa-credit-card-alt:after, .fa-duotone.fa-credit-card:after, .fad.fa-credit-card-alt:after, .fad.fa-credit-card:after {
  content: "";
}

.fa-duotone.fa-circle-s:after, .fad.fa-circle-s:after {
  content: "";
}

.fa-duotone.fa-box-ballot:after, .fad.fa-box-ballot:after {
  content: "";
}

.fa-duotone.fa-automobile:after, .fa-duotone.fa-car:after, .fad.fa-automobile:after, .fad.fa-car:after {
  content: "";
}

.fa-duotone.fa-hand-holding-hand:after, .fad.fa-hand-holding-hand:after {
  content: "";
}

.fa-duotone.fa-user-tie-hair:after, .fad.fa-user-tie-hair:after {
  content: "";
}

.fa-duotone.fa-podium-star:after, .fad.fa-podium-star:after {
  content: "";
}

.fa-duotone.fa-business-front:after, .fa-duotone.fa-party-back:after, .fa-duotone.fa-trian-balbot:after, .fa-duotone.fa-user-hair-mullet:after, .fad.fa-business-front:after, .fad.fa-party-back:after, .fad.fa-trian-balbot:after, .fad.fa-user-hair-mullet:after {
  content: "";
}

.fa-duotone.fa-microphone-stand:after, .fad.fa-microphone-stand:after {
  content: "";
}

.fa-duotone.fa-book-open-reader:after, .fa-duotone.fa-book-reader:after, .fad.fa-book-open-reader:after, .fad.fa-book-reader:after {
  content: "";
}

.fa-duotone.fa-family-dress:after, .fad.fa-family-dress:after {
  content: "";
}

.fa-duotone.fa-circle-x:after, .fad.fa-circle-x:after {
  content: "";
}

.fa-duotone.fa-cabin:after, .fad.fa-cabin:after {
  content: "";
}

.fa-duotone.fa-mountain-sun:after, .fad.fa-mountain-sun:after {
  content: "";
}

.fa-duotone.fa-chart-simple-horizontal:after, .fad.fa-chart-simple-horizontal:after {
  content: "";
}

.fa-duotone.fa-arrows-left-right-to-line:after, .fad.fa-arrows-left-right-to-line:after {
  content: "";
}

.fa-duotone.fa-hand-back-point-left:after, .fad.fa-hand-back-point-left:after {
  content: "";
}

.fa-duotone.fa-comment-alt-dots:after, .fa-duotone.fa-message-dots:after, .fa-duotone.fa-messaging:after, .fad.fa-comment-alt-dots:after, .fad.fa-message-dots:after, .fad.fa-messaging:after {
  content: "";
}

.fa-duotone.fa-file-heart:after, .fad.fa-file-heart:after {
  content: "";
}

.fa-duotone.fa-beer-foam:after, .fa-duotone.fa-beer-mug:after, .fad.fa-beer-foam:after, .fad.fa-beer-mug:after {
  content: "";
}

.fa-duotone.fa-dice-d20:after, .fad.fa-dice-d20:after {
  content: "";
}

.fa-duotone.fa-drone:after, .fad.fa-drone:after {
  content: "";
}

.fa-duotone.fa-truck-droplet:after, .fad.fa-truck-droplet:after {
  content: "";
}

.fa-duotone.fa-file-circle-xmark:after, .fad.fa-file-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-temperature-arrow-up:after, .fa-duotone.fa-temperature-up:after, .fad.fa-temperature-arrow-up:after, .fad.fa-temperature-up:after {
  content: "";
}

.fa-duotone.fa-medal:after, .fad.fa-medal:after {
  content: "";
}

.fa-duotone.fa-person-fairy:after, .fad.fa-person-fairy:after {
  content: "";
}

.fa-duotone.fa-bed:after, .fad.fa-bed:after {
  content: "";
}

.fa-duotone.fa-book-copy:after, .fad.fa-book-copy:after {
  content: "";
}

.fa-duotone.fa-h-square:after, .fa-duotone.fa-square-h:after, .fad.fa-h-square:after, .fad.fa-square-h:after {
  content: "";
}

.fa-duotone.fa-square-c:after, .fad.fa-square-c:after {
  content: "";
}

.fa-duotone.fa-clock-two:after, .fad.fa-clock-two:after {
  content: "";
}

.fa-duotone.fa-square-ellipsis-vertical:after, .fad.fa-square-ellipsis-vertical:after {
  content: "";
}

.fa-duotone.fa-calendar-users:after, .fad.fa-calendar-users:after {
  content: "";
}

.fa-duotone.fa-podcast:after, .fad.fa-podcast:after {
  content: "";
}

.fa-duotone.fa-bee:after, .fad.fa-bee:after {
  content: "";
}

.fa-duotone.fa-temperature-4:after, .fa-duotone.fa-temperature-full:after, .fa-duotone.fa-thermometer-4:after, .fa-duotone.fa-thermometer-full:after, .fad.fa-temperature-4:after, .fad.fa-temperature-full:after, .fad.fa-thermometer-4:after, .fad.fa-thermometer-full:after {
  content: "";
}

.fa-duotone.fa-bell:after, .fad.fa-bell:after {
  content: "";
}

.fa-duotone.fa-candy-bar:after, .fa-duotone.fa-chocolate-bar:after, .fad.fa-candy-bar:after, .fad.fa-chocolate-bar:after {
  content: "";
}

.fa-duotone.fa-xmark-large:after, .fad.fa-xmark-large:after {
  content: "";
}

.fa-duotone.fa-pinata:after, .fad.fa-pinata:after {
  content: "";
}

.fa-duotone.fa-arrows-from-line:after, .fad.fa-arrows-from-line:after {
  content: "";
}

.fa-duotone.fa-superscript:after, .fad.fa-superscript:after {
  content: "";
}

.fa-duotone.fa-bowl-spoon:after, .fad.fa-bowl-spoon:after {
  content: "";
}

.fa-duotone.fa-hexagon-check:after, .fad.fa-hexagon-check:after {
  content: "";
}

.fa-duotone.fa-plug-circle-xmark:after, .fad.fa-plug-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-star-of-life:after, .fad.fa-star-of-life:after {
  content: "";
}

.fa-duotone.fa-phone-slash:after, .fad.fa-phone-slash:after {
  content: "";
}

.fa-duotone.fa-traffic-light-stop:after, .fad.fa-traffic-light-stop:after {
  content: "";
}

.fa-duotone.fa-paint-roller:after, .fad.fa-paint-roller:after {
  content: "";
}

.fa-duotone.fa-accent-grave:after, .fad.fa-accent-grave:after {
  content: "``";
}

.fa-duotone.fa-hands-helping:after, .fa-duotone.fa-handshake-angle:after, .fad.fa-hands-helping:after, .fad.fa-handshake-angle:after {
  content: "";
}

.fa-duotone.fa-circle-0:after, .fad.fa-circle-0:after {
  content: "";
}

.fa-duotone.fa-dial-med-low:after, .fad.fa-dial-med-low:after {
  content: "";
}

.fa-duotone.fa-location-dot:after, .fa-duotone.fa-map-marker-alt:after, .fad.fa-location-dot:after, .fad.fa-map-marker-alt:after {
  content: "";
}

.fa-duotone.fa-crab:after, .fad.fa-crab:after {
  content: "";
}

.fa-duotone.fa-box-full:after, .fa-duotone.fa-box-open-full:after, .fad.fa-box-full:after, .fad.fa-box-open-full:after {
  content: "";
}

.fa-duotone.fa-file:after, .fad.fa-file:after {
  content: "";
}

.fa-duotone.fa-greater-than:after, .fad.fa-greater-than:after {
  content: ">>";
}

.fa-duotone.fa-quotes:after, .fad.fa-quotes:after {
  content: "";
}

.fa-duotone.fa-pretzel:after, .fad.fa-pretzel:after {
  content: "";
}

.fa-duotone.fa-person-swimming:after, .fa-duotone.fa-swimmer:after, .fad.fa-person-swimming:after, .fad.fa-swimmer:after {
  content: "";
}

.fa-duotone.fa-arrow-down:after, .fad.fa-arrow-down:after {
  content: "";
}

.fa-duotone.fa-user-robot-xmarks:after, .fad.fa-user-robot-xmarks:after {
  content: "";
}

.fa-duotone.fa-comment-alt-quote:after, .fa-duotone.fa-message-quote:after, .fad.fa-comment-alt-quote:after, .fad.fa-message-quote:after {
  content: "";
}

.fa-duotone.fa-candy-corn:after, .fad.fa-candy-corn:after {
  content: "";
}

.fa-duotone.fa-folder-magnifying-glass:after, .fa-duotone.fa-folder-search:after, .fad.fa-folder-magnifying-glass:after, .fad.fa-folder-search:after {
  content: "";
}

.fa-duotone.fa-notebook:after, .fad.fa-notebook:after {
  content: "";
}

.fa-duotone.fa-droplet:after, .fa-duotone.fa-tint:after, .fad.fa-droplet:after, .fad.fa-tint:after {
  content: "";
}

.fa-duotone.fa-bullseye-pointer:after, .fad.fa-bullseye-pointer:after {
  content: "";
}

.fa-duotone.fa-eraser:after, .fad.fa-eraser:after {
  content: "";
}

.fa-duotone.fa-hexagon-image:after, .fad.fa-hexagon-image:after {
  content: "";
}

.fa-duotone.fa-earth-america:after, .fa-duotone.fa-earth-americas:after, .fa-duotone.fa-earth:after, .fa-duotone.fa-globe-americas:after, .fad.fa-earth-america:after, .fad.fa-earth-americas:after, .fad.fa-earth:after, .fad.fa-globe-americas:after {
  content: "";
}

.fa-duotone.fa-crate-apple:after, .fad.fa-crate-apple:after, .fa-duotone.fa-apple-crate:after, .fad.fa-apple-crate:after {
  content: "";
}

.fa-duotone.fa-person-burst:after, .fad.fa-person-burst:after {
  content: "";
}

.fa-duotone.fa-game-board:after, .fad.fa-game-board:after {
  content: "";
}

.fa-duotone.fa-hat-chef:after, .fad.fa-hat-chef:after {
  content: "";
}

.fa-duotone.fa-hand-back-point-right:after, .fad.fa-hand-back-point-right:after {
  content: "";
}

.fa-duotone.fa-dove:after, .fad.fa-dove:after {
  content: "";
}

.fa-duotone.fa-snowflake-droplets:after, .fad.fa-snowflake-droplets:after {
  content: "";
}

.fa-duotone.fa-battery-0:after, .fa-duotone.fa-battery-empty:after, .fad.fa-battery-0:after, .fad.fa-battery-empty:after {
  content: "";
}

.fa-duotone.fa-grid-4:after, .fad.fa-grid-4:after {
  content: "";
}

.fa-duotone.fa-socks:after, .fad.fa-socks:after {
  content: "";
}

.fa-duotone.fa-face-sunglasses:after, .fad.fa-face-sunglasses:after {
  content: "";
}

.fa-duotone.fa-inbox:after, .fad.fa-inbox:after {
  content: "";
}

.fa-duotone.fa-square-0:after, .fad.fa-square-0:after {
  content: "";
}

.fa-duotone.fa-section:after, .fad.fa-section:after {
  content: "";
}

.fa-duotone.fa-box-up:after, .fa-duotone.fa-square-this-way-up:after, .fad.fa-box-up:after, .fad.fa-square-this-way-up:after {
  content: "";
}

.fa-duotone.fa-gauge-high:after, .fa-duotone.fa-tachometer-alt-fast:after, .fa-duotone.fa-tachometer-alt:after, .fad.fa-gauge-high:after, .fad.fa-tachometer-alt-fast:after, .fad.fa-tachometer-alt:after {
  content: "";
}

.fa-duotone.fa-square-ampersand:after, .fad.fa-square-ampersand:after {
  content: "";
}

.fa-duotone.fa-envelope-open-text:after, .fad.fa-envelope-open-text:after {
  content: "";
}

.fa-duotone.fa-lamp-desk:after, .fad.fa-lamp-desk:after {
  content: "";
}

.fa-duotone.fa-hospital-alt:after, .fa-duotone.fa-hospital-wide:after, .fa-duotone.fa-hospital:after, .fad.fa-hospital-alt:after, .fad.fa-hospital-wide:after, .fad.fa-hospital:after {
  content: "";
}

.fa-duotone.fa-poll-people:after, .fad.fa-poll-people:after {
  content: "";
}

.fa-duotone.fa-glass-whiskey-rocks:after, .fa-duotone.fa-whiskey-glass-ice:after, .fad.fa-glass-whiskey-rocks:after, .fad.fa-whiskey-glass-ice:after {
  content: "";
}

.fa-duotone.fa-wine-bottle:after, .fad.fa-wine-bottle:after {
  content: "";
}

.fa-duotone.fa-chess-rook:after, .fad.fa-chess-rook:after {
  content: "";
}

.fa-duotone.fa-user-bounty-hunter:after, .fad.fa-user-bounty-hunter:after {
  content: "";
}

.fa-duotone.fa-bars-staggered:after, .fa-duotone.fa-reorder:after, .fa-duotone.fa-stream:after, .fad.fa-bars-staggered:after, .fad.fa-reorder:after, .fad.fa-stream:after {
  content: "";
}

.fa-duotone.fa-diagram-sankey:after, .fad.fa-diagram-sankey:after {
  content: "";
}

.fa-duotone.fa-cloud-hail-mixed:after, .fad.fa-cloud-hail-mixed:after {
  content: "";
}

.fa-duotone.fa-circle-up-left:after, .fad.fa-circle-up-left:after {
  content: "";
}

.fa-duotone.fa-dharmachakra:after, .fad.fa-dharmachakra:after {
  content: "";
}

.fa-duotone.fa-objects-align-left:after, .fad.fa-objects-align-left:after {
  content: "";
}

.fa-duotone.fa-oil-can-drip:after, .fad.fa-oil-can-drip:after {
  content: "";
}

.fa-duotone.fa-face-smiling-hands:after, .fad.fa-face-smiling-hands:after {
  content: "";
}

.fa-duotone.fa-broccoli:after, .fad.fa-broccoli:after {
  content: "";
}

.fa-duotone.fa-route-interstate:after, .fad.fa-route-interstate:after {
  content: "";
}

.fa-duotone.fa-ear-muffs:after, .fad.fa-ear-muffs:after {
  content: "";
}

.fa-duotone.fa-hotdog:after, .fad.fa-hotdog:after {
  content: "";
}

.fa-duotone.fa-transporter-empty:after, .fad.fa-transporter-empty:after {
  content: "";
}

.fa-duotone.fa-blind:after, .fa-duotone.fa-person-walking-with-cane:after, .fad.fa-blind:after, .fad.fa-person-walking-with-cane:after {
  content: "";
}

.fa-duotone.fa-angle-90:after, .fad.fa-angle-90:after {
  content: "";
}

.fa-duotone.fa-rectangle-terminal:after, .fad.fa-rectangle-terminal:after {
  content: "";
}

.fa-duotone.fa-kite:after, .fad.fa-kite:after {
  content: "";
}

.fa-duotone.fa-drum:after, .fad.fa-drum:after {
  content: "";
}

.fa-duotone.fa-scrubber:after, .fad.fa-scrubber:after {
  content: "";
}

.fa-duotone.fa-ice-cream:after, .fad.fa-ice-cream:after {
  content: "";
}

.fa-duotone.fa-heart-circle-bolt:after, .fad.fa-heart-circle-bolt:after {
  content: "";
}

.fa-duotone.fa-fish-bones:after, .fad.fa-fish-bones:after {
  content: "";
}

.fa-duotone.fa-deer-rudolph:after, .fad.fa-deer-rudolph:after {
  content: "";
}

.fa-duotone.fa-fax:after, .fad.fa-fax:after {
  content: "";
}

.fa-duotone.fa-paragraph:after, .fad.fa-paragraph:after {
  content: "";
}

.fa-duotone.fa-head-side-heart:after, .fad.fa-head-side-heart:after {
  content: "";
}

.fa-duotone.fa-square-e:after, .fad.fa-square-e:after {
  content: "";
}

.fa-duotone.fa-meter-fire:after, .fad.fa-meter-fire:after {
  content: "";
}

.fa-duotone.fa-cloud-hail:after, .fad.fa-cloud-hail:after {
  content: "";
}

.fa-duotone.fa-check-to-slot:after, .fa-duotone.fa-vote-yea:after, .fad.fa-check-to-slot:after, .fad.fa-vote-yea:after {
  content: "";
}

.fa-duotone.fa-money-from-bracket:after, .fad.fa-money-from-bracket:after {
  content: "";
}

.fa-duotone.fa-star-half:after, .fad.fa-star-half:after {
  content: "";
}

.fa-duotone.fa-car-bus:after, .fad.fa-car-bus:after {
  content: "";
}

.fa-duotone.fa-speaker:after, .fad.fa-speaker:after {
  content: "";
}

.fa-duotone.fa-timer:after, .fad.fa-timer:after {
  content: "";
}

.fa-duotone.fa-boxes-alt:after, .fa-duotone.fa-boxes-stacked:after, .fa-duotone.fa-boxes:after, .fad.fa-boxes-alt:after, .fad.fa-boxes-stacked:after, .fad.fa-boxes:after {
  content: "";
}

.fa-duotone.fa-grill-hot:after, .fad.fa-grill-hot:after {
  content: "";
}

.fa-duotone.fa-ballot-check:after, .fad.fa-ballot-check:after {
  content: "";
}

.fa-duotone.fa-chain:after, .fa-duotone.fa-link:after, .fad.fa-chain:after, .fad.fa-link:after {
  content: "";
}

.fa-duotone.fa-assistive-listening-systems:after, .fa-duotone.fa-ear-listen:after, .fad.fa-assistive-listening-systems:after, .fad.fa-ear-listen:after {
  content: "";
}

.fa-duotone.fa-file-minus:after, .fad.fa-file-minus:after {
  content: "";
}

.fa-duotone.fa-tree-city:after, .fad.fa-tree-city:after {
  content: "";
}

.fa-duotone.fa-play:after, .fad.fa-play:after {
  content: "";
}

.fa-duotone.fa-font:after, .fad.fa-font:after {
  content: "";
}

.fa-duotone.fa-coffee-togo:after, .fa-duotone.fa-cup-togo:after, .fad.fa-coffee-togo:after, .fad.fa-cup-togo:after {
  content: "";
}

.fa-duotone.fa-square-down-left:after, .fad.fa-square-down-left:after {
  content: "";
}

.fa-duotone.fa-burger-lettuce:after, .fad.fa-burger-lettuce:after {
  content: "";
}

.fa-duotone.fa-rupiah-sign:after, .fad.fa-rupiah-sign:after {
  content: "";
}

.fa-duotone.fa-magnifying-glass:after, .fa-duotone.fa-search:after, .fad.fa-magnifying-glass:after, .fad.fa-search:after {
  content: "";
}

.fa-duotone.fa-ping-pong-paddle-ball:after, .fa-duotone.fa-table-tennis-paddle-ball:after, .fa-duotone.fa-table-tennis:after, .fad.fa-ping-pong-paddle-ball:after, .fad.fa-table-tennis-paddle-ball:after, .fad.fa-table-tennis:after {
  content: "";
}

.fa-duotone.fa-diagnoses:after, .fa-duotone.fa-person-dots-from-line:after, .fad.fa-diagnoses:after, .fad.fa-person-dots-from-line:after {
  content: "";
}

.fa-duotone.fa-chevron-double-down:after, .fa-duotone.fa-chevrons-down:after, .fad.fa-chevron-double-down:after, .fad.fa-chevrons-down:after {
  content: "";
}

.fa-duotone.fa-trash-can-arrow-up:after, .fa-duotone.fa-trash-restore-alt:after, .fad.fa-trash-can-arrow-up:after, .fad.fa-trash-restore-alt:after {
  content: "";
}

.fa-duotone.fa-signal-3:after, .fa-duotone.fa-signal-good:after, .fad.fa-signal-3:after, .fad.fa-signal-good:after {
  content: "";
}

.fa-duotone.fa-location-question:after, .fa-duotone.fa-map-marker-question:after, .fad.fa-location-question:after, .fad.fa-map-marker-question:after {
  content: "";
}

.fa-duotone.fa-floppy-disk-circle-xmark:after, .fa-duotone.fa-floppy-disk-times:after, .fa-duotone.fa-save-circle-xmark:after, .fa-duotone.fa-save-times:after, .fad.fa-floppy-disk-circle-xmark:after, .fad.fa-floppy-disk-times:after, .fad.fa-save-circle-xmark:after, .fad.fa-save-times:after {
  content: "";
}

.fa-duotone.fa-naira-sign:after, .fad.fa-naira-sign:after {
  content: "";
}

.fa-duotone.fa-peach:after, .fad.fa-peach:after {
  content: "";
}

.fa-duotone.fa-taxi-bus:after, .fad.fa-taxi-bus:after {
  content: "";
}

.fa-duotone.fa-bracket-curly-left:after, .fa-duotone.fa-bracket-curly:after, .fad.fa-bracket-curly-left:after, .fad.fa-bracket-curly:after {
  content: "{{";
}

.fa-duotone.fa-lobster:after, .fad.fa-lobster:after {
  content: "";
}

.fa-duotone.fa-cart-flatbed-empty:after, .fa-duotone.fa-dolly-flatbed-empty:after, .fad.fa-cart-flatbed-empty:after, .fad.fa-dolly-flatbed-empty:after {
  content: "";
}

.fa-duotone.fa-colon:after, .fad.fa-colon:after {
  content: "::";
}

.fa-duotone.fa-cart-arrow-down:after, .fad.fa-cart-arrow-down:after {
  content: "";
}

.fa-duotone.fa-wand:after, .fad.fa-wand:after {
  content: "";
}

.fa-duotone.fa-walkie-talkie:after, .fad.fa-walkie-talkie:after {
  content: "";
}

.fa-duotone.fa-file-edit:after, .fa-duotone.fa-file-pen:after, .fad.fa-file-edit:after, .fad.fa-file-pen:after {
  content: "";
}

.fa-duotone.fa-receipt:after, .fad.fa-receipt:after {
  content: "";
}

.fa-duotone.fa-table-picnic:after, .fad.fa-table-picnic:after {
  content: "";
}

.fa-duotone.fa-pen-square:after, .fa-duotone.fa-pencil-square:after, .fa-duotone.fa-square-pen:after, .fad.fa-pen-square:after, .fad.fa-pencil-square:after, .fad.fa-square-pen:after {
  content: "";
}

.fa-duotone.fa-circle-microphone-lines:after, .fa-duotone.fa-microphone-circle-alt:after, .fad.fa-circle-microphone-lines:after, .fad.fa-microphone-circle-alt:after {
  content: "";
}

.fa-duotone.fa-desktop-slash:after, .fa-duotone.fa-display-slash:after, .fad.fa-desktop-slash:after, .fad.fa-display-slash:after {
  content: "";
}

.fa-duotone.fa-suitcase-rolling:after, .fad.fa-suitcase-rolling:after {
  content: "";
}

.fa-duotone.fa-person-circle-exclamation:after, .fad.fa-person-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-transporter-2:after, .fad.fa-transporter-2:after {
  content: "";
}

.fa-duotone.fa-hand-receiving:after, .fa-duotone.fa-hands-holding-diamond:after, .fad.fa-hand-receiving:after, .fad.fa-hands-holding-diamond:after {
  content: "";
}

.fa-duotone.fa-money-bill-simple-wave:after, .fad.fa-money-bill-simple-wave:after {
  content: "";
}

.fa-duotone.fa-chevron-down:after, .fad.fa-chevron-down:after {
  content: "";
}

.fa-duotone.fa-battery-5:after, .fa-duotone.fa-battery-full:after, .fa-duotone.fa-battery:after, .fad.fa-battery-5:after, .fad.fa-battery-full:after, .fad.fa-battery:after {
  content: "";
}

.fa-duotone.fa-bell-plus:after, .fad.fa-bell-plus:after {
  content: "";
}

.fa-duotone.fa-book-arrow-right:after, .fad.fa-book-arrow-right:after {
  content: "";
}

.fa-duotone.fa-hospitals:after, .fad.fa-hospitals:after {
  content: "";
}

.fa-duotone.fa-club:after, .fad.fa-club:after {
  content: "";
}

.fa-duotone.fa-skull-crossbones:after, .fad.fa-skull-crossbones:after {
  content: "";
}

.fa-duotone.fa-dewpoint:after, .fa-duotone.fa-droplet-degree:after, .fad.fa-dewpoint:after, .fad.fa-droplet-degree:after {
  content: "";
}

.fa-duotone.fa-code-compare:after, .fad.fa-code-compare:after {
  content: "";
}

.fa-duotone.fa-list-dots:after, .fa-duotone.fa-list-ul:after, .fad.fa-list-dots:after, .fad.fa-list-ul:after {
  content: "";
}

.fa-duotone.fa-hand-holding-magic:after, .fad.fa-hand-holding-magic:after {
  content: "";
}

.fa-duotone.fa-watermelon-slice:after, .fad.fa-watermelon-slice:after {
  content: "";
}

.fa-duotone.fa-circle-ellipsis:after, .fad.fa-circle-ellipsis:after {
  content: "";
}

.fa-duotone.fa-school-lock:after, .fad.fa-school-lock:after {
  content: "";
}

.fa-duotone.fa-tower-cell:after, .fad.fa-tower-cell:after {
  content: "";
}

.fa-duotone.fa-sd-cards:after, .fad.fa-sd-cards:after {
  content: "";
}

.fa-duotone.fa-jug-bottle:after, .fad.fa-jug-bottle:after {
  content: "";
}

.fa-duotone.fa-down-long:after, .fa-duotone.fa-long-arrow-alt-down:after, .fad.fa-down-long:after, .fad.fa-long-arrow-alt-down:after {
  content: "";
}

.fa-duotone.fa-envelopes:after, .fad.fa-envelopes:after {
  content: "";
}

.fa-duotone.fa-phone-office:after, .fad.fa-phone-office:after {
  content: "";
}

.fa-duotone.fa-ranking-star:after, .fad.fa-ranking-star:after {
  content: "";
}

.fa-duotone.fa-chess-king:after, .fad.fa-chess-king:after {
  content: "";
}

.fa-duotone.fa-nfc-pen:after, .fad.fa-nfc-pen:after {
  content: "";
}

.fa-duotone.fa-person-harassing:after, .fad.fa-person-harassing:after {
  content: "";
}

.fa-duotone.fa-hat-winter:after, .fad.fa-hat-winter:after {
  content: "";
}

.fa-duotone.fa-brazilian-real-sign:after, .fad.fa-brazilian-real-sign:after {
  content: "";
}

.fa-duotone.fa-landmark-alt:after, .fa-duotone.fa-landmark-dome:after, .fad.fa-landmark-alt:after, .fad.fa-landmark-dome:after {
  content: "";
}

.fa-duotone.fa-bone-break:after, .fad.fa-bone-break:after {
  content: "";
}

.fa-duotone.fa-arrow-up:after, .fad.fa-arrow-up:after {
  content: "";
}

.fa-duotone.fa-down-from-dotted-line:after, .fad.fa-down-from-dotted-line:after {
  content: "";
}

.fa-duotone.fa-television:after, .fa-duotone.fa-tv-alt:after, .fa-duotone.fa-tv:after, .fad.fa-television:after, .fad.fa-tv-alt:after, .fad.fa-tv:after {
  content: "";
}

.fa-duotone.fa-border-left:after, .fad.fa-border-left:after {
  content: "";
}

.fa-duotone.fa-circle-divide:after, .fad.fa-circle-divide:after {
  content: "";
}

.fa-duotone.fa-shrimp:after, .fad.fa-shrimp:after {
  content: "";
}

.fa-duotone.fa-list-check:after, .fa-duotone.fa-tasks:after, .fad.fa-list-check:after, .fad.fa-tasks:after {
  content: "";
}

.fa-duotone.fa-diagram-subtask:after, .fad.fa-diagram-subtask:after {
  content: "";
}

.fa-duotone.fa-jug-detergent:after, .fad.fa-jug-detergent:after {
  content: "";
}

.fa-duotone.fa-circle-user:after, .fa-duotone.fa-user-circle:after, .fad.fa-circle-user:after, .fad.fa-user-circle:after {
  content: "";
}

.fa-duotone.fa-square-y:after, .fad.fa-square-y:after {
  content: "";
}

.fa-duotone.fa-user-doctor-hair:after, .fad.fa-user-doctor-hair:after {
  content: "";
}

.fa-duotone.fa-planet-ringed:after, .fad.fa-planet-ringed:after {
  content: "";
}

.fa-duotone.fa-mushroom:after, .fad.fa-mushroom:after {
  content: "";
}

.fa-duotone.fa-user-shield:after, .fad.fa-user-shield:after {
  content: "";
}

.fa-duotone.fa-megaphone:after, .fad.fa-megaphone:after {
  content: "";
}

.fa-duotone.fa-wreath-laurel:after, .fad.fa-wreath-laurel:after {
  content: "";
}

.fa-duotone.fa-circle-exclamation-check:after, .fad.fa-circle-exclamation-check:after {
  content: "";
}

.fa-duotone.fa-wind:after, .fad.fa-wind:after {
  content: "";
}

.fa-duotone.fa-box-dollar:after, .fa-duotone.fa-box-usd:after, .fad.fa-box-dollar:after, .fad.fa-box-usd:after {
  content: "";
}

.fa-duotone.fa-car-burst:after, .fa-duotone.fa-car-crash:after, .fad.fa-car-burst:after, .fad.fa-car-crash:after {
  content: "";
}

.fa-duotone.fa-y:after, .fad.fa-y:after {
  content: "YY";
}

.fa-duotone.fa-user-headset:after, .fad.fa-user-headset:after {
  content: "";
}

.fa-duotone.fa-arrows-retweet:after, .fa-duotone.fa-retweet-alt:after, .fad.fa-arrows-retweet:after, .fad.fa-retweet-alt:after {
  content: "";
}

.fa-duotone.fa-person-snowboarding:after, .fa-duotone.fa-snowboarding:after, .fad.fa-person-snowboarding:after, .fad.fa-snowboarding:after {
  content: "";
}

.fa-duotone.fa-chevron-square-right:after, .fa-duotone.fa-square-chevron-right:after, .fad.fa-chevron-square-right:after, .fad.fa-square-chevron-right:after {
  content: "";
}

.fa-duotone.fa-lacrosse-stick-ball:after, .fad.fa-lacrosse-stick-ball:after {
  content: "";
}

.fa-duotone.fa-shipping-fast:after, .fa-duotone.fa-truck-fast:after, .fad.fa-shipping-fast:after, .fad.fa-truck-fast:after {
  content: "";
}

.fa-duotone.fa-user-magnifying-glass:after, .fad.fa-user-magnifying-glass:after {
  content: "";
}

.fa-duotone.fa-star-sharp:after, .fad.fa-star-sharp:after {
  content: "";
}

.fa-duotone.fa-comment-heart:after, .fad.fa-comment-heart:after {
  content: "";
}

.fa-duotone.fa-circle-1:after, .fad.fa-circle-1:after {
  content: "";
}

.fa-duotone.fa-circle-star:after, .fa-duotone.fa-star-circle:after, .fad.fa-circle-star:after, .fad.fa-star-circle:after {
  content: "";
}

.fa-duotone.fa-fish:after, .fad.fa-fish:after {
  content: "";
}

.fa-duotone.fa-cloud-fog:after, .fa-duotone.fa-fog:after, .fad.fa-cloud-fog:after, .fad.fa-fog:after {
  content: "";
}

.fa-duotone.fa-waffle:after, .fad.fa-waffle:after {
  content: "";
}

.fa-duotone.fa-music-alt:after, .fa-duotone.fa-music-note:after, .fad.fa-music-alt:after, .fad.fa-music-note:after {
  content: "";
}

.fa-duotone.fa-hexagon-exclamation:after, .fad.fa-hexagon-exclamation:after {
  content: "";
}

.fa-duotone.fa-cart-shopping-fast:after, .fad.fa-cart-shopping-fast:after {
  content: "";
}

.fa-duotone.fa-object-union:after, .fad.fa-object-union:after {
  content: "";
}

.fa-duotone.fa-user-graduate:after, .fad.fa-user-graduate:after {
  content: "";
}

.fa-duotone.fa-starfighter:after, .fad.fa-starfighter:after {
  content: "";
}

.fa-duotone.fa-adjust:after, .fa-duotone.fa-circle-half-stroke:after, .fad.fa-adjust:after, .fad.fa-circle-half-stroke:after {
  content: "";
}

.fa-duotone.fa-arrow-right-long-to-line:after, .fad.fa-arrow-right-long-to-line:after {
  content: "";
}

.fa-duotone.fa-arrow-square-down:after, .fa-duotone.fa-square-arrow-down:after, .fad.fa-arrow-square-down:after, .fad.fa-square-arrow-down:after {
  content: "";
}

.fa-duotone.fa-diamond-half-stroke:after, .fad.fa-diamond-half-stroke:after {
  content: "";
}

.fa-duotone.fa-clapperboard:after, .fad.fa-clapperboard:after {
  content: "";
}

.fa-duotone.fa-chevron-square-left:after, .fa-duotone.fa-square-chevron-left:after, .fad.fa-chevron-square-left:after, .fad.fa-square-chevron-left:after {
  content: "";
}

.fa-duotone.fa-phone-intercom:after, .fad.fa-phone-intercom:after {
  content: "";
}

.fa-duotone.fa-chain-horizontal:after, .fa-duotone.fa-link-horizontal:after, .fad.fa-chain-horizontal:after, .fad.fa-link-horizontal:after {
  content: "";
}

.fa-duotone.fa-mango:after, .fad.fa-mango:after {
  content: "";
}

.fa-duotone.fa-music-alt-slash:after, .fa-duotone.fa-music-note-slash:after, .fad.fa-music-alt-slash:after, .fad.fa-music-note-slash:after {
  content: "";
}

.fa-duotone.fa-circle-radiation:after, .fa-duotone.fa-radiation-alt:after, .fad.fa-circle-radiation:after, .fad.fa-radiation-alt:after {
  content: "";
}

.fa-duotone.fa-face-tongue-sweat:after, .fad.fa-face-tongue-sweat:after {
  content: "";
}

.fa-duotone.fa-globe-stand:after, .fad.fa-globe-stand:after {
  content: "";
}

.fa-duotone.fa-baseball-ball:after, .fa-duotone.fa-baseball:after, .fad.fa-baseball-ball:after, .fad.fa-baseball:after {
  content: "";
}

.fa-duotone.fa-circle-p:after, .fad.fa-circle-p:after {
  content: "";
}

.fa-duotone.fa-award-simple:after, .fad.fa-award-simple:after {
  content: "";
}

.fa-duotone.fa-jet-fighter-up:after, .fad.fa-jet-fighter-up:after {
  content: "";
}

.fa-duotone.fa-diagram-project:after, .fa-duotone.fa-project-diagram:after, .fad.fa-diagram-project:after, .fad.fa-project-diagram:after {
  content: "";
}

.fa-duotone.fa-pedestal:after, .fad.fa-pedestal:after {
  content: "";
}

.fa-duotone.fa-chart-pyramid:after, .fad.fa-chart-pyramid:after {
  content: "";
}

.fa-duotone.fa-sidebar:after, .fad.fa-sidebar:after {
  content: "";
}

.fa-duotone.fa-frosty-head:after, .fa-duotone.fa-snowman-head:after, .fad.fa-frosty-head:after, .fad.fa-snowman-head:after {
  content: "";
}

.fa-duotone.fa-copy:after, .fad.fa-copy:after {
  content: "";
}

.fa-duotone.fa-burger-glass:after, .fad.fa-burger-glass:after {
  content: "";
}

.fa-duotone.fa-volume-mute:after, .fa-duotone.fa-volume-times:after, .fa-duotone.fa-volume-xmark:after, .fad.fa-volume-mute:after, .fad.fa-volume-times:after, .fad.fa-volume-xmark:after {
  content: "";
}

.fa-duotone.fa-hand-sparkles:after, .fad.fa-hand-sparkles:after {
  content: "";
}

.fa-duotone.fa-bars-filter:after, .fad.fa-bars-filter:after {
  content: "";
}

.fa-duotone.fa-paintbrush-pencil:after, .fad.fa-paintbrush-pencil:after {
  content: "";
}

.fa-duotone.fa-party-bell:after, .fad.fa-party-bell:after {
  content: "";
}

.fa-duotone.fa-user-vneck-hair:after, .fad.fa-user-vneck-hair:after {
  content: "";
}

.fa-duotone.fa-jack-o-lantern:after, .fad.fa-jack-o-lantern:after {
  content: "";
}

.fa-duotone.fa-grip-horizontal:after, .fa-duotone.fa-grip:after, .fad.fa-grip-horizontal:after, .fad.fa-grip:after {
  content: "";
}

.fa-duotone.fa-share-from-square:after, .fa-duotone.fa-share-square:after, .fad.fa-share-from-square:after, .fad.fa-share-square:after {
  content: "";
}

.fa-duotone.fa-keynote:after, .fad.fa-keynote:after {
  content: "";
}

.fa-duotone.fa-child-combatant:after, .fa-duotone.fa-child-rifle:after, .fad.fa-child-combatant:after, .fad.fa-child-rifle:after {
  content: "";
}

.fa-duotone.fa-gun:after, .fad.fa-gun:after {
  content: "";
}

.fa-duotone.fa-phone-square:after, .fa-duotone.fa-square-phone:after, .fad.fa-phone-square:after, .fad.fa-square-phone:after {
  content: "";
}

.fa-duotone.fa-hat-beach:after, .fad.fa-hat-beach:after {
  content: "";
}

.fa-duotone.fa-add:after, .fa-duotone.fa-plus:after, .fad.fa-add:after, .fad.fa-plus:after {
  content: "++";
}

.fa-duotone.fa-expand:after, .fad.fa-expand:after {
  content: "";
}

.fa-duotone.fa-computer:after, .fad.fa-computer:after {
  content: "";
}

.fa-duotone.fa-fort:after, .fad.fa-fort:after {
  content: "";
}

.fa-duotone.fa-cloud-check:after, .fad.fa-cloud-check:after {
  content: "";
}

.fa-duotone.fa-close:after, .fa-duotone.fa-multiply:after, .fa-duotone.fa-remove:after, .fa-duotone.fa-times:after, .fa-duotone.fa-xmark:after, .fad.fa-close:after, .fad.fa-multiply:after, .fad.fa-remove:after, .fad.fa-times:after, .fad.fa-xmark:after {
  content: "";
}

.fa-duotone.fa-face-smirking:after, .fad.fa-face-smirking:after {
  content: "";
}

.fa-duotone.fa-arrows-up-down-left-right:after, .fa-duotone.fa-arrows:after, .fad.fa-arrows-up-down-left-right:after, .fad.fa-arrows:after {
  content: "";
}

.fa-duotone.fa-chalkboard-teacher:after, .fa-duotone.fa-chalkboard-user:after, .fad.fa-chalkboard-teacher:after, .fad.fa-chalkboard-user:after {
  content: "";
}

.fa-duotone.fa-rhombus:after, .fad.fa-rhombus:after {
  content: "";
}

.fa-duotone.fa-claw-marks:after, .fad.fa-claw-marks:after {
  content: "";
}

.fa-duotone.fa-peso-sign:after, .fad.fa-peso-sign:after {
  content: "";
}

.fa-duotone.fa-face-smile-tongue:after, .fad.fa-face-smile-tongue:after {
  content: "";
}

.fa-duotone.fa-cart-circle-xmark:after, .fad.fa-cart-circle-xmark:after {
  content: "";
}

.fa-duotone.fa-building-shield:after, .fad.fa-building-shield:after {
  content: "";
}

.fa-duotone.fa-circle-phone-flip:after, .fa-duotone.fa-phone-circle-alt:after, .fad.fa-circle-phone-flip:after, .fad.fa-phone-circle-alt:after {
  content: "";
}

.fa-duotone.fa-baby:after, .fad.fa-baby:after {
  content: "";
}

.fa-duotone.fa-users-line:after, .fad.fa-users-line:after {
  content: "";
}

.fa-duotone.fa-quote-left-alt:after, .fa-duotone.fa-quote-left:after, .fad.fa-quote-left-alt:after, .fad.fa-quote-left:after {
  content: "";
}

.fa-duotone.fa-tractor:after, .fad.fa-tractor:after {
  content: "";
}

.fa-duotone.fa-key-skeleton:after, .fad.fa-key-skeleton:after {
  content: "";
}

.fa-duotone.fa-trash-arrow-up:after, .fa-duotone.fa-trash-restore:after, .fad.fa-trash-arrow-up:after, .fad.fa-trash-restore:after {
  content: "";
}

.fa-duotone.fa-arrow-down-up-lock:after, .fad.fa-arrow-down-up-lock:after {
  content: "";
}

.fa-duotone.fa-arrow-down-to-bracket:after, .fad.fa-arrow-down-to-bracket:after {
  content: "";
}

.fa-duotone.fa-lines-leaning:after, .fad.fa-lines-leaning:after {
  content: "";
}

.fa-duotone.fa-square-q:after, .fad.fa-square-q:after {
  content: "";
}

.fa-duotone.fa-ruler-combined:after, .fad.fa-ruler-combined:after {
  content: "";
}

.fa-duotone.fa-icons-alt:after, .fa-duotone.fa-symbols:after, .fad.fa-icons-alt:after, .fad.fa-symbols:after {
  content: "";
}

.fa-duotone.fa-copyright:after, .fad.fa-copyright:after {
  content: "";
}

.fa-duotone.fa-flask-gear:after, .fad.fa-flask-gear:after {
  content: "";
}

.fa-duotone.fa-highlighter-line:after, .fad.fa-highlighter-line:after {
  content: "";
}

.fa-duotone.fa-bracket-left:after, .fa-duotone.fa-bracket-square:after, .fa-duotone.fa-bracket:after, .fad.fa-bracket-left:after, .fad.fa-bracket-square:after, .fad.fa-bracket:after {
  content: "[[";
}

.fa-duotone.fa-island-tree-palm:after, .fa-duotone.fa-island-tropical:after, .fad.fa-island-tree-palm:after, .fad.fa-island-tropical:after {
  content: "";
}

.fa-duotone.fa-arrow-from-left:after, .fa-duotone.fa-arrow-right-from-line:after, .fad.fa-arrow-from-left:after, .fad.fa-arrow-right-from-line:after {
  content: "";
}

.fa-duotone.fa-h2:after, .fad.fa-h2:after {
  content: "";
}

.fa-duotone.fa-equals:after, .fad.fa-equals:after {
  content: "==";
}

.fa-duotone.fa-cake-slice:after, .fa-duotone.fa-shortcake:after, .fad.fa-cake-slice:after, .fad.fa-shortcake:after {
  content: "";
}

.fa-duotone.fa-peanut:after, .fad.fa-peanut:after {
  content: "";
}

.fa-duotone.fa-wrench-simple:after, .fad.fa-wrench-simple:after {
  content: "";
}

.fa-duotone.fa-blender:after, .fad.fa-blender:after {
  content: "";
}

.fa-duotone.fa-teeth:after, .fad.fa-teeth:after {
  content: "";
}

.fa-duotone.fa-tally-2:after, .fad.fa-tally-2:after {
  content: "";
}

.fa-duotone.fa-ils:after, .fa-duotone.fa-shekel-sign:after, .fa-duotone.fa-shekel:after, .fa-duotone.fa-sheqel-sign:after, .fa-duotone.fa-sheqel:after, .fad.fa-ils:after, .fad.fa-shekel-sign:after, .fad.fa-shekel:after, .fad.fa-sheqel-sign:after, .fad.fa-sheqel:after {
  content: "";
}

.fa-duotone.fa-cars:after, .fad.fa-cars:after {
  content: "";
}

.fa-duotone.fa-axe-battle:after, .fad.fa-axe-battle:after {
  content: "";
}

.fa-duotone.fa-user-hair-long:after, .fad.fa-user-hair-long:after {
  content: "";
}

.fa-duotone.fa-map:after, .fad.fa-map:after {
  content: "";
}

.fa-duotone.fa-arrow-left-from-arc:after, .fad.fa-arrow-left-from-arc:after {
  content: "";
}

.fa-duotone.fa-file-circle-info:after, .fad.fa-file-circle-info:after {
  content: "";
}

.fa-duotone.fa-face-disappointed:after, .fad.fa-face-disappointed:after {
  content: "";
}

.fa-duotone.fa-lasso-sparkles:after, .fad.fa-lasso-sparkles:after {
  content: "";
}

.fa-duotone.fa-clock-eleven:after, .fad.fa-clock-eleven:after {
  content: "";
}

.fa-duotone.fa-rocket:after, .fad.fa-rocket:after {
  content: "";
}

.fa-duotone.fa-siren-on:after, .fad.fa-siren-on:after {
  content: "";
}

.fa-duotone.fa-clock-ten:after, .fad.fa-clock-ten:after {
  content: "";
}

.fa-duotone.fa-candle-holder:after, .fad.fa-candle-holder:after {
  content: "";
}

.fa-duotone.fa-video-arrow-down-left:after, .fad.fa-video-arrow-down-left:after {
  content: "";
}

.fa-duotone.fa-photo-film:after, .fa-duotone.fa-photo-video:after, .fad.fa-photo-film:after, .fad.fa-photo-video:after {
  content: "";
}

.fa-duotone.fa-floppy-disk-circle-arrow-right:after, .fa-duotone.fa-save-circle-arrow-right:after, .fad.fa-floppy-disk-circle-arrow-right:after, .fad.fa-save-circle-arrow-right:after {
  content: "";
}

.fa-duotone.fa-folder-minus:after, .fad.fa-folder-minus:after {
  content: "";
}

.fa-duotone.fa-planet-moon:after, .fad.fa-planet-moon:after {
  content: "";
}

.fa-duotone.fa-face-eyes-xmarks:after, .fad.fa-face-eyes-xmarks:after {
  content: "";
}

.fa-duotone.fa-chart-scatter:after, .fad.fa-chart-scatter:after {
  content: "";
}

.fa-duotone.fa-display-arrow-down:after, .fad.fa-display-arrow-down:after {
  content: "";
}

.fa-duotone.fa-store:after, .fad.fa-store:after {
  content: "";
}

.fa-duotone.fa-arrow-trend-up:after, .fad.fa-arrow-trend-up:after {
  content: "";
}

.fa-duotone.fa-plug-circle-minus:after, .fad.fa-plug-circle-minus:after {
  content: "";
}

.fa-duotone.fa-olive-branch:after, .fad.fa-olive-branch:after {
  content: "";
}

.fa-duotone.fa-angle:after, .fad.fa-angle:after {
  content: "";
}

.fa-duotone.fa-vacuum-robot:after, .fad.fa-vacuum-robot:after {
  content: "";
}

.fa-duotone.fa-sign-hanging:after, .fa-duotone.fa-sign:after, .fad.fa-sign-hanging:after, .fad.fa-sign:after {
  content: "";
}

.fa-duotone.fa-square-divide:after, .fad.fa-square-divide:after {
  content: "";
}

.fa-duotone.fa-signal-stream-slash:after, .fad.fa-signal-stream-slash:after {
  content: "";
}

.fa-duotone.fa-bezier-curve:after, .fad.fa-bezier-curve:after {
  content: "";
}

.fa-duotone.fa-eye-dropper-half:after, .fad.fa-eye-dropper-half:after {
  content: "";
}

.fa-duotone.fa-store-lock:after, .fad.fa-store-lock:after {
  content: "";
}

.fa-duotone.fa-bell-slash:after, .fad.fa-bell-slash:after {
  content: "";
}

.fa-duotone.fa-cloud-bolt-sun:after, .fa-duotone.fa-thunderstorm-sun:after, .fad.fa-cloud-bolt-sun:after, .fad.fa-thunderstorm-sun:after {
  content: "";
}

.fa-duotone.fa-camera-slash:after, .fad.fa-camera-slash:after {
  content: "";
}

.fa-duotone.fa-comment-quote:after, .fad.fa-comment-quote:after {
  content: "";
}

.fa-duotone.fa-tablet-android:after, .fa-duotone.fa-tablet:after, .fad.fa-tablet-android:after, .fad.fa-tablet:after {
  content: "";
}

.fa-duotone.fa-school-flag:after, .fad.fa-school-flag:after {
  content: "";
}

.fa-duotone.fa-message-code:after, .fad.fa-message-code:after {
  content: "";
}

.fa-duotone.fa-glass-half-empty:after, .fa-duotone.fa-glass-half-full:after, .fa-duotone.fa-glass-half:after, .fad.fa-glass-half-empty:after, .fad.fa-glass-half-full:after, .fad.fa-glass-half:after {
  content: "";
}

.fa-duotone.fa-fill:after, .fad.fa-fill:after {
  content: "";
}

.fa-duotone.fa-comment-alt-minus:after, .fa-duotone.fa-message-minus:after, .fad.fa-comment-alt-minus:after, .fad.fa-message-minus:after {
  content: "";
}

.fa-duotone.fa-angle-up:after, .fad.fa-angle-up:after {
  content: "";
}

.fa-duotone.fa-dinosaur:after, .fad.fa-dinosaur:after {
  content: "";
}

.fa-duotone.fa-drumstick-bite:after, .fad.fa-drumstick-bite:after {
  content: "";
}

.fa-duotone.fa-chain-horizontal-slash:after, .fa-duotone.fa-link-horizontal-slash:after, .fad.fa-chain-horizontal-slash:after, .fad.fa-link-horizontal-slash:after {
  content: "";
}

.fa-duotone.fa-holly-berry:after, .fad.fa-holly-berry:after {
  content: "";
}

.fa-duotone.fa-nose:after, .fad.fa-nose:after {
  content: "";
}

.fa-duotone.fa-arrow-left-to-arc:after, .fad.fa-arrow-left-to-arc:after {
  content: "";
}

.fa-duotone.fa-chevron-left:after, .fad.fa-chevron-left:after {
  content: "";
}

.fa-duotone.fa-bacteria:after, .fad.fa-bacteria:after {
  content: "";
}

.fa-duotone.fa-clouds:after, .fad.fa-clouds:after {
  content: "";
}

.fa-duotone.fa-money-bill-simple:after, .fad.fa-money-bill-simple:after {
  content: "";
}

.fa-duotone.fa-hand-lizard:after, .fad.fa-hand-lizard:after {
  content: "";
}

.fa-duotone.fa-table-pivot:after, .fad.fa-table-pivot:after {
  content: "";
}

.fa-duotone.fa-filter-slash:after, .fad.fa-filter-slash:after {
  content: "";
}

.fa-duotone.fa-trash-can-arrow-turn-left:after, .fa-duotone.fa-trash-can-undo:after, .fa-duotone.fa-trash-undo-alt:after, .fad.fa-trash-can-arrow-turn-left:after, .fad.fa-trash-can-undo:after, .fad.fa-trash-undo-alt:after {
  content: "";
}

.fa-duotone.fa-notdef:after, .fad.fa-notdef:after {
  content: "";
}

.fa-duotone.fa-disease:after, .fad.fa-disease:after {
  content: "";
}

.fa-duotone.fa-person-to-door:after, .fad.fa-person-to-door:after {
  content: "";
}

.fa-duotone.fa-turntable:after, .fad.fa-turntable:after {
  content: "";
}

.fa-duotone.fa-briefcase-medical:after, .fad.fa-briefcase-medical:after {
  content: "";
}

.fa-duotone.fa-genderless:after, .fad.fa-genderless:after {
  content: "";
}

.fa-duotone.fa-chevron-right:after, .fad.fa-chevron-right:after {
  content: "";
}

.fa-duotone.fa-signal-1:after, .fa-duotone.fa-signal-weak:after, .fad.fa-signal-1:after, .fad.fa-signal-weak:after {
  content: "";
}

.fa-duotone.fa-clock-five:after, .fad.fa-clock-five:after {
  content: "";
}

.fa-duotone.fa-retweet:after, .fad.fa-retweet:after {
  content: "";
}

.fa-duotone.fa-car-alt:after, .fa-duotone.fa-car-rear:after, .fad.fa-car-alt:after, .fad.fa-car-rear:after {
  content: "";
}

.fa-duotone.fa-pump-soap:after, .fad.fa-pump-soap:after {
  content: "";
}

.fa-duotone.fa-computer-classic:after, .fad.fa-computer-classic:after {
  content: "";
}

.fa-duotone.fa-frame:after, .fad.fa-frame:after {
  content: "";
}

.fa-duotone.fa-video-slash:after, .fad.fa-video-slash:after {
  content: "";
}

.fa-duotone.fa-battery-2:after, .fa-duotone.fa-battery-quarter:after, .fad.fa-battery-2:after, .fad.fa-battery-quarter:after {
  content: "";
}

.fa-duotone.fa-ellipsis-h-alt:after, .fa-duotone.fa-ellipsis-stroke:after, .fad.fa-ellipsis-h-alt:after, .fad.fa-ellipsis-stroke:after {
  content: "";
}

.fa-duotone.fa-radio:after, .fad.fa-radio:after {
  content: "";
}

.fa-duotone.fa-baby-carriage:after, .fa-duotone.fa-carriage-baby:after, .fad.fa-baby-carriage:after, .fad.fa-carriage-baby:after {
  content: "";
}

.fa-duotone.fa-face-expressionless:after, .fad.fa-face-expressionless:after {
  content: "";
}

.fa-duotone.fa-down-to-dotted-line:after, .fad.fa-down-to-dotted-line:after {
  content: "";
}

.fa-duotone.fa-cloud-music:after, .fad.fa-cloud-music:after {
  content: "";
}

.fa-duotone.fa-traffic-light:after, .fad.fa-traffic-light:after {
  content: "";
}

.fa-duotone.fa-cloud-minus:after, .fad.fa-cloud-minus:after {
  content: "";
}

.fa-duotone.fa-thermometer:after, .fad.fa-thermometer:after {
  content: "";
}

.fa-duotone.fa-shield-minus:after, .fad.fa-shield-minus:after {
  content: "";
}

.fa-duotone.fa-vr-cardboard:after, .fad.fa-vr-cardboard:after {
  content: "";
}

.fa-duotone.fa-car-tilt:after, .fad.fa-car-tilt:after {
  content: "";
}

.fa-duotone.fa-gauge-circle-minus:after, .fad.fa-gauge-circle-minus:after {
  content: "";
}

.fa-duotone.fa-brightness-low:after, .fad.fa-brightness-low:after {
  content: "";
}

.fa-duotone.fa-hand-middle-finger:after, .fad.fa-hand-middle-finger:after {
  content: "";
}

.fa-duotone.fa-percent:after, .fa-duotone.fa-percentage:after, .fad.fa-percent:after, .fad.fa-percentage:after {
  content: "%%";
}

.fa-duotone.fa-truck-moving:after, .fad.fa-truck-moving:after {
  content: "";
}

.fa-duotone.fa-glass-water-droplet:after, .fad.fa-glass-water-droplet:after {
  content: "";
}

.fa-duotone.fa-conveyor-belt:after, .fad.fa-conveyor-belt:after {
  content: "";
}

.fa-duotone.fa-location-check:after, .fa-duotone.fa-map-marker-check:after, .fad.fa-location-check:after, .fad.fa-map-marker-check:after {
  content: "";
}

.fa-duotone.fa-coin-vertical:after, .fad.fa-coin-vertical:after {
  content: "";
}

.fa-duotone.fa-display:after, .fad.fa-display:after {
  content: "";
}

.fa-duotone.fa-person-sign:after, .fad.fa-person-sign:after {
  content: "";
}

.fa-duotone.fa-face-smile:after, .fa-duotone.fa-smile:after, .fad.fa-face-smile:after, .fad.fa-smile:after {
  content: "";
}

.fa-duotone.fa-phone-hangup:after, .fad.fa-phone-hangup:after {
  content: "";
}

.fa-duotone.fa-signature-slash:after, .fad.fa-signature-slash:after {
  content: "";
}

.fa-duotone.fa-thumb-tack:after, .fa-duotone.fa-thumbtack:after, .fad.fa-thumb-tack:after, .fad.fa-thumbtack:after {
  content: "";
}

.fa-duotone.fa-wheat-slash:after, .fad.fa-wheat-slash:after {
  content: "";
}

.fa-duotone.fa-trophy:after, .fad.fa-trophy:after {
  content: "";
}

.fa-duotone.fa-clouds-sun:after, .fad.fa-clouds-sun:after {
  content: "";
}

.fa-duotone.fa-person-praying:after, .fa-duotone.fa-pray:after, .fad.fa-person-praying:after, .fad.fa-pray:after {
  content: "";
}

.fa-duotone.fa-hammer:after, .fad.fa-hammer:after {
  content: "";
}

.fa-duotone.fa-face-vomit:after, .fad.fa-face-vomit:after {
  content: "";
}

.fa-duotone.fa-speakers:after, .fad.fa-speakers:after {
  content: "";
}

.fa-duotone.fa-teletype-answer:after, .fa-duotone.fa-tty-answer:after, .fad.fa-teletype-answer:after, .fad.fa-tty-answer:after {
  content: "";
}

.fa-duotone.fa-mug-tea-saucer:after, .fad.fa-mug-tea-saucer:after {
  content: "";
}

.fa-duotone.fa-diagram-lean-canvas:after, .fad.fa-diagram-lean-canvas:after {
  content: "";
}

.fa-duotone.fa-alt:after, .fad.fa-alt:after {
  content: "";
}

.fa-duotone.fa-dial-med-high:after, .fa-duotone.fa-dial:after, .fad.fa-dial-med-high:after, .fad.fa-dial:after {
  content: "";
}

.fa-duotone.fa-hand-peace:after, .fad.fa-hand-peace:after {
  content: "";
}

.fa-duotone.fa-circle-trash:after, .fa-duotone.fa-trash-circle:after, .fad.fa-circle-trash:after, .fad.fa-trash-circle:after {
  content: "";
}

.fa-duotone.fa-rotate:after, .fa-duotone.fa-sync-alt:after, .fad.fa-rotate:after, .fad.fa-sync-alt:after {
  content: "";
}

.fa-duotone.fa-circle-quarters:after, .fad.fa-circle-quarters:after {
  content: "";
}

.fa-duotone.fa-spinner:after, .fad.fa-spinner:after {
  content: "";
}

.fa-duotone.fa-tower-control:after, .fad.fa-tower-control:after {
  content: "";
}

.fa-duotone.fa-arrow-up-triangle-square:after, .fa-duotone.fa-sort-shapes-up:after, .fad.fa-arrow-up-triangle-square:after, .fad.fa-sort-shapes-up:after {
  content: "";
}

.fa-duotone.fa-whale:after, .fad.fa-whale:after {
  content: "";
}

.fa-duotone.fa-robot:after, .fad.fa-robot:after {
  content: "";
}

.fa-duotone.fa-peace:after, .fad.fa-peace:after {
  content: "";
}

.fa-duotone.fa-party-horn:after, .fad.fa-party-horn:after {
  content: "";
}

.fa-duotone.fa-cogs:after, .fa-duotone.fa-gears:after, .fad.fa-cogs:after, .fad.fa-gears:after {
  content: "";
}

.fa-duotone.fa-sun-alt:after, .fa-duotone.fa-sun-bright:after, .fad.fa-sun-alt:after, .fad.fa-sun-bright:after {
  content: "";
}

.fa-duotone.fa-warehouse:after, .fad.fa-warehouse:after {
  content: "";
}

.fa-duotone.fa-conveyor-belt-arm:after, .fad.fa-conveyor-belt-arm:after {
  content: "";
}

.fa-duotone.fa-lock-keyhole-open:after, .fa-duotone.fa-lock-open-alt:after, .fad.fa-lock-keyhole-open:after, .fad.fa-lock-open-alt:after {
  content: "";
}

.fa-duotone.fa-box-fragile:after, .fa-duotone.fa-square-fragile:after, .fa-duotone.fa-square-wine-glass-crack:after, .fad.fa-box-fragile:after, .fad.fa-square-fragile:after, .fad.fa-square-wine-glass-crack:after {
  content: "";
}

.fa-duotone.fa-arrow-up-right-dots:after, .fad.fa-arrow-up-right-dots:after {
  content: "";
}

.fa-duotone.fa-square-n:after, .fad.fa-square-n:after {
  content: "";
}

.fa-duotone.fa-splotch:after, .fad.fa-splotch:after {
  content: "";
}

.fa-duotone.fa-face-grin-hearts:after, .fa-duotone.fa-grin-hearts:after, .fad.fa-face-grin-hearts:after, .fad.fa-grin-hearts:after {
  content: "";
}

.fa-duotone.fa-meter:after, .fad.fa-meter:after {
  content: "";
}

.fa-duotone.fa-mandolin:after, .fad.fa-mandolin:after {
  content: "";
}

.fa-duotone.fa-dice-four:after, .fad.fa-dice-four:after {
  content: "";
}

.fa-duotone.fa-sim-card:after, .fad.fa-sim-card:after {
  content: "";
}

.fa-duotone.fa-transgender-alt:after, .fa-duotone.fa-transgender:after, .fad.fa-transgender-alt:after, .fad.fa-transgender:after {
  content: "";
}

.fa-duotone.fa-mercury:after, .fad.fa-mercury:after {
  content: "";
}

.fa-duotone.fa-up-from-bracket:after, .fad.fa-up-from-bracket:after {
  content: "";
}

.fa-duotone.fa-knife-kitchen:after, .fad.fa-knife-kitchen:after {
  content: "";
}

.fa-duotone.fa-border-right:after, .fad.fa-border-right:after {
  content: "";
}

.fa-duotone.fa-arrow-turn-down:after, .fa-duotone.fa-level-down:after, .fad.fa-arrow-turn-down:after, .fad.fa-level-down:after {
  content: "";
}

.fa-duotone.fa-spade:after, .fad.fa-spade:after {
  content: "";
}

.fa-duotone.fa-card-spade:after, .fad.fa-card-spade:after {
  content: "";
}

.fa-duotone.fa-line-columns:after, .fad.fa-line-columns:after {
  content: "";
}

.fa-duotone.fa-arrow-right-to-line:after, .fa-duotone.fa-arrow-to-right:after, .fad.fa-arrow-right-to-line:after, .fad.fa-arrow-to-right:after {
  content: "";
}

.fa-duotone.fa-person-falling-burst:after, .fad.fa-person-falling-burst:after {
  content: "";
}

.fa-duotone.fa-flag-pennant:after, .fa-duotone.fa-pennant:after, .fad.fa-flag-pennant:after, .fad.fa-pennant:after {
  content: "";
}

.fa-duotone.fa-conveyor-belt-empty:after, .fad.fa-conveyor-belt-empty:after {
  content: "";
}

.fa-duotone.fa-user-group-simple:after, .fad.fa-user-group-simple:after {
  content: "";
}

.fa-duotone.fa-award:after, .fad.fa-award:after {
  content: "";
}

.fa-duotone.fa-ticket-alt:after, .fa-duotone.fa-ticket-simple:after, .fad.fa-ticket-alt:after, .fad.fa-ticket-simple:after {
  content: "";
}

.fa-duotone.fa-building:after, .fad.fa-building:after {
  content: "";
}

.fa-duotone.fa-angle-double-left:after, .fa-duotone.fa-angles-left:after, .fad.fa-angle-double-left:after, .fad.fa-angles-left:after {
  content: "";
}

.fa-duotone.fa-camcorder:after, .fa-duotone.fa-video-handheld:after, .fad.fa-camcorder:after, .fad.fa-video-handheld:after {
  content: "";
}

.fa-duotone.fa-pancakes:after, .fad.fa-pancakes:after {
  content: "";
}

.fa-duotone.fa-album-circle-user:after, .fad.fa-album-circle-user:after {
  content: "";
}

.fa-duotone.fa-subtitles-slash:after, .fad.fa-subtitles-slash:after {
  content: "";
}

.fa-duotone.fa-qrcode:after, .fad.fa-qrcode:after {
  content: "";
}

.fa-duotone.fa-dice-d10:after, .fad.fa-dice-d10:after {
  content: "";
}

.fa-duotone.fa-fireplace:after, .fad.fa-fireplace:after {
  content: "";
}

.fa-duotone.fa-browser:after, .fad.fa-browser:after {
  content: "";
}

.fa-duotone.fa-pen-paintbrush:after, .fa-duotone.fa-pencil-paintbrush:after, .fad.fa-pen-paintbrush:after, .fad.fa-pencil-paintbrush:after {
  content: "";
}

.fa-duotone.fa-fish-cooked:after, .fad.fa-fish-cooked:after {
  content: "";
}

.fa-duotone.fa-chair-office:after, .fad.fa-chair-office:after {
  content: "";
}

.fa-duotone.fa-nesting-dolls:after, .fad.fa-nesting-dolls:after {
  content: "";
}

.fa-duotone.fa-clock-rotate-left:after, .fa-duotone.fa-history:after, .fad.fa-clock-rotate-left:after, .fad.fa-history:after {
  content: "";
}

.fa-duotone.fa-trumpet:after, .fad.fa-trumpet:after {
  content: "";
}

.fa-duotone.fa-face-grin-beam-sweat:after, .fa-duotone.fa-grin-beam-sweat:after, .fad.fa-face-grin-beam-sweat:after, .fad.fa-grin-beam-sweat:after {
  content: "";
}

.fa-duotone.fa-fire-smoke:after, .fad.fa-fire-smoke:after {
  content: "";
}

.fa-duotone.fa-phone-missed:after, .fad.fa-phone-missed:after {
  content: "";
}

.fa-duotone.fa-arrow-right-from-file:after, .fa-duotone.fa-file-export:after, .fad.fa-arrow-right-from-file:after, .fad.fa-file-export:after {
  content: "";
}

.fa-duotone.fa-shield-blank:after, .fa-duotone.fa-shield:after, .fad.fa-shield-blank:after, .fad.fa-shield:after {
  content: "";
}

.fa-duotone.fa-arrow-up-short-wide:after, .fa-duotone.fa-sort-amount-up-alt:after, .fad.fa-arrow-up-short-wide:after, .fad.fa-sort-amount-up-alt:after {
  content: "";
}

.fa-duotone.fa-arrows-repeat-1:after, .fa-duotone.fa-repeat-1-alt:after, .fad.fa-arrows-repeat-1:after, .fad.fa-repeat-1-alt:after {
  content: "";
}

.fa-duotone.fa-gun-slash:after, .fad.fa-gun-slash:after {
  content: "";
}

.fa-duotone.fa-avocado:after, .fad.fa-avocado:after {
  content: "";
}

.fa-duotone.fa-binary:after, .fad.fa-binary:after {
  content: "";
}

.fa-duotone.fa-glasses-alt:after, .fa-duotone.fa-glasses-round:after, .fad.fa-glasses-alt:after, .fad.fa-glasses-round:after {
  content: "";
}

.fa-duotone.fa-phone-plus:after, .fad.fa-phone-plus:after {
  content: "";
}

.fa-duotone.fa-ditto:after, .fad.fa-ditto:after {
  content: "\"\"";
}

.fa-duotone.fa-person-seat:after, .fad.fa-person-seat:after {
  content: "";
}

.fa-duotone.fa-house-medical:after, .fad.fa-house-medical:after {
  content: "";
}

.fa-duotone.fa-golf-ball-tee:after, .fa-duotone.fa-golf-ball:after, .fad.fa-golf-ball-tee:after, .fad.fa-golf-ball:after {
  content: "";
}

.fa-duotone.fa-chevron-circle-left:after, .fa-duotone.fa-circle-chevron-left:after, .fad.fa-chevron-circle-left:after, .fad.fa-circle-chevron-left:after {
  content: "";
}

.fa-duotone.fa-house-chimney-window:after, .fad.fa-house-chimney-window:after {
  content: "";
}

.fa-duotone.fa-scythe:after, .fad.fa-scythe:after {
  content: "";
}

.fa-duotone.fa-pen-nib:after, .fad.fa-pen-nib:after {
  content: "";
}

.fa-duotone.fa-ban-parking:after, .fa-duotone.fa-parking-circle-slash:after, .fad.fa-ban-parking:after, .fad.fa-parking-circle-slash:after {
  content: "";
}

.fa-duotone.fa-tent-arrow-turn-left:after, .fad.fa-tent-arrow-turn-left:after {
  content: "";
}

.fa-duotone.fa-face-diagonal-mouth:after, .fad.fa-face-diagonal-mouth:after {
  content: "";
}

.fa-duotone.fa-diagram-cells:after, .fad.fa-diagram-cells:after {
  content: "";
}

.fa-duotone.fa-cricket-bat-ball:after, .fa-duotone.fa-cricket:after, .fad.fa-cricket-bat-ball:after, .fad.fa-cricket:after {
  content: "";
}

.fa-duotone.fa-tents:after, .fad.fa-tents:after {
  content: "";
}

.fa-duotone.fa-magic:after, .fa-duotone.fa-wand-magic:after, .fad.fa-magic:after, .fad.fa-wand-magic:after {
  content: "";
}

.fa-duotone.fa-dog:after, .fad.fa-dog:after {
  content: "";
}

.fa-duotone.fa-pen-line:after, .fad.fa-pen-line:after {
  content: "";
}

.fa-duotone.fa-atom-alt:after, .fa-duotone.fa-atom-simple:after, .fad.fa-atom-alt:after, .fad.fa-atom-simple:after {
  content: "";
}

.fa-duotone.fa-ampersand:after, .fad.fa-ampersand:after {
  content: "&&";
}

.fa-duotone.fa-carrot:after, .fad.fa-carrot:after {
  content: "";
}

.fa-duotone.fa-arrow-from-bottom:after, .fa-duotone.fa-arrow-up-from-line:after, .fad.fa-arrow-from-bottom:after, .fad.fa-arrow-up-from-line:after {
  content: "";
}

.fa-duotone.fa-moon:after, .fad.fa-moon:after {
  content: "";
}

.fa-duotone.fa-pen-slash:after, .fad.fa-pen-slash:after {
  content: "";
}

.fa-duotone.fa-wine-glass-alt:after, .fa-duotone.fa-wine-glass-empty:after, .fad.fa-wine-glass-alt:after, .fad.fa-wine-glass-empty:after {
  content: "";
}

.fa-duotone.fa-square-star:after, .fad.fa-square-star:after {
  content: "";
}

.fa-duotone.fa-cheese:after, .fad.fa-cheese:after {
  content: "";
}

.fa-duotone.fa-send-backward:after, .fad.fa-send-backward:after {
  content: "";
}

.fa-duotone.fa-yin-yang:after, .fad.fa-yin-yang:after {
  content: "";
}

.fa-duotone.fa-music:after, .fad.fa-music:after {
  content: "";
}

.fa-duotone.fa-compass-slash:after, .fad.fa-compass-slash:after {
  content: "";
}

.fa-duotone.fa-clock-one:after, .fad.fa-clock-one:after {
  content: "";
}

.fa-duotone.fa-file-music:after, .fad.fa-file-music:after {
  content: "";
}

.fa-duotone.fa-code-commit:after, .fad.fa-code-commit:after {
  content: "";
}

.fa-duotone.fa-temperature-low:after, .fad.fa-temperature-low:after {
  content: "";
}

.fa-duotone.fa-biking:after, .fa-duotone.fa-person-biking:after, .fad.fa-biking:after, .fad.fa-person-biking:after {
  content: "";
}

.fa-duotone.fa-display-chart-up-circle-currency:after, .fad.fa-display-chart-up-circle-currency:after {
  content: "";
}

.fa-duotone.fa-skeleton:after, .fad.fa-skeleton:after {
  content: "";
}

.fa-duotone.fa-circle-g:after, .fad.fa-circle-g:after {
  content: "";
}

.fa-duotone.fa-circle-arrow-up-left:after, .fad.fa-circle-arrow-up-left:after {
  content: "";
}

.fa-duotone.fa-coin-blank:after, .fad.fa-coin-blank:after {
  content: "";
}

.fa-duotone.fa-broom:after, .fad.fa-broom:after {
  content: "";
}

.fa-duotone.fa-vacuum:after, .fad.fa-vacuum:after {
  content: "";
}

.fa-duotone.fa-shield-heart:after, .fad.fa-shield-heart:after {
  content: "";
}

.fa-duotone.fa-card-heart:after, .fad.fa-card-heart:after {
  content: "";
}

.fa-duotone.fa-lightbulb-cfl-on:after, .fad.fa-lightbulb-cfl-on:after {
  content: "";
}

.fa-duotone.fa-melon:after, .fad.fa-melon:after {
  content: "";
}

.fa-duotone.fa-gopuram:after, .fad.fa-gopuram:after {
  content: "";
}

.fa-duotone.fa-earth-oceania:after, .fa-duotone.fa-globe-oceania:after, .fad.fa-earth-oceania:after, .fad.fa-globe-oceania:after {
  content: "";
}

.fa-duotone.fa-container-storage:after, .fad.fa-container-storage:after {
  content: "";
}

.fa-duotone.fa-face-pouting:after, .fad.fa-face-pouting:after {
  content: "";
}

.fa-duotone.fa-square-xmark:after, .fa-duotone.fa-times-square:after, .fa-duotone.fa-xmark-square:after, .fad.fa-square-xmark:after, .fad.fa-times-square:after, .fad.fa-xmark-square:after {
  content: "";
}

.fa-duotone.fa-exploding-head:after, .fa-duotone.fa-face-explode:after, .fad.fa-exploding-head:after, .fad.fa-face-explode:after {
  content: "";
}

.fa-duotone.fa-hashtag:after, .fad.fa-hashtag:after {
  content: "##";
}

.fa-duotone.fa-expand-alt:after, .fa-duotone.fa-up-right-and-down-left-from-center:after, .fad.fa-expand-alt:after, .fad.fa-up-right-and-down-left-from-center:after {
  content: "";
}

.fa-duotone.fa-oil-can:after, .fad.fa-oil-can:after {
  content: "";
}

.fa-duotone.fa-t:after, .fad.fa-t:after {
  content: "TT";
}

.fa-duotone.fa-transformer-bolt:after, .fad.fa-transformer-bolt:after {
  content: "";
}

.fa-duotone.fa-hippo:after, .fad.fa-hippo:after {
  content: "";
}

.fa-duotone.fa-chart-column:after, .fad.fa-chart-column:after {
  content: "";
}

.fa-duotone.fa-cassette-vhs:after, .fa-duotone.fa-vhs:after, .fad.fa-cassette-vhs:after, .fad.fa-vhs:after {
  content: "";
}

.fa-duotone.fa-infinity:after, .fad.fa-infinity:after {
  content: "";
}

.fa-duotone.fa-vial-circle-check:after, .fad.fa-vial-circle-check:after {
  content: "";
}

.fa-duotone.fa-chimney:after, .fad.fa-chimney:after {
  content: "";
}

.fa-duotone.fa-object-intersect:after, .fad.fa-object-intersect:after {
  content: "";
}

.fa-duotone.fa-person-arrow-down-to-line:after, .fad.fa-person-arrow-down-to-line:after {
  content: "";
}

.fa-duotone.fa-voicemail:after, .fad.fa-voicemail:after {
  content: "";
}

.fa-duotone.fa-block-brick:after, .fa-duotone.fa-wall-brick:after, .fad.fa-block-brick:after, .fad.fa-wall-brick:after {
  content: "";
}

.fa-duotone.fa-fan:after, .fad.fa-fan:after {
  content: "";
}

.fa-duotone.fa-bags-shopping:after, .fad.fa-bags-shopping:after {
  content: "";
}

.fa-duotone.fa-paragraph-left:after, .fa-duotone.fa-paragraph-rtl:after, .fad.fa-paragraph-left:after, .fad.fa-paragraph-rtl:after {
  content: "";
}

.fa-duotone.fa-person-walking-luggage:after, .fad.fa-person-walking-luggage:after {
  content: "";
}

.fa-duotone.fa-caravan-alt:after, .fa-duotone.fa-caravan-simple:after, .fad.fa-caravan-alt:after, .fad.fa-caravan-simple:after {
  content: "";
}

.fa-duotone.fa-turtle:after, .fad.fa-turtle:after {
  content: "";
}

.fa-duotone.fa-pencil-mechanical:after, .fad.fa-pencil-mechanical:after {
  content: "";
}

.fa-duotone.fa-arrows-alt-v:after, .fa-duotone.fa-up-down:after, .fad.fa-arrows-alt-v:after, .fad.fa-up-down:after {
  content: "";
}

.fa-duotone.fa-cloud-moon-rain:after, .fad.fa-cloud-moon-rain:after {
  content: "";
}

.fa-duotone.fa-booth-curtain:after, .fad.fa-booth-curtain:after {
  content: "";
}

.fa-duotone.fa-calendar:after, .fad.fa-calendar:after {
  content: "";
}

.fa-duotone.fa-box-heart:after, .fad.fa-box-heart:after {
  content: "";
}

.fa-duotone.fa-trailer:after, .fad.fa-trailer:after {
  content: "";
}

.fa-duotone.fa-user-doctor-message:after, .fa-duotone.fa-user-md-chat:after, .fad.fa-user-doctor-message:after, .fad.fa-user-md-chat:after {
  content: "";
}

.fa-duotone.fa-bahai:after, .fa-duotone.fa-haykal:after, .fad.fa-bahai:after, .fad.fa-haykal:after {
  content: "";
}

.fa-duotone.fa-lighthouse:after, .fad.fa-lighthouse:after {
  content: "";
}

.fa-duotone.fa-amp-guitar:after, .fad.fa-amp-guitar:after {
  content: "";
}

.fa-duotone.fa-sd-card:after, .fad.fa-sd-card:after {
  content: "";
}

.fa-duotone.fa-volume-slash:after, .fad.fa-volume-slash:after {
  content: "";
}

.fa-duotone.fa-border-bottom:after, .fad.fa-border-bottom:after {
  content: "";
}

.fa-duotone.fa-wifi-1:after, .fa-duotone.fa-wifi-weak:after, .fad.fa-wifi-1:after, .fad.fa-wifi-weak:after {
  content: "";
}

.fa-duotone.fa-dragon:after, .fad.fa-dragon:after {
  content: "";
}

.fa-duotone.fa-shoe-prints:after, .fad.fa-shoe-prints:after {
  content: "";
}

.fa-duotone.fa-circle-plus:after, .fa-duotone.fa-plus-circle:after, .fad.fa-circle-plus:after, .fad.fa-plus-circle:after {
  content: "";
}

.fa-duotone.fa-face-grin-tongue-wink:after, .fa-duotone.fa-grin-tongue-wink:after, .fad.fa-face-grin-tongue-wink:after, .fad.fa-grin-tongue-wink:after {
  content: "";
}

.fa-duotone.fa-hand-holding:after, .fad.fa-hand-holding:after {
  content: "";
}

.fa-duotone.fa-plug-circle-exclamation:after, .fad.fa-plug-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-chain-broken:after, .fa-duotone.fa-chain-slash:after, .fa-duotone.fa-link-slash:after, .fa-duotone.fa-unlink:after, .fad.fa-chain-broken:after, .fad.fa-chain-slash:after, .fad.fa-link-slash:after, .fad.fa-unlink:after {
  content: "";
}

.fa-duotone.fa-clone:after, .fad.fa-clone:after {
  content: "";
}

.fa-duotone.fa-person-walking-arrow-loop-left:after, .fad.fa-person-walking-arrow-loop-left:after {
  content: "";
}

.fa-duotone.fa-arrow-up-z-a:after, .fa-duotone.fa-sort-alpha-up-alt:after, .fad.fa-arrow-up-z-a:after, .fad.fa-sort-alpha-up-alt:after {
  content: "";
}

.fa-duotone.fa-fire-alt:after, .fa-duotone.fa-fire-flame-curved:after, .fad.fa-fire-alt:after, .fad.fa-fire-flame-curved:after {
  content: "";
}

.fa-duotone.fa-tornado:after, .fad.fa-tornado:after {
  content: "";
}

.fa-duotone.fa-file-circle-plus:after, .fad.fa-file-circle-plus:after {
  content: "";
}

.fa-duotone.fa-delete-right:after, .fad.fa-delete-right:after {
  content: "";
}

.fa-duotone.fa-book-quran:after, .fa-duotone.fa-quran:after, .fad.fa-book-quran:after, .fad.fa-quran:after {
  content: "";
}

.fa-duotone.fa-circle-quarter:after, .fad.fa-circle-quarter:after {
  content: "";
}

.fa-duotone.fa-anchor:after, .fad.fa-anchor:after {
  content: "";
}

.fa-duotone.fa-border-all:after, .fad.fa-border-all:after {
  content: "";
}

.fa-duotone.fa-function:after, .fad.fa-function:after {
  content: "";
}

.fa-duotone.fa-angry:after, .fa-duotone.fa-face-angry:after, .fad.fa-angry:after, .fad.fa-face-angry:after {
  content: "";
}

.fa-duotone.fa-people-simple:after, .fad.fa-people-simple:after {
  content: "";
}

.fa-duotone.fa-cookie-bite:after, .fad.fa-cookie-bite:after {
  content: "";
}

.fa-duotone.fa-arrow-trend-down:after, .fad.fa-arrow-trend-down:after {
  content: "";
}

.fa-duotone.fa-feed:after, .fa-duotone.fa-rss:after, .fad.fa-feed:after, .fad.fa-rss:after {
  content: "";
}

.fa-duotone.fa-face-monocle:after, .fad.fa-face-monocle:after {
  content: "";
}

.fa-duotone.fa-draw-polygon:after, .fad.fa-draw-polygon:after {
  content: "";
}

.fa-duotone.fa-balance-scale:after, .fa-duotone.fa-scale-balanced:after, .fad.fa-balance-scale:after, .fad.fa-scale-balanced:after {
  content: "";
}

.fa-duotone.fa-calendar-lines:after, .fa-duotone.fa-calendar-note:after, .fad.fa-calendar-lines:after, .fad.fa-calendar-note:after {
  content: "";
}

.fa-duotone.fa-arrow-down-big-small:after, .fa-duotone.fa-sort-size-down:after, .fad.fa-arrow-down-big-small:after, .fad.fa-sort-size-down:after {
  content: "";
}

.fa-duotone.fa-gauge-simple-high:after, .fa-duotone.fa-tachometer-fast:after, .fa-duotone.fa-tachometer:after, .fad.fa-gauge-simple-high:after, .fad.fa-tachometer-fast:after, .fad.fa-tachometer:after {
  content: "";
}

.fa-duotone.fa-do-not-enter:after, .fad.fa-do-not-enter:after {
  content: "";
}

.fa-duotone.fa-shower:after, .fad.fa-shower:after {
  content: "";
}

.fa-duotone.fa-dice-d8:after, .fad.fa-dice-d8:after {
  content: "";
}

.fa-duotone.fa-desktop-alt:after, .fa-duotone.fa-desktop:after, .fad.fa-desktop-alt:after, .fad.fa-desktop:after {
  content: "";
}

.fa-duotone.fa-m:after, .fad.fa-m:after {
  content: "MM";
}

.fa-duotone.fa-grip-dots-vertical:after, .fad.fa-grip-dots-vertical:after {
  content: "";
}

.fa-duotone.fa-face-viewfinder:after, .fad.fa-face-viewfinder:after {
  content: "";
}

.fa-duotone.fa-creemee:after, .fa-duotone.fa-soft-serve:after, .fad.fa-creemee:after, .fad.fa-soft-serve:after {
  content: "";
}

.fa-duotone.fa-h5:after, .fad.fa-h5:after {
  content: "";
}

.fa-duotone.fa-hand-back-point-down:after, .fad.fa-hand-back-point-down:after {
  content: "";
}

.fa-duotone.fa-table-list:after, .fa-duotone.fa-th-list:after, .fad.fa-table-list:after, .fad.fa-th-list:after {
  content: "";
}

.fa-duotone.fa-comment-sms:after, .fa-duotone.fa-sms:after, .fad.fa-comment-sms:after, .fad.fa-sms:after {
  content: "";
}

.fa-duotone.fa-rectangle-landscape:after, .fa-duotone.fa-rectangle:after, .fad.fa-rectangle-landscape:after, .fad.fa-rectangle:after {
  content: "";
}

.fa-duotone.fa-clipboard-list-check:after, .fad.fa-clipboard-list-check:after {
  content: "";
}

.fa-duotone.fa-turkey:after, .fad.fa-turkey:after {
  content: "";
}

.fa-duotone.fa-book:after, .fad.fa-book:after {
  content: "";
}

.fa-duotone.fa-user-plus:after, .fad.fa-user-plus:after {
  content: "";
}

.fa-duotone.fa-ice-skate:after, .fad.fa-ice-skate:after {
  content: "";
}

.fa-duotone.fa-check:after, .fad.fa-check:after {
  content: "";
}

.fa-duotone.fa-battery-4:after, .fa-duotone.fa-battery-three-quarters:after, .fad.fa-battery-4:after, .fad.fa-battery-three-quarters:after {
  content: "";
}

.fa-duotone.fa-tomato:after, .fad.fa-tomato:after {
  content: "";
}

.fa-duotone.fa-sword-laser:after, .fad.fa-sword-laser:after {
  content: "";
}

.fa-duotone.fa-house-circle-check:after, .fad.fa-house-circle-check:after {
  content: "";
}

.fa-duotone.fa-buildings:after, .fad.fa-buildings:after {
  content: "";
}

.fa-duotone.fa-angle-left:after, .fad.fa-angle-left:after {
  content: "";
}

.fa-duotone.fa-cart-flatbed-boxes:after, .fa-duotone.fa-dolly-flatbed-alt:after, .fad.fa-cart-flatbed-boxes:after, .fad.fa-dolly-flatbed-alt:after {
  content: "";
}

.fa-duotone.fa-diagram-successor:after, .fad.fa-diagram-successor:after {
  content: "";
}

.fa-duotone.fa-truck-arrow-right:after, .fad.fa-truck-arrow-right:after {
  content: "";
}

.fa-duotone.fa-square-w:after, .fad.fa-square-w:after {
  content: "";
}

.fa-duotone.fa-arrows-split-up-and-left:after, .fad.fa-arrows-split-up-and-left:after {
  content: "";
}

.fa-duotone.fa-lamp:after, .fad.fa-lamp:after {
  content: "";
}

.fa-duotone.fa-airplay:after, .fad.fa-airplay:after {
  content: "";
}

.fa-duotone.fa-fist-raised:after, .fa-duotone.fa-hand-fist:after, .fad.fa-fist-raised:after, .fad.fa-hand-fist:after {
  content: "";
}

.fa-duotone.fa-shield-quartered:after, .fad.fa-shield-quartered:after {
  content: "";
}

.fa-duotone.fa-slash-forward:after, .fad.fa-slash-forward:after {
  content: "//";
}

.fa-duotone.fa-location-pen:after, .fa-duotone.fa-map-marker-edit:after, .fad.fa-location-pen:after, .fad.fa-map-marker-edit:after {
  content: "";
}

.fa-duotone.fa-cloud-moon:after, .fad.fa-cloud-moon:after {
  content: "";
}

.fa-duotone.fa-pot-food:after, .fad.fa-pot-food:after {
  content: "";
}

.fa-duotone.fa-briefcase:after, .fad.fa-briefcase:after {
  content: "";
}

.fa-duotone.fa-person-falling:after, .fad.fa-person-falling:after {
  content: "";
}

.fa-duotone.fa-image-portrait:after, .fa-duotone.fa-portrait:after, .fad.fa-image-portrait:after, .fad.fa-portrait:after {
  content: "";
}

.fa-duotone.fa-user-tag:after, .fad.fa-user-tag:after {
  content: "";
}

.fa-duotone.fa-rug:after, .fad.fa-rug:after {
  content: "";
}

.fa-duotone.fa-print-slash:after, .fad.fa-print-slash:after {
  content: "";
}

.fa-duotone.fa-earth-europe:after, .fa-duotone.fa-globe-europe:after, .fad.fa-earth-europe:after, .fad.fa-globe-europe:after {
  content: "";
}

.fa-duotone.fa-cart-flatbed-suitcase:after, .fa-duotone.fa-luggage-cart:after, .fad.fa-cart-flatbed-suitcase:after, .fad.fa-luggage-cart:after {
  content: "";
}

.fa-duotone.fa-hand-back-point-ribbon:after, .fad.fa-hand-back-point-ribbon:after {
  content: "";
}

.fa-duotone.fa-rectangle-times:after, .fa-duotone.fa-rectangle-xmark:after, .fa-duotone.fa-times-rectangle:after, .fa-duotone.fa-window-close:after, .fad.fa-rectangle-times:after, .fad.fa-rectangle-xmark:after, .fad.fa-times-rectangle:after, .fad.fa-window-close:after {
  content: "";
}

.fa-duotone.fa-tire-rugged:after, .fad.fa-tire-rugged:after {
  content: "";
}

.fa-duotone.fa-lightbulb-dollar:after, .fad.fa-lightbulb-dollar:after {
  content: "";
}

.fa-duotone.fa-cowbell:after, .fad.fa-cowbell:after {
  content: "";
}

.fa-duotone.fa-baht-sign:after, .fad.fa-baht-sign:after {
  content: "";
}

.fa-duotone.fa-corner:after, .fad.fa-corner:after {
  content: "";
}

.fa-duotone.fa-chevron-double-right:after, .fa-duotone.fa-chevrons-right:after, .fad.fa-chevron-double-right:after, .fad.fa-chevrons-right:after {
  content: "";
}

.fa-duotone.fa-book-open:after, .fad.fa-book-open:after {
  content: "";
}

.fa-duotone.fa-book-journal-whills:after, .fa-duotone.fa-journal-whills:after, .fad.fa-book-journal-whills:after, .fad.fa-journal-whills:after {
  content: "";
}

.fa-duotone.fa-inhaler:after, .fad.fa-inhaler:after {
  content: "";
}

.fa-duotone.fa-handcuffs:after, .fad.fa-handcuffs:after {
  content: "";
}

.fa-duotone.fa-snake:after, .fad.fa-snake:after {
  content: "";
}

.fa-duotone.fa-exclamation-triangle:after, .fa-duotone.fa-triangle-exclamation:after, .fa-duotone.fa-warning:after, .fad.fa-exclamation-triangle:after, .fad.fa-triangle-exclamation:after, .fad.fa-warning:after {
  content: "";
}

.fa-duotone.fa-note-medical:after, .fad.fa-note-medical:after {
  content: "";
}

.fa-duotone.fa-database:after, .fad.fa-database:after {
  content: "";
}

.fa-duotone.fa-down-left:after, .fad.fa-down-left:after {
  content: "";
}

.fa-duotone.fa-arrow-turn-right:after, .fa-duotone.fa-mail-forward:after, .fa-duotone.fa-share:after, .fad.fa-arrow-turn-right:after, .fad.fa-mail-forward:after, .fad.fa-share:after {
  content: "";
}

.fa-duotone.fa-face-thinking:after, .fad.fa-face-thinking:after {
  content: "";
}

.fa-duotone.fa-turn-down-right:after, .fad.fa-turn-down-right:after {
  content: "";
}

.fa-duotone.fa-bottle-droplet:after, .fad.fa-bottle-droplet:after {
  content: "";
}

.fa-duotone.fa-mask-face:after, .fad.fa-mask-face:after {
  content: "";
}

.fa-duotone.fa-hill-rockslide:after, .fad.fa-hill-rockslide:after {
  content: "";
}

.fa-duotone.fa-scanner-keyboard:after, .fad.fa-scanner-keyboard:after {
  content: "";
}

.fa-duotone.fa-circle-o:after, .fad.fa-circle-o:after {
  content: "";
}

.fa-duotone.fa-grid-horizontal:after, .fad.fa-grid-horizontal:after {
  content: "";
}

.fa-duotone.fa-comment-alt-dollar:after, .fa-duotone.fa-message-dollar:after, .fad.fa-comment-alt-dollar:after, .fad.fa-message-dollar:after {
  content: "";
}

.fa-duotone.fa-exchange-alt:after, .fa-duotone.fa-right-left:after, .fad.fa-exchange-alt:after, .fad.fa-right-left:after {
  content: "";
}

.fa-duotone.fa-columns-3:after, .fad.fa-columns-3:after {
  content: "";
}

.fa-duotone.fa-paper-plane:after, .fad.fa-paper-plane:after {
  content: "";
}

.fa-duotone.fa-road-circle-exclamation:after, .fad.fa-road-circle-exclamation:after {
  content: "";
}

.fa-duotone.fa-dungeon:after, .fad.fa-dungeon:after {
  content: "";
}

.fa-duotone.fa-hand-holding-box:after, .fad.fa-hand-holding-box:after {
  content: "";
}

.fa-duotone.fa-input-text:after, .fad.fa-input-text:after {
  content: "";
}

.fa-duotone.fa-window-alt:after, .fa-duotone.fa-window-flip:after, .fad.fa-window-alt:after, .fad.fa-window-flip:after {
  content: "";
}

.fa-duotone.fa-align-right:after, .fad.fa-align-right:after {
  content: "";
}

.fa-duotone.fa-scanner-gun:after, .fa-duotone.fa-scanner:after, .fad.fa-scanner-gun:after, .fad.fa-scanner:after {
  content: "";
}

.fa-duotone.fa-tire:after, .fad.fa-tire:after {
  content: "";
}

.fa-duotone.fa-engine:after, .fad.fa-engine:after {
  content: "";
}

.fa-duotone.fa-money-bill-1-wave:after, .fa-duotone.fa-money-bill-wave-alt:after, .fad.fa-money-bill-1-wave:after, .fad.fa-money-bill-wave-alt:after {
  content: "";
}

.fa-duotone.fa-life-ring:after, .fad.fa-life-ring:after {
  content: "";
}

.fa-duotone.fa-hands:after, .fa-duotone.fa-sign-language:after, .fa-duotone.fa-signing:after, .fad.fa-hands:after, .fad.fa-sign-language:after, .fad.fa-signing:after {
  content: "";
}

.fa-duotone.fa-caret-circle-right:after, .fa-duotone.fa-circle-caret-right:after, .fad.fa-caret-circle-right:after, .fad.fa-circle-caret-right:after {
  content: "";
}

.fa-duotone.fa-wheat:after, .fad.fa-wheat:after {
  content: "";
}

.fa-duotone.fa-file-spreadsheet:after, .fad.fa-file-spreadsheet:after {
  content: "";
}

.fa-duotone.fa-audio-description-slash:after, .fad.fa-audio-description-slash:after {
  content: "";
}

.fa-duotone.fa-calendar-day:after, .fad.fa-calendar-day:after {
  content: "";
}

.fa-duotone.fa-ladder-water:after, .fa-duotone.fa-swimming-pool:after, .fa-duotone.fa-water-ladder:after, .fad.fa-ladder-water:after, .fad.fa-swimming-pool:after, .fad.fa-water-ladder:after {
  content: "";
}

.fa-duotone.fa-arrows-up-down:after, .fa-duotone.fa-arrows-v:after, .fad.fa-arrows-up-down:after, .fad.fa-arrows-v:after {
  content: "";
}

.fa-duotone.fa-chess-pawn-alt:after, .fa-duotone.fa-chess-pawn-piece:after, .fad.fa-chess-pawn-alt:after, .fad.fa-chess-pawn-piece:after {
  content: "";
}

.fa-duotone.fa-face-grimace:after, .fa-duotone.fa-grimace:after, .fad.fa-face-grimace:after, .fad.fa-grimace:after {
  content: "";
}

.fa-duotone.fa-wheelchair-alt:after, .fa-duotone.fa-wheelchair-move:after, .fad.fa-wheelchair-alt:after, .fad.fa-wheelchair-move:after {
  content: "";
}

.fa-duotone.fa-level-down-alt:after, .fa-duotone.fa-turn-down:after, .fad.fa-level-down-alt:after, .fad.fa-turn-down:after {
  content: "";
}

.fa-duotone.fa-square-s:after, .fad.fa-square-s:after {
  content: "";
}

.fa-duotone.fa-barcode-alt:after, .fa-duotone.fa-rectangle-barcode:after, .fad.fa-barcode-alt:after, .fad.fa-rectangle-barcode:after {
  content: "";
}

.fa-duotone.fa-person-walking-arrow-right:after, .fad.fa-person-walking-arrow-right:after {
  content: "";
}

.fa-duotone.fa-envelope-square:after, .fa-duotone.fa-square-envelope:after, .fad.fa-envelope-square:after, .fad.fa-square-envelope:after {
  content: "";
}

.fa-duotone.fa-dice:after, .fad.fa-dice:after {
  content: "";
}

.fa-duotone.fa-unicorn:after, .fad.fa-unicorn:after {
  content: "";
}

.fa-duotone.fa-bowling-ball:after, .fad.fa-bowling-ball:after {
  content: "";
}

.fa-duotone.fa-pompebled:after, .fad.fa-pompebled:after {
  content: "";
}

.fa-duotone.fa-brain:after, .fad.fa-brain:after {
  content: "";
}

.fa-duotone.fa-watch-smart:after, .fad.fa-watch-smart:after {
  content: "";
}

.fa-duotone.fa-book-user:after, .fad.fa-book-user:after {
  content: "";
}

.fa-duotone.fa-sensor-cloud:after, .fa-duotone.fa-sensor-smoke:after, .fad.fa-sensor-cloud:after, .fad.fa-sensor-smoke:after {
  content: "";
}

.fa-duotone.fa-clapperboard-play:after, .fad.fa-clapperboard-play:after {
  content: "";
}

.fa-duotone.fa-band-aid:after, .fa-duotone.fa-bandage:after, .fad.fa-band-aid:after, .fad.fa-bandage:after {
  content: "";
}

.fa-duotone.fa-calendar-minus:after, .fad.fa-calendar-minus:after {
  content: "";
}

.fa-duotone.fa-circle-xmark:after, .fa-duotone.fa-times-circle:after, .fa-duotone.fa-xmark-circle:after, .fad.fa-circle-xmark:after, .fad.fa-times-circle:after, .fad.fa-xmark-circle:after {
  content: "";
}

.fa-duotone.fa-circle-4:after, .fad.fa-circle-4:after {
  content: "";
}

.fa-duotone.fa-gifts:after, .fad.fa-gifts:after {
  content: "";
}

.fa-duotone.fa-album-collection:after, .fad.fa-album-collection:after {
  content: "";
}

.fa-duotone.fa-hotel:after, .fad.fa-hotel:after {
  content: "";
}

.fa-duotone.fa-earth-asia:after, .fa-duotone.fa-globe-asia:after, .fad.fa-earth-asia:after, .fad.fa-globe-asia:after {
  content: "";
}

.fa-duotone.fa-id-card-alt:after, .fa-duotone.fa-id-card-clip:after, .fad.fa-id-card-alt:after, .fad.fa-id-card-clip:after {
  content: "";
}

.fa-duotone.fa-magnifying-glass-plus:after, .fa-duotone.fa-search-plus:after, .fad.fa-magnifying-glass-plus:after, .fad.fa-search-plus:after {
  content: "";
}

.fa-duotone.fa-thumbs-up:after, .fad.fa-thumbs-up:after {
  content: "";
}

.fa-duotone.fa-cloud-showers:after, .fad.fa-cloud-showers:after {
  content: "";
}

.fa-duotone.fa-user-clock:after, .fad.fa-user-clock:after {
  content: "";
}

.fa-duotone.fa-onion:after, .fad.fa-onion:after {
  content: "";
}

.fa-duotone.fa-clock-twelve-thirty:after, .fad.fa-clock-twelve-thirty:after {
  content: "";
}

.fa-duotone.fa-arrow-down-to-dotted-line:after, .fad.fa-arrow-down-to-dotted-line:after {
  content: "";
}

.fa-duotone.fa-allergies:after, .fa-duotone.fa-hand-dots:after, .fad.fa-allergies:after, .fad.fa-hand-dots:after {
  content: "";
}

.fa-duotone.fa-file-invoice:after, .fad.fa-file-invoice:after {
  content: "";
}

.fa-duotone.fa-window-minimize:after, .fad.fa-window-minimize:after {
  content: "";
}

.fa-duotone.fa-rectangle-wide:after, .fad.fa-rectangle-wide:after {
  content: "";
}

.fa-duotone.fa-comment-arrow-up:after, .fad.fa-comment-arrow-up:after {
  content: "";
}

.fa-duotone.fa-garlic:after, .fad.fa-garlic:after {
  content: "";
}

.fa-duotone.fa-coffee:after, .fa-duotone.fa-mug-saucer:after, .fad.fa-coffee:after, .fad.fa-mug-saucer:after {
  content: "";
}

.fa-duotone.fa-brush:after, .fad.fa-brush:after {
  content: "";
}

.fa-duotone.fa-tree-decorated:after, .fad.fa-tree-decorated:after {
  content: "";
}

.fa-duotone.fa-mask:after, .fad.fa-mask:after {
  content: "";
}

.fa-duotone.fa-calendar-heart:after, .fad.fa-calendar-heart:after {
  content: "";
}

.fa-duotone.fa-magnifying-glass-minus:after, .fa-duotone.fa-search-minus:after, .fad.fa-magnifying-glass-minus:after, .fad.fa-search-minus:after {
  content: "";
}

.fa-duotone.fa-flower:after, .fad.fa-flower:after {
  content: "";
}

.fa-duotone.fa-arrow-down-from-arc:after, .fad.fa-arrow-down-from-arc:after {
  content: "";
}

.fa-duotone.fa-right-left-large:after, .fad.fa-right-left-large:after {
  content: "";
}

.fa-duotone.fa-ruler-vertical:after, .fad.fa-ruler-vertical:after {
  content: "";
}

.fa-duotone.fa-circles-overlap:after, .fad.fa-circles-overlap:after {
  content: "";
}

.fa-duotone.fa-user-alt:after, .fa-duotone.fa-user-large:after, .fad.fa-user-alt:after, .fad.fa-user-large:after {
  content: "";
}

.fa-duotone.fa-starship-freighter:after, .fad.fa-starship-freighter:after {
  content: "";
}

.fa-duotone.fa-train-tram:after, .fad.fa-train-tram:after {
  content: "";
}

.fa-duotone.fa-bridge-suspension:after, .fad.fa-bridge-suspension:after {
  content: "";
}

.fa-duotone.fa-trash-check:after, .fad.fa-trash-check:after {
  content: "";
}

.fa-duotone.fa-user-nurse:after, .fad.fa-user-nurse:after {
  content: "";
}

.fa-duotone.fa-boombox:after, .fad.fa-boombox:after {
  content: "";
}

.fa-duotone.fa-syringe:after, .fad.fa-syringe:after {
  content: "";
}

.fa-duotone.fa-cloud-sun:after, .fad.fa-cloud-sun:after {
  content: "";
}

.fa-duotone.fa-shield-exclamation:after, .fad.fa-shield-exclamation:after {
  content: "";
}

.fa-duotone.fa-stopwatch-20:after, .fad.fa-stopwatch-20:after {
  content: "";
}

.fa-duotone.fa-square-full:after, .fad.fa-square-full:after {
  content: "";
}

.fa-duotone.fa-grip-dots:after, .fad.fa-grip-dots:after {
  content: "";
}

.fa-duotone.fa-comment-exclamation:after, .fad.fa-comment-exclamation:after {
  content: "";
}

.fa-duotone.fa-pen-swirl:after, .fad.fa-pen-swirl:after {
  content: "";
}

.fa-duotone.fa-falafel:after, .fad.fa-falafel:after {
  content: "";
}

.fa-duotone.fa-circle-2:after, .fad.fa-circle-2:after {
  content: "";
}

.fa-duotone.fa-magnet:after, .fad.fa-magnet:after {
  content: "";
}

.fa-duotone.fa-jar:after, .fad.fa-jar:after {
  content: "";
}

.fa-duotone.fa-gramophone:after, .fad.fa-gramophone:after {
  content: "";
}

.fa-duotone.fa-dice-d12:after, .fad.fa-dice-d12:after {
  content: "";
}

.fa-duotone.fa-note-sticky:after, .fa-duotone.fa-sticky-note:after, .fad.fa-note-sticky:after, .fad.fa-sticky-note:after {
  content: "";
}

.fa-duotone.fa-arrow-alt-down:after, .fa-duotone.fa-down:after, .fad.fa-arrow-alt-down:after, .fad.fa-down:after {
  content: "";
}

.fa-duotone.fa-100:after, .fa-duotone.fa-hundred-points:after, .fad.fa-100:after, .fad.fa-hundred-points:after {
  content: "";
}

.fa-duotone.fa-paperclip-vertical:after, .fad.fa-paperclip-vertical:after {
  content: "";
}

.fa-duotone.fa-wind-circle-exclamation:after, .fa-duotone.fa-wind-warning:after, .fad.fa-wind-circle-exclamation:after, .fad.fa-wind-warning:after {
  content: "";
}

.fa-duotone.fa-location-pin-slash:after, .fa-duotone.fa-map-marker-slash:after, .fad.fa-location-pin-slash:after, .fad.fa-map-marker-slash:after {
  content: "";
}

.fa-duotone.fa-face-sad-sweat:after, .fad.fa-face-sad-sweat:after {
  content: "";
}

.fa-duotone.fa-bug-slash:after, .fad.fa-bug-slash:after {
  content: "";
}

.fa-duotone.fa-cupcake:after, .fad.fa-cupcake:after {
  content: "";
}

.fa-duotone.fa-light-switch-off:after, .fad.fa-light-switch-off:after {
  content: "";
}

.fa-duotone.fa-toggle-large-off:after, .fad.fa-toggle-large-off:after {
  content: "";
}

.fa-duotone.fa-pen-fancy-slash:after, .fad.fa-pen-fancy-slash:after {
  content: "";
}

.fa-duotone.fa-truck-container:after, .fad.fa-truck-container:after {
  content: "";
}

.fa-duotone.fa-boot:after, .fad.fa-boot:after {
  content: "";
}

.fa-duotone.fa-arrow-up-from-water-pump:after, .fad.fa-arrow-up-from-water-pump:after {
  content: "";
}

.fa-duotone.fa-file-check:after, .fad.fa-file-check:after {
  content: "";
}

.fa-duotone.fa-bone:after, .fad.fa-bone:after {
  content: "";
}

.fa-duotone.fa-cards-blank:after, .fad.fa-cards-blank:after {
  content: "";
}

.fa-duotone.fa-circle-3:after, .fad.fa-circle-3:after {
  content: "";
}

.fa-duotone.fa-bench-tree:after, .fad.fa-bench-tree:after {
  content: "";
}

.fa-duotone.fa-keyboard-brightness-low:after, .fad.fa-keyboard-brightness-low:after {
  content: "";
}

.fa-duotone.fa-ski-boot-ski:after, .fad.fa-ski-boot-ski:after {
  content: "";
}

.fa-duotone.fa-brain-circuit:after, .fad.fa-brain-circuit:after {
  content: "";
}

.fa-duotone.fa-user-injured:after, .fad.fa-user-injured:after {
  content: "";
}

.fa-duotone.fa-block-brick-fire:after, .fa-duotone.fa-firewall:after, .fad.fa-block-brick-fire:after, .fad.fa-firewall:after {
  content: "";
}

.fa-duotone.fa-face-sad-tear:after, .fa-duotone.fa-sad-tear:after, .fad.fa-face-sad-tear:after, .fad.fa-sad-tear:after {
  content: "";
}

.fa-duotone.fa-plane:after, .fad.fa-plane:after {
  content: "";
}

.fa-duotone.fa-tent-arrows-down:after, .fad.fa-tent-arrows-down:after {
  content: "";
}

.fa-duotone.fa-exclamation:after, .fad.fa-exclamation:after {
  content: "!!";
}

.fa-duotone.fa-arrows-spin:after, .fad.fa-arrows-spin:after {
  content: "";
}

.fa-duotone.fa-face-smile-relaxed:after, .fad.fa-face-smile-relaxed:after {
  content: "";
}

.fa-duotone.fa-comment-times:after, .fa-duotone.fa-comment-xmark:after, .fad.fa-comment-times:after, .fad.fa-comment-xmark:after {
  content: "";
}

.fa-duotone.fa-print:after, .fad.fa-print:after {
  content: "";
}

.fa-duotone.fa-try:after, .fa-duotone.fa-turkish-lira-sign:after, .fa-duotone.fa-turkish-lira:after, .fad.fa-try:after, .fad.fa-turkish-lira-sign:after, .fad.fa-turkish-lira:after {
  content: "";
}

.fa-duotone.fa-face-nose-steam:after, .fad.fa-face-nose-steam:after {
  content: "";
}

.fa-duotone.fa-circle-waveform-lines:after, .fa-duotone.fa-waveform-circle:after, .fad.fa-circle-waveform-lines:after, .fad.fa-waveform-circle:after {
  content: "";
}

.fa-duotone.fa-dollar-sign:after, .fa-duotone.fa-dollar:after, .fa-duotone.fa-usd:after, .fad.fa-dollar-sign:after, .fad.fa-dollar:after, .fad.fa-usd:after {
  content: "$$";
}

.fa-duotone.fa-ferris-wheel:after, .fad.fa-ferris-wheel:after {
  content: "";
}

.fa-duotone.fa-computer-speaker:after, .fad.fa-computer-speaker:after {
  content: "";
}

.fa-duotone.fa-skull-cow:after, .fad.fa-skull-cow:after {
  content: "";
}

.fa-duotone.fa-x:after, .fad.fa-x:after {
  content: "XX";
}

.fa-duotone.fa-magnifying-glass-dollar:after, .fa-duotone.fa-search-dollar:after, .fad.fa-magnifying-glass-dollar:after, .fad.fa-search-dollar:after {
  content: "";
}

.fa-duotone.fa-users-cog:after, .fa-duotone.fa-users-gear:after, .fad.fa-users-cog:after, .fad.fa-users-gear:after {
  content: "";
}

.fa-duotone.fa-person-military-pointing:after, .fad.fa-person-military-pointing:after {
  content: "";
}

.fa-duotone.fa-bank:after, .fa-duotone.fa-building-columns:after, .fa-duotone.fa-institution:after, .fa-duotone.fa-museum:after, .fa-duotone.fa-university:after, .fad.fa-bank:after, .fad.fa-building-columns:after, .fad.fa-institution:after, .fad.fa-museum:after, .fad.fa-university:after {
  content: "";
}

.fa-duotone.fa-circle-t:after, .fad.fa-circle-t:after {
  content: "";
}

.fa-duotone.fa-sack:after, .fad.fa-sack:after {
  content: "";
}

.fa-duotone.fa-grid-2:after, .fad.fa-grid-2:after {
  content: "";
}

.fa-duotone.fa-camera-cctv:after, .fa-duotone.fa-cctv:after, .fad.fa-camera-cctv:after, .fad.fa-cctv:after {
  content: "";
}

.fa-duotone.fa-umbrella:after, .fad.fa-umbrella:after {
  content: "";
}

.fa-duotone.fa-trowel:after, .fad.fa-trowel:after {
  content: "";
}

.fa-duotone.fa-horizontal-rule:after, .fad.fa-horizontal-rule:after {
  content: "";
}

.fa-duotone.fa-bed-alt:after, .fa-duotone.fa-bed-front:after, .fad.fa-bed-alt:after, .fad.fa-bed-front:after {
  content: "";
}

.fa-duotone.fa-d:after, .fad.fa-d:after {
  content: "DD";
}

.fa-duotone.fa-stapler:after, .fad.fa-stapler:after {
  content: "";
}

.fa-duotone.fa-masks-theater:after, .fa-duotone.fa-theater-masks:after, .fad.fa-masks-theater:after, .fad.fa-theater-masks:after {
  content: "";
}

.fa-duotone.fa-kip-sign:after, .fad.fa-kip-sign:after {
  content: "";
}

.fa-duotone.fa-face-woozy:after, .fad.fa-face-woozy:after {
  content: "";
}

.fa-duotone.fa-cloud-question:after, .fad.fa-cloud-question:after {
  content: "";
}

.fa-duotone.fa-pineapple:after, .fad.fa-pineapple:after {
  content: "";
}

.fa-duotone.fa-hand-point-left:after, .fad.fa-hand-point-left:after {
  content: "";
}

.fa-duotone.fa-gallery-thumbnails:after, .fad.fa-gallery-thumbnails:after {
  content: "";
}

.fa-duotone.fa-circle-j:after, .fad.fa-circle-j:after {
  content: "";
}

.fa-duotone.fa-eyes:after, .fad.fa-eyes:after {
  content: "";
}

.fa-duotone.fa-handshake-alt:after, .fa-duotone.fa-handshake-simple:after, .fad.fa-handshake-alt:after, .fad.fa-handshake-simple:after {
  content: "";
}

.fa-duotone.fa-file-caret-up:after, .fa-duotone.fa-page-caret-up:after, .fad.fa-file-caret-up:after, .fad.fa-page-caret-up:after {
  content: "";
}

.fa-duotone.fa-fighter-jet:after, .fa-duotone.fa-jet-fighter:after, .fad.fa-fighter-jet:after, .fad.fa-jet-fighter:after {
  content: "";
}

.fa-duotone.fa-comet:after, .fad.fa-comet:after {
  content: "";
}

.fa-duotone.fa-share-alt-square:after, .fa-duotone.fa-square-share-nodes:after, .fad.fa-share-alt-square:after, .fad.fa-square-share-nodes:after {
  content: "";
}

.fa-duotone.fa-shield-keyhole:after, .fad.fa-shield-keyhole:after {
  content: "";
}

.fa-duotone.fa-barcode:after, .fad.fa-barcode:after {
  content: "";
}

.fa-duotone.fa-plus-minus:after, .fad.fa-plus-minus:after {
  content: "";
}

.fa-duotone.fa-sliders-v-square:after, .fa-duotone.fa-square-sliders-vertical:after, .fad.fa-sliders-v-square:after, .fad.fa-square-sliders-vertical:after {
  content: "";
}

.fa-duotone.fa-video-camera:after, .fa-duotone.fa-video:after, .fad.fa-video-camera:after, .fad.fa-video:after {
  content: "";
}

.fa-duotone.fa-comment-middle-alt:after, .fa-duotone.fa-message-middle:after, .fad.fa-comment-middle-alt:after, .fad.fa-message-middle:after {
  content: "";
}

.fa-duotone.fa-graduation-cap:after, .fa-duotone.fa-mortar-board:after, .fad.fa-graduation-cap:after, .fad.fa-mortar-board:after {
  content: "";
}

.fa-duotone.fa-hand-holding-medical:after, .fad.fa-hand-holding-medical:after {
  content: "";
}

.fa-duotone.fa-person-circle-check:after, .fad.fa-person-circle-check:after {
  content: "";
}

.fa-duotone.fa-square-z:after, .fad.fa-square-z:after {
  content: "";
}

.fa-duotone.fa-comment-alt-text:after, .fa-duotone.fa-message-text:after, .fad.fa-comment-alt-text:after, .fad.fa-message-text:after {
  content: "";
}

.fa-duotone.fa-level-up-alt:after, .fa-duotone.fa-turn-up:after, .fad.fa-level-up-alt:after, .fad.fa-turn-up:after {
  content: "";
}

:host, :root {
  --fa-font-light: normal 300 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("fa-light-300.edf02048.woff2") format("woff2"), url("fa-light-300.742ec50c.ttf") format("truetype");
}

.fa-light, .fal {
  font-weight: 300;
}

:host, :root {
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.88e6c9dc.woff2") format("woff2"), url("fa-regular-400.85756c65.ttf") format("truetype");
}

.fa-regular, .far {
  font-weight: 400;
}

:host, :root {
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.0ffe2ae3.woff2") format("woff2"), url("fa-solid-900.3608e4e7.ttf") format("truetype");
}

.fa-solid, .fas {
  font-weight: 900;
}

:host, :root {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-thin: normal 100 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("fa-thin-100.d3e0968e.woff2") format("woff2"), url("fa-thin-100.bac1794e.ttf") format("truetype");
}

.fa-thin, .fat {
  font-weight: 100;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-display: block;
  font-weight: 400;
  src: url("fa-brands-400.ac3983a6.woff2") format("woff2"), url("fa-brands-400.de996382.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-display: block;
  font-weight: 900;
  src: url("fa-solid-900.0ffe2ae3.woff2") format("woff2"), url("fa-solid-900.3608e4e7.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-display: block;
  font-weight: 400;
  src: url("fa-regular-400.88e6c9dc.woff2") format("woff2"), url("fa-regular-400.85756c65.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-display: block;
  font-weight: 300;
  src: url("fa-light-300.edf02048.woff2") format("woff2"), url("fa-light-300.742ec50c.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Duotone";
  font-display: block;
  font-weight: 900;
  src: url("fa-duotone-900.995705c3.woff2") format("woff2"), url("fa-duotone-900.cfd0cff7.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-solid-900.0ffe2ae3.woff2") format("woff2"), url("fa-solid-900.3608e4e7.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-brands-400.ac3983a6.woff2") format("woff2"), url("fa-brands-400.de996382.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-regular-400.88e6c9dc.woff2") format("woff2"), url("fa-regular-400.85756c65.ttf") format("truetype");
  unicode-range: U+F003, U+F006, U+F014, U+F016-F017, U+F01A-F01B, U+F01D, U+F022, U+F03E, U+F044, U+F046, U+F05C-F05D, U+F06E, U+F070, U+F087-F088, U+F08A, U+F094, U+F096-F097, U+F09D, U+F0A0, U+F0A2, U+F0A4-F0A7, U+F0C5, U+F0C7, U+F0E5-F0E6, U+F0EB, U+F0F6-F0F8, U+F10C, U+F114-F115, U+F118-F11A, U+F11C-F11D, U+F133, U+F147, U+F14E, U+F150-F152, U+F185-F186, U+F18E, U+F190-F192, U+F196, U+F1C1-F1C9, U+F1D9, U+F1DB, U+F1E3, U+F1EA, U+F1F7, U+F1F9, U+F20A, U+F247-F248, U+F24A, U+F24D, U+F255-F25B, U+F25D, U+F271-F274, U+F278, U+F27B, U+F28C, U+F28E, U+F29C, U+F2B5, U+F2B7, U+F2BA, U+F2BC, U+F2BE, U+F2C0-F2C1, U+F2C3, U+F2D0, U+F2D2, U+F2D4, U+F2DC;
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-v4compatibility.c538e0d3.woff2") format("woff2"), url("fa-v4compatibility.cda60345.ttf") format("truetype");
  unicode-range: U+F041, U+F047, U+F065-F066, U+F07D-F07E, U+F080, U+F08B, U+F08E, U+F090, U+F09A, U+F0AC, U+F0AE, U+F0B2, U+F0D0, U+F0D6, U+F0E4, U+F0EC, U+F10A-F10B, U+F123, U+F13E, U+F148-F149, U+F14C, U+F156, U+F15E, U+F160-F161, U+F163, U+F175-F178, U+F195, U+F1F8, U+F219, U+F27A;
}
/*# sourceMappingURL=index.c9e3f9cf.css.map */
